import { DeserializedModel } from './deserialize'
import {
	ModelClass,
	AnyModel,
	ModelTypeInfo,
	getTypeInfo,
	getModelDataType,
	ObjectTypeInfoProps,
	TypeInfo,
	AnyStandardType,
	ArrayType,
	AnyType,
	ArrayTypeInfo,
	OrTypeInfo,
	LiteralTypeInfo,
	NumberTypeInfo,
	BooleanTypeInfo,
	StringTypeInfo,
	UncheckedTypeInfo,
	ObjectTypeInfo,
} from 'mobx-keystone'
import { mapValues } from 'lodash'

const ModelProps: { [key: string]: ObjectTypeInfoProps } = {}
export function getModelProps(modelClass: ModelClass<AnyModel>): ObjectTypeInfoProps {
	// Not known?
	const modelClassName = modelClass.toString().split(/#/)[1]

	if (!ModelProps[modelClassName]) {
		//@ts-ignore
		ModelProps[modelClassName] = getTypeInfo(getModelDataType(modelClass)).props
	}
	return ModelProps[modelClassName]
}

export function castModels<T extends AnyModel>(records: DeserializedModel[], modelClass: ModelClass<T>): T[] {
	// Go through records
	const models = records.map(record => {
		// Cast it to model
		return castAsModel<T>(record, modelClass)
	})

	return models
}
export function castAsModel<T extends AnyModel>(data: Partial<DeserializedModel>, modelClass: ModelClass<T>): T {
	// Get props
	const props = getModelProps(modelClass)

	// Cast all the attributes
	const attributes = mapValues(props, (prop, key) => {
		// Do we have a value?
		const value = data[key]
		if (value === undefined || value === null) return value

		// Get the type
		return castValue(value, prop.typeInfo)
	})

	// From snapshot.
	return new modelClass(attributes)
}

export function castValue(value: any, typeInfo: TypeInfo): any {
	// Get type name
	const type = typeof typeInfo.thisType === 'function' ? typeInfo.thisType() : typeInfo.thisType
	let name = type.getTypeName()

	// Start with type itself
	let info = typeInfo

	// An or type?
	let maybeNull = false
	let maybeUndefined = false
	if (info instanceof OrTypeInfo) {
		// Filter out maybe-types
		const possibleInfos = info.orTypeInfos.filter(info => {
			// Null? Undefined
			if (info instanceof LiteralTypeInfo) {
				if (info.literal === undefined) {
					maybeUndefined = true
					return false
				}
				if (info.literal === null) {
					maybeNull = true
					return false
				}
			}
			return true
		})

		// Multiple types
		if (possibleInfos.length > 1) {
			// An enum (literals only)?
			const literalInfos = possibleInfos.filter(info => info instanceof LiteralTypeInfo)
			if (literalInfos.length === possibleInfos.length && !!value) return value

			console.warn('What to do with multiple types?', name)
		}

		// Use the first one
		info = possibleInfos[0]
	}

	// Maybe's?
	if (maybeUndefined && value === undefined) return undefined
	if ((maybeNull && value === null) || value === undefined) return null
	if (maybeUndefined && value === null) return undefined

	// Array?
	if (info instanceof ArrayTypeInfo) {
		// Get the sub-type
		const subType = info.itemTypeInfo

		// Map the value
		if (!Array.isArray(value)) return []
		return value.map(v => castValue(v, subType))
	}

	// Model?
	if (info instanceof ModelTypeInfo) {
		return castAsModel(value, info.modelClass)
	}

	// Primitives
	if (info instanceof StringTypeInfo) return typeof value === 'string' ? value : value.toString()

	if (info instanceof NumberTypeInfo) return typeof value === 'number' ? value : parseFloat(value)
	if (info instanceof BooleanTypeInfo) {
		if (typeof value === 'boolean') value
		if (typeof value === 'number') return value === 1
		if (typeof value === 'string') return /^(1|true)/i.test(value)
		return !!value
	}

	// Object?
	if (info instanceof ObjectTypeInfo) {
		return value
	}

	// Any?
	if (info instanceof UncheckedTypeInfo) return value

	// Failed.
	throw `Casting not implemented: ${name}`
}
