import * as React from 'react'
import { makeStyles, createStyles, Theme, Table, AppBar, Toolbar, Snackbar } from '@material-ui/core'

import { get } from 'lodash'
import TranslationEditorRow from './TranslationEditorRow'
import { LanguageFlags, LanguageNames } from '~core/enums/language'
import Button from '~components/buttons/Button'
import { getErrorMessage } from '~api/helpers'
import { Alert } from '@material-ui/lab'

// ███████╗████████╗██╗   ██╗██╗     ███████╗███████╗
// ██╔════╝╚══██╔══╝╚██╗ ██╔╝██║     ██╔════╝██╔════╝
// ███████╗   ██║    ╚████╔╝ ██║     █████╗  ███████╗
// ╚════██║   ██║     ╚██╔╝  ██║     ██╔══╝  ╚════██║
// ███████║   ██║      ██║   ███████╗███████╗███████║
// ╚══════╝   ╚═╝      ╚═╝   ╚══════╝╚══════╝╚══════╝
//

const styles = ({ palette, spacing }: Theme) =>
	createStyles({
		table: {
			'& thead': {
				background: palette.secondary.main,
				'& th': {
					padding: spacing(1),
					textAlign: 'left',
					'& img': {}
				}
			}
		},
		bottomBar: {
			alignItems: 'flex-end',
			top: 'auto',
			bottom: 0
		}
	})
const useStyles = makeStyles(styles)

//  ██████╗ ██████╗ ███╗   ███╗██████╗  ██████╗ ███╗   ██╗███████╗███╗   ██╗████████╗
// ██╔════╝██╔═══██╗████╗ ████║██╔══██╗██╔═══██╗████╗  ██║██╔════╝████╗  ██║╚══██╔══╝
// ██║     ██║   ██║██╔████╔██║██████╔╝██║   ██║██╔██╗ ██║█████╗  ██╔██╗ ██║   ██║
// ██║     ██║   ██║██║╚██╔╝██║██╔═══╝ ██║   ██║██║╚██╗██║██╔══╝  ██║╚██╗██║   ██║
// ╚██████╗╚██████╔╝██║ ╚═╝ ██║██║     ╚██████╔╝██║ ╚████║███████╗██║ ╚████║   ██║
//  ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝      ╚═════╝ ╚═╝  ╚═══╝╚══════╝╚═╝  ╚═══╝   ╚═╝
//

export interface ITranslationEditorProps {
	data: { [key: string]: any }
	onSubmit: (dirtyNodes: TranslationEditorNode[]) => Promise<boolean>
}

export type TranslationEditorNode = {
	path: string[]
	children?: TranslationEditorNode[]
	translations?: { [lang: string]: string }
}

const getNodes = (
	cur: any,
	parentNode: TranslationEditorNode | null = null,
	all: { [lang: string]: any }
): TranslationEditorNode[] => {
	// Go through keys
	return Object.keys(cur).map(key => {
		// Create node
		const node: TranslationEditorNode = {
			path: parentNode ? [...parentNode.path, key] : [key]
		}

		// Get value
		const value = cur[key]
		if (typeof value === 'object') {
			// Get children
			node.children = getNodes(value, node, all)
		} else {
			// Get translations
			node.translations = {}
			Object.keys(all).map(lang => {
				node.translations![lang] = get(all[lang], node.path.join('.'))
			})
		}

		return node
	})
}

const TranslationEditor = ({ data, onSubmit }: ITranslationEditorProps) => {
	// Styling
	const classes = useStyles()

	// State
	const [nodes, setNodes] = React.useState<TranslationEditorNode[]>([])
	const [dirtyNodes, setDirtyNodes] = React.useState<TranslationEditorNode[]>([])
	const [isSaving, setIsSaving] = React.useState<boolean>(false)
	const [saveError, setSaveError] = React.useState<string | null>(null)
	const [saveResultMessage, setSaveResultMessage] = React.useState<string | null>(null)

	React.useEffect(() => {
		// Use first language as basis
		const nl = data[Object.keys(data)[0]]
		setNodes(getNodes(nl, null, data))
	}, [data])

	const handleEdit = React.useCallback(
		(node: TranslationEditorNode, translations: { [lang: string]: string }) => {
			// Set value
			node.translations = translations

			// Add to dirty
			setDirtyNodes([...dirtyNodes, node])
		},
		[dirtyNodes]
	)

	/**
	 * SAVE
	 */
	const saveChanges = React.useCallback(async () => {
		setIsSaving(true)
		setSaveError(null)
		try {
			await onSubmit(dirtyNodes)
			setDirtyNodes([])
			setSaveResultMessage('De wijzigingen zijn opgeslagen')
		} catch (error) {
			setSaveError(getErrorMessage(error))
		}
		setIsSaving(false)
	}, [dirtyNodes, onSubmit, isSaving])

	return (
		<React.Fragment>
			<Table className={classes.table}>
				<thead>
					<tr>
						<th></th>
						{Object.keys(data).map(lang => (
							<th key={lang}>{LanguageNames[lang] || lang}</th>
						))}
					</tr>
				</thead>
				<tbody>
					{nodes.map(node => (
						<TranslationEditorRow onEdit={handleEdit} node={node} key={node.path.join('.')} />
					))}
				</tbody>
			</Table>

			{/* Result */}
			{saveResultMessage && (
				<Snackbar
					anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
					open={!!saveResultMessage}
					autoHideDuration={3000}
					onClose={() => setSaveResultMessage(null)}>
					<Alert severity={'success'}>{saveResultMessage}</Alert>
				</Snackbar>
			)}

			{dirtyNodes.length > 0 && (
				<AppBar position='fixed' color='secondary' className={classes.bottomBar}>
					<Toolbar>
						{saveError && <Alert severity='error'>{saveError}</Alert>}
						<Button loading={isSaving} color='primary' onClick={() => saveChanges()}>
							{dirtyNodes.length === 1 ? 'Één wijziging' : `${dirtyNodes.length} wijzigingen`} opslaan
						</Button>
					</Toolbar>
				</AppBar>
			)}
		</React.Fragment>
	)
}
export default TranslationEditor
