import * as React from 'react'
import styled from 'styled-components'

import Select from './Select'
import { map } from 'lodash'
import SelectOption from './SelectOption'
import { Image } from '~core/enums/images'

// ███████╗████████╗██╗   ██╗██╗     ███████╗██████╗
// ██╔════╝╚══██╔══╝╚██╗ ██╔╝██║     ██╔════╝██╔══██╗
// ███████╗   ██║    ╚████╔╝ ██║     █████╗  ██║  ██║
// ╚════██║   ██║     ╚██╔╝  ██║     ██╔══╝  ██║  ██║
// ███████║   ██║      ██║   ███████╗███████╗██████╔╝
// ╚══════╝   ╚═╝      ╚═╝   ╚══════╝╚══════╝╚═════╝
//

//  ██████╗ ██████╗ ███╗   ███╗██████╗  ██████╗ ███╗   ██╗███████╗███╗   ██╗████████╗
// ██╔════╝██╔═══██╗████╗ ████║██╔══██╗██╔═══██╗████╗  ██║██╔════╝████╗  ██║╚══██╔══╝
// ██║     ██║   ██║██╔████╔██║██████╔╝██║   ██║██╔██╗ ██║█████╗  ██╔██╗ ██║   ██║
// ██║     ██║   ██║██║╚██╔╝██║██╔═══╝ ██║   ██║██║╚██╗██║██╔══╝  ██║╚██╗██║   ██║
// ╚██████╗╚██████╔╝██║ ╚═╝ ██║██║     ╚██████╔╝██║ ╚████║███████╗██║ ╚████║   ██║
//  ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝      ╚═════╝ ╚═╝  ╚═══╝╚══════╝╚═╝  ╚═══╝   ╚═╝
//
export interface IImageSelectProps {
	value: Image | undefined
	onChange: (value: Image) => void
	options: { [key: string]: Image[] }
}

const ImageSelect = (props: IImageSelectProps) => {
	const { value, onChange, options } = props

	const imageOptions = React.useMemo(() => {
		return map(options, (icons, key) => ({
			label: key,
			options: icons.map(icon => ({
				...icon
			}))
		}))
	}, [options])
	return (
		<Select
			onChange={v => v && onChange(v)}
			value={value}
			options={imageOptions}
			renderOption={option => <SelectOption title={option.data.title} imageBase64={option.data.base64} />}
		/>
	)
}
export default ImageSelect
