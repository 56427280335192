import * as React from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react'
import { uniq } from 'lodash'
import { EditableProperty, Layer } from '~keolis-reizen-map-element/index'
import { FormControlLabel, Checkbox, Input, Slider, Mark } from '@material-ui/core'
import Button from '~components/buttons/Button'
import ColorPicker from '~components/forms/ColorPicker'
import Icon from '~components/gui/Icon'

// ███████╗████████╗██╗   ██╗██╗     ███████╗██████╗
// ██╔════╝╚══██╔══╝╚██╗ ██╔╝██║     ██╔════╝██╔══██╗
// ███████╗   ██║    ╚████╔╝ ██║     █████╗  ██║  ██║
// ╚════██║   ██║     ╚██╔╝  ██║     ██╔══╝  ██║  ██║
// ███████║   ██║      ██║   ███████╗███████╗██████╔╝
// ╚══════╝   ╚═╝      ╚═╝   ╚══════╝╚══════╝╚═════╝
//

const Container = styled.div`
	display: block;
	padding: 8px 12px;

	&:nth-child(2n) {
		background: #ffffff11;
	}
	input,
	textarea {
		width: 100%;
	}
`

const ButtonContainer = styled.div`
	display: inline-block;
	padding: 8px 0;
	margin: 0 8px 0 12px;
`
const ButtonContainerSecond = styled(ButtonContainer)`
	margin-left: 0;
`

const Label = styled.label`
	display: block;
	font-weight: bold;
	color: #999;
	margin-bottom: 6px;
`

const Rotations: Mark[] = [
	{ value: 0, label: 'Oost' },
	{ value: 90, label: 'Zuid' },
	{ value: 180, label: 'West' },
	{ value: 270, label: 'Noord' },
]

const FreeRotations: Mark[] = [
	{ value: 0, label: '0°' },
	{ value: 90, label: '90°' },
	{ value: 180, label: '180°' },
	{ value: 270, label: '270°' },
	{ value: 360, label: '360°' },
]

//  ██████╗ ██████╗ ███╗   ███╗██████╗  ██████╗ ███╗   ██╗███████╗███╗   ██╗████████╗
// ██╔════╝██╔═══██╗████╗ ████║██╔══██╗██╔═══██╗████╗  ██║██╔════╝████╗  ██║╚══██╔══╝
// ██║     ██║   ██║██╔████╔██║██████╔╝██║   ██║██╔██╗ ██║█████╗  ██╔██╗ ██║   ██║
// ██║     ██║   ██║██║╚██╔╝██║██╔═══╝ ██║   ██║██║╚██╗██║██╔══╝  ██║╚██╗██║   ██║
// ╚██████╗╚██████╔╝██║ ╚═╝ ██║██║     ╚██████╔╝██║ ╚████║███████╗██║ ╚████║   ██║
//  ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝      ╚═════╝ ╚═╝  ╚═══╝╚══════╝╚═╝  ╚═══╝   ╚═╝
//
export interface ILayerPropertyProps {
	property: EditableProperty
	isEditing: boolean
	isSplitting: boolean
	layers: Layer[]
}

const LayerProperty = (props: ILayerPropertyProps) => {
	const { property, isEditing, isSplitting, layers } = props

	const getProperty = React.useCallback(() => {
		// Getter?
		const prop = property.getter || property.name
		const values = uniq(layers.map(l => l[prop]))
		if (values.length === 1) return values[0]

		return undefined
	}, [property, layers])

	const setProperty = React.useCallback(
		value => {
			// Setter?
			if (property.setter) {
				layers.forEach(layer => layer[property.setter](value))
			} else {
				layers.forEach(layer => layer.setProperty(property.name, value))
			}
		},
		[property, layers]
	)

	// Toggle?
	if (property.type === 'boolean') {
		return (
			<Container>
				<FormControlLabel
					control={
						<Checkbox
							indeterminate={getProperty() === undefined}
							checked={getProperty() || false}
							onChange={e => setProperty(e.target.checked)}
							color='primary'
						/>
					}
					label={property.label}
				/>
			</Container>
		)
	}

	// Edit?
	if (property.type === 'editPolyline') {
		return (
			<ButtonContainer>
				{getProperty() ? (
					<Button
						color={'secondary'}
						fullWidth
						onClick={() => setProperty(false)}
						startIcon={<Icon name={'check-circle'} />}>
						Klaar
					</Button>
				) : (
					<Button
						color={'secondary'}
						disabled={isSplitting}
						fullWidth
						onClick={() => setProperty(true)}
						startIcon={<Icon name={'pen'} />}>
						Wijzig lijn
					</Button>
				)}
			</ButtonContainer>
		)
	}

	// Split?
	if (property.type === 'splitPolyline') {
		return (
			<ButtonContainerSecond>
				<Button
					color={getProperty() ? 'primary' : 'secondary'}
					fullWidth
					disabled={isEditing}
					onClick={() => setProperty(!getProperty())}
					startIcon={<Icon name={getProperty() ? 'times-circle' : 'cut'} />}>
					Knippen
				</Button>
			</ButtonContainerSecond>
		)
	}

	const renderInput = () => {
		switch (property.type) {
			case 'color':
				return <ColorPicker value={getProperty() || ''} allowTransparent onChange={c => setProperty(c)} />

			case 'fontSize':
				return <Slider value={getProperty() || 13} min={8} max={80} step={1} onChange={(e, v) => setProperty(v)} />
			case 'size':
				return <Slider value={getProperty() || 48} min={8} max={320} step={1} onChange={(e, v) => setProperty(v)} />

			case 'rotation':
				return (
					<Slider
						style={{ marginBottom: 16 }}
						value={getProperty() || 0}
						min={-10}
						max={370}
						step={5}
						marks={FreeRotations}
						onChange={(e, v) => setProperty(v)}
					/>
				)

			case 'labelRotation':
				return (
					<Slider
						style={{ marginBottom: 16 }}
						value={getProperty() || 0}
						min={-45}
						max={315}
						// dots
						marks={Rotations}
						step={45}
						onChange={(e, v) => setProperty(v)}
					/>
				)

			case 'number':
				return (
					<Input
						fullWidth
						type={'number'}
						value={getProperty() || ''}
						onChange={e => setProperty(parseFloat(e.target.value))}
					/>
				)
			case 'text':
				return <Input fullWidth multiline value={getProperty() || ''} onChange={e => setProperty(e.target.value)} />
			default:
				return <Input fullWidth value={getProperty() || ''} onChange={e => setProperty(e.target.value)} />
		}
	}

	return (
		<Container>
			<Label>{property.label}</Label>
			{renderInput()}
		</Container>
	)
}
export default observer(LayerProperty)
