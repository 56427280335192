import * as React from 'react'
import { ModelClass, AnyModel, Draft, draft as makeDraft } from 'mobx-keystone'
import { ApiGetOptions } from '~api/types'
import usePrevious from '~hooks/usePrevious'
import useStore from '~store/hooks/useStore'
import { render } from 'react-dom'
import PageHeader, { IPageHeaderConfigProps } from '~components/layout/pages/PageHeader'
import Button from '~components/buttons/Button'
import { Skeleton, Alert } from '@material-ui/lab'
import { Paper, createStyles, Theme, makeStyles, Container, Snackbar, Dialog, Grid } from '@material-ui/core'
import Field from './Field'
import { validateModel, ValidationError } from '~api/model/validateModel'
import { ValidationProvider } from './ValidationContext'

import { observe } from 'mobx'
import { useDebouncedCallback } from 'use-debounce'
import { SaveModelOptions, isNewModel } from '~api/model/saveModel'
import { getErrorMessage } from '~api/helpers'
import ConfirmDialog from '~components/dialogs/ConfirmDialog'
import { deleteModel } from '~api/model/deleteModel'
import { createFormModel } from '~api/model/createFormModel'
import { ModelFormContext, ModelFormProvider } from './ModelFormContext'
import { GuiStoreNotificationSeverity } from '~store/stores/GuiStore'
import { useHistory, Prompt } from 'react-router-dom'
import { deepObserve } from 'mobx-utils'
import { useDraftIsDirty } from '~hooks/useDraftIsDirty'

// ███████╗████████╗██╗   ██╗██╗     ███████╗███████╗
// ██╔════╝╚══██╔══╝╚██╗ ██╔╝██║     ██╔════╝██╔════╝
// ███████╗   ██║    ╚████╔╝ ██║     █████╗  ███████╗
// ╚════██║   ██║     ╚██╔╝  ██║     ██╔══╝  ╚════██║
// ███████║   ██║      ██║   ███████╗███████╗███████║
// ╚══════╝   ╚═╝      ╚═╝   ╚══════╝╚══════╝╚══════╝
//

const styles = (theme: Theme) =>
	createStyles({
		paper: {
			//marginTop: theme.spacing(2),
			marginBottom: theme.spacing(3),
			padding: theme.spacing(3),
		},
	})
const useStyles = makeStyles(styles)

// ██████╗ ██████╗  ██████╗ ██████╗ ███████╗
// ██╔══██╗██╔══██╗██╔═══██╗██╔══██╗██╔════╝
// ██████╔╝██████╔╝██║   ██║██████╔╝███████╗
// ██╔═══╝ ██╔══██╗██║   ██║██╔═══╝ ╚════██║
// ██║     ██║  ██║╚██████╔╝██║     ███████║
// ╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚═╝     ╚══════╝
//

export interface IModelFormRenderProps<T extends AnyModel> {
	draft: Draft<T>
	record: T
	newModel: boolean
	actionButtons: React.ReactElement
	resources?: any
}

export interface IModelFormProps<T extends AnyModel> {
	// Record
	model: ModelClass<T>

	modelId?: number | string
	apiOptions?: ApiGetOptions

	newModel?: boolean
	skipValidation?: boolean
	validateOnChange?: boolean | 'auto'
	saveOptions?: SaveModelOptions
	allowDelete?: boolean
	confirmLeave?: boolean

	// Events
	onDraft?: (draft: Draft<T>) => any
	onLoad?: (model: T) => any
	onCreated?: (record: T) => any
	onSaved?: (record: T) => any
	onUpdated?: (record: T) => any
	onDeleted?: (record: T) => any

	confirmSaveMessage?: (record: T) => string | undefined | false | null
	confirmLeaveMessage?: (record: T) => string

	// Rendering
	render: React.ComponentType<IModelFormRenderProps<T>> | React.ComponentType<IModelFormRenderProps<T>>
	renderConfirmDelete?: React.ComponentType<IModelFormRenderProps<T>> | React.ComponentType<IModelFormRenderProps<T>>
	skeleton?: React.ReactElement
	paper?: boolean
	container?: boolean

	// Resources
	loadResources?: (record: T) => Promise<any>

	// Header
	title?: string
	header?: IPageHeaderConfigProps
	renderTitle?: (props: IModelFormRenderProps<T>) => string
	hideHeader?: boolean
}

function ModelForm<T extends AnyModel>(props: IModelFormProps<T>) {
	// ██╗   ██╗ █████╗ ██████╗ ██╗ █████╗ ██████╗ ██╗     ███████╗███████╗
	// ██║   ██║██╔══██╗██╔══██╗██║██╔══██╗██╔══██╗██║     ██╔════╝██╔════╝
	// ██║   ██║███████║██████╔╝██║███████║██████╔╝██║     █████╗  ███████╗
	// ╚██╗ ██╔╝██╔══██║██╔══██╗██║██╔══██║██╔══██╗██║     ██╔══╝  ╚════██║
	//  ╚████╔╝ ██║  ██║██║  ██║██║██║  ██║██████╔╝███████╗███████╗███████║
	//   ╚═══╝  ╚═╝  ╚═╝╚═╝  ╚═╝╚═╝╚═╝  ╚═╝╚═════╝ ╚══════╝╚══════╝╚══════╝
	//

	// Destructure props
	const {
		model,
		modelId,
		newModel,
		apiOptions = {},
		hideHeader,
		title,
		header,
		container,
		onDraft,
		onLoad,
		onCreated,
		onUpdated,
		onSaved,
		onDeleted,
		paper,
		render,
		renderConfirmDelete,
		confirmSaveMessage,
		confirmLeaveMessage,
		confirmLeave = true,
		allowDelete = true,
		renderTitle,
		saveOptions,
		skipValidation,
		loadResources,
		validateOnChange = 'auto',

		skeleton,
	} = props

	// Store
	const { api, gui } = useStore()

	// Styling
	const classes = useStyles()

	// State
	const [isLoading, setIsLoading] = React.useState<boolean>(false)
	const [isSaving, setIsSaving] = React.useState<boolean>(false)
	const [hasSubmitted, setHasSubmitted] = React.useState<boolean>(false)

	const [resources, setResources] = React.useState<any>()

	const [validationErrors, setValidationErrors] = React.useState<ValidationError[]>([])
	const [saveError, setSaveError] = React.useState<string | null>(null)
	const [showConfirmDelete, setShowConfirmDelete] = React.useState<boolean>(false)

	const [showConfirmSave, setShowConfirmSave] = React.useState<boolean>(false)
	const [showConfirmSaveMessage, setShowConfirmSaveMessage] = React.useState<string | undefined>()

	const [record, setRecord] = React.useState<T | undefined>()
	const [draft, setDraft] = React.useState<Draft<T> | undefined>()

	const [isDirty, setIsDirty] = React.useState<boolean>(false)

	// ██████╗ ███████╗██╗  ██╗ █████╗ ██╗   ██╗██╗ ██████╗ ██████╗
	// ██╔══██╗██╔════╝██║  ██║██╔══██╗██║   ██║██║██╔═══██╗██╔══██╗
	// ██████╔╝█████╗  ███████║███████║██║   ██║██║██║   ██║██████╔╝
	// ██╔══██╗██╔══╝  ██╔══██║██╔══██║╚██╗ ██╔╝██║██║   ██║██╔══██╗
	// ██████╔╝███████╗██║  ██║██║  ██║ ╚████╔╝ ██║╚██████╔╝██║  ██║
	// ╚═════╝ ╚══════╝╚═╝  ╚═╝╚═╝  ╚═╝  ╚═══╝  ╚═╝ ╚═════╝ ╚═╝  ╚═╝
	//

	/**
	 * LOAD MODEL
	 */
	const loadModel = React.useCallback(async () => {
		// Set loading
		setIsLoading(true)
		setHasSubmitted(false)

		// New?
		let record
		if (newModel) {
			// Create new model
			record = createFormModel<T>(model)
		} else {
			// Load it
			if (!modelId) throw `You need to set either "newModel" or "modelId"`
			record = await api.one(model, modelId, apiOptions)

			// Pass it
			if (onLoad) onLoad(record)
		}

		// Load resources
		if (loadResources) {
			setResources(await loadResources(record))
		}

		// Create draft
		const draft = makeDraft(record)
		setDraft(draft)
		setRecord(record)

		// Draft it.
		if (onDraft) onDraft(draft)

		// Done.
		setIsLoading(false)
	}, [model, modelId, newModel, apiOptions, loadResources, onLoad, onDraft])

	/**
	 * EFFECT: CHANGE MODEL PROPS
	 */
	const previousModelData = usePrevious({ model, modelId, newModel, options: JSON.stringify(apiOptions) })

	React.useEffect(() => {
		// Changed?
		if (
			previousModelData &&
			previousModelData.model === model &&
			previousModelData.modelId === modelId &&
			previousModelData.newModel === newModel &&
			previousModelData.options === JSON.stringify(apiOptions)
		)
			return

		// Load
		loadModel()
	}, [model, modelId, newModel, apiOptions])

	/**
	 * AUTO-VALIDATE
	 */
	const [autoValidate] = useDebouncedCallback(async () => {
		// Do we want/need to validate?
		if (!isSaving && draft && ((hasSubmitted && validateOnChange === 'auto') || validateOnChange === true)) {
			// Validate now
			const result = await validateModel(draft)
			setValidationErrors(result === true ? [] : result)
		}
	}, 250)

	/**
	 * EFFECT: Watch the draft
	 */
	React.useEffect(() => {
		const revoke =
			draft &&
			draft.data &&
			deepObserve(draft.data.$, (change, path) => {
				autoValidate()
				setIsDirty(draft.isDirty)
			})

		return () => {
			if (revoke) revoke()
		}
	}, [draft, setIsDirty])

	const draftIsDirty = useDraftIsDirty(draft)

	//  █████╗  ██████╗████████╗██╗ ██████╗ ███╗   ██╗███████╗
	// ██╔══██╗██╔════╝╚══██╔══╝██║██╔═══██╗████╗  ██║██╔════╝
	// ███████║██║        ██║   ██║██║   ██║██╔██╗ ██║███████╗
	// ██╔══██║██║        ██║   ██║██║   ██║██║╚██╗██║╚════██║
	// ██║  ██║╚██████╗   ██║   ██║╚██████╔╝██║ ╚████║███████║
	// ╚═╝  ╚═╝ ╚═════╝   ╚═╝   ╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝
	//

	const submit = React.useCallback(
		async (confirm: boolean = false) => {
			// No model yet?
			if (!draft) return

			// Confirm?
			const confirmMessage = confirmSaveMessage && confirmSaveMessage(draft.data)
			if (confirmMessage && !confirm) {
				setShowConfirmSaveMessage(confirmMessage)
				setShowConfirmSave(true)
				return
			}

			// State.
			setSaveError(null)
			setHasSubmitted(true)
			setIsSaving(true)

			// Validate it first
			if (!skipValidation) {
				const result = await validateModel(draft)
				if (result !== true) {
					setValidationErrors(result)
					setIsSaving(false)
					return
				}
			}
			setValidationErrors([])

			// Was new?
			const wasNew = isNewModel(draft.data)

			try {
				// Save it now
				await api.saveModel(draft, saveOptions)

				// Events
				if (onCreated && wasNew) onCreated(draft.originalData)
				if (onUpdated && !wasNew) onUpdated(draft.originalData)
				if (onSaved) onSaved(draft.originalData)

				// Done
				gui.showNotification({
					message: wasNew ? 'Het record is aangemaakt' : 'Het record is gewijzigd',
					severity: GuiStoreNotificationSeverity.success,
				})
			} catch (error) {
				setSaveError(getErrorMessage(error))
			}

			// Done.
			setIsSaving(false)
		},
		[isNewModel, draft, onCreated, onSaved, onUpdated, confirmSaveMessage]
	)

	/**
	 * DELETE
	 */
	const deleteRecord = React.useCallback(async () => {
		// No model yet?
		if (!draft) return

		// State.
		setIsSaving(true)

		try {
			// Do it
			await api.deleteModel(draft!.originalData)

			// Events
			if (onDeleted) onDeleted(draft.originalData)

			// Done
			gui.showNotification({ message: 'Het record is verwijderd', severity: GuiStoreNotificationSeverity.success })
		} catch (error) {
			setSaveError(getErrorMessage(error))
		}

		// Done.
		setIsSaving(false)
	}, [draft, onDeleted])

	// ██████╗ ███████╗███╗   ██╗██████╗ ███████╗██████╗
	// ██╔══██╗██╔════╝████╗  ██║██╔══██╗██╔════╝██╔══██╗
	// ██████╔╝█████╗  ██╔██╗ ██║██║  ██║█████╗  ██████╔╝
	// ██╔══██╗██╔══╝  ██║╚██╗██║██║  ██║██╔══╝  ██╔══██╗
	// ██║  ██║███████╗██║ ╚████║██████╔╝███████╗██║  ██║
	// ╚═╝  ╚═╝╚══════╝╚═╝  ╚═══╝╚═════╝ ╚══════╝╚═╝  ╚═╝
	//

	// Create action buttons
	const actionButtons = record && draft && (
		<React.Fragment>
			<Grid item xs={allowDelete && !newModel ? 8 : 12}>
				<Button color={'secondary'} fullWidth onClick={() => submit()} loading={isSaving}>
					Opslaan
				</Button>
			</Grid>
			{allowDelete && !newModel && (
				<Grid item xs={4}>
					<Button variant={'outlined'} fullWidth onClick={() => setShowConfirmDelete(true)} loading={isSaving}>
						Verwijderen
					</Button>
				</Grid>
			)}
		</React.Fragment>
	)

	// Prepare the props
	const formProps: IModelFormRenderProps<T> | null =
		record && draft ? { record, draft, resources, actionButtons: actionButtons!, newModel: !!newModel } : null
	const Form = render
	const ConfirmDelete = renderConfirmDelete

	const inside = formProps ? <Form {...formProps} /> : skeleton || <Skeleton height={100} />
	const Wrapper = container ? Container : React.Fragment

	return (
		<Wrapper>
			{/* Prevent leaving */}
			{confirmLeave && (
				<Prompt
					when={!hasSubmitted && draftIsDirty}
					message={() =>
						confirmLeaveMessage && draft
							? confirmLeaveMessage(draft.data)
							: 'Weet je zeker dat je de pagina wilt verlaten? De gemaakte wijzigingen worden dan niet opgeslagen.'
					}
				/>
			)}

			{/* Header */}
			{!hideHeader && (title || renderTitle) && (
				<PageHeader
					isLoading={isLoading || isSaving}
					title={title || (formProps ? renderTitle!(formProps) : '...')}
					{...header}
				/>
			)}

			{/* Main */}
			<form autoComplete='off'>
				<ValidationProvider value={validationErrors}>
					<ModelFormProvider value={draft ? draft.data : null}>
						{paper ? <Paper className={classes.paper}>{inside}</Paper> : inside}
					</ModelFormProvider>
				</ValidationProvider>

				{/* Error */}
				{saveError && <Alert severity={'error'}>{saveError}</Alert>}
			</form>

			{/* Delete confirm */}
			{allowDelete && (
				<Dialog open={showConfirmDelete} onClose={() => setShowConfirmDelete(false)}>
					<ConfirmDialog
						onConfirm={() => {
							deleteRecord()
							setShowConfirmDelete(false)
						}}
						onCancel={() => setShowConfirmDelete(false)}
					/>
				</Dialog>
			)}

			{/* Confirm save */}
			{confirmSaveMessage && (
				<Dialog open={showConfirmSave} onClose={() => setShowConfirmSave(false)}>
					<ConfirmDialog
						content={showConfirmSaveMessage}
						confirmLabel={'Ja, opslaan'}
						onConfirm={() => {
							submit(true)
							setShowConfirmSave(false)
						}}
						onCancel={() => setShowConfirmSave(false)}
					/>
				</Dialog>
			)}
		</Wrapper>
	)
}
export default ModelForm
