import * as React from 'react'
import { ValidationError } from '~api/model/validateModel'

export const ValidationContext = React.createContext<ValidationError[]>([])
export const ValidationProvider = ValidationContext.Provider
export const ValidationConsumer = ValidationContext.Consumer

export const useValidation = (key?: string): ValidationError[] => {
	// Get context
	const errors = React.useContext(ValidationContext)
	if (!errors) return []

	// No key?
	if (!key) return []

	// Return errors for my attribute
	return errors.filter(err => err.key === key)
}
