import { AnyModel, draft as makeDraft, Draft, applySnapshot, getSnapshot, SnapshotOutOfModel } from 'mobx-keystone'
import { serializeModel } from '~api/serialize'
import { ApiStore } from '~store/stores/ApiStore'
import { call } from '~api/calls'
import { ApiCallOptions } from '~api/types'
import { getApiUri } from '~api/helpers'
import { castAsModel } from '~api/cast'
import { omit, each } from 'lodash'
import { AnyApiModel } from './ApiModel'

export interface SaveModelOptions {
	onlyDirty: boolean
	omitAttributes: string[]
	applyAttributesFromServer: 'all' | string[]
}

export function isNewModel(record: AnyModel): boolean {
	return !record.id
}

function getAttributes(draft: Draft<AnyApiModel>, options: Partial<SaveModelOptions> = {}): { [key: string]: any } {
	// Custom method?
	const attributes: { [key: string]: any } = {
		...draft.data.$,
	}

	// Skip relationships
	const config = draft.data.apiConfig()
	if (config.relationships) {
		config.relationships.forEach(rel => {
			if (attributes[rel.name] !== undefined) delete attributes[rel.name]
		})
	}

	// Dirty?
	if (options.onlyDirty) {
		for (let s in attributes) {
			if (!draft.isDirtyByPath([s])) delete attributes[s]
		}
	}

	// Omit?
	if (options.omitAttributes) return omit(attributes, options.omitAttributes)

	return attributes
}

export type SaveModelResult = boolean

export async function saveModel(
	draft: Draft<AnyApiModel>,
	options: Partial<SaveModelOptions> = {},
	apiOptions: ApiCallOptions = {}
): Promise<SaveModelResult> {
	// Do we have a new record?
	const isNew = isNewModel(draft.data)

	// Default settings
	const settings: SaveModelOptions = {
		onlyDirty: !isNew,
		applyAttributesFromServer: 'all',
		...options,
	}
	const apiConfig = draft.data.apiConfig()

	// Collect basics
	const attributes = getAttributes(draft, settings)

	// Something to save?
	if (Object.keys(attributes).length > 0) {
		// Make the call
		const data = {
			data: serializeModel(draft.data, attributes),
		}

		// Save it
		const result = await call(getApiUri(draft.data, draft.data.id), isNew ? 'post' : 'patch', {
			...apiOptions,
			postJson: true,
			data: JSON.stringify(data),
		})

		// Apply new attributes gotten from server
		// @ts-ignore
		const casted = castAsModel(result.records[0], draft.data.constructor)

		// Apply it again
		if (settings.applyAttributesFromServer === 'all') {
			// Make a combined snapshot from what we had, and what we retrieved from the server
			const snap = {
				...getSnapshot(draft.data),
				...getSnapshot(casted),
				$modelId: draft.data.$modelId,
				$modelType: draft.data.$modelType,
			}

			// Check relationships, so they won't be overwritten
			if (apiConfig.relationships) {
				apiConfig.relationships.forEach(rel => {
					// Set before?
					if (draft.data[rel.name] !== undefined && !casted[rel.name]) {
						snap[rel.name] = getSnapshot(draft.data[rel.name])
					}
				})
			}

			// @HACK: Remove mapData from Map records, because that breaks things...
			const cleaned = cleanSnapshot(snap)

			// Combine all back into a single record
			try {
				applySnapshot(draft.data, cleaned)
			} catch (error) {
				console.warn('Failed to apply snapshot from server', error)
			}
		} else {
			// Only selected attributes
			settings.applyAttributesFromServer.forEach(key => {
				draft.data.setAttribute(key, casted[key])
			})
		}
	}

	// Commit the draft.
	try {
		draft.commit()
	} catch (error) {
		console.warn('Committing draft has failed', error)
	}

	return true
}

function cleanSnapshot(data: SnapshotOutOfModel<AnyApiModel>): SnapshotOutOfModel<AnyApiModel> {
	// Null?
	if (!data) return data

	// Map?
	let result: { [key: string]: any } = { ...data }
	if (result.$modelType === 'Map' && result.mapData !== undefined) delete result.mapData

	// Sub models?
	each(result, (value, key) => {
		if (value && typeof value === 'object' && value.$modelType) {
			result[key] = cleanSnapshot(value)
		}
	})

	return result as SnapshotOutOfModel<AnyModel>
}
