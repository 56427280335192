import * as React from 'react'
import useStore from '~store/hooks/useStore'
import { Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { observer } from 'mobx-react'

export interface IMainSnackbarProps {}

const MainSnackbar = (props: IMainSnackbarProps) => {
	const { gui } = useStore()

	return (
		<React.Fragment>
			{gui.notifications.map(noti => (
				<Snackbar
					open
					key={noti.$modelId}
					anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
					autoHideDuration={noti.autoHideDuration}
					onClose={() => gui.dismissNotification(noti)}>
					<Alert onClose={() => gui.dismissNotification(noti)} severity={noti.severity}>
						{noti.message}
					</Alert>
				</Snackbar>
			))}
		</React.Fragment>
	)
}
export default observer(MainSnackbar)
