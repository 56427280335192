import { divIcon, DivIconOptions } from 'leaflet';
import * as React from 'react';
import { Marker, MarkerProps } from 'react-leaflet';

//  ██████╗ ██████╗ ███╗   ██╗████████╗███████╗███╗   ██╗████████╗
// ██╔════╝██╔═══██╗████╗  ██║╚══██╔══╝██╔════╝████╗  ██║╚══██╔══╝
// ██║     ██║   ██║██╔██╗ ██║   ██║   █████╗  ██╔██╗ ██║   ██║
// ██║     ██║   ██║██║╚██╗██║   ██║   ██╔══╝  ██║╚██╗██║   ██║
// ╚██████╗╚██████╔╝██║ ╚████║   ██║   ███████╗██║ ╚████║   ██║
//  ╚═════╝ ╚═════╝ ╚═╝  ╚═══╝   ╚═╝   ╚══════╝╚═╝  ╚═══╝   ╚═╝
//

interface IReactMarkerContentProps {
  onRender: (html: string) => any;
  title?: string;
}
class ReactMarkerContent extends React.Component<IReactMarkerContentProps> {
  previousHtml: string = '';

  onRef(ref: any) {
    if (ref) {
      const html = ref.innerHTML;
      if (html !== this.previousHtml) {
        this.props.onRender(html);
        this.previousHtml = html;
      }
    }
  }

  render() {
    const { children, title } = this.props;

    return (
      <div
        style={{ display: 'none' }}
        ref={this.onRef.bind(this)}
        title={title}
      >
        {children}
      </div>
    );
  }
}

//  ██████╗ ██████╗ ███╗   ███╗██████╗  ██████╗ ███╗   ██╗███████╗███╗   ██╗████████╗
// ██╔════╝██╔═══██╗████╗ ████║██╔══██╗██╔═══██╗████╗  ██║██╔════╝████╗  ██║╚══██╔══╝
// ██║     ██║   ██║██╔████╔██║██████╔╝██║   ██║██╔██╗ ██║█████╗  ██╔██╗ ██║   ██║
// ██║     ██║   ██║██║╚██╔╝██║██╔═══╝ ██║   ██║██║╚██╗██║██╔══╝  ██║╚██╗██║   ██║
// ╚██████╗╚██████╔╝██║ ╚═╝ ██║██║     ╚██████╔╝██║ ╚████║███████╗██║ ╚████║   ██║
//  ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝      ╚═════╝ ╚═╝  ╚═══╝╚══════╝╚═╝  ╚═══╝   ╚═╝
//
export interface IReactMarkerProps extends MarkerProps {
  className?: string;
  onClick?: () => any;
  title?: string;
  children: any;
  settings?: DivIconOptions;
}

const ReactMarker = (props: IReactMarkerProps) => {
  // const [html, setHtmlState] = React.useState<string | null>(null);
  const { className, title } = props;

  // When HTML is set
  const [marker, setMarker] = React.useState<boolean | JSX.Element>(false);
  const setHtml = React.useCallback(
    (html: string) => {
      // Create divIcon
      const { children, settings = {}, ...markerProps } = props;
      const icon = divIcon({
        className,
        ...settings,
        html: html || false,
      });
      setMarker(<Marker {...markerProps} icon={icon} />);
    },
    [setMarker, props, className]
  );

  return (
    <React.Fragment>
      <ReactMarkerContent onRender={html => setHtml(html)} title={title}>
        {props.children}
      </ReactMarkerContent>

      {marker}
    </React.Fragment>
  );
};
export default ReactMarker;
