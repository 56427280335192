import { getReactSelectTheme, reactSelectStyles } from './react-select-styles'
import * as React from 'react'
import ReactSelect, { components, OptionProps, SingleValueProps } from 'react-select'
import { Props } from 'react-select/src/Select'
import styled, { ThemeContext, ThemeProvider } from 'styled-components'

const OptionDiv = styled.div`
	padding: 5px 15px 5px;
	background: ${props => props.theme.palette.background.paper};
	color: ${props => props.theme.palette.text.primary};

	.selected & {
		color: white;
	}

	&:hover {
		cursor: pointer;
		background: ${props => props.theme.palette.primary.main};
		color: ${props => props.theme.palette.primary.contrastText};
	}
`
const SingleValueDiv = styled.div`
	padding: 4px 4px;
	.meta,
	.lines {
		display: none;
	}
`

const Option = (renderMethod: (data: OptionProps<any>) => JSX.Element) => {
	// Custom rendering
	return (props: OptionProps<any>) => {
		return (
			<OptionDiv className={props.isSelected ? 'selected' : ''} {...props.innerProps}>
				{renderMethod(props)}
			</OptionDiv>
		)
	}
}

const SingleValue = (renderMethod: (data: SingleValueProps<any>) => JSX.Element) => {
	// Custom rendering
	return (props: SingleValueProps<any>) => {
		return <SingleValueDiv {...props.innerProps}>{renderMethod(props)}</SingleValueDiv>
	}
}

export interface ISelectProps extends Props {
	renderOption?: (data: OptionProps<any>) => JSX.Element
	onChange?: (v: any) => any
	value?: any
	options?: any
	components?: any
	placeholder?: string
}

const Select = (props: ISelectProps) => {
	const { renderOption } = props
	const theme = React.useContext(ThemeContext)

	return (
		<ReactSelect
			styles={reactSelectStyles}
			components={{
				...props.components,
				Option: renderOption ? Option(renderOption) : components.Option,
				//@ts-ignore
				SingleValue: renderOption ? SingleValue(renderOption) : components.SingleValue
			}}
			placeholder={''}
			noOptionsMessage={() => ''}
			menuPlacement={'bottom'}
			menuPosition={'fixed'}
			menuPortalTarget={document.body}
			isSearchable
			theme={(rsTheme: any) => getReactSelectTheme(theme, rsTheme)}
			{...props}
		/>
	)
}
export default Select
