import * as React from 'react'
import styled from 'styled-components'
import classNames from 'classnames'
import { Journey } from '~store/models/Journey'
import { groupBy, map, flatten } from 'lodash'
import { Line } from '~store/models/Line'
import { Table as MuiTable, FormControlLabel, Checkbox } from '@material-ui/core'
import Icon from '~components/gui/Icon'
import Select from '~components/select/Select'

// ███████╗████████╗██╗   ██╗██╗     ███████╗██████╗
// ██╔════╝╚══██╔══╝╚██╗ ██╔╝██║     ██╔════╝██╔══██╗
// ███████╗   ██║    ╚████╔╝ ██║     █████╗  ██║  ██║
// ╚════██║   ██║     ╚██╔╝  ██║     ██╔══╝  ██║  ██║
// ███████║   ██║      ██║   ███████╗███████╗██████╔╝
// ╚══════╝   ╚═╝      ╚═╝   ╚══════╝╚══════╝╚═════╝
//

const Container = styled.div``

const DeleteIcon = styled(Icon)`
	color: ${props => props.theme.palette.error.main};
	cursor: pointer;
	&:hover {
		color: ${props => props.theme.palette.error.dark};
	}
`

const Table = styled.table`
	width: 100%;
	thead {
		tr {
			color: ${props => props.theme.palette.secondary.main};
			font-size: 12px;
			th {
				padding: 8px 8px 4px 0;
				text-align: left;
				border-bottom: 1px solid ${props => props.theme.palette.secondary.main};
			}
		}
	}
	tbody {
		tr {
			td {
				padding-right: 8px;
			}
		}
	}
`
const DirectionTd = styled.td`
	padding: 0;
	margin: 0;
	> label {
		display: block;
		margin-right: 8px;
		&:hover {
			background: #333;
		}
		> * {
			font-size: 13px;
		}
	}
`
const ActionsTd = styled.td``

const NoDirection = styled.div``

//  ██████╗ ██████╗ ███╗   ███╗██████╗  ██████╗ ███╗   ██╗███████╗███╗   ██╗████████╗
// ██╔════╝██╔═══██╗████╗ ████║██╔══██╗██╔═══██╗████╗  ██║██╔════╝████╗  ██║╚══██╔══╝
// ██║     ██║   ██║██╔████╔██║██████╔╝██║   ██║██╔██╗ ██║█████╗  ██╔██╗ ██║   ██║
// ██║     ██║   ██║██║╚██╔╝██║██╔═══╝ ██║   ██║██║╚██╗██║██╔══╝  ██║╚██╗██║   ██║
// ╚██████╗╚██████╔╝██║ ╚═╝ ██║██║     ╚██████╔╝██║ ╚████║███████╗██║ ╚████║   ██║
//  ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝      ╚═════╝ ╚═╝  ╚═══╝╚══════╝╚═╝  ╚═══╝   ╚═╝
//
export interface ILineAndDirectionSelectorProps {
	numbers: string[] | null
	directions: string[] | null
	journeys: Journey[]

	onChange: (lineNumbers: string[] | null, directions: string[] | null) => any
}

interface LineValue {
	line: Line
	awayJourney: Journey | null
	returnJourney: Journey | null
	selectedJourneys: Journey[]
}

const LineAndDirectionSelector = ({ numbers, directions, journeys, onChange }: ILineAndDirectionSelectorProps) => {
	// ██████╗  █████╗ ████████╗ █████╗
	// ██╔══██╗██╔══██╗╚══██╔══╝██╔══██╗
	// ██║  ██║███████║   ██║   ███████║
	// ██║  ██║██╔══██║   ██║   ██╔══██║
	// ██████╔╝██║  ██║   ██║   ██║  ██║
	// ╚═════╝ ╚═╝  ╚═╝   ╚═╝   ╚═╝  ╚═╝
	//

	// Resources
	const journeysByLine = React.useMemo(() => groupBy(journeys, journey => journey.line_number), [journeys])
	const allLines = React.useMemo(() => map(journeysByLine, journeys => journeys[0].lineVersion!.line!), [
		journeysByLine,
	])

	const lineOptions = React.useMemo(() => {
		return allLines.map(line => ({
			label: line.title,
			value: line.id,
			line,
		}))
	}, [allLines])

	// State
	const [selectedLines, setSelectedLines] = React.useState<LineValue[]>([])

	// ██████╗ ███████╗██╗  ██╗ █████╗ ██╗   ██╗██╗ ██████╗ ██╗   ██╗██████╗
	// ██╔══██╗██╔════╝██║  ██║██╔══██╗██║   ██║██║██╔═══██╗██║   ██║██╔══██╗
	// ██████╔╝█████╗  ███████║███████║██║   ██║██║██║   ██║██║   ██║██████╔╝
	// ██╔══██╗██╔══╝  ██╔══██║██╔══██║╚██╗ ██╔╝██║██║   ██║██║   ██║██╔══██╗
	// ██████╔╝███████╗██║  ██║██║  ██║ ╚████╔╝ ██║╚██████╔╝╚██████╔╝██║  ██║
	// ╚═════╝ ╚══════╝╚═╝  ╚═╝╚═╝  ╚═╝  ╚═══╝  ╚═╝ ╚═════╝  ╚═════╝ ╚═╝  ╚═╝
	//

	const applyJourneys = React.useCallback(
		(lineNumbers: string[], lineDirections: string[]) => {
			// Collect journeys
			const lines: LineValue[] = []
			lineNumbers.forEach(lineNumber => {
				// Get journeys
				const lineJourneys = journeysByLine[lineNumber]
				if (lineJourneys) {
					// Create line obj
					const line: LineValue = {
						line: lineJourneys[0].lineVersion!.line!,
						awayJourney: lineJourneys.find(journey => journey.direction === 'Away') || null,
						returnJourney: lineJourneys.find(journey => journey.direction === 'Return') || null,
						selectedJourneys: [],
					}

					// Do we have a selection already?
					const prefix = `${lineJourneys[0].line_number}:`
					const selection = lineDirections.filter(dir => dir.substr(0, prefix.length) === prefix)
					if (selection.length > 0) {
						// Filter from selection
						line.selectedJourneys = selection
							.map(key => lineJourneys.find(j => `${j.line_number}:${j.direction}` === key))
							.filter(v => v !== undefined) as Journey[]
					} else {
						// Add all journeys
						line.selectedJourneys = [line.awayJourney, line.returnJourney].filter(v => v !== null) as Journey[]
					}

					// Add it
					lines.push(line)
				}
			})

			// Set value
			setSelectedLines(lines)
		},
		[journeysByLine, directions, onChange]
	)

	React.useEffect(() => {
		// Apply values
		applyJourneys(numbers || [], directions || [])
	}, [numbers, directions])

	//  █████╗  ██████╗████████╗██╗ ██████╗ ███╗   ██╗███████╗
	// ██╔══██╗██╔════╝╚══██╔══╝██║██╔═══██╗████╗  ██║██╔════╝
	// ███████║██║        ██║   ██║██║   ██║██╔██╗ ██║███████╗
	// ██╔══██║██║        ██║   ██║██║   ██║██║╚██╗██║╚════██║
	// ██║  ██║╚██████╗   ██║   ██║╚██████╔╝██║ ╚████║███████║
	// ╚═╝  ╚═╝ ╚═════╝   ╚═╝   ╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝
	//

	const updateValue = React.useCallback(
		(lines?: LineValue[]) => {
			// Use value
			const value = lines || selectedLines

			// Get numbers
			const numbers = value.filter(v => v.selectedJourneys.length > 0).map(v => v.line.number)

			// ...and journeys
			const directions = flatten(value.map(v => v.selectedJourneys.map(j => `${j.line_number}:${j.direction}`)))

			// Call it.
			onChange(numbers, directions)
		},
		[onChange, selectedLines]
	)

	/**
	 * TOGGLE JOURNEY
	 */
	const toggleJourney = React.useCallback(
		(journey: Journey, value: boolean) => {
			// Copy the state
			const lines = [...selectedLines.map(l => ({ ...l, selectedJourneys: [...l.selectedJourneys] }))]

			// Find the line value
			const lineValue = lines.find(v => v.line.id === journey.lineVersion!.line!.id)!
			if (value) {
				if (!lineValue.selectedJourneys.find(j => j.id === journey.id)) {
					lineValue.selectedJourneys.push(journey)
				}
			} else {
				lineValue.selectedJourneys = lineValue.selectedJourneys.filter(j => journey.id !== j.id)
			}

			// Update
			updateValue(lines)
		},
		[selectedLines, updateValue]
	)

	/**
	 * ADD LINE
	 */
	const addLine = React.useCallback(
		(line: Line) => {
			// Already in there?
			if (selectedLines.find(l => l.line.number === line.number)) return

			// Add it
			const lineJourneys = journeysByLine[line.number]
			const newLine: LineValue = {
				line,
				awayJourney: lineJourneys.find(journey => journey.direction === 'Away') || null,
				returnJourney: lineJourneys.find(journey => journey.direction === 'Return') || null,
				selectedJourneys: [...lineJourneys],
			}

			const lines: LineValue[] = [...selectedLines, newLine]

			updateValue(lines)
		},
		[selectedLines, updateValue, journeysByLine]
	)

	/**
	 * REMOVE LINE
	 */
	const removeLine = React.useCallback(
		(lineNumber: string) => {
			// Filter.
			const lines = selectedLines.filter(l => l.line.number !== lineNumber)
			updateValue(lines)
		},
		[selectedLines, updateValue]
	)

	// ██████╗ ███████╗███╗   ██╗██████╗ ███████╗██████╗
	// ██╔══██╗██╔════╝████╗  ██║██╔══██╗██╔════╝██╔══██╗
	// ██████╔╝█████╗  ██╔██╗ ██║██║  ██║█████╗  ██████╔╝
	// ██╔══██╗██╔══╝  ██║╚██╗██║██║  ██║██╔══╝  ██╔══██╗
	// ██║  ██║███████╗██║ ╚████║██████╔╝███████╗██║  ██║
	// ╚═╝  ╚═╝╚══════╝╚═╝  ╚═══╝╚═════╝ ╚══════╝╚═╝  ╚═╝
	//

	return (
		<Container>
			{selectedLines.length > 0 ? (
				<Table cellPadding={0} cellSpacing={0}>
					<thead>
						<tr>
							<th>Concessie</th>
							<th>Lijn</th>
							<th>Heen</th>
							<th>Terug</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{selectedLines.map(line => (
							// Row
							<tr key={line.line.id}>
								<td> {line.line.concession!.title}</td>
								<td>
									<strong>{line.line.public_number}</strong> <small>({line.line.number})</small>
								</td>
								{[line.awayJourney, line.returnJourney].map(journey => (
									<DirectionTd key={journey ? journey.id : 'none'}>
										{journey ? (
											<FormControlLabel
												control={
													<Checkbox
														checked={!!line.selectedJourneys.find(j => j.direction === journey.direction)}
														onChange={e => toggleJourney(journey, e.target.checked)}
														color='primary'
													/>
												}
												label={`richting ${journey.toDestination!.name}`}
											/>
										) : (
											<NoDirection>n.v.t.</NoDirection>
										)}
									</DirectionTd>
								))}
								<ActionsTd>
									<DeleteIcon onClick={() => removeLine(line.line.number)} name={'trash'} />
								</ActionsTd>
							</tr>
						))}
					</tbody>
				</Table>
			) : (
				<span>Geen lijnen geselecteerd</span>
			)}

			{/* Add line */}
			<Select options={lineOptions} value={null} placeholder={'Voeg een lijn toe'} onChange={v => addLine(v.line)} />
		</Container>
	)
}
export default LineAndDirectionSelector
