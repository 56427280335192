import * as React from 'react'
import styled, { ThemeContext } from 'styled-components'
import { CustomPicker, TwitterPicker, TwitterPickerProps, SketchPicker, PhotoshopPicker } from 'react-color'
import Popover from 'react-popover'
import { InputBase, Input } from '@material-ui/core'
import { KeolisColors } from '../../theme'

// ███████╗████████╗██╗   ██╗██╗     ███████╗██████╗
// ██╔════╝╚══██╔══╝╚██╗ ██╔╝██║     ██╔════╝██╔══██╗
// ███████╗   ██║    ╚████╔╝ ██║     █████╗  ██║  ██║
// ╚════██║   ██║     ╚██╔╝  ██║     ██╔══╝  ██║  ██║
// ███████║   ██║      ██║   ███████╗███████╗██████╔╝
// ╚══════╝   ╚═╝      ╚═╝   ╚══════╝╚══════╝╚═════╝
//

const FakeInput = styled(Input)`
	&:hover {
		cursor: pointer;
	}
`
const Value = styled.div`
	display: flex;
	align-items: center;
	> span {
		opacity: 0.5;
	}
`

const Swatch = styled.div`
	display: block;
	width: 64px;
	height: 18px;
	margin-right: 8px;
	border: 1px solid #fff;
	outline: 1px solid #eee;
`
const Placeholder = styled.div`
	opacity: 0.5;
`
const PopoverWithStyle = styled(Popover)`
	z-index: 100000;
	& > .Popover-tip {
		display: none;
	}
`

//  ██████╗ ██████╗ ███╗   ███╗██████╗  ██████╗ ███╗   ██╗███████╗███╗   ██╗████████╗
// ██╔════╝██╔═══██╗████╗ ████║██╔══██╗██╔═══██╗████╗  ██║██╔════╝████╗  ██║╚══██╔══╝
// ██║     ██║   ██║██╔████╔██║██████╔╝██║   ██║██╔██╗ ██║█████╗  ██╔██╗ ██║   ██║
// ██║     ██║   ██║██║╚██╔╝██║██╔═══╝ ██║   ██║██║╚██╗██║██╔══╝  ██║╚██╗██║   ██║
// ╚██████╗╚██████╔╝██║ ╚═╝ ██║██║     ╚██████╔╝██║ ╚████║███████╗██║ ╚████║   ██║
//  ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝      ╚═════╝ ╚═╝  ╚═══╝╚══════╝╚═╝  ╚═══╝   ╚═╝
//

export const Transparent = 'rgba(0,0,0,0)'

export interface IColorPickerProps {
	value: string
	onChange: (value: string) => any
	autoOpen?: boolean
	allowTransparent?: boolean
}

const ColorPicker = (props: IColorPickerProps) => {
	const theme = React.useContext(ThemeContext)

	const { value, onChange, autoOpen = false, allowTransparent = false } = props

	// Keolis theme colors
	const colors = [...KeolisColors]
	if (allowTransparent) colors.push(Transparent)

	const [show, setShow] = React.useState<boolean>(autoOpen)

	return (
		<React.Fragment>
			<PopoverWithStyle
				isOpen={show}
				preferPlace={'below'}
				onOuterAction={() => setShow(false)}
				enterExitTransitionDurationMs={0}
				body={
					<div onDoubleClick={() => setShow(false)}>
						<SketchPicker
							presetColors={colors}
							color={value}
							onChangeComplete={c =>
								onChange(c.hsl.a === 1 ? c.hex : `rgba(${c.rgb.r},${c.rgb.g},${c.rgb.b},${c.rgb.a})`)
							}
						/>
					</div>
				}>
				<FakeInput
					fullWidth
					onClick={() => setShow(!show)}
					inputComponent={Value}
					inputProps={{
						children: value ? (
							<React.Fragment>
								<Swatch style={{ backgroundColor: value }} />
								<span>{value === Transparent ? 'doorzichtig' : value}</span>
							</React.Fragment>
						) : (
							<Placeholder>Kies een kleur</Placeholder>
						)
					}}
				/>
			</PopoverWithStyle>
		</React.Fragment>
	)
}
export default ColorPicker
