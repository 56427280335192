import { observer } from 'mobx-react'
import * as React from 'react'
import { useHistory } from 'react-router-dom'
import BackButton from '~components/buttons/header-buttons/BackButton'
import Fieldset from '~components/model-form/Fieldset'
import ModelForm, { IModelFormRenderProps } from '~components/model-form/ModelForm'
import TranslationsField from '~components/model-form/TranslationsField'
import { IResourceRoutesFormProps } from '~components/routing/ResourceRoutes'
import PageUri from '~core/enums/pages'
import useStore from '~store/hooks/useStore'
import { Concession } from '~store/models/Concession'
import { Place } from '~store/models/Place'
import SimpleField from '~components/model-form/SimpleField'
import IconSelect from '~components/select/IconSelect'
import SelectField from '~components/model-form/SelectField'
import { PlaceCategory } from '~store/models/PlaceCategory'
import { Grid } from '@material-ui/core'
import styled from 'styled-components'
import Button from '~components/buttons/Button'
import Icon from '~components/gui/Icon'
import Map from '~components/leaflet/Map'
import { Marker, Popup } from 'react-leaflet'
import { GoogleProvider, OpenStreetMapProvider } from 'leaflet-geosearch'

const ImageDiv = styled.div`
	width: 100%;
	height: 240px;
	background: ${props => props.theme.palette.background.default};
	background-size: auto 100%;
	background-repeat: no-repeat;
	background-position: center;
`

// ███████╗ ██████╗ ██████╗ ███╗   ███╗
// ██╔════╝██╔═══██╗██╔══██╗████╗ ████║
// █████╗  ██║   ██║██████╔╝██╔████╔██║
// ██╔══╝  ██║   ██║██╔══██╗██║╚██╔╝██║
// ██║     ╚██████╔╝██║  ██║██║ ╚═╝ ██║
// ╚═╝      ╚═════╝ ╚═╝  ╚═╝╚═╝     ╚═╝
//

const PlaceFormMain = observer(({ draft, resources, actionButtons }: IModelFormRenderProps<Place>) => {
	const concessions = resources.concessions as Concession[]
	const concessionOptions = React.useMemo(() => {
		const options: { [key: string]: string } = {}
		concessions.forEach(c => (options[c.slug] = c.slug))
		return options
	}, [concessions])

	const categories = resources.categories as PlaceCategory[]
	const categoryOptions = React.useMemo(() => {
		const options: { [key: string]: string } = {}
		categories.forEach(c => (options[c.id!] = c.title.nl))
		return options
	}, [categories])

	const latLng = React.useMemo(() => {
		const { latitude, longitude } = draft.data
		if (latitude && longitude) return { lat: latitude, lng: longitude }
		return null
	}, [draft.data.latitude, draft.data.longitude])

	const [isGeocoding, setIsGeocoding] = React.useState<boolean>(false)

	const geoProvider = React.useMemo(
		() => new OpenStreetMapProvider({}),

		[]
	)

	const geoCode = React.useCallback(async () => {
		if (isGeocoding) return
		setIsGeocoding(true)

		const result = await geoProvider.search({
			query: `${draft.data.streetname} ${draft.data.housenumber}, ${draft.data.city}`,
		})
		if (result.length > 0) {
			draft.data.setLatLng(parseFloat(result[0].y), parseFloat(result[0].x))
		}

		setIsGeocoding(false)
	}, [draft.data, isGeocoding, setIsGeocoding, geoProvider])

	return (
		<React.Fragment>
			{/* Algemeen */}
			<Fieldset title={`Algemeen`}>
				{/* Category */}
				<SelectField
					required
					attribute='place_category_id'
					label='Categorie'
					options={categoryOptions}
					valueIsNumeric
				/>

				<TranslationsField attribute='title' required label='Titel' />
				<TranslationsField html attribute='description' required label='Omschrijving' />
				<TranslationsField attribute='website_url' label='Website URL' input={{ inputMode: 'url' }} />
				<SimpleField attribute='image_url' label='Afbeelding URL' />
				<Grid item xs={12}>
					{draft.data.image_url && <ImageDiv style={{ backgroundImage: `url(${draft.data.image_url})` }} />}
				</Grid>
			</Fieldset>

			{/* Locatie */}
			<Fieldset title={'Locatie'}>
				{/* Concessions */}
				<SelectField multiple attribute='concession_slugs' label='Concessie(s)' options={concessionOptions} />

				<SimpleField grid={{ xs: 9 }} attribute={'streetname'} label={'Straat'} />
				<SimpleField grid={{ xs: 3 }} attribute={'housenumber'} label={'Huisnummer'} />
				<SimpleField grid={{ xs: 4 }} attribute={'postalcode'} label={'Postcode'} />
				<SimpleField grid={{ xs: 8 }} attribute={'city'} label={'Plaats'} />

				<Grid item xs={12}>
					<Button
						primary={!isGeocoding}
						fullWidth
						startIcon={<Icon name={'arrow-down'} />}
						disabled={isGeocoding}
						onClick={geoCode}>
						Zoek coördinaten bij adres
					</Button>
				</Grid>

				<SimpleField grid={{ xs: 6 }} attribute={'latitude'} label={'Latitude'} inputMode={'numeric'} />
				<SimpleField grid={{ xs: 6 }} attribute={'longitude'} label={'Longitude'} inputMode={'numeric'} />

				{latLng && (
					<Grid item xs={12} style={{ height: 400 }}>
						<Map
							center={latLng}
							zoom={15}
							mapProps={{
								onclick: e => {
									draft.data.setAttribute('latitude', e.latlng.lat)
									draft.data.setAttribute('longitude', e.latlng.lng)
								},
							}}>
							<Popup
								position={latLng}
								closeButton={false}
								autoClose={false}
								closeOnEscapeKey={false}
								closeOnClick={false}>
								{draft.data.title.nl}
							</Popup>
						</Map>
					</Grid>
				)}
			</Fieldset>

			{/* Actions */}
			<Fieldset>{actionButtons}</Fieldset>
		</React.Fragment>
	)
})

// ██╗    ██╗██████╗  █████╗ ██████╗ ██████╗ ███████╗██████╗
// ██║    ██║██╔══██╗██╔══██╗██╔══██╗██╔══██╗██╔════╝██╔══██╗
// ██║ █╗ ██║██████╔╝███████║██████╔╝██████╔╝█████╗  ██████╔╝
// ██║███╗██║██╔══██╗██╔══██║██╔═══╝ ██╔═══╝ ██╔══╝  ██╔══██╗
// ╚███╔███╔╝██║  ██║██║  ██║██║     ██║     ███████╗██║  ██║
//  ╚══╝╚══╝ ╚═╝  ╚═╝╚═╝  ╚═╝╚═╝     ╚═╝     ╚══════╝╚═╝  ╚═╝
//

const PlaceForm = ({ id, newModel }: IResourceRoutesFormProps) => {
	const history = useHistory()
	const { api } = useStore()

	return (
		<ModelForm<Place>
			model={Place}
			modelId={id}
			newModel={newModel}
			apiOptions={{
				include: ['category'],
			}}
			onCreated={record => history.push(`${PageUri.Places}/${record.id}`)}
			onDeleted={record => history.push(PageUri.Places)}
			paper
			header={{
				backUri: PageUri.Places,
			}}
			loadResources={async record => {
				const { records: concessions } = await api.all(Concession)
				const { records: categories } = await api.all(PlaceCategory)
				return { concessions, categories }
			}}
			container
			render={PlaceFormMain}
			renderTitle={({ record }) => (newModel ? 'Nieuwe POI' : `POI: ${record.title.nl}`)}
		/>
	)
}
export default observer(PlaceForm)
