import * as React from 'react'
import styled from 'styled-components'
import classNames from 'classnames'
import {
	List as MuiList,
	ListItem,
	ListItemIcon,
	Checkbox,
	ListItemText,
	ListSubheader as MuiListSubheader,
	ListProps
} from '@material-ui/core'
import { ModelInstanceData, AnyModel } from 'mobx-keystone'
import { groupBy as _groupBy } from 'lodash'

// ███████╗████████╗██╗   ██╗██╗     ███████╗██████╗
// ██╔════╝╚══██╔══╝╚██╗ ██╔╝██║     ██╔════╝██╔══██╗
// ███████╗   ██║    ╚████╔╝ ██║     █████╗  ██║  ██║
// ╚════██║   ██║     ╚██╔╝  ██║     ██╔══╝  ██║  ██║
// ███████║   ██║      ██║   ███████╗███████╗██████╔╝
// ╚══════╝   ╚═╝      ╚═╝   ╚══════╝╚══════╝╚═════╝
//

const List = styled(MuiList)``
const ListSubheader = styled(MuiListSubheader)`
	padding: 0;
	font-size: 12px;
	line-height: 2;
	text-transform: uppercase;
`

//  ██████╗ ██████╗ ███╗   ███╗██████╗  ██████╗ ███╗   ██╗███████╗███╗   ██╗████████╗
// ██╔════╝██╔═══██╗████╗ ████║██╔══██╗██╔═══██╗████╗  ██║██╔════╝████╗  ██║╚══██╔══╝
// ██║     ██║   ██║██╔████╔██║██████╔╝██║   ██║██╔██╗ ██║█████╗  ██╔██╗ ██║   ██║
// ██║     ██║   ██║██║╚██╔╝██║██╔═══╝ ██║   ██║██║╚██╗██║██╔══╝  ██║╚██╗██║   ██║
// ╚██████╗╚██████╔╝██║ ╚═╝ ██║██║     ╚██████╔╝██║ ╚████║███████╗██║ ╚████║   ██║
//  ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝      ╚═════╝ ╚═╝  ╚═══╝╚══════╝╚═╝  ╚═══╝   ╚═╝
//
export interface ICheckSelectProps<T extends AnyModel> {
	list?: ListProps
	options: T[]
	value?: T[]
	onChange?: (selected: T[]) => any
	groupBy?: (option: T) => string
	renderText: (option: T) => React.ReactElement<any, typeof ListItemText>
}

const CheckSelect = <T extends AnyModel>({
	options,
	value = [],
	groupBy,
	renderText,
	onChange,
	...props
}: ICheckSelectProps<T>) => {
	// On click
	const onClick = React.useCallback(
		(option: T) => {
			// (De)select
			if (onChange) onChange(value.indexOf(option) === -1 ? [...value, option] : value.filter(v => v !== option))
		},
		[onChange, value]
	)

	// Render option
	const renderOption = React.useCallback(
		(option: T) => (
			<ListItem key={option.$modelId} dense button onClick={() => onClick(option)} divider>
				<ListItemIcon>
					<Checkbox edge='start' checked={value.indexOf(option) !== -1} tabIndex={-1} disableRipple />
				</ListItemIcon>
				{renderText(option)}
			</ListItem>
		),
		[renderText, value, onClick]
	)

	// List props
	const listProps: ListProps = {
		dense: true,

		...(props.list || {})
	}

	// Grouped?
	const groupedOptions = React.useMemo(() => {
		if (!groupBy) return null
		return _groupBy(options, groupBy)
	}, [groupBy, options])
	if (groupedOptions) {
		return (
			<List {...listProps}>
				{Object.keys(groupedOptions).map(key => (
					<React.Fragment key={key}>
						<ListSubheader disableSticky color={'primary'}>
							{key}
						</ListSubheader>
						{groupedOptions[key].map(renderOption)}
					</React.Fragment>
				))}
			</List>
		)
	}

	// Simple
	return <List {...listProps}>{options.map(renderOption)}</List>
}
export default CheckSelect
