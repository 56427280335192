import * as React from 'react'
import { createStyles, makeStyles, Theme, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import keolisLogo from '~assets/images/keolis-logo.png'
import rrreisLogo from '~assets/images/rrreis-logo.svg'
import { observer } from 'mobx-react'
import useStore from '~store/hooks/useStore'
import MenuItem from './MenuItem'
import PageUri from '~core/enums/pages'

import { getModulesForUser } from '~core/security/modules'
import Icon from '~components/gui/Icon'
import styled from 'styled-components'
import Config, { isRRReis } from '~core/config/Config'

// ███████╗████████╗██╗   ██╗██╗     ███████╗███████╗
// ██╔════╝╚══██╔══╝╚██╗ ██╔╝██║     ██╔════╝██╔════╝
// ███████╗   ██║    ╚████╔╝ ██║     █████╗  ███████╗
// ╚════██║   ██║     ╚██╔╝  ██║     ██╔══╝  ╚════██║
// ███████║   ██║      ██║   ███████╗███████╗███████║
// ╚══════╝   ╚═╝      ╚═╝   ╚══════╝╚══════╝╚══════╝
//

const styles = ({ palette, spacing }: Theme) =>
	createStyles({
		main: {
			textAlign: 'center',
		},
		logo: {
			width: 160,
			margin: spacing(2),
		},
	})
const useStyles = makeStyles(styles)

const Environment = styled.code`
	color: ${props => props.theme.palette.primary.main};
	margin: -8px 0 0 12px;
	padding: 4px 0;
	text-transform: uppercase;
	font-weight: bold;
	font-size: 12px;
	margin-left: 0;
	display: block;
	background: #000;

	i {
		font-size: 0.85em !important;
	}
`

//  ██████╗ ██████╗ ███╗   ███╗██████╗  ██████╗ ███╗   ██╗███████╗███╗   ██╗████████╗
// ██╔════╝██╔═══██╗████╗ ████║██╔══██╗██╔═══██╗████╗  ██║██╔════╝████╗  ██║╚══██╔══╝
// ██║     ██║   ██║██╔████╔██║██████╔╝██║   ██║██╔██╗ ██║█████╗  ██╔██╗ ██║   ██║
// ██║     ██║   ██║██║╚██╔╝██║██╔═══╝ ██║   ██║██║╚██╗██║██╔══╝  ██║╚██╗██║   ██║
// ╚██████╗╚██████╔╝██║ ╚═╝ ██║██║     ╚██████╔╝██║ ╚████║███████╗██║ ╚████║   ██║
//  ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝      ╚═════╝ ╚═╝  ╚═══╝╚══════╝╚═╝  ╚═══╝   ╚═╝
//
export interface IMenuProps {}

const Menu = (props: IMenuProps) => {
	// Styles
	const classes = useStyles()

	const { api } = useStore()
	const { user } = api

	const modules = React.useMemo(
		() => user && getModulesForUser(user!),

		[user]
	)

	if (!user) return null
	return (
		<div className={classes.main}>
			{/* Logo */}
			<img src={isRRReis ? rrreisLogo : keolisLogo} className={classes.logo} />

			<Environment>
				<Icon name={'laptop-code'} inline />
				{Config.environment}
			</Environment>

			{/* Menu */}
			<List>
				{/* Dashboard */}
				<MenuItem icon={'home'} label={'Dashboard'} uri={PageUri.Dashboard} />

				{/* Modules. */}
				{modules &&
					modules.map(mod => (
						<MenuItem key={`${mod.uri}/${mod.title}`} icon={mod.icon} label={mod.title} uri={mod.uri} />
					))}

				{/* Logout */}
				<MenuItem icon={'sign-out-alt'} label={'Uitloggen'} onClick={() => api.logout()} />
			</List>
		</div>
	)
}
export default observer(Menu)
