import * as React from 'react'
import { AnyModel } from 'mobx-keystone'
import { AnyApiModel } from '~api/model/ApiModel'

export const ModelFormContext = React.createContext<AnyApiModel | null>(null)
export const ModelFormProvider = ModelFormContext.Provider
export const ModelFormConsumer = ModelFormContext.Consumer

export function useFormRecord<T extends AnyApiModel>(): T | null {
	// Get context
	const record = React.useContext(ModelFormContext)

	// Return errors for my attribute
	return record ? (record as T) : null
}
