import { Model, model, tProp, types, prop } from 'mobx-keystone'
import { TransportType } from '~core/enums/vehicles'

@model('StopSummary')
export class StopSummary extends Model({
	id: tProp(types.number),
	stop_id: tProp(types.number),
	code: tProp(types.string),
	name: tProp(types.string),
	city: tProp(types.string),
	is_wheelchair_accessible: tProp(types.maybeNull(types.boolean)),
	latitude: tProp(types.number),
	longitude: tProp(types.number),
	journey_information: tProp(
		types.array(
			types.object(() => ({
				type: types.enum<TransportType>(TransportType),
				number: types.string,
				destination: types.string,
				category_key: types.maybeNull(types.string),
				public_number: types.string
			}))
		)
	)
}) {}
