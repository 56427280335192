import * as React from 'react'
import styled, { ThemeContext } from 'styled-components'
import Spinner from 'react-spinkit'

// ███████╗████████╗██╗   ██╗██╗     ███████╗██████╗
// ██╔════╝╚══██╔══╝╚██╗ ██╔╝██║     ██╔════╝██╔══██╗
// ███████╗   ██║    ╚████╔╝ ██║     █████╗  ██║  ██║
// ╚════██║   ██║     ╚██╔╝  ██║     ██╔══╝  ██║  ██║
// ███████║   ██║      ██║   ███████╗███████╗██████╔╝
// ╚══════╝   ╚═╝      ╚═╝   ╚══════╝╚══════╝╚═════╝
//

const Container = styled.div`
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	background: ${props => props.theme.palette.background.default};
	opacity: 0.8;
	display: flex;
	justify-content: space-around;
	flex-direction: column;
	align-items: center;
	z-index: 9999;
`
const Label = styled.div`
	font-size: 11px;
	margin: 0;
`
const Loader = styled(Spinner)`
	transform: scale(0.5);
	text-align: center;
`

//  ██████╗ ██████╗ ███╗   ███╗██████╗  ██████╗ ███╗   ██╗███████╗███╗   ██╗████████╗
// ██╔════╝██╔═══██╗████╗ ████║██╔══██╗██╔═══██╗████╗  ██║██╔════╝████╗  ██║╚══██╔══╝
// ██║     ██║   ██║██╔████╔██║██████╔╝██║   ██║██╔██╗ ██║█████╗  ██╔██╗ ██║   ██║
// ██║     ██║   ██║██║╚██╔╝██║██╔═══╝ ██║   ██║██║╚██╗██║██╔══╝  ██║╚██╗██║   ██║
// ╚██████╗╚██████╔╝██║ ╚═╝ ██║██║     ╚██████╔╝██║ ╚████║███████╗██║ ╚████║   ██║
//  ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝      ╚═════╝ ╚═╝  ╚═══╝╚══════╝╚═╝  ╚═══╝   ╚═╝
//
export interface ILoadingProps {
	label?: string
}

const Loading = (props: ILoadingProps) => {
	const { label } = props
	const theme = React.useContext(ThemeContext)

	return (
		<Container>
			<div>
				<Loader name={'ball-beat'} color={theme.palette.primary.main} fadeIn={'none'} />
				{label && <Label>{label}</Label>}
			</div>
		</Container>
	)
}
export default Loading
