import * as React from 'react'
import styled from 'styled-components'
import classNames from 'classnames'
import useStore from '~store/hooks/useStore'
import { useMapModel } from '~store/hooks/useMapModel'
import { StopSummary } from '~store/models/StopSummary'
import { KeolisColors } from '../../../theme'
import { IToolProps } from '../IToolProps'
import Field from '../Field'
import StopSelect from '~components/select/StopSelect'
import ColorPicker, { Transparent } from '~components/forms/ColorPicker'
import ToolWindowButtons from '../ToolWindowButtons'
import Button from '~components/buttons/Button'
import { LatLng } from 'leaflet'
import LocationPicker from '../LocationPicker'
import { Input, RadioGroup, FormControlLabel, Radio } from '@material-ui/core'
import ImageSelect from '~components/select/ImageSelect'
import { Image, TrafficSigns } from '~core/enums/images'
import IconSelect from '~components/select/IconSelect'
import { FontAwesomeIconName } from '~core/enums/icons'

const IconTypes = { TrafficSign: 'Verkeersbord', FontAwesome: 'Standaard-icoon' }
type IconType = keyof typeof IconTypes

const IconTool = ({ onClose, pickLocation }: IToolProps) => {
	// Resources
	const map = useMapModel()

	// State
	const [latLng, setLatLng] = React.useState<LatLng | undefined>()
	const [type, setType] = React.useState<IconType>('TrafficSign')

	const [image, setImage] = React.useState<Image | undefined>()

	const [icon, setIcon] = React.useState<FontAwesomeIconName | undefined>()
	const [color, setColor] = React.useState<string>(KeolisColors[0])
	const [backgroundColor, setBackgroundColor] = React.useState<string>(Transparent)

	// Validation
	const formIsComplete = React.useMemo(() => {
		if (type === 'TrafficSign') return latLng && color && image
		return latLng && icon && color
	}, [type, latLng, color, icon, image, backgroundColor])

	// Submit
	const submit = React.useCallback(() => {
		// Image?
		if (type === 'TrafficSign') {
			// Add image
			map.addImageLayer(latLng!, image!)
		} else {
			// Add icon
			map.addIconLayer(latLng!, icon!, color!, backgroundColor!)
		}
		onClose()
	}, [map, latLng, type, image, icon, color, backgroundColor, onClose])

	return (
		<React.Fragment>
			<p>Kies een locatie op de kaart en vul een tekst in om een label toe te voegen.</p>

			<Field label={'Locatie'} select>
				<LocationPicker value={latLng} onChange={l => setLatLng(l)} pickLocation={pickLocation} />
			</Field>

			{/* Type */}
			<Field>
				<RadioGroup row value={type} onChange={e => setType(e.target.value as IconType)}>
					{Object.keys(IconTypes).map(key => (
						<FormControlLabel key={key} value={key} label={IconTypes[key as IconType]} control={<Radio />} />
					))}
				</RadioGroup>
			</Field>

			{/* Verkeersbord? */}
			{type === 'TrafficSign' ? (
				<React.Fragment>
					<Field label={'Afbeelding'} select>
						<ImageSelect value={image} onChange={i => setImage(i)} options={TrafficSigns} />
					</Field>
				</React.Fragment>
			) : (
				<React.Fragment>
					<Field label={'Icoon'} select>
						<IconSelect value={icon} onChange={i => setIcon(i)} />
					</Field>
					<Field label={'Kleur'} select>
						<ColorPicker value={color} onChange={c => setColor(c)} />
					</Field>
					<Field label={'Achtergrond-kleur'} select>
						<ColorPicker value={backgroundColor} onChange={c => setBackgroundColor(c)} allowTransparent />
					</Field>
				</React.Fragment>
			)}
			<ToolWindowButtons>
				<Button color='primary' disabled={!formIsComplete} onClick={submit}>
					Toevoegen
				</Button>
			</ToolWindowButtons>
		</React.Fragment>
	)
}
export default IconTool
