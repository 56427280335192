import { model, tProp, types } from 'mobx-keystone'
import { ApiModel } from '~api/model/ApiModel'
import { Translation } from './utility/Translation'
import { computed } from 'mobx'
import { FontAwesomeIconName } from '~core/enums/icons'

@model('PlaceCategory')
export class PlaceCategory extends ApiModel({
	id: tProp(types.maybe(types.number)),
	slug: tProp(types.string),
	title: tProp(types.model<Translation>(Translation)),
	description: tProp(types.model<Translation>(Translation)),
	icon: tProp(types.maybeNull(types.string)),
	color: tProp(types.maybeNull(types.string)),
	show_listing: tProp(types.boolean),
}) {
	@computed
	get iconName(): FontAwesomeIconName | null {
		if (this.icon) return this.icon.replace(/^fa\-/, '') as FontAwesomeIconName
		return null
	}
}
