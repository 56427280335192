import * as React from 'react'
import styled from 'styled-components'
import classNames from 'classnames'
import useStore from '~store/hooks/useStore'
import { useMapModel } from '~store/hooks/useMapModel'
import { StopSummary } from '~store/models/StopSummary'
import { KeolisColors } from '../../../theme'
import { IToolProps } from '../IToolProps'
import Field from '../Field'
import StopSelect from '~components/select/StopSelect'
import ColorPicker from '~components/forms/ColorPicker'
import ToolWindowButtons from '../ToolWindowButtons'
import Button from '~components/buttons/Button'

// ███████╗████████╗██╗   ██╗██╗     ███████╗██████╗
// ██╔════╝╚══██╔══╝╚██╗ ██╔╝██║     ██╔════╝██╔══██╗
// ███████╗   ██║    ╚████╔╝ ██║     █████╗  ██║  ██║
// ╚════██║   ██║     ╚██╔╝  ██║     ██╔══╝  ██║  ██║
// ███████║   ██║      ██║   ███████╗███████╗██████╔╝
// ╚══════╝   ╚═╝      ╚═╝   ╚══════╝╚══════╝╚═════╝
//

//  ██████╗ ██████╗ ███╗   ███╗██████╗  ██████╗ ███╗   ██╗███████╗███╗   ██╗████████╗
// ██╔════╝██╔═══██╗████╗ ████║██╔══██╗██╔═══██╗████╗  ██║██╔════╝████╗  ██║╚══██╔══╝
// ██║     ██║   ██║██╔████╔██║██████╔╝██║   ██║██╔██╗ ██║█████╗  ██╔██╗ ██║   ██║
// ██║     ██║   ██║██║╚██╔╝██║██╔═══╝ ██║   ██║██║╚██╗██║██╔══╝  ██║╚██╗██║   ██║
// ╚██████╗╚██████╔╝██║ ╚═╝ ██║██║     ╚██████╔╝██║ ╚████║███████╗██║ ╚████║   ██║
//  ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝      ╚═════╝ ╚═╝  ╚═══╝╚══════╝╚═╝  ╚═══╝   ╚═╝
//

const StopTool = ({ onClose }: IToolProps) => {
	// Resources
	const { stopSummaries } = useStore().resources
	const map = useMapModel()

	// State
	const [stop, setStop] = React.useState<StopSummary | undefined>()
	const [color, setColor] = React.useState<string>(KeolisColors[0])

	// Validation
	const formIsComplete = React.useMemo(() => stop && color, [stop, color])

	// Submit
	const submit = React.useCallback(() => {
		// Do it.
		map.addStopLayer(stop!, color)
		onClose()
	}, [map, stop, color, onClose])

	return (
		<React.Fragment>
			<p>Kies een bestaande halte uit KV1 om toe te voegen op de kaart.</p>
			<Field label={'Halte'} select>
				<StopSelect stops={stopSummaries!} value={stop} onChange={(l: any) => setStop(l as StopSummary)} />
			</Field>
			<Field label={'Kleur'} select>
				<ColorPicker value={color} onChange={c => setColor(c)} />
			</Field>
			<ToolWindowButtons>
				<Button color='primary' disabled={!formIsComplete} onClick={submit}>
					Toevoegen
				</Button>
			</ToolWindowButtons>
		</React.Fragment>
	)
}
export default StopTool
