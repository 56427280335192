import { AnyModel } from 'mobx-keystone'
import { ApiCallOptions } from '~api/types'
import { call } from '~api/calls'
import { getApiUri } from '~api/helpers'

export type DeleteModelResult = true

export async function deleteModel(record: AnyModel, apiOptions: ApiCallOptions = {}): Promise<DeleteModelResult> {
	// Make the call
	await call(getApiUri(record, record.id), 'delete', {
		...apiOptions,
		deserialize: false
	})
	return true
}
