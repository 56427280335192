import Draggable from 'react-draggable'
import * as React from 'react'
import styled from 'styled-components'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import { IToolConfig } from '~core/enums/tools'
import { FontAwesomeIconName } from '~core/enums/icons'
import useStore from '~store/hooks/useStore'
import Icon from '~components/gui/Icon'
import Loading from '~components/gui/Loading'
import { Box, Paper, Typography } from '@material-ui/core'

// ███████╗████████╗██╗   ██╗██╗     ███████╗██████╗
// ██╔════╝╚══██╔══╝╚██╗ ██╔╝██║     ██╔════╝██╔══██╗
// ███████╗   ██║    ╚████╔╝ ██║     █████╗  ██║  ██║
// ╚════██║   ██║     ╚██╔╝  ██║     ██╔══╝  ██║  ██║
// ███████║   ██║      ██║   ███████╗███████╗██████╔╝
// ╚══════╝   ╚═╝      ╚═╝   ╚══════╝╚══════╝╚═════╝
//

const Window = styled(Paper)`
	position: absolute;
	top: 62px;
	left: 18px;

	display: flex;
	flex-direction: column;

	z-index: 3000;

	width: 480px;
	border-radius: 4px;
	overflow: hidden;

	transform: translateY(0);
	&.hidden {
		transition: transform 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
		transform: translateY(-1000px) !important;
	}
`
const Header = styled.div`
	flex: 0 0 auto;
	background: ${props => props.theme.palette.secondary.main};
	color: ${props => props.theme.palette.secondary.contrastText};
	margin: 0;
	padding: 12px 16px;

	display: flex;
	justify-content: space-between;
	align-items: center;
`
const Title = styled.div`
	/* {Fonts.header.style}; */

	.fa {
		margin-right: 0.5em;
	}
`
const Icons = styled.div`
	> * {
		margin-left: 0.5em;
		opacity: 0.5;
		cursor: pointer;
		&:hover {
			opacity: 1;
		}
	}
`

const DragHandle = styled.span`
	cursor: move;
`

const Main = styled.div`
	flex: 1 1 auto;
	padding: 16px;
	overflow: auto;
	position: relative;
	min-height: 180px;
	max-height: 500px;
	overflow: auto;

	font-size: 13px;
	line-height: 1.5;

	&.minimized {
		display: none;
	}

	p {
		&:first-child {
			margin-top: 0;
		}
	}
`

//  ██████╗ ██████╗ ███╗   ███╗██████╗  ██████╗ ███╗   ██╗███████╗███╗   ██╗████████╗
// ██╔════╝██╔═══██╗████╗ ████║██╔══██╗██╔═══██╗████╗  ██║██╔════╝████╗  ██║╚══██╔══╝
// ██║     ██║   ██║██╔████╔██║██████╔╝██║   ██║██╔██╗ ██║█████╗  ██╔██╗ ██║   ██║
// ██║     ██║   ██║██║╚██╔╝██║██╔═══╝ ██║   ██║██║╚██╗██║██╔══╝  ██║╚██╗██║   ██║
// ╚██████╗╚██████╔╝██║ ╚═╝ ██║██║     ╚██████╔╝██║ ╚████║███████╗██║ ╚████║   ██║
//  ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝      ╚═════╝ ╚═╝  ╚═══╝╚══════╝╚═╝  ╚═══╝   ╚═╝
//
export interface IToolWindowProps {
	tool: IToolConfig
	children?: any
	onClose: () => any
	hidden?: boolean

	// Minimize
	isMinimized?: boolean
	minimizeActionIcon?: FontAwesomeIconName
	minimizeActionTitle?: string
	onRestore?: () => any
}

const ToolWindow = (props: IToolWindowProps) => {
	const { hidden, tool, children, isMinimized, onClose, minimizeActionIcon, minimizeActionTitle, onRestore } = props
	const { resources } = useStore()

	const [isLoaded, setIsLoaded] = React.useState<boolean>(false)

	// Load resources when tool changes
	React.useEffect(() => {
		const loadResources = async () => {
			const promises = tool.resources!.map(key => resources.load(key))
			await Promise.all(promises)
			setIsLoaded(true)
		}

		// Anything
		if (tool) {
			setIsLoaded(!tool.resources)
			if (tool.resources) loadResources()
		}
	}, [tool])

	return (
		<Draggable handle={'.drag-handle'}>
			<Window elevation={2} className={classNames({ hidden })}>
				<Header>
					<Typography variant={'h4'}>
						<Icon name={isMinimized && minimizeActionIcon ? minimizeActionIcon : tool.icon} inline />
						{isMinimized && minimizeActionTitle ? minimizeActionTitle : tool.title}
					</Typography>
					<Icons>
						{isMinimized ? (
							<span title={'Annuleren'} onClick={() => onRestore && onRestore()}>
								<Icon name={'window-restore'} />
							</span>
						) : (
							<React.Fragment>
								<DragHandle className={'drag-handle'} title={'Verplaats scherm'}>
									<Icon name={'arrows-alt'} />
								</DragHandle>
								<span title={'Sluiten'} onClick={() => onClose()}>
									<Icon name={'times'} />
								</span>
							</React.Fragment>
						)}
					</Icons>
				</Header>
				<Main className={isMinimized ? 'minimized' : ''}>
					{isLoaded ? children : <Loading label={'Bezig met laden...'} />}
				</Main>
			</Window>
		</Draggable>
	)
}
export default observer(ToolWindow)
