import {
	Model,
	model,
	tProp,
	modelAction,
	modelFlow,
	_async,
	_await,
	types,
	fromSnapshot,
	getSnapshot,
	toTreeNode,
	isModel,
	withoutUndo,
} from 'mobx-keystone'
import { ValidationRule, ModelValidationConfig } from '~api/model/validateModel'
import { computed } from 'mobx'
import {
	MapData,
	LayerType,
	Layer,
	getPolylineFromLatLngs,
	latLngBoundsFromBBox,
} from '~keolis-reizen-map-element/index'
import { Line } from './Line'
import { Journey } from './Journey'
import { StopVersion } from './StopVersion'
import { StopSummary } from './StopSummary'
import { LatLng, LatLngBounds } from 'leaflet'
import { truncate } from 'lodash'
import { Image } from '~core/enums/images'
import { FontAwesomeIconName } from '~core/enums/icons'
import { ApiModel } from '~api/model/ApiModel'
import Config from '~core/config/Config'
import { User } from './User'

@model('Map')
export class Map extends ApiModel({
	//  █████╗ ████████╗████████╗██████╗ ██╗██████╗ ██╗   ██╗████████╗███████╗███████╗
	// ██╔══██╗╚══██╔══╝╚══██╔══╝██╔══██╗██║██╔══██╗██║   ██║╚══██╔══╝██╔════╝██╔════╝
	// ███████║   ██║      ██║   ██████╔╝██║██████╔╝██║   ██║   ██║   █████╗  ███████╗
	// ██╔══██║   ██║      ██║   ██╔══██╗██║██╔══██╗██║   ██║   ██║   ██╔══╝  ╚════██║
	// ██║  ██║   ██║      ██║   ██║  ██║██║██████╔╝╚██████╔╝   ██║   ███████╗███████║
	// ╚═╝  ╚═╝   ╚═╝      ╚═╝   ╚═╝  ╚═╝╚═╝╚═════╝  ╚═════╝    ╚═╝   ╚══════╝╚══════╝
	//
	id: tProp(types.maybe(types.number)),
	title: tProp(types.maybeNull(types.string), null),
	public_token: tProp(types.maybeNull(types.string), null),
	data: tProp(types.maybeNull(types.unchecked()), null),
	latlng_bounds: tProp(types.maybeNull(types.string), null),

	mapData: tProp(types.model<MapData>(MapData), () => new MapData({})),
	images: tProp(
		types.maybeNull(
			types.array(
				types.object(() => ({
					base64: types.string,
					width: types.number,
					height: types.number,
				}))
			)
		),
		null
	),
	created_at: tProp(types.maybeNull(types.string), null),
	updated_at: tProp(types.maybeNull(types.string), null),

	createdByUser: tProp(types.maybe(types.model<User>(User))),
}) {
	//  ██████╗ ██████╗ ███╗   ███╗██████╗ ██╗   ██╗████████╗███████╗██████╗
	// ██╔════╝██╔═══██╗████╗ ████║██╔══██╗██║   ██║╚══██╔══╝██╔════╝██╔══██╗
	// ██║     ██║   ██║██╔████╔██║██████╔╝██║   ██║   ██║   █████╗  ██║  ██║
	// ██║     ██║   ██║██║╚██╔╝██║██╔═══╝ ██║   ██║   ██║   ██╔══╝  ██║  ██║
	// ╚██████╗╚██████╔╝██║ ╚═╝ ██║██║     ╚██████╔╝   ██║   ███████╗██████╔╝
	//  ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝      ╚═════╝    ╚═╝   ╚══════╝╚═════╝
	//

	@computed
	get bounds(): LatLngBounds | undefined {
		if (!this.latlng_bounds) return
		return latLngBoundsFromBBox(this.latlng_bounds)
	}

	@computed
	get publicUrl(): string | null {
		if (this.public_token) return `${Config.publicUrl}/melding-kaart/${this.public_token}`
		return null
	}

	@computed
	get iframeCode(): string | null {
		if (this.publicUrl) return `<iframe src="${this.publicUrl}" width="640" height="480"></iframe>`
		return null
	}

	// ███╗   ███╗███████╗████████╗██╗  ██╗ ██████╗ ██████╗ ███████╗
	// ████╗ ████║██╔════╝╚══██╔══╝██║  ██║██╔═══██╗██╔══██╗██╔════╝
	// ██╔████╔██║█████╗     ██║   ███████║██║   ██║██║  ██║███████╗
	// ██║╚██╔╝██║██╔══╝     ██║   ██╔══██║██║   ██║██║  ██║╚════██║
	// ██║ ╚═╝ ██║███████╗   ██║   ██║  ██║╚██████╔╝██████╔╝███████║
	// ╚═╝     ╚═╝╚══════╝   ╚═╝   ╚═╝  ╚═╝ ╚═════╝ ╚═════╝ ╚══════╝
	//

	onInit() {
		if (this.data) {
			const json = typeof this.data === 'string' ? JSON.parse(this.data) : this.data
			this.mapData = fromSnapshot(json)
		}
	}

	copy(): Map {
		return new Map({
			data: JSON.stringify(getSnapshot(this.mapData)),
			images: this.images ? [...this.images.map(img => ({ ...img }))] : null,
			latlng_bounds: this.latlng_bounds,
		})
	}

	@modelAction
	prepareForSaving() {
		// Serialize the map
		this.data = JSON.stringify(getSnapshot(this.mapData))
	}

	prepareForImage() {
		// Deselect/dehover all layers
		withoutUndo(() => {
			this.mapData.selectedLayers.forEach(l => l.setSelected(false))
			this.mapData.getAllLayers(l => l.isHovering).forEach(l => l.setHover(false))
		})
	}

	@modelAction
	setBounds(bounds: LatLngBounds) {
		this.latlng_bounds = bounds.toBBoxString()
	}

	/**
	 * ADD JOURNEY(S)
	 */
	async addJourneyLayers(line: Line, journeys: Journey[], color: string) {
		// Load the journey details
		await Promise.all(journeys.map(j => j.loadSteps()))

		// Keep track of stops that are doubly used, or already added in previous ones
		const existingStopLayers = this.mapData.getAllStopLayers()
		const usedStopCodes: string[] = existingStopLayers.map(stop => stop.stopCode!)
		const usedStopNames: string[] = existingStopLayers.map(stop => stop.stopName!)

		// Add stop
		const createStopLayer = (stop: StopVersion): Layer => {
			// Already used before?
			const isVisible = usedStopCodes.indexOf(stop.code) === -1
			const showLabel = isVisible && usedStopNames.indexOf(stop.name) === -1

			// Add me
			if (isVisible) usedStopCodes.push(stop.code)
			if (showLabel) usedStopNames.push(stop.name)

			// Create stop layer
			return new Layer({
				type: LayerType.Stop,
				stopName: stop.name,
				name: `Halte ${stop.name} (${stop.code})`,
				latitude: stop.latitude,
				longitude: stop.longitude,
				stopCode: stop.code,
				showLabel,
				isVisible,
			})
		}

		// This line already in there?
		let lineLayer = this.mapData.layers.find(layer => layer.type === LayerType.Line && layer.lineNumber === line.number)
		const lineIsNew = !lineLayer
		if (!lineLayer) {
			// Create line
			lineLayer = new Layer({
				type: LayerType.Line,
				name: line.title,
				icon: line.icon,
				lineNumber: line.number,
				color,
				isExpanded: true,
				subLayers: [],
			})
		}

		// Go through journeys
		journeys.forEach(journey => {
			// Create stops and steps first
			const subLayers: Layer[] = []
			journey.steps!.forEach(step => {
				// From stop?
				const fromStop = step.fromStopVersion!
				const toStop = step.toStopVersion!
				if (step.order === 1) subLayers.push(createStopLayer(fromStop))

				// Line segment
				subLayers.push(
					new Layer({
						type: LayerType.LineSegment,
						name: `Van ${fromStop.code} naar ${toStop.code}`,
						polyline: step.polyline,
					})
				)

				// To stop
				subLayers.push(createStopLayer(toStop))
			})

			// Create journey
			const journeyLayer = new Layer({
				type: LayerType.Journey,
				name: journey.title,
				icon: line.icon,
				color,
				isExpanded: journeys.length === 1,
				subLayers,
			})

			// Add to line
			lineLayer!.addLayer(journeyLayer)
		})

		// Add me
		if (lineIsNew) this.mapData.addLayer(lineLayer)
	}

	/**
	 * ADD FREE LINE
	 */
	addFreeLineLayer(path: LatLng[], color: string) {
		// Convert to poly
		const polyline = getPolylineFromLatLngs(path)

		// Create layer
		const layer = new Layer({
			type: LayerType.FreeLine,
			name: 'Vrije lijn',
			color,
			polyline,
		})
		this.mapData.addLayer(layer)
		return layer
	}

	/**
	 * ADD STOP
	 */
	addStopLayer(stop: StopSummary, color: string): Layer {
		// Create stop layer
		const layer = new Layer({
			type: LayerType.Stop,
			stopName: stop.name,
			color,
			name: `Halte ${stop.name} (${stop.code})`,
			latitude: stop.latitude,
			longitude: stop.longitude,
			stopCode: stop.code,
			showLabel: true,
		})
		this.mapData.addLayer(layer)
		return layer
	}

	addTemporaryStopLayer(latLng: LatLng, name: string, color: string): Layer {
		const layer = new Layer({
			type: LayerType.Stop,
			color,
			stopName: name,
			name: `Halte ${name}`,
			latitude: latLng.lat,
			longitude: latLng.lng,
			showLabel: true,
		})
		this.mapData.addLayer(layer)
		return layer
	}

	/**
	 * LABEL
	 */
	addLabelLayer(latLng: LatLng, text: string, color: string, backgroundColor: string): Layer {
		const layer = new Layer({
			type: LayerType.Label,
			color,
			backgroundColor,
			label: text,
			name: `Tekst "${truncate(text, { length: 20 })}"`,
			latitude: latLng.lat,
			longitude: latLng.lng,
		})
		this.mapData.addLayer(layer)
		return layer
	}

	/**
	 * IMAGE
	 */
	addImageLayer(latLng: LatLng, image: Image, size: number = 48): Layer {
		const layer = new Layer({
			type: LayerType.Image,
			name: truncate(image.title, { length: 40 }),
			latitude: latLng.lat,
			longitude: latLng.lng,
			imageBase64: image.base64,
			imageWidth: size,
		})
		this.mapData.addLayer(layer)
		return layer
	}

	/**
	 * ICON
	 */
	addIconLayer(latLng: LatLng, icon: FontAwesomeIconName, color: string, backgroundColor: string): Layer {
		const layer = new Layer({
			type: LayerType.Icon,
			name: `Icoon "${icon}"`,
			icon,
			color,
			backgroundColor,
			latitude: latLng.lat,
			longitude: latLng.lng,
		})
		this.mapData.addLayer(layer)
		return layer
	}

	// ██╗███╗   ███╗ █████╗  ██████╗ ███████╗
	// ██║████╗ ████║██╔══██╗██╔════╝ ██╔════╝
	// ██║██╔████╔██║███████║██║  ███╗█████╗
	// ██║██║╚██╔╝██║██╔══██║██║   ██║██╔══╝
	// ██║██║ ╚═╝ ██║██║  ██║╚██████╔╝███████╗
	// ╚═╝╚═╝     ╚═╝╚═╝  ╚═╝ ╚═════╝ ╚══════╝
	//

	@modelAction
	setImageByCanvas(canvas: HTMLCanvasElement) {
		this.images = [
			{
				base64: canvas.toDataURL('JPG', 80),
				width: canvas.width,
				height: canvas.height,
			},
		]
	}

	//  ██████╗ ██████╗ ███╗   ██╗███████╗██╗ ██████╗ ██╗   ██╗██████╗  █████╗ ████████╗██╗ ██████╗ ███╗   ██╗
	// ██╔════╝██╔═══██╗████╗  ██║██╔════╝██║██╔════╝ ██║   ██║██╔══██╗██╔══██╗╚══██╔══╝██║██╔═══██╗████╗  ██║
	// ██║     ██║   ██║██╔██╗ ██║█████╗  ██║██║  ███╗██║   ██║██████╔╝███████║   ██║   ██║██║   ██║██╔██╗ ██║
	// ██║     ██║   ██║██║╚██╗██║██╔══╝  ██║██║   ██║██║   ██║██╔══██╗██╔══██║   ██║   ██║██║   ██║██║╚██╗██║
	// ╚██████╗╚██████╔╝██║ ╚████║██║     ██║╚██████╔╝╚██████╔╝██║  ██║██║  ██║   ██║   ██║╚██████╔╝██║ ╚████║
	//  ╚═════╝ ╚═════╝ ╚═╝  ╚═══╝╚═╝     ╚═╝ ╚═════╝  ╚═════╝ ╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚═╝ ╚═════╝ ╚═╝  ╚═══╝
	//
}
