import * as React from 'react';
import styled from 'styled-components';
import { IMapLayerProps } from '../MapLayer';
import { LatLng } from 'leaflet';
import { observer } from 'mobx-react';
import ReactMarker from '../markers/ReactMarker';
import classnames from 'classnames';
import { Colors } from '../../utils/theme';

// ███████╗████████╗██╗   ██╗██╗     ███████╗██████╗
// ██╔════╝╚══██╔══╝╚██╗ ██╔╝██║     ██╔════╝██╔══██╗
// ███████╗   ██║    ╚████╔╝ ██║     █████╗  ██║  ██║
// ╚════██║   ██║     ╚██╔╝  ██║     ██╔══╝  ██║  ██║
// ███████║   ██║      ██║   ███████╗███████╗██████╔╝
// ╚══════╝   ╚═╝      ╚═╝   ╚══════╝╚══════╝╚═════╝
//

const Container = styled.div`
  font-size: 13px;
  position: absolute;
  left: 0;
  top: 0;
  white-space: nowrap;
  border-radius: 100%;
  > i {
    font-size: inherit;
    color: inherit;
  }
  align-items: center;
  display: flex;
  justify-content: space-around;

  transition: box-shadow ease-in-out 400ms;
  &.hover {
    box-shadow: 0 0 3px ${Colors.layerHover};
  }
  &.selected {
    box-shadow: 0 0 3px ${Colors.layerSelected};
  }
`;

const I = styled.i``;

//  ██████╗ ██████╗ ███╗   ███╗██████╗  ██████╗ ███╗   ██╗███████╗███╗   ██╗████████╗
// ██╔════╝██╔═══██╗████╗ ████║██╔══██╗██╔═══██╗████╗  ██║██╔════╝████╗  ██║╚══██╔══╝
// ██║     ██║   ██║██╔████╔██║██████╔╝██║   ██║██╔██╗ ██║█████╗  ██╔██╗ ██║   ██║
// ██║     ██║   ██║██║╚██╔╝██║██╔═══╝ ██║   ██║██║╚██╗██║██╔══╝  ██║╚██╗██║   ██║
// ╚██████╗╚██████╔╝██║ ╚═╝ ██║██║     ╚██████╔╝██║ ╚████║███████╗██║ ╚████║   ██║
//  ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝      ╚═════╝ ╚═╝  ╚═══╝╚══════╝╚═╝  ╚═══╝   ╚═╝
//
const Icon = (props: IMapLayerProps) => {
  const { layer, onClick, interactive } = props;

  const latLng = React.useMemo(
    () => new LatLng(layer.latitude!, layer.longitude!),
    [layer.latitude, layer.longitude]
  );
  const size = layer.fontSize
    ? parseFloat(layer.fontSize.toString()) * 1.8
    : 13 * 1.8;
  const classes = React.useMemo(
    () =>
      interactive
        ? classnames({ hover: layer.isHovering, selected: layer.isSelected })
        : '',
    [layer.isHovering, layer.isSelected, interactive]
  );

  return (
    <ReactMarker
      settings={{ iconSize: [size, size] }}
      position={latLng}
      onClick={onClick}
      ondragend={e => layer.setLatLng(e.target.getLatLng())}
      draggable={interactive && layer.isSelected}
      onmouseover={layer.isSelected ? undefined : props.onMouseEnter}
      onmouseout={layer.isSelected ? undefined : props.onMouseLeave}
    >
      <Container
        className={classes}
        style={{
          fontSize: layer.fontSize || 13,
          width: size,
          height: size,
          backgroundColor: layer.backgroundColor || undefined,
          color: layer.color || undefined,
          transform: `rotate(${layer.rotation || 0}deg)`,
        }}
      >
        <I className={`fa fa-${layer.icon}`} />
      </Container>
    </ReactMarker>
  );
};
export default observer(Icon);
