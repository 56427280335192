import * as React from 'react'
import { TranslationEditorNode } from './TranslationEditor'
import { Languages } from '~core/enums/language'
import { createStyles, Theme, makeStyles, Input, Button } from '@material-ui/core'
import classNames from 'classnames'
import IconButton from '~components/buttons/IconButton'
import { each } from 'lodash'

// ███████╗████████╗██╗   ██╗██╗     ███████╗███████╗
// ██╔════╝╚══██╔══╝╚██╗ ██╔╝██║     ██╔════╝██╔════╝
// ███████╗   ██║    ╚████╔╝ ██║     █████╗  ███████╗
// ╚════██║   ██║     ╚██╔╝  ██║     ██╔══╝  ╚════██║
// ███████║   ██║      ██║   ███████╗███████╗███████║
// ╚══════╝   ╚═╝      ╚═╝   ╚══════╝╚══════╝╚══════╝
//

const styles = ({ palette, spacing }: Theme) =>
	createStyles({
		levelIcon: {
			fontSize: 10,
			opacity: 0.25,
			marginRight: 2,
		},
		row: {
			'&:nth-child(2n)': {
				background: '#ffffff11',
			},
			'&.isEditing': {
				background: palette.secondary.dark,
				'&:nth-child(2n)': {
					background: palette.secondary.main,
				},
			},
			'&.isEditable:not(.isEditing)': {
				'&:hover': {
					background: palette.primary.main,
					cursor: 'pointer',
				},
			},
			'& td': {
				padding: spacing(1),
				'& span': {
					wordWrap: 'break-word',
				},
				'& .path': {
					fontSize: 12,
					'&:not(:last-child)': {
						opacity: 0.7,
					},
				},
			},
		},
	})
const useStyles = makeStyles(styles)

//  ██████╗ ██████╗ ███╗   ███╗██████╗  ██████╗ ███╗   ██╗███████╗███╗   ██╗████████╗
// ██╔════╝██╔═══██╗████╗ ████║██╔══██╗██╔═══██╗████╗  ██║██╔════╝████╗  ██║╚══██╔══╝
// ██║     ██║   ██║██╔████╔██║██████╔╝██║   ██║██╔██╗ ██║█████╗  ██╔██╗ ██║   ██║
// ██║     ██║   ██║██║╚██╔╝██║██╔═══╝ ██║   ██║██║╚██╗██║██╔══╝  ██║╚██╗██║   ██║
// ╚██████╗╚██████╔╝██║ ╚═╝ ██║██║     ╚██████╔╝██║ ╚████║███████╗██║ ╚████║   ██║
//  ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝      ╚═════╝ ╚═╝  ╚═══╝╚══════╝╚═╝  ╚═══╝   ╚═╝
//

export interface ITranslationEditorRowProps {
	node: TranslationEditorNode
	onEdit: (node: TranslationEditorNode, value: { [lang: string]: string }) => any
}

const TranslationEditorRow = ({ node, onEdit }: ITranslationEditorRowProps) => {
	const classes = useStyles()

	const [isEditing, setIsEditing] = React.useState<boolean>(false)
	const isEditable = React.useMemo(() => !!node.translations, [node])

	const [translations, setTranslations] = React.useState<{ [lang: string]: string }>(
		node.translations ? { ...node.translations } : {}
	)

	const handleClick = React.useCallback(() => {
		if (isEditable && !isEditing) setIsEditing(true)
	}, [isEditable, isEditing])

	const setTranslation = React.useCallback(
		(lang: string, value: string) => {
			setTranslations({
				...translations,
				[lang]: value,
			})
		},
		[node, translations]
	)
	React.useEffect(() => {
		const obj = { nl: '', en: '' }
		if (node.translations)
			each(node.translations, (value, key) => {
				if (value) obj[key] = value
			})

		return setTranslations(obj)
	}, [node.translations])

	const cancelEdit = React.useCallback(() => {
		// Reset
		setIsEditing(false)
		setTranslations(node.translations ? { ...node.translations } : {})
	}, [node])

	const confirmEdit = React.useCallback(() => {
		// Changed?

		// Callback and done.
		onEdit(node, translations)
		setIsEditing(false)
	}, [onEdit, node, translations])

	return (
		<React.Fragment>
			<tr className={classNames(classes.row, { isEditable, isEditing })} onClick={handleClick}>
				<td>
					{/* Path */}
					{node.path.map((key, index) => (
						<span className='path' key={`${key}/${index}`}>
							{key}
							{index !== node.path.length - 1 ? '.' : ''}
						</span>
					))}
					{isEditing && (
						<React.Fragment>
							<br />
							<IconButton icon={'check-circle'} onClick={() => confirmEdit()} />
							<IconButton icon={'ban'} onClick={() => cancelEdit()} />
						</React.Fragment>
					)}
				</td>
				{/* Translations */}
				{Languages.map((lang, index) => (
					<td key={lang}>
						{translations &&
							translations[lang] !== undefined &&
							(isEditing ? (
								<Input
									fullWidth
									multiline
									rowsMin={2}
									autoFocus={index === 0}
									value={translations[lang]}
									onChange={e => setTranslation(lang, e.target.value)}
								/>
							) : (
								<span>{typeof translations[lang] == 'object' ? 'N/A' : translations[lang]}</span>
							))}
					</td>
				))}
			</tr>
			{node.children &&
				node.children.map(subNode => (
					<TranslationEditorRow onEdit={onEdit} node={subNode} key={subNode.path.join('.')} />
				))}
		</React.Fragment>
	)
}
export default TranslationEditorRow
