import { observer } from 'mobx-react'
import * as React from 'react'
import { useHistory } from 'react-router-dom'
import BackButton from '~components/buttons/header-buttons/BackButton'
import Fieldset from '~components/model-form/Fieldset'
import ModelForm, { IModelFormRenderProps } from '~components/model-form/ModelForm'
import TranslationsField from '~components/model-form/TranslationsField'
import { IResourceRoutesFormProps } from '~components/routing/ResourceRoutes'
import PageUri from '~core/enums/pages'
import useStore from '~store/hooks/useStore'
import { Concession } from '~store/models/Concession'
import { PlaceCategory } from '~store/models/PlaceCategory'
import SimpleField from '~components/model-form/SimpleField'
import IconSelect from '~components/select/IconSelect'
import SelectField from '~components/model-form/SelectField'

// ███████╗ ██████╗ ██████╗ ███╗   ███╗
// ██╔════╝██╔═══██╗██╔══██╗████╗ ████║
// █████╗  ██║   ██║██████╔╝██╔████╔██║
// ██╔══╝  ██║   ██║██╔══██╗██║╚██╔╝██║
// ██║     ╚██████╔╝██║  ██║██║ ╚═╝ ██║
// ╚═╝      ╚═════╝ ╚═╝  ╚═╝╚═╝     ╚═╝
//

const PlaceCategoryFormMain = observer(({ draft, resources, actionButtons }: IModelFormRenderProps<PlaceCategory>) => {
	return (
		<React.Fragment>
			{/* Algemeen */}
			<Fieldset title={`Categorie`}>
				<TranslationsField attribute='title' required label='Titel' />
				<TranslationsField html attribute='description' required label='Omschrijving' />
				<SimpleField attribute='icon' required label='Icoon' />
			</Fieldset>

			{/* Actions */}
			<Fieldset>{actionButtons}</Fieldset>
		</React.Fragment>
	)
})

// ██╗    ██╗██████╗  █████╗ ██████╗ ██████╗ ███████╗██████╗
// ██║    ██║██╔══██╗██╔══██╗██╔══██╗██╔══██╗██╔════╝██╔══██╗
// ██║ █╗ ██║██████╔╝███████║██████╔╝██████╔╝█████╗  ██████╔╝
// ██║███╗██║██╔══██╗██╔══██║██╔═══╝ ██╔═══╝ ██╔══╝  ██╔══██╗
// ╚███╔███╔╝██║  ██║██║  ██║██║     ██║     ███████╗██║  ██║
//  ╚══╝╚══╝ ╚═╝  ╚═╝╚═╝  ╚═╝╚═╝     ╚═╝     ╚══════╝╚═╝  ╚═╝
//

const PlaceCategoryForm = ({ id, newModel }: IResourceRoutesFormProps) => {
	const history = useHistory()
	const { api } = useStore()

	return (
		<ModelForm<PlaceCategory>
			model={PlaceCategory}
			modelId={id}
			newModel={newModel}
			onCreated={record => history.push(`${PageUri.PlaceCategories}/${record.id}`)}
			onDeleted={record => history.push(PageUri.PlaceCategories)}
			paper
			header={{
				backUri: PageUri.PlaceCategories,
			}}
			container
			render={PlaceCategoryFormMain}
			renderTitle={({ record }) => (newModel ? 'Nieuwe POI-categorie' : `POI-categorie: ${record.title.nl}`)}
		/>
	)
}
export default observer(PlaceCategoryForm)
