import { ApiCallMethod, ApiPostOptions, ApiGetOptions, ApiCallResult, ApiCallOptions } from '~api/types'
import qs from 'qs'
import { mapValues, each } from 'lodash'
import axios, { Method, AxiosResponse, AxiosRequestConfig } from 'axios'
import Config from '~core/config/Config'
import { postProcessApiResult } from './postProcess'
import { makeApiUrl } from './helpers'

export async function call(uri: string, method: Method, options: ApiCallOptions = {}) {
	// Make params
	const params = options.params || {}

	// Includses
	if (options.include) params.include = options.include.join(',')

	// Check data
	const data = options.data ? (options.postJson ? options.data : qs.stringify(options.data)) : null

	// Headers
	const headers = {
		...options.headers,
		'X-Api-Key': Config.apiKey,
	}
	if (options.postJson) headers['Content-Type'] = 'application/json'

	try {
		// Prepare config
		const config: AxiosRequestConfig = {
			url: makeApiUrl(uri),
			method,
			data,
			params: params,
			headers,
		}

		// Authorize
		if (options.token) config.headers.Authorization = `Bearer ${options.token}`

		// Make the call
		const response = await axios(config)

		return postProcessApiResult(response, options)
	} catch (err) {
		throw err
	}
}

export async function get<T>(uri: string, options: ApiGetOptions = {}): Promise<ApiCallResult<T>> {
	// Make params
	const params = options.params || {}

	// Add filters
	if (options.filters) {
		const filters = mapValues(options.filters, (filter, key) => {
			// Simple?
			if (typeof filter === 'string') {
				return filter
			}

			// Boolean?
			if (typeof filter === 'boolean') {
				return filter
			}

			// Array?
			if (Array.isArray(filter)) {
				const f = {
					value: filter,
					operator: 'IN',
				}
				return `@Q${JSON.stringify(f)}`
			}

			throw new Error('Complicated filters not implemented')
		})
		each(filters, (value, key) => {
			params[`filter[${key}]`] = value
		})
	}

	// Sorting
	if (options.sort) {
		// Unify
		const sorts: string[] = (Array.isArray(options.sort) ? options.sort : [options.sort]).map(s => {
			// Simple
			if (typeof s === 'string') return s

			// With direction
			return s.direction === 'desc' ? `-${s.attribute}` : s.attribute
		})
		params.sort = sorts.join(',')
	}

	// Search
	if (options.search) params.search = options.search

	// Includses
	if (options.include) params.include = options.include.join(',')

	// Page size?
	if (options.page) params['page[number]'] = options.page
	if (options.pageSize) params['page[size]'] = options.pageSize

	// Cached?
	let response: AxiosResponse

	// Make the call
	const url = makeApiUrl(uri)
	try {
		// Prepare config
		const config: AxiosRequestConfig = {
			params,
			headers: {
				...options.headers,
				'X-Api-Key': Config.apiKey,
			},
		}

		// Authorize
		if (options.token) config.headers.Authorization = `Bearer ${options.token}`

		// Call
		response = await axios.get(url, config)
	} catch (err) {
		throw err
	}

	return postProcessApiResult(response, options)
}
