import * as React from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import PageUri from '~core/enums/pages'
import useStore from '~store/hooks/useStore'
import { observer } from 'mobx-react'

const ProtectedRoute = ({ children, ...rest }: RouteProps) => {
	const {
		api: { isAuthenticated, user }
	} = useStore()

	return (
		<Route
			{...rest}
			render={({ location }) =>
				isAuthenticated && user ? (
					children
				) : (
					<Redirect
						to={{
							pathname: PageUri.Login,
							state: { from: location }
						}}
					/>
				)
			}
		/>
	)
}
export default observer(ProtectedRoute)
