import * as React from 'react'
import { IResourceKey } from './resources'
import { FontAwesomeIconName } from './icons'

export type IToolKey = 'Line' | 'FreeLine' | 'Stop' | 'TemporaryStop' | 'Label' | 'Icon'

export interface IToolConfig {
	key: IToolKey
	title: string
	icon: FontAwesomeIconName
	resources?: IResourceKey[]
}

export const Tools: IToolConfig[] = [
	{
		key: 'Line',
		title: 'Basislijn',
		icon: 'bus',
		resources: ['Lines', 'Journeys', 'StopSummaries']
	},
	{
		key: 'FreeLine',
		title: 'Vrije lijn',
		icon: 'draw-polygon'
	},
	{
		key: 'Stop',
		title: 'Bestaande halte',
		icon: 'columns',
		resources: ['StopSummaries']
	},
	{
		key: 'TemporaryStop',
		title: 'Tijdelijke halte',
		icon: 'stopwatch'
	},
	{
		key: 'Label',
		title: 'Tekst',
		icon: 'align-left'
	},
	{
		key: 'Icon',
		title: 'Icoon',
		icon: 'smile'
	}
]

export const ToolKeys = Tools.map(t => t.key)

export function getToolConfig(key: IToolKey): IToolConfig | null {
	return Tools.find(t => t.key === key) || null
}
