import { model, tProp, types } from 'mobx-keystone'
import { ApiModel, ApiModelConfig } from '~api/model/ApiModel'
import { Navigation } from './Navigation'
import { Translation } from './utility/Translation'

@model('NavigationItem')
export class NavigationItem extends ApiModel({
	id: tProp(types.maybe(types.number)),
	navigation: tProp(types.maybe(types.model<Navigation>(Navigation))),
	url: tProp(types.model<Translation>(Translation)),
	title: tProp(types.model<Translation>(Translation)),
	icon: tProp(types.maybeNull(types.string)),
	is_active: tProp(types.boolean, false)
}) {
	public apiConfig(): ApiModelConfig {
		return {
			relationships: [
				{
					name: 'navigation',
					type: 'BelongsTo',
					model: Navigation
				}
			],
			apiUri: '/admin/navigation_items'
		}
	}
}
