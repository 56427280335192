import * as React from 'react'
import {
	createStyles,
	makeStyles,
	Theme,
	MenuItem as MuiMenuItem,
	MenuItemProps,
	ListItemIcon as MuiListItemIcon,
	ListItemText,
	ListItem,
	ListItemProps
} from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import classNames from 'classnames'
import Icon from '~components/gui/Icon'
import { FontAwesomeIconName } from '~core/enums/icons'
import styled from 'styled-components'

// ███████╗████████╗██╗   ██╗██╗     ███████╗███████╗
// ██╔════╝╚══██╔══╝╚██╗ ██╔╝██║     ██╔════╝██╔════╝
// ███████╗   ██║    ╚████╔╝ ██║     █████╗  ███████╗
// ╚════██║   ██║     ╚██╔╝  ██║     ██╔══╝  ╚════██║
// ███████║   ██║      ██║   ███████╗███████╗███████║
// ╚══════╝   ╚═╝      ╚═╝   ╚══════╝╚══════╝╚══════╝
//

const I = styled(Icon)`
	font-size: 18px;
`
const ListItemIcon = styled(MuiListItemIcon)`
	width: 24px;
	margin-right: 16px;
	justify-content: center;
	min-width: 0;
`

const styles = ({ palette, spacing }: Theme) =>
	createStyles({
		main: {},
		selected: {
			background: palette.primary.main,
			color: palette.primary.contrastText,
			'&:hover': {
				background: palette.primary.light
			}
		}
	})
const useStyles = makeStyles(styles)

//  ██████╗ ██████╗ ███╗   ███╗██████╗  ██████╗ ███╗   ██╗███████╗███╗   ██╗████████╗
// ██╔════╝██╔═══██╗████╗ ████║██╔══██╗██╔═══██╗████╗  ██║██╔════╝████╗  ██║╚══██╔══╝
// ██║     ██║   ██║██╔████╔██║██████╔╝██║   ██║██╔██╗ ██║█████╗  ██╔██╗ ██║   ██║
// ██║     ██║   ██║██║╚██╔╝██║██╔═══╝ ██║   ██║██║╚██╗██║██╔══╝  ██║╚██╗██║   ██║
// ╚██████╗╚██████╔╝██║ ╚═╝ ██║██║     ╚██████╔╝██║ ╚████║███████╗██║ ╚████║   ██║
//  ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝      ╚═════╝ ╚═╝  ╚═══╝╚══════╝╚═╝  ╚═══╝   ╚═╝
//
export interface IMenuItemProps extends ListItemProps {
	icon?: FontAwesomeIconName
	label?: string
	uri?: string
}

const MenuItem = ({ icon, label, uri, onClick, children, className, button, ...other }: IMenuItemProps) => {
	// Styles
	const classes = useStyles()

	// Click callback
	const history = useHistory()
	const onClickCallback = React.useCallback(
		e => {
			if (uri) {
				history.push(uri)
			} else if (onClick) {
				onClick(e)
			}
		},
		[uri, onClick]
	)

	// Is selected
	const isSelected = React.useMemo<boolean>(() => {
		// No link?
		if (!uri || !history.location) return false

		// The root url?
		if (uri === '/') {
			// Only exact match
			return history.location.pathname === '/'
		}

		// Is the root part the same?
		return history.location.pathname.substr(0, uri.length) === uri
	}, [uri, history.location])

	return (
		<ListItem
			button
			onClick={onClickCallback}
			className={classNames(classes.main, className, isSelected ? classes.selected : '')}
			{...other}>
			{icon && (
				<ListItemIcon>
					<I name={icon} />
				</ListItemIcon>
			)}
			{label && <ListItemText primary={label} />}
			{children}
		</ListItem>
	)
}
export default MenuItem
