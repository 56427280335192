import * as React from 'react'
import { FontAwesomeIconName, FontAwesomeIconOption } from '~core/enums/icons'
import classNames from 'classnames'
import styled from 'styled-components'

const I = styled.i`
	text-decoration: none !important;

	&.fa-inline {
		margin-right: 0.5em;
		display: inline-block;
		font-size: 1em;
	}
`

export interface IIconProps {
	name: FontAwesomeIconName
	options?: FontAwesomeIconOption[]
	className?: string
	loading?: boolean
	pulse?: boolean
	size?: '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10'
	fixedWidth?: boolean
	inline?: boolean
	style?: React.CSSProperties
	onClick?: (e: MouseEvent) => any
}

const Icon = (props: IIconProps) => {
	const options: (FontAwesomeIconOption | 'inline')[] = [...(props.options || [])]
	if (props.fixedWidth) options.push('fw')
	// @ts-ignore
	if (props.size) options.push(`${props.size}x`)

	// Custom options
	if (props.inline) options.push('inline')

	return (
		<I
			onClick={props.onClick}
			style={props.style}
			className={classNames('fa', `fa-${props.name}`, options.map(o => `fa-${o}`).join(', '), props.className)}
		/>
	)
}
export default Icon
