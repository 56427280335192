import { FontAwesomeIconName } from './icons'

type Direction = {
	icon: FontAwesomeIconName
	translation: string
}

const Directions: { [key: string]: Direction } = {
	Away: {
		icon: 'arrow-circle-right',
		translation: 'Heen'
	},
	Return: {
		icon: 'arrow-circle-left',
		translation: 'Terug'
	}
}

export enum DirectionsEnum {
	Away = 'Away',
	Return = 'Return'
}

export type IDirection = keyof typeof Directions

export function getDirectionIcon(type: IDirection): FontAwesomeIconName {
	return Directions[type].icon
}
export function getDirectionTranslation(type: IDirection): string {
	return Directions[type].translation
}
