import * as React from 'react'
import styled from 'styled-components'
import classNames from 'classnames'
import useStore from '~store/hooks/useStore'
import { useMapModel } from '~store/hooks/useMapModel'
import { StopSummary } from '~store/models/StopSummary'
import { KeolisColors } from '../../../theme'
import { IToolProps } from '../IToolProps'
import Field from '../Field'
import StopSelect from '~components/select/StopSelect'
import ColorPicker from '~components/forms/ColorPicker'
import ToolWindowButtons from '../ToolWindowButtons'
import Button from '~components/buttons/Button'
import { LatLng } from 'leaflet'
import LocationPicker from '../LocationPicker'
import { Input } from '@material-ui/core'

// ███████╗████████╗██╗   ██╗██╗     ███████╗██████╗
// ██╔════╝╚══██╔══╝╚██╗ ██╔╝██║     ██╔════╝██╔══██╗
// ███████╗   ██║    ╚████╔╝ ██║     █████╗  ██║  ██║
// ╚════██║   ██║     ╚██╔╝  ██║     ██╔══╝  ██║  ██║
// ███████║   ██║      ██║   ███████╗███████╗██████╔╝
// ╚══════╝   ╚═╝      ╚═╝   ╚══════╝╚══════╝╚═════╝
//

//  ██████╗ ██████╗ ███╗   ███╗██████╗  ██████╗ ███╗   ██╗███████╗███╗   ██╗████████╗
// ██╔════╝██╔═══██╗████╗ ████║██╔══██╗██╔═══██╗████╗  ██║██╔════╝████╗  ██║╚══██╔══╝
// ██║     ██║   ██║██╔████╔██║██████╔╝██║   ██║██╔██╗ ██║█████╗  ██╔██╗ ██║   ██║
// ██║     ██║   ██║██║╚██╔╝██║██╔═══╝ ██║   ██║██║╚██╗██║██╔══╝  ██║╚██╗██║   ██║
// ╚██████╗╚██████╔╝██║ ╚═╝ ██║██║     ╚██████╔╝██║ ╚████║███████╗██║ ╚████║   ██║
//  ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝      ╚═════╝ ╚═╝  ╚═══╝╚══════╝╚═╝  ╚═══╝   ╚═╝
//

const TemporaryStopTool = ({ onClose, pickLocation }: IToolProps) => {
	// Resources
	const map = useMapModel()

	// State
	const [latLng, setLatLng] = React.useState<LatLng | undefined>()
	const [stopName, setStopName] = React.useState<string>(' (tijdelijk)')
	const [color, setColor] = React.useState<string>(KeolisColors[0])

	// Validation
	const formIsComplete = React.useMemo(() => stopName && latLng && color, [stopName, latLng, color])

	// Submit
	const submit = React.useCallback(() => {
		// Do it.
		map.addTemporaryStopLayer(latLng!, stopName, color)
		onClose()
	}, [map, latLng, stopName, color, onClose])

	return (
		<React.Fragment>
			<p>Kies een locatie op de kaart en vul de naam in van de tijdelijke halte die je wilt toevoegen.</p>

			<Field label={'Locatie'} select>
				<LocationPicker value={latLng} onChange={l => setLatLng(l)} pickLocation={pickLocation} />
			</Field>
			<Field label={'Naam'}>
				<Input value={stopName} onChange={e => setStopName(e.target.value)} />
			</Field>
			<Field label={'Kleur'} select>
				<ColorPicker value={color} onChange={c => setColor(c)} />
			</Field>
			<ToolWindowButtons>
				<Button color='primary' disabled={!formIsComplete} onClick={submit}>
					Toevoegen
				</Button>
			</ToolWindowButtons>
		</React.Fragment>
	)
}
export default TemporaryStopTool
