import { Theme as ReactSelectTheme } from 'react-select'
import { CmsTheme } from '../../theme'

export const getReactSelectTheme = (theme: CmsTheme, rsTheme: ReactSelectTheme) => {
	return {
		...rsTheme,

		colors: {
			...rsTheme.colors,
			primary: theme.palette.primary.main,
			primary25: theme.palette.primary.light,
			text: theme.palette.background.default
		}
	}
}

export const reactSelectStyles = {
	menuPortal: (base: any, state: any) => ({
		...base,
		zIndex: 99999
	}),
	control: (base: any, state: any) => {
		return {
			...base,
			background: 'transparent',
			boxShadow: 'none',

			border: 'none',
			outline: 'none',
			borderRadius: 0,
			borderBottom: `1px solid ${state.isFocused ? '#e72766' : '#eee'}`
		}
	},
	singleValue: (base: any) => ({
		...base,
		color: '#fff'
	}),
	input: (base: any) => ({
		...base,
		color: '#fff'
	}),
	menuList: (base: any) => ({
		...base,
		background: '#424242'
	}),
	indicatorSeparator: (base: any) => ({
		...base,
		backgroundColor: '#999'
	})
}
