import { LatLng } from 'leaflet';
import { observer } from 'mobx-react';
import * as React from 'react';
import styled from 'styled-components';
import classnames from 'classnames';

import ReactMarker from './ReactMarker';
import { Colors } from '../../utils/theme';

// ███████╗████████╗██╗   ██╗██╗     ███████╗██████╗
// ██╔════╝╚══██╔══╝╚██╗ ██╔╝██║     ██╔════╝██╔══██╗
// ███████╗   ██║    ╚████╔╝ ██║     █████╗  ██║  ██║
// ╚════██║   ██║     ╚██╔╝  ██║     ██╔══╝  ██║  ██║
// ███████║   ██║      ██║   ███████╗███████╗██████╔╝
// ╚══════╝   ╚═╝      ╚═╝   ╚══════╝╚══════╝╚═════╝
//

const CircleIcon = styled.div`
  background: #0ff;
  width: 16px;
  height: 16px;
  opacity: 1;
  border-radius: 50%;

  position: relative;
  &:after {
    position: absolute;
    left: 4px;
    top: 4px;
    width: 8px;
    height: 8px;
    background: hsla(0, 0%, 100%, 0.5);
    border-radius: 50%;
    content: '';
  }
  &.hover {
    background: ${Colors.layerHover} !important;
  }
  &.selected {
    background: ${Colors.layerSelected} !important;
  }
`;

const Label = styled.div`
  position: absolute;
  left: 18px;
  top: -2px;
  white-space: nowrap;
  pointer-events: none;
  text-transform: none;
  font-weight: 700;
  color: #666;
  font-size: 11px;
  background: hsla(0, 0%, 100%, 0.8);
  padding: 2px 4px;
  border-radius: 4px;

  transform-origin: -11px 10px;

  &.cancelled {
    color: ${Colors.red};
  }

  &.hover {
    background: ${Colors.layerHover};
    color: #fff;
  }
  &.selected {
    background: ${Colors.layerSelected};
    color: #fff;
  }
`;
const LabelText = styled.div``;

const CancelledIcon = styled.div`
  margin-left: -5px;
  margin-top: -3px;
  width: 24px;
  height: 24px;

  background: white;
  text-align: center;
  border-radius: 100%;
  > .fa {
    margin-top: 1px;
    font-size: 22px;
    color: ${Colors.red};
    &.isHovering {
      font-size: 26px;
      margin-top: -1px;
      margin-left: -2px;
    }
  }
`;

//  ██████╗ ██████╗ ███╗   ███╗██████╗  ██████╗ ███╗   ██╗███████╗███╗   ██╗████████╗
// ██╔════╝██╔═══██╗████╗ ████║██╔══██╗██╔═══██╗████╗  ██║██╔════╝████╗  ██║╚══██╔══╝
// ██║     ██║   ██║██╔████╔██║██████╔╝██║   ██║██╔██╗ ██║█████╗  ██╔██╗ ██║   ██║
// ██║     ██║   ██║██║╚██╔╝██║██╔═══╝ ██║   ██║██║╚██╗██║██╔══╝  ██║╚██╗██║   ██║
// ╚██████╗╚██████╔╝██║ ╚═╝ ██║██║     ╚██████╔╝██║ ╚████║███████╗██║ ╚████║   ██║
//  ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝      ╚═════╝ ╚═╝  ╚═══╝╚══════╝╚═╝  ╚═══╝   ╚═╝
//
export interface IStopMarkerProps {
  latLng: LatLng;
  name: string;
  color: string;
  isSelected?: boolean;
  isCancelled?: boolean;
  isHovering?: boolean;
  showLabel?: boolean;
  labelRotation?: number | null;
  onClick?: () => any;
  onMouseEnter?: () => any;
  onMouseLeave?: () => any;
}

const StopMarker = (props: IStopMarkerProps) => {
  // Props
  const {
    onClick,
    onMouseEnter,
    onMouseLeave,
    name,
    color,
    isCancelled = false,
    isSelected = false,
    showLabel = true,
    isHovering = false,
    labelRotation,
    latLng,
  } = props;

  const labelClass = React.useMemo(
    () =>
      classnames({
        cancelled: isCancelled,
        hover: isHovering,
        selected: isSelected,
      }),
    [isHovering, isCancelled, isSelected]
  );

  const iconColor = React.useMemo(() => {
    if (isHovering) return Colors.layerHover;
    if (isSelected) return Colors.layerSelected;
    return color;
  }, [isHovering, isSelected, color]);

  return (
    <ReactMarker
      settings={{ iconSize: [16, 16] }}
      position={latLng}
      onClick={onClick}
      onmouseover={onMouseEnter}
      onmouseout={onMouseLeave}
    >
      {isCancelled ? (
        <CancelledIcon>
          <i className={classnames('fa fa-times-circle', { isHovering })} />
        </CancelledIcon>
      ) : (
        <CircleIcon style={{ background: iconColor }} />
      )}

      {showLabel && (
        <Label
          style={
            labelRotation
              ? { transform: `rotate(${labelRotation}deg)` }
              : undefined
          }
          className={labelClass}
        >
          <LabelText
            style={
              labelRotation && labelRotation > 90 && labelRotation < 270
                ? { transform: `rotate(180deg)` }
                : undefined
            }
          >
            {name}
          </LabelText>
        </Label>
      )}
    </ReactMarker>
  );
};
export default observer(StopMarker);
