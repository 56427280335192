import * as React from 'react'
import { ModelClass, AnyModel, getModelDataType } from 'mobx-keystone'
import { ApiGetOptions, ApiCallResultPageInfo, ApiCallResult, ApiSort } from '~api/types'
import useAsyncEffect from 'use-async-effect'
import useStore from '~store/hooks/useStore'
import { Skeleton } from '@material-ui/lab'
import PageHeader from '~components/layout/pages/PageHeader'
import { Paper, TableContainer, TablePagination } from '@material-ui/core'
import usePrevious from '~hooks/usePrevious'
import RemoteTable, { RemoteTableConfig } from './RemoteTable'
import { map } from 'lodash'
import { getApiUri } from '~api/helpers'
import styled from 'styled-components'

const Intro = styled.div`
	margin: 1em 0;
`

export type RemoteAttribute = {
	key: string
	display?: string
	label?: string
	sortable?: boolean
	defaultSortDirection?: 'asc' | 'desc'
}

export interface RemoteViewRenderProps<T extends AnyModel> {
	records: T[]
	pageInfo?: ApiCallResultPageInfo
}

export interface RemoteViewRenderSingleProps<T extends AnyModel> {
	record: T
}

export interface RemoteViewRenderEmptyProps<T extends AnyModel> {
	pageInfo?: ApiCallResultPageInfo
}

export interface IRemoteViewProps<T extends AnyModel> {
	model: ModelClass<T>
	modelId?: number | string
	apiOptions?: ApiGetOptions
	render?: React.ComponentType<RemoteViewRenderProps<T>> | React.ComponentType<RemoteViewRenderProps<T>>
	renderSingle?:
		| React.ComponentType<RemoteViewRenderSingleProps<T>>
		| React.ComponentType<RemoteViewRenderSingleProps<T>>
	renderEmpty?: React.ComponentType<RemoteViewRenderEmptyProps<T>> | React.ComponentType<RemoteViewRenderEmptyProps<T>>
	page?: number
	pageSize?: number
	skeleton?: React.ReactElement
	defaultSort?: ApiSort
	allowSearch?: boolean
	rowsPerPageOptions?: number[]

	// Table
	attributes?: RemoteAttribute[]
	table?: RemoteTableConfig<T>

	// Header
	title?: string
	intro?: any
	headerButtons?: React.ReactElement
	renderTitle?: (props: RemoteViewRenderProps<T>) => string
	hideHeader?: boolean

	// Variant
	variant?: 'custom' | 'table'
}

function RemoteView<T extends AnyModel>({
	model,
	page,

	table,
	apiOptions = {},
	modelId,
	skeleton,
	title,
	intro,
	defaultSort,
	renderTitle,
	hideHeader,
	headerButtons,
	variant = 'custom',
	rowsPerPageOptions = [5, 10, 25, 100, 250],
	allowSearch,

	...props
}: IRemoteViewProps<T>) {
	// State
	const [isLoading, setIsLoading] = React.useState<boolean>(false)
	const [pageInfo, setPageInfo] = React.useState<ApiCallResultPageInfo | undefined>()
	const [currentPage, setCurrentPage] = React.useState<number>(page || 1)
	const [pageSize, setPageSize] = React.useState<number>(props.pageSize || 25)
	const [records, setRecords] = React.useState<T[] | null>(null)
	const [sortBy, setSortBy] = React.useState<ApiSort | undefined>()
	const [search, setSearch] = React.useState<string>('')

	// Check the attributes
	const [attributes, setAttributes] = React.useState<RemoteAttribute[] | undefined>(props.attributes)

	// Get the rendering
	const RenderContent = props.render
	const RenderEmpty = props.renderEmpty
	const RenderSingle = props.renderSingle

	// Store
	const { api, gui } = useStore()

	// Remember previous versions
	const previousModel = usePrevious(model)
	const previousApiOptionsJSON = usePrevious(JSON.stringify(apiOptions))
	const previousModelId = usePrevious(modelId)
	const previousPage = usePrevious(currentPage)
	const previousPageSize = usePrevious(pageSize)
	const previousSortBy = usePrevious(sortBy)
	const previousSearch = usePrevious(search)

	// Set records
	const setResult = React.useCallback(
		(result: ApiCallResult<T>) => {
			// No attributes known yet?
			if (!attributes) {
				// Use the fields we have in the model
				const attr = map(
					getModelDataType(model)().typeInfo.props,
					(prop: any, key: string): RemoteAttribute => {
						return {
							key
						}
					}
				)
				setAttributes(attr)
			}

			// Do it
			setPageInfo(result.pageInfo)
			setRecords(result.records)
		},
		[attributes]
	)

	// Apply default sort
	React.useEffect(() => {
		if (defaultSort && !sortBy) setSortBy(defaultSort)
	}, [defaultSort])

	// Apply attributes
	React.useEffect(() => {
		if (props.attributes) setAttributes(props.attributes)
	}, [props.attributes])

	/**
	 * LOAD RECORDS
	 */
	const loadRecords = React.useCallback(async () => {
		// Make the call
		setIsLoading(true)
		try {
			const result = await api.all(
				model,
				modelId
					? {
							// Single
							...apiOptions
					  }
					: {
							// Collection
							...apiOptions,
							page: currentPage,
							pageSize,
							sort: sortBy,
							search: search.length > 0 ? search : undefined
					  },
				modelId ? `${getApiUri(model)}/${modelId}` : undefined
			)
			setResult(result)
		} catch (error) {
			gui.showError(error)
		}

		// Done.
		setIsLoading(false)
	}, [model, modelId, currentPage, apiOptions, pageSize, sortBy, search, gui])

	// Check changes in input
	React.useEffect(() => {
		// Actually different?
		if (
			model &&
			previousModel &&
			model.name === previousModel.name &&
			modelId === previousModelId &&
			currentPage === previousPage &&
			pageSize === previousPageSize &&
			sortBy === previousSortBy &&
			search === previousSearch &&
			JSON.stringify(apiOptions) === previousApiOptionsJSON
		)
			return

		// Load
		loadRecords()
	}, [model, modelId, currentPage, apiOptions, pageSize, sortBy, search])

	// Render inside
	const renderInside = React.useCallback(() => {
		// Empty?
		if (records && records.length === 0 && RenderEmpty) return <RenderEmpty pageInfo={pageInfo} />

		// Single record?
		if (RenderSingle) {
			if (records && records.length > 0) return <RenderSingle record={records[0]} />
			return null
		}

		// What variant?
		switch (variant) {
			/**
			 * TABLE
			 */
			case 'table':
				if (!records) return null
				return (
					<RemoteTable<T>
						attributes={attributes!}
						pageInfo={pageInfo}
						records={records!}
						currentSort={sortBy}
						onSort={sort => setSortBy(sort)}
						{...table}
						pagination={
							pageInfo && (
								<TablePagination
									component={'div'}
									rowsPerPageOptions={rowsPerPageOptions}
									onChangePage={(e, page) => {
										setCurrentPage(page + 1)
									}}
									onChangeRowsPerPage={({ target }) => setPageSize(parseInt(target.value))}
									rowsPerPage={pageInfo.perPage}
									page={pageInfo.currentPage - 1}
									count={pageInfo.totalRecords}
								/>
							)
						}>
						{RenderContent && <RenderContent records={records} pageInfo={pageInfo} />}
					</RemoteTable>
				)

			/**
			 * CUSTOM
			 */
			case 'custom':
			default:
				if (!RenderContent) throw 'Please provide a "render" component or method on the RemoteView.'
				return <RenderContent records={records!} pageInfo={pageInfo} />
		}
	}, [RenderContent, RenderEmpty, RenderSingle, records, pageInfo, attributes])

	// On search
	const onSearch = React.useMemo(() => {
		if (allowSearch === false) return undefined
		return (query: string) => {
			setSearch(query)
		}
	}, [allowSearch])

	// The container
	return (
		<React.Fragment>
			{(title || renderTitle) && !hideHeader && (
				<PageHeader
					onSearch={onSearch}
					isLoading={isLoading}
					buttons={headerButtons}
					title={renderTitle ? (records ? renderTitle({ records, pageInfo }) : '...') : title!}
				/>
			)}
			{intro && <Intro>{intro}</Intro>}
			{isLoading && !records ? skeleton || <Skeleton height={200} /> : renderInside()}
		</React.Fragment>
	)
}
export default RemoteView
