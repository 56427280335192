import * as React from 'react'
import classNames from 'classnames'
import {
	Button as MuiButton,
	ButtonTypeMap,
	ButtonProps,
	CircularProgress,
	Typography,
	Theme,
	createStyles,
	makeStyles,
	PropTypes
} from '@material-ui/core'
import styled from 'styled-components'

// ███████╗████████╗██╗   ██╗██╗     ███████╗███████╗
// ██╔════╝╚══██╔══╝╚██╗ ██╔╝██║     ██╔════╝██╔════╝
// ███████╗   ██║    ╚████╔╝ ██║     █████╗  ███████╗
// ╚════██║   ██║     ╚██╔╝  ██║     ██╔══╝  ╚════██║
// ███████║   ██║      ██║   ███████╗███████╗███████║
// ╚══════╝   ╚═╝      ╚═╝   ╚══════╝╚══════╝╚══════╝
//

const Btn = styled(MuiButton)`
	height: 48px;

	&.danger {
		background: ${props => props.theme.palette.error.main};
		color: ${props => props.theme.palette.error.contrastText};
	}

	&.primary {
		background: ${props => props.theme.palette.primary.main};
		color: ${props => props.theme.palette.primary.contrastText};
	}

	&.outlined {
		&.danger {
			color: ${props => props.theme.palette.error.main};
			background: transparent;
			border: 1px solid ${props => props.theme.palette.error.main}99;
			&:hover {
				background: ${props => props.theme.palette.error.main}11;
			}
		}
	}

	.fa {
		font-size: 1em !important;
	}
	&.small {
		font-size: 12px;
		height: 36px;
	}
	&.mini {
		font-size: 10px;
		height: 32px;
	}
`

//  ██████╗ ██████╗ ███╗   ███╗██████╗  ██████╗ ███╗   ██╗███████╗███╗   ██╗████████╗
// ██╔════╝██╔═══██╗████╗ ████║██╔══██╗██╔═══██╗████╗  ██║██╔════╝████╗  ██║╚══██╔══╝
// ██║     ██║   ██║██╔████╔██║██████╔╝██║   ██║██╔██╗ ██║█████╗  ██╔██╗ ██║   ██║
// ██║     ██║   ██║██║╚██╔╝██║██╔═══╝ ██║   ██║██║╚██╗██║██╔══╝  ██║╚██╗██║   ██║
// ╚██████╗╚██████╔╝██║ ╚═╝ ██║██║     ╚██████╔╝██║ ╚████║███████╗██║ ╚████║   ██║
//  ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝      ╚═════╝ ╚═╝  ╚═══╝╚══════╝╚═╝  ╚═══╝   ╚═╝
//
export interface IButtonProps extends ButtonProps {
	loading?: boolean
	danger?: boolean
	primary?: boolean
	small?: boolean
	mini?: boolean
}

const Button = ({
	loading = false,
	variant = 'contained',
	mini,
	small,
	danger,
	primary,
	className,
	disabled,
	children,
	...rest
}: IButtonProps) => {
	return (
		<Btn
			variant={variant}
			disabled={loading || disabled}
			className={classNames(className, { danger, primary, mini, small, outlined: variant === 'outlined' })}
			{...rest}>
			{loading ? (
				<React.Fragment>
					<CircularProgress color={'inherit'} size={16} />
				</React.Fragment>
			) : (
				children
			)}
		</Btn>
	)
}
export default Button
