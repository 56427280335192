import { Container, TableCell } from '@material-ui/core'
import * as React from 'react'
import { useHistory, Link } from 'react-router-dom'
import RemoteView from '~components/remote-data/RemoteView'
import PageUri from '~core/enums/pages'
import { Line } from '~store/models/Line'
import AddBoxIcon from '@material-ui/icons/AddBox'
import Icon from '~components/gui/Icon'
import IconButton from '~components/buttons/IconButton'
import { LineConfiguration } from '~store/models/LineConfiguration'
import { Place } from '~store/models/Place'
import { FontAwesomeIconName } from '~core/enums/icons'
import Button from '~components/buttons/Button'
import { PlaceCategory } from '~store/models/PlaceCategory'

export interface PlaceCategoryIndexPageProps {}

const PlaceCategoryIndexPage = (props: PlaceCategoryIndexPageProps) => {
	const history = useHistory()

	// Loading?
	return (
		<Container>
			<RemoteView
				title={'POI categoriën'}
				model={PlaceCategory}
				variant={'table'}
				apiOptions={{ include: [] }}
				defaultSort={{ attribute: 'slug', direction: 'asc' }}
				attributes={[
					{ key: 'icon', label: 'Icon', sortable: true },
					{ key: 'slug', label: 'Slug', sortable: true },
					{ key: 'title', label: 'Titel', sortable: false },
				]}
				table={{
					renderCell: {
						icon: record => (
							<TableCell key={'icon'}>
								<Icon name={record.iconName as FontAwesomeIconName} inline />
							</TableCell>
						),
					},
					onSelectRow: record => history.push(`${PageUri.PlaceCategories}/${record.id}`),
				}}
				headerButtons={
					<React.Fragment>
						<IconButton icon={'map-marker-alt'} edge={'end'} to={`${PageUri.Places}`} color='default' title={`POI's`} />
						<IconButton
							icon={'plus-square'}
							edge={'end'}
							to={`${PageUri.PlaceCategories}/nieuw`}
							color='default'
							title={'Nieuwe POI-categorie'}
						/>
					</React.Fragment>
				}
			/>
		</Container>
	)
}
export default PlaceCategoryIndexPage
