import { model, tProp, types, modelAction } from 'mobx-keystone'
import { ApiModel, ApiModelConfig } from '~api/model/ApiModel'
import { Translation } from './utility/Translation'
import { PlaceCategory } from './PlaceCategory'
import { computed } from 'mobx'
import { FontAwesomeIconName } from '~core/enums/icons'

@model('Place')
export class Place extends ApiModel({
	id: tProp(types.maybe(types.number)),
	place_category_id: tProp(types.number),
	category: tProp(types.maybe(types.model<PlaceCategory>(PlaceCategory))),
	concession_slugs: tProp(types.maybeNull(types.array(types.string))),
	slug: tProp(types.string),
	title: tProp(types.model<Translation>(Translation)),
	description: tProp(types.model<Translation>(Translation)),
	website_url: tProp(types.model<Translation>(Translation)),
	image_url: tProp(types.maybeNull(types.string)),
	icon: tProp(types.maybeNull(types.string)),
	color: tProp(types.maybeNull(types.string)),
	latitude: tProp(types.number),
	longitude: tProp(types.number),
	streetname: tProp(types.string),
	housenumber: tProp(types.string),
	postalcode: tProp(types.string),
	city: tProp(types.string),
}) {
	public apiConfig(): ApiModelConfig {
		return {
			relationships: [
				{
					name: 'category',
					type: 'BelongsTo',
					model: PlaceCategory,
					foreignKey: 'place_category_id',
				},
			],
		}
	}

	@modelAction
	setLatLng(lat: number, lng: number) {
		this.latitude = lat
		this.longitude = lng
	}

	@computed
	get iconName(): FontAwesomeIconName | null {
		// Me?
		if (this.icon) return this.icon.replace(/^fa\-/, '') as FontAwesomeIconName

		// Category
		if (this.category && this.category.icon) return this.category.icon.replace(/^fa\-/, '') as FontAwesomeIconName

		return null
	}
}
