import { Input, InputAdornment, InputProps } from '@material-ui/core'
import { ModelInstanceData } from 'mobx-keystone'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AnyApiModel } from '~api/model/ApiModel'
import HtmlEditor from '~components/forms/HtmlEditor'
import { LanguageFlags } from '~core/enums/language'
import Field, { IFieldProps } from './Field'
import { useFormRecord } from './ModelFormContext'
import { Translation } from '~store/models/utility/Translation'

export interface ITranslationsFieldProps {
	record?: AnyApiModel
	attribute: string
	required?: boolean
	input?: InputProps
	label?: string
	field?: IFieldProps
	html?: boolean
}

const TranslationsField = (props: ITranslationsFieldProps) => {
	const formRecord = useFormRecord()

	const { attribute, label, required = false, html = false, field } = props
	const record = props.record || formRecord

	const [translation, setTranslation] = React.useState<Translation>()
	React.useEffect(() => {
		// No record?
		if (!record) return

		// One in record already?
		let value = record[attribute]

		// Nothing?
		if (!record[attribute]) {
			value = new Translation({ nl: '', en: '' })
			record.setAttribute(attribute, value)
		}

		// Done.
		setTranslation(value)
	}, [record, attribute])

	// const translation: Translation = React.useMemo(() => record![attribute] || { nl: '', en: '' }, [record])

	if (!record) throw `You need to set the record attribute, or render inside a ModelFormProvider`

	return translation ? (
		<>
			{['nl', 'en'].map(lang => (
				<Field
					labelProps={{ shrink: html ? true : undefined }}
					label={lang === 'nl' ? label : ' '}
					required={lang === 'nl' ? required : false}
					grid={{ xs: 6 }}
					key={lang}
					attribute={attribute}
					{...field}>
					{html ? (
						<HtmlEditor
							onChange={value => {
								translation.setAttribute(lang, value)
							}}
							value={translation[lang]}
						/>
					) : (
						<Input
							{...props.input}
							onChange={e => translation.setAttribute(lang, e.target.value)}
							startAdornment={
								<InputAdornment position='start'>
									<img src={LanguageFlags[lang]} width='24' />
								</InputAdornment>
							}
							value={translation[lang]}
						/>
					)}
				</Field>
			))}
		</>
	) : null
}
export default observer(TranslationsField)
