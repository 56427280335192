import { Model, model, tProp, modelAction, modelFlow, _async, _await, types } from 'mobx-keystone'
import { ValidationRule, ModelValidationConfig } from '~api/model/validateModel'
import { computed } from 'mobx'

@model('Destination')
export class Destination extends Model({
	id: tProp(types.maybe(types.number)),
	key: tProp(types.string),
	name: tProp(types.string)
}) {
	static validation(record?: Destination): ModelValidationConfig {
		return {
			required: []
		}
	}
}
