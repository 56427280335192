import { Model, model, tProp, types } from 'mobx-keystone'
import { ModelValidationConfig } from '~api/model/validateModel'
import { StopVersion } from './StopVersion'

@model('JourneyStep')
export class JourneyStep extends Model({
	id: tProp(types.number),
	order: tProp(types.number),
	is_timing_stop: tProp(types.boolean),
	polyline: tProp(types.string),

	fromStopVersion: tProp(types.maybe(types.model<StopVersion>(StopVersion))),
	toStopVersion: tProp(types.maybe(types.model<StopVersion>(StopVersion)))
}) {}
