import * as React from 'react'
import { debounce } from 'lodash'
import { FormControl, Theme, createStyles, makeStyles } from '@material-ui/core'
import { convertFromHTML, ContentState, convertToRaw, EditorState } from 'draft-js'
import MUIRichTextEditor from 'mui-rte'
import { stateToHTML } from 'draft-js-export-html'

// ███████╗████████╗██╗   ██╗██╗     ███████╗███████╗
// ██╔════╝╚══██╔══╝╚██╗ ██╔╝██║     ██╔════╝██╔════╝
// ███████╗   ██║    ╚████╔╝ ██║     █████╗  ███████╗
// ╚════██║   ██║     ╚██╔╝  ██║     ██╔══╝  ╚════██║
// ███████║   ██║      ██║   ███████╗███████╗███████║
// ╚══════╝   ╚═╝      ╚═╝   ╚══════╝╚══════╝╚══════╝
//

const styles = ({ palette, spacing }: Theme) =>
	createStyles({
		main: {
			paddingTop: 12
		}
	})
const useStyles = makeStyles(styles)

export interface IHtmlEditorProps {
	value?: string
	onChange?: (value: string) => any
}

const HtmlEditor = ({ value, onChange }: IHtmlEditorProps) => {
	const classes = useStyles()

	const [editorState, setEditorState] = React.useState<any>()
	const [_value, _setValue] = React.useState<string>('')

	const createEditorState = (value: string) => {
		// Convert HTML to editor state
		const html = convertFromHTML(value || '')
		const state = ContentState.createFromBlockArray(html.contentBlocks, html.entityMap)

		// Set it
		const json = JSON.stringify(convertToRaw(state))
		setEditorState(json)
	}

	const handleChange = React.useCallback(
		debounce((state: EditorState) => {
			const html = stateToHTML(state.getCurrentContent())
			_setValue(html)
			onChange && onChange(html)
		}, 250),
		[onChange]
	)

	React.useEffect(() => {
		// Same?
		if (value === _value) return

		// Override
		if (!editorState) createEditorState(value || '')
	}, [value, editorState])

	return (
		<div className={classes.main}>
			<MUIRichTextEditor
				controls={[
					'title',
					'bold',
					'italic',
					'underline',
					'link',
					'numberList',
					'bulletList',
					'quote',
					'clear',
					'strikethrough'
				]}
				value={editorState}
				onChange={handleChange}
			/>
		</div>
	)
}
export default HtmlEditor
