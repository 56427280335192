import { Container, TableCell } from '@material-ui/core'
import * as React from 'react'
import { useHistory, Link } from 'react-router-dom'
import RemoteView from '~components/remote-data/RemoteView'
import PageUri from '~core/enums/pages'
import { Line } from '~store/models/Line'
import AddBoxIcon from '@material-ui/icons/AddBox'
import Icon from '~components/gui/Icon'
import IconButton from '~components/buttons/IconButton'
import { LineConfiguration } from '~store/models/LineConfiguration'
import { NavigationItem } from '~store/models/NavigationItem'
import { FontAwesomeIconName } from '~core/enums/icons'

export interface NavigationItemIndexPageProps {}

const NavigationItemIndexPage = (props: NavigationItemIndexPageProps) => {
	const history = useHistory()

	// Loading?
	return (
		<Container>
			<RemoteView
				title={'Navigatie items'}
				model={NavigationItem}
				variant={'table'}
				apiOptions={{ include: ['navigation'] }}
				attributes={[
					{ key: 'navigation', label: 'Navigatie', sortable: true },
					{ key: 'title', label: 'Titel', sortable: true },
					{ key: 'url', label: 'URL', sortable: true },
					{ key: 'icon', label: 'Icoon' }
				]}
				table={{
					renderCell: {
						navigation: record => <TableCell key={'navigation'}>{record.navigation!.key}</TableCell>,
						icon: record => (
							<TableCell key={'icon'}>{record.icon && <Icon name={record.icon as FontAwesomeIconName} />}</TableCell>
						)
					},
					onSelectRow: record => history.push(`${PageUri.NavigationItems}/${record.id}`)
				}}
			/>
		</Container>
	)
}
export default NavigationItemIndexPage
