import { Model, model, tProp, modelAction, modelFlow, _async, _await, types, tProp_setArray } from 'mobx-keystone'
import { ValidationRule, ModelValidationConfig } from '~api/model/validateModel'
import { computed } from 'mobx'
import { ApiModel } from '~api/model/ApiModel'

@model('Translation')
export class Translation extends ApiModel({
	nl: tProp(types.string, ''),
	en: tProp(types.string, '')
}) {}
