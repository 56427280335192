import {
	Model,
	model,
	prop,
	modelAction,
	modelFlow,
	_async,
	_await,
	ModelClass,
	AnyModel,
	getModelDataType,
	getTypeInfo,
	Draft,
	tProp,
	types,
} from 'mobx-keystone'
import { post, get, call } from '~api/calls'
import { User } from '~store/models/User'
import { ApiGetOptions, ApiCallResult, ApiCallOptions } from '~api/types'
import { AxiosError, Method } from 'axios'
import inflection from 'inflection'
import { getApiUri } from '~api/helpers'
import { SaveModelOptions, SaveModelResult, saveModel } from '~api/model/saveModel'
import { deleteModel, DeleteModelResult } from '~api/model/deleteModel'
import { AnyApiModel } from '~api/model/ApiModel'
import lscache from 'lscache'

const SessionStorageKey = 'keolis-reizen-cms:token'
const TokenValidForSeconds = 1800

@model('cms/ApiStore')
export class ApiStore extends Model({
	isAuthenticated: tProp(types.boolean, false),
	token: tProp(types.maybeNull(types.string), null),
	tokenError: tProp(types.maybeNull(types.string), null),

	user: tProp(types.maybeNull(types.model<User>(User)), null),
}) {
	onInit() {
		// Check session
		const token = lscache.get(SessionStorageKey)
		if (token || this.token) {
			// Store that token
			if (token) {
				this.token = token
				this.isAuthenticated = true
			}

			// Get the current user (validating the token in the process)
			this.getUser()
		}
	}

	//  █████╗ ██╗   ██╗████████╗██╗  ██╗███████╗███╗   ██╗████████╗██╗ ██████╗ █████╗ ████████╗██╗ ██████╗ ███╗   ██╗
	// ██╔══██╗██║   ██║╚══██╔══╝██║  ██║██╔════╝████╗  ██║╚══██╔══╝██║██╔════╝██╔══██╗╚══██╔══╝██║██╔═══██╗████╗  ██║
	// ███████║██║   ██║   ██║   ███████║█████╗  ██╔██╗ ██║   ██║   ██║██║     ███████║   ██║   ██║██║   ██║██╔██╗ ██║
	// ██╔══██║██║   ██║   ██║   ██╔══██║██╔══╝  ██║╚██╗██║   ██║   ██║██║     ██╔══██║   ██║   ██║██║   ██║██║╚██╗██║
	// ██║  ██║╚██████╔╝   ██║   ██║  ██║███████╗██║ ╚████║   ██║   ██║╚██████╗██║  ██║   ██║   ██║╚██████╔╝██║ ╚████║
	// ╚═╝  ╚═╝ ╚═════╝    ╚═╝   ╚═╝  ╚═╝╚══════╝╚═╝  ╚═══╝   ╚═╝   ╚═╝ ╚═════╝╚═╝  ╚═╝   ╚═╝   ╚═╝ ╚═════╝ ╚═╝  ╚═══╝
	//

	/**
	 * LOGIN
	 */
	@modelFlow
	login = _async(function* (this: ApiStore, email: string, password: string) {
		// Get result
		this.tokenError = null
		const result: any = yield* _await(
			call('/admin/authenticate', 'post', {
				deserialize: false,
				data: {
					email,
					password,
				},
			})
		)

		// Store the token
		if (result && result.token) {
			// Store the token
			this.token = result.token
			this.isAuthenticated = true

			// Store in session too
			lscache.set(SessionStorageKey, this.token!, TokenValidForSeconds)

			// Get the current user
			yield* _await(this.getUser())
		} else {
			throw 'Het is niet gelukt om in te loggen.'
		}
	})

	/**
	 * LOGOUT
	 */
	@modelAction
	logout() {
		sessionStorage.clear()
		this.isAuthenticated = false
		this.token = null
	}

	/**
	 * GET USER
	 */
	@modelFlow
	getUser = _async(function* (this: ApiStore) {
		// Get user
		try {
			const user = yield* _await<User>(this.one(User, '/admin/session/user'))
			this.user = user
		} catch (error) {
			// Any error will log you out.
			this.logout()
			this.tokenError = error.toString()
		}
	})

	//  █████╗ ██████╗ ██╗     ██████╗ █████╗ ██╗     ██╗     ███████╗
	// ██╔══██╗██╔══██╗██║    ██╔════╝██╔══██╗██║     ██║     ██╔════╝
	// ███████║██████╔╝██║    ██║     ███████║██║     ██║     ███████╗
	// ██╔══██║██╔═══╝ ██║    ██║     ██╔══██║██║     ██║     ╚════██║
	// ██║  ██║██║     ██║    ╚██████╗██║  ██║███████╗███████╗███████║
	// ╚═╝  ╚═╝╚═╝     ╚═╝     ╚═════╝╚═╝  ╚═╝╚══════╝╚══════╝╚══════╝
	//

	/**
	 * Get single model from database
	 */
	async one<T extends AnyModel>(
		model: ModelClass<T>,
		uriOrId: string | number,
		options: ApiGetOptions = {}
	): Promise<T> {
		// Set cast as
		options.castAs = model

		// Get the one
		const result = await this.get(getApiUri(model, uriOrId), options)
		if (result && result.records.length > 0) return result.records[0] as T

		throw 'Record not found'
	}

	/**
	 * Get all records from database
	 */
	async all<T extends AnyModel>(
		model: ModelClass<T>,
		options: ApiGetOptions = {},
		uri?: string
	): Promise<ApiCallResult<T>> {
		// Set cast as
		options.castAs = model

		// Get the one
		return await this.get(uri || getApiUri(model), options)
	}

	/**
	 * API: GET
	 * @param uri
	 * @param options
	 */
	async get(uri: string, options: ApiGetOptions = {}): Promise<ApiCallResult<any>> {
		try {
			// Make the call
			return await get(uri, {
				...options,
				token: this.isAuthenticated && this.token ? this.token : undefined,
			})
		} catch (error) {
			this._handleApiCallError(error)
			throw error
		}
	}

	/**
	 * API: Call
	 * @param uri
	 * @param method
	 * @param options
	 */
	async call(uri: string, method: Method, options: ApiGetOptions = {}): Promise<ApiCallResult<any>> {
		try {
			// Make the call
			return await call(uri, method, {
				...options,
				token: this.isAuthenticated && this.token ? this.token : undefined,
			})
		} catch (error) {
			this._handleApiCallError(error)
			throw error
		}
	}

	/**
	 * API: Save model
	 * @param error
	 */
	async saveModel(
		draft: Draft<AnyApiModel>,
		options: Partial<SaveModelOptions> = {},
		apiOptions: ApiCallOptions = {}
	): Promise<SaveModelResult> {
		try {
			// Make the call
			return await saveModel(draft, options, {
				...apiOptions,
				token: this.isAuthenticated && this.token ? this.token : undefined,
			})
		} catch (error) {
			this._handleApiCallError(error)
			throw error
		}
	}

	/**
	 * API: Delete model
	 * @param error
	 */
	async deleteModel(record: AnyModel, apiOptions: ApiCallOptions = {}): Promise<DeleteModelResult> {
		try {
			// Make the call
			return await deleteModel(record, {
				...apiOptions,
				token: this.isAuthenticated && this.token ? this.token : undefined,
			})
		} catch (error) {
			this._handleApiCallError(error)
			throw error
		}
	}

	/**
	 * Handle request error
	 * @param error
	 */
	@modelAction
	_handleApiCallError(error: AxiosError | any) {
		// Axios error?
		if (error.isAxiosError) {
			const ae = error as AxiosError
			if (ae.response) {
				// Bad request?
				if ((ae.response.status === 400 || ae.response.status === 401) && ae.response.data.error) {
					// Check which error
					switch (ae.response.data.error) {
						case 'token_expired':
						case 'token_invalid':
						case 'token_not_provided':
							this.tokenError = 'Je sessie is verlopen, log alsjeblieft opnieuw in.'
							this.isAuthenticated = false
							this.user = null
							this.token = null
							return

						default:
							console.warn('UNKNOWN ERROR', ae.response.data.error)
					}
				}
			} else {
				// Don't know what this is...
				throw error
			}
		} else {
			// Don't know what this is...
			throw error
		}
	}
}
