import * as React from 'react'
import { createStyles, makeStyles, Theme, Grid, Typography } from '@material-ui/core'

// ███████╗████████╗██╗   ██╗██╗     ███████╗███████╗
// ██╔════╝╚══██╔══╝╚██╗ ██╔╝██║     ██╔════╝██╔════╝
// ███████╗   ██║    ╚████╔╝ ██║     █████╗  ███████╗
// ╚════██║   ██║     ╚██╔╝  ██║     ██╔══╝  ╚════██║
// ███████║   ██║      ██║   ███████╗███████╗███████║
// ╚══════╝   ╚═╝      ╚═╝   ╚══════╝╚══════╝╚══════╝
//

const styles = ({ palette, spacing }: Theme) =>
	createStyles({
		main: {},
		title: {
			color: palette.primary.main,
			fontSize: 16,
			marginBottom: spacing(2)
		},
		grid: {
			'&:not(:last-child)': {
				marginBottom: spacing(3)
			}
		}
	})
const useStyles = makeStyles(styles)

//  ██████╗ ██████╗ ███╗   ███╗██████╗  ██████╗ ███╗   ██╗███████╗███╗   ██╗████████╗
// ██╔════╝██╔═══██╗████╗ ████║██╔══██╗██╔═══██╗████╗  ██║██╔════╝████╗  ██║╚══██╔══╝
// ██║     ██║   ██║██╔████╔██║██████╔╝██║   ██║██╔██╗ ██║█████╗  ██╔██╗ ██║   ██║
// ██║     ██║   ██║██║╚██╔╝██║██╔═══╝ ██║   ██║██║╚██╗██║██╔══╝  ██║╚██╗██║   ██║
// ╚██████╗╚██████╔╝██║ ╚═╝ ██║██║     ╚██████╔╝██║ ╚████║███████╗██║ ╚████║   ██║
//  ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝      ╚═════╝ ╚═╝  ╚═══╝╚══════╝╚═╝  ╚═══╝   ╚═╝
//
export interface IFieldsetProps {
	children: any
	title?: string
}

const Fieldset = ({ children, title }: IFieldsetProps) => {
	// Styles
	const classes = useStyles()

	return (
		<React.Fragment>
			{title && (
				<Typography variant='h3' gutterBottom className={classes.title}>
					{title}
				</Typography>
			)}
			<Grid container spacing={3} className={classes.grid}>
				{children}
			</Grid>
		</React.Fragment>
	)
}
export default Fieldset
