import { ApiCallOptions, ApiCallResult, ApiCallResultPageInfo } from './types'
import { AxiosResponse } from 'axios'
import { deserializeApiResult } from './deserialize'
import { castModels } from './cast'

export function postProcessApiResult<T>(response: AxiosResponse, options: ApiCallOptions): ApiCallResult<T> {
	// No deserialization?
	if (options.deserialize === false) return response.data

	// Deserialize
	let results = deserializeApiResult(response.data).map(item =>
		options.extraAttributes
			? {
					...item,
					...options.extraAttributes
			  }
			: item
	)

	let records
	if (options.castAs) {
		// Cast models, and get linked models for in the global stores
		const models = castModels(results, options.castAs)

		// Add
		records = (models as unknown) as T[]
	} else {
		// As they are
		records = (results as unknown) as T[]
	}

	// Check page info
	let pageInfo: ApiCallResultPageInfo
	if (response.data.meta && response.data.meta.pagination) {
		const page = response.data.meta.pagination
		pageInfo = {
			currentPage: page.current_page,
			totalPages: page.total_pages,
			totalRecords: page.total,
			perPage: page.per_page
		}
	}

	// Make result
	return {
		records,
		pageInfo
	}
}
