import * as React from 'react'
import styled from 'styled-components'
import { FontAwesomeIconName } from '~core/enums/icons'
import { Button, ButtonProps } from '@material-ui/core'
import classNames from 'classnames'
import Icon from '~components/gui/Icon'

// ███████╗████████╗██╗   ██╗██╗     ███████╗██████╗
// ██╔════╝╚══██╔══╝╚██╗ ██╔╝██║     ██╔════╝██╔══██╗
// ███████╗   ██║    ╚████╔╝ ██║     █████╗  ██║  ██║
// ╚════██║   ██║     ╚██╔╝  ██║     ██╔══╝  ██║  ██║
// ███████║   ██║      ██║   ███████╗███████╗██████╔╝
// ╚══════╝   ╚═╝      ╚═╝   ╚══════╝╚══════╝╚═════╝
//

const Btn = styled(Button)`
	border-radius: 0;
	text-transform: none;
	padding: ${props => props.theme.spacing(1)}px ${props => props.theme.spacing(2)}px;

	&:not(:last-child) {
		border-right: 2px dotted #ffffff99;
	}

	/* background: ${props => props.theme.palette.secondary.main}; */
	&.active {
	  background: ${props => props.theme.palette.background.default};
		color: ${props => props.theme.palette.secondary.main};
	}

	> span {
		font-size: 12px;
	}
`
const I = styled(Icon)`
	font-size: 1.25em;
`
const Label = styled.span`
	display: inline-block;
	margin-left: 0.5em;
`

//  ██████╗ ██████╗ ███╗   ███╗██████╗  ██████╗ ███╗   ██╗███████╗███╗   ██╗████████╗
// ██╔════╝██╔═══██╗████╗ ████║██╔══██╗██╔═══██╗████╗  ██║██╔════╝████╗  ██║╚══██╔══╝
// ██║     ██║   ██║██╔████╔██║██████╔╝██║   ██║██╔██╗ ██║█████╗  ██╔██╗ ██║   ██║
// ██║     ██║   ██║██║╚██╔╝██║██╔═══╝ ██║   ██║██║╚██╗██║██╔══╝  ██║╚██╗██║   ██║
// ╚██████╗╚██████╔╝██║ ╚═╝ ██║██║     ╚██████╔╝██║ ╚████║███████╗██║ ╚████║   ██║
//  ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝      ╚═════╝ ╚═╝  ╚═══╝╚══════╝╚═╝  ╚═══╝   ╚═╝
//
export interface IToolbarButtonProps extends ButtonProps {
	icon: FontAwesomeIconName
	label?: string
	active?: boolean
}

const ToolbarButton = (props: IToolbarButtonProps) => {
	const { icon, label, active, ...buttonProps } = props

	return (
		<Btn className={classNames(props.className, { active })} {...buttonProps}>
			<I name={icon} />
			{label && <Label>{label}</Label>}
		</Btn>
	)
}
export default ToolbarButton
