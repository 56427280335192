import * as React from 'react'
import styled from 'styled-components'

export interface IErrorBoundaryProps {
	children: any
	renderError?: (error: any) => React.ReactNode
}
export interface IErrorBoundaryState {
	hasError: boolean
	error?: any
}

class ErrorBoundary extends React.Component<IErrorBoundaryProps> {
	state = { hasError: false, error: null }

	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true, error }
	}

	componentDidCatch(error, errorInfo) {
		// You can also log the error to an error reporting service
	}

	render() {
		const { children, renderError } = this.props
		const { hasError, error } = this.state

		// Error?
		if (this.state.hasError)
			return renderError ? renderError(error) : <h1>Error: {error ? error.toString() : 'Unknown'}</h1>

		return <React.Fragment>{children}</React.Fragment>
	}
}
export default ErrorBoundary
