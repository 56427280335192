import * as React from 'react'
import styled from 'styled-components'
import { useLeaflet } from 'react-leaflet'
import { Draw, Map as LeafletMap, Polyline } from 'leaflet'
import { Map } from '~store/models/Map'
import { useLeafletMap } from '~components/leaflet/useLeafletMap'

export interface IMapListenerProps {
	map: Map
}

const MapListener = (props: IMapListenerProps) => {
	const leafletMap = useLeafletMap()
	const mapModel = props.map

	const applyViewport = React.useCallback(
		(map: LeafletMap) => {
			// Apply
			mapModel.setBounds(map.getBounds())

			// Set rendered
			window['IMAGE_RENDERED'] = true
		},
		[mapModel]
	)

	React.useEffect(() => {
		if (leafletMap) {
			// Zooming, moving and resizing
			leafletMap.leafletElement.addEventListener('zoomend moveend resize', () =>
				applyViewport(leafletMap.leafletElement)
			)
			applyViewport(leafletMap.leafletElement)

			// Escape.
			// map.addEventListener('keyup', e => {
			// 	if (e.originalEvent.key === 'Escape') mapModel.selectLayer(null)
			// })
		}
	}, [leafletMap])

	return null
}
export default MapListener
