import { LatLng, latLngBounds, LatLngBounds, LatLngExpression } from 'leaflet';
import { computed } from 'mobx';

import { lineString, point, Position } from '@turf/helpers';
import lineSlice from '@turf/line-slice';
import {
  findParent,
  isModel,
  Model,
  model,
  modelAction,
  tProp,
  types,
  withoutUndo,
} from 'mobx-keystone';
import { FontAwesomeIconName } from '../enums/icons';
import { ILayerConfig, Layers, LayerType } from '../enums/layers';
import { LineStyle } from '../enums/style';
import {
  getLatLngsFromPolyline,
  latLngBoundsFromBBox,
  getPolylineFromLatLngs,
} from '../utils/geo';
import { MapData } from './MapData';

@model('@mapElement/Layer')
export class Layer extends Model({
  // Basics
  type: tProp(types.enum<LayerType>(LayerType)),
  name: tProp(types.string),

  // Identifiers
  lineNumber: tProp(types.maybeNull(types.string), null),
  stopCode: tProp(types.maybeNull(types.string), null),

  // Data
  latitude: tProp(types.maybeNull(types.number), null),
  longitude: tProp(types.maybeNull(types.number), null),
  polyline: tProp(types.maybeNull(types.string), null),
  stopName: tProp(types.maybeNull(types.string), null),
  fromStopCode: tProp(types.maybeNull(types.string), null),
  toStopCode: tProp(types.maybeNull(types.string), null),
  isCancelled: tProp(types.maybeNull(types.boolean), null),
  imageBase64: tProp(types.maybeNull(types.string), null),

  // Layout
  color: tProp(types.maybeNull(types.string), null),
  backgroundColor: tProp(types.maybeNull(types.string), null),
  showLabel: tProp(types.maybeNull(types.boolean), null),
  icon: tProp(types.maybeNull(types.string), null),
  iconSize: tProp(types.maybeNull(types.number), null),
  lineStyle: tProp(types.maybeNull(types.enum<LineStyle>(LineStyle)), null),
  fontSize: tProp(types.maybeNull(types.or(types.string, types.number)), null),
  label: tProp(types.maybeNull(types.string), null),
  rotation: tProp(types.maybeNull(types.number), null),
  labelRotation: tProp(types.maybeNull(types.number), null),
  imageWidth: tProp(types.maybeNull(types.number), null),

  // State
  isVisible: tProp(types.boolean, true),
  isHovering: tProp(types.boolean, false),
  isEditing: tProp(types.boolean, false),
  isSplitting: tProp(types.boolean, false),
  isSelected: tProp(types.boolean, false),
  isExpanded: tProp(types.boolean, false),

  // Sub layers
  subLayers: tProp(
    types.maybeNull(types.array(types.model<Layer>(() => Layer))),
    null
  ),
}) {
  //  ██████╗ ███████╗████████╗████████╗███████╗██████╗ ███████╗
  // ██╔════╝ ██╔════╝╚══██╔══╝╚══██╔══╝██╔════╝██╔══██╗██╔════╝
  // ██║  ███╗█████╗     ██║      ██║   █████╗  ██████╔╝███████╗
  // ██║   ██║██╔══╝     ██║      ██║   ██╔══╝  ██╔══██╗╚════██║
  // ╚██████╔╝███████╗   ██║      ██║   ███████╗██║  ██║███████║
  //  ╚═════╝ ╚══════╝   ╚═╝      ╚═╝   ╚══════╝╚═╝  ╚═╝╚══════╝
  //

  getAllSubLayers(
    predicate?: (layer: Layer) => boolean,
    allowSublayersForNonPredicatedLayers: boolean = true
  ): Layer[] {
    // Go through 'em all
    const all: Layer[] = [];
    if (!this.subLayers) return all;
    this.subLayers.forEach(sub => {
      const matches = predicate ? predicate(sub) : true;
      if (matches) all.push(sub);
      if (matches || allowSublayersForNonPredicatedLayers) {
        const subs = sub.getAllSubLayers(predicate);
        subs.forEach(l => all.push(l));
      }
    });
    return all;
  }

  @computed
  get parentLayer(): Layer | undefined {
    return findParent(this, node => isModel(node) && node instanceof Layer);
  }

  @computed
  get parentObject(): Layer | MapData | undefined {
    return findParent(
      this,
      node =>
        isModel(node) && (node instanceof MapData || node instanceof Layer)
    );
  }

  @computed
  get isHidden(): boolean {
    if (!this.isVisible) return true;
    if (this.parentLayer) return this.parentLayer.isHidden;
    return false;
  }

  @computed
  get layerColor(): string {
    if (this.color) return this.color;
    if (this.parentLayer) return this.parentLayer.layerColor;
    return '#ccc';
  }

  @computed
  get layerIcon(): FontAwesomeIconName {
    if (this.icon) return this.icon as FontAwesomeIconName;
    return this.layerConfig.icon;
  }

  @computed
  get layerConfig(): ILayerConfig {
    return Layers.find(l => l.key === this.type)!;
  }

  @computed
  get latLngBounds(): LatLngBounds | null {
    // Sub layers?
    if (this.subLayers) {
      // Combine children
      let bounds: LatLngBounds | null = null;
      this.subLayers.forEach(layer => {
        const layerBounds = layer.latLngBounds;
        if (!layerBounds) return;
        if (bounds) {
          bounds.extend(layerBounds);
        } else {
          bounds = latLngBoundsFromBBox(layerBounds.toBBoxString());
        }
      });
      return bounds;
    }

    // Polyline
    if (this.polylineLatLngs)
      return latLngBounds(this.polylineLatLngs as LatLngExpression[]);

    // LatLng?
    if (this.latLng) return this.latLng.toBounds(500);

    // Nothing to bound.
    return null;
  }

  @computed
  get polylineLatLngs(): Position[] | null {
    if (!this.polyline) return null;
    return getLatLngsFromPolyline(this.polyline);
  }

  @computed
  get latLng(): LatLng | null {
    if (!this.latitude || !this.longitude) return null;
    return new LatLng(this.latitude, this.longitude);
  }

  @computed
  get hasDottedLine(): boolean {
    return this.lineStyle === LineStyle.Dotted;
  }

  //  █████╗  ██████╗████████╗██╗ ██████╗ ███╗   ██╗███████╗
  // ██╔══██╗██╔════╝╚══██╔══╝██║██╔═══██╗████╗  ██║██╔════╝
  // ███████║██║        ██║   ██║██║   ██║██╔██╗ ██║███████╗
  // ██╔══██║██║        ██║   ██║██║   ██║██║╚██╗██║╚════██║
  // ██║  ██║╚██████╗   ██║   ██║╚██████╔╝██║ ╚████║███████║
  // ╚═╝  ╚═╝ ╚═════╝   ╚═╝   ╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝
  //

  @modelAction
  setDottedLine(value: boolean) {
    this.lineStyle = value ? LineStyle.Dotted : LineStyle.Solid;
  }

  @modelAction
  addLayer(layer: Layer) {
    if (!this.subLayers) this.subLayers = [];
    this.subLayers.push(layer);
  }

  @modelAction
  insertLayer(layer: Layer, insertAfter: Layer) {
    // Get index
    const index = this.subLayers ? this.subLayers.indexOf(insertAfter) || 0 : 0;
    if (!this.subLayers) this.subLayers = [];
    this.subLayers.splice(index, 0, layer);
  }

  @modelAction
  deleteLayer(layer: Layer) {
    if (!this.subLayers) return;
    this.subLayers = this.subLayers.filter(l => l !== layer);
  }

  @modelAction
  setHover(value: boolean) {
    withoutUndo(() => {
      this.isHovering = value;
    });
  }

  @modelAction
  setIsEditing(value: boolean) {
    withoutUndo(() => {
      this.isEditing = value;
    });
  }

  @modelAction
  setSelected(value: boolean) {
    withoutUndo(() => {
      this.isSelected = value;
      if (!value && this.isEditing) this.isEditing = false;
      if (!value && this.isSplitting) this.isSplitting = false;
      if (!value && this.isHovering) this.isHovering = false;
    });
  }

  @modelAction
  toggleExpanded(value?: boolean) {
    withoutUndo(() => {
      this.isExpanded = value === undefined ? !this.isExpanded : value;
    });
  }

  @modelAction
  setLatLng(latLng: LatLng) {
    this.latitude = latLng.lat;
    this.longitude = latLng.lng;
  }

  @modelAction
  setVisible(value: boolean) {
    // Apply the value
    this.isVisible = value;

    // Show? Check parent
    if (value) {
      try {
        const parent = this.parentLayer;
        if (parent && parent.isHidden) parent.setVisible(true);
      } catch (err) {}
    }
  }

  @modelAction
  setProperty(name: string, value: any) {
    //@ts-ignore
    this[name] = value;
  }

  @modelAction
  delete() {
    // Get parent
    const parent = findParent(
      this,
      node =>
        isModel(node) && (node instanceof MapData || node instanceof Layer)
    );

    if (!parent) return;

    // Other layer / map
    parent.deleteLayer(this);
  }

  /**
   * SPLIT
   */
  @modelAction
  splitAt(at: LatLng) {
    // Get the latlngs
    const positions = this.polylineLatLngs;
    if (!positions || positions.length === 0) return;
    const journeyLineString = lineString(positions);

    // Get two polylines
    const firstPart = lineSlice(
      point(positions[0]),
      point([at.lat, at.lng]),
      journeyLineString
    );
    const secondPart = lineSlice(
      point([at.lat, at.lng]),
      positions[positions.length - 1],
      journeyLineString
    );

    // Use first part for me
    this.polyline = getPolylineFromLatLngs(
      firstPart.geometry.coordinates.map(
        coord => new LatLng(coord[0], coord[1])
      )
    );
    this.isSplitting = false;
    this.isEditing = false;

    // Create a copy.
    const copy = new Layer({
      ...this.$,
      polyline: getPolylineFromLatLngs(
        secondPart.geometry.coordinates.map(
          coord => new LatLng(coord[0], coord[1])
        )
      ),
      name: `Kopie ${this.name}`,
      isSelected: false,
      $modelId: undefined,
    });

    // Insert after
    const parent = this.parentObject;
    if (parent) {
      parent.insertLayer(copy, this);
    }
  }
}
