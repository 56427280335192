import {
	Checkbox,
	GridProps,
	InputProps,
	ListItemText,
	MenuItem,
	Checkbox,
	CheckboxProps,
	Grid,
	FormControlLabel,
} from '@material-ui/core'
import { map } from 'lodash'
import { AnyModel } from 'mobx-keystone'
import { observer } from 'mobx-react'
import * as React from 'react'
import Field from './Field'
import { useFormRecord } from './ModelFormContext'
import { AnyApiModel } from '~api/model/ApiModel'

export interface ICheckboxFieldProps {
	record?: AnyApiModel
	attribute: string
	required?: boolean
	label: string | React.ReactNode
	grid?: GridProps
	checkbox?: CheckboxProps
}

const CheckboxField = (props: ICheckboxFieldProps) => {
	const formRecord = useFormRecord()

	const { attribute, label, required = false } = props
	const record = props.record || formRecord

	const checked = record ? record[attribute] : false

	if (!record) throw `You need to set the record attribute, or render inside a ModelFormProvider`

	return (
		<Grid item xs={12} {...props.grid}>
			<FormControlLabel
				control={
					<Checkbox
						checked={checked}
						onChange={e => record.setAttribute(attribute, e.target.checked)}
						color='primary'
					/>
				}
				label={label}
			/>
		</Grid>
	)
}
export default observer(CheckboxField)
