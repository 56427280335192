import * as React from 'react'
import { useLocation, Redirect } from 'react-router-dom'
import PageUri from '../../core/enums/pages'
import {
	Theme,
	createStyles,
	Container,
	makeStyles,
	Avatar,
	Typography,
	TextField,
	Checkbox,
	FormControlLabel,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import useStore from '~store/hooks/useStore'
import { observer } from 'mobx-react'
import Button from '~components/buttons/Button'
import { AxiosError } from 'axios'
import Icon from '~components/gui/Icon'

// ███████╗████████╗██╗   ██╗██╗     ███████╗███████╗
// ██╔════╝╚══██╔══╝╚██╗ ██╔╝██║     ██╔════╝██╔════╝
// ███████╗   ██║    ╚████╔╝ ██║     █████╗  ███████╗
// ╚════██║   ██║     ╚██╔╝  ██║     ██╔══╝  ╚════██║
// ███████║   ██║      ██║   ███████╗███████╗███████║
// ╚══════╝   ╚═╝      ╚═╝   ╚══════╝╚══════╝╚══════╝
//

const styles = ({ palette, spacing }: Theme) =>
	createStyles({
		paper: {
			marginTop: spacing(8),
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
		},
		avatar: {
			margin: spacing(1),
			backgroundColor: palette.secondary.main,
		},
		form: {
			width: '100%', // Fix IE 11 issue.
			marginTop: spacing(1),
		},
		submit: {
			margin: spacing(3, 0, 2),
		},
	})
const useStyles = makeStyles(styles)

//  ██████╗ ██████╗ ███╗   ███╗██████╗  ██████╗ ███╗   ██╗███████╗███╗   ██╗████████╗
// ██╔════╝██╔═══██╗████╗ ████║██╔══██╗██╔═══██╗████╗  ██║██╔════╝████╗  ██║╚══██╔══╝
// ██║     ██║   ██║██╔████╔██║██████╔╝██║   ██║██╔██╗ ██║█████╗  ██╔██╗ ██║   ██║
// ██║     ██║   ██║██║╚██╔╝██║██╔═══╝ ██║   ██║██║╚██╗██║██╔══╝  ██║╚██╗██║   ██║
// ╚██████╗╚██████╔╝██║ ╚═╝ ██║██║     ╚██████╔╝██║ ╚████║███████╗██║ ╚████║   ██║
//  ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝      ╚═════╝ ╚═╝  ╚═══╝╚══════╝╚═╝  ╚═══╝   ╚═╝
//
export interface ILoginPageProps {}

const LoginPage = (props: ILoginPageProps) => {
	// Where were we redirected from
	const location = useLocation()
	const { from } = (location.state as { from: { pathname: string } }) || { from: { pathname: PageUri.Dashboard } }

	// Styling
	const classes = useStyles()

	// State
	const { api } = useStore()

	const [email, setEmail] = React.useState<string>('')
	const [password, setPassword] = React.useState<string>('')
	const [rememberMe, setRememberMe] = React.useState<boolean>(false)
	const [isLoading, setIsLoading] = React.useState<boolean>(false)

	const [error, setError] = React.useState<string | null>(null)

	// Login method
	const login = React.useCallback(async () => {
		setIsLoading(true)
		setError(null)

		try {
			// Do the login.
			await api.login(email, password)
		} catch (error) {
			if (error.isAxiosError) {
				const axiosError = error as AxiosError
				setError(
					axiosError.response && axiosError.response.status === 401
						? 'De ingevulde gegevens zijn niet correct.'
						: axiosError.message
				)
				setIsLoading(false)
			} else {
				throw error
			}
		}
	}, [email, password, rememberMe, isLoading])

	// Already logged in?
	if (api.isAuthenticated && api.user) return <Redirect to={from.pathname === '/inloggen' ? { pathname: '/' } : from} />

	return (
		<React.Fragment>
			<Container component='main' maxWidth='xs'>
				<div className={classes.paper}>
					<Avatar className={classes.avatar}>
						<Icon name={'sign-in-alt'} />
					</Avatar>
					<Typography component='h1' variant='h3'>
						Inloggen
					</Typography>

					<form className={classes.form} noValidate onSubmit={e => e.preventDefault()}>
						<TextField
							variant='outlined'
							margin='normal'
							required
							fullWidth
							label='E-mailadres'
							autoComplete='email'
							autoFocus
							value={email}
							onChange={e => setEmail(e.target.value)}
						/>
						<TextField
							type='password'
							variant='outlined'
							margin='normal'
							required
							fullWidth
							label='Wachtwoord'
							autoComplete='current-password'
							value={password}
							onChange={e => setPassword(e.target.value)}
						/>
						{/* <FormControlLabel
							control={
								<Checkbox checked={rememberMe} onChange={e => setRememberMe(e.target.checked)} color='primary' />
							}
							label='Onthoud mijn gegevens'
						/> */}
						<Button
							fullWidth
							loading={isLoading}
							variant='contained'
							type='submit'
							color='primary'
							className={classes.submit}
							onClick={() => login()}>
							Inloggen
						</Button>

						{/* Error */}
						{error && <Alert severity={'error'}>{error}</Alert>}

						{/* Session error */}
						{api.tokenError && <Alert severity={'warning'}>{api.tokenError}</Alert>}
					</form>
				</div>
			</Container>
		</React.Fragment>
	)
}
export default observer(LoginPage)
