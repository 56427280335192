import * as React from 'react'
import {
	createStyles,
	makeStyles,
	Theme,
	GridProps,
	Grid,
	FormControlProps,
	FormControl,
	InputLabel,
	FormHelperText,
	FormControlLabelProps,
	InputLabelProps,
} from '@material-ui/core'
import { useValidation } from './ValidationContext'
import styled from 'styled-components'

// ███████╗████████╗██╗   ██╗██╗     ███████╗███████╗
// ██╔════╝╚══██╔══╝╚██╗ ██╔╝██║     ██╔════╝██╔════╝
// ███████╗   ██║    ╚████╔╝ ██║     █████╗  ███████╗
// ╚════██║   ██║     ╚██╔╝  ██║     ██╔══╝  ╚════██║
// ███████║   ██║      ██║   ███████╗███████╗███████║
// ╚══════╝   ╚═╝      ╚═╝   ╚══════╝╚══════╝╚══════╝
//

const Padder = styled.div`
	padding-top: 16px;
`

//  ██████╗ ██████╗ ███╗   ███╗██████╗  ██████╗ ███╗   ██╗███████╗███╗   ██╗████████╗
// ██╔════╝██╔═══██╗████╗ ████║██╔══██╗██╔═══██╗████╗  ██║██╔════╝████╗  ██║╚══██╔══╝
// ██║     ██║   ██║██╔████╔██║██████╔╝██║   ██║██╔██╗ ██║█████╗  ██╔██╗ ██║   ██║
// ██║     ██║   ██║██║╚██╔╝██║██╔═══╝ ██║   ██║██║╚██╗██║██╔══╝  ██║╚██╗██║   ██║
// ╚██████╗╚██████╔╝██║ ╚═╝ ██║██║     ╚██████╔╝██║ ╚████║███████╗██║ ╚████║   ██║
//  ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝      ╚═════╝ ╚═╝  ╚═══╝╚══════╝╚═╝  ╚═══╝   ╚═╝
//
export interface IFieldProps extends FormControlProps {
	grid?: GridProps
	label?: string | React.ReactElement
	help?: string | React.ReactElement
	labelProps?: InputLabelProps
	attribute?: string
	padded?: boolean
}

const Field = ({
	grid = { xs: 12 },
	attribute,
	label,
	help,
	labelProps,
	padded,
	required,
	children,
	...props
}: IFieldProps) => {
	// Use error context
	const errors = useValidation(attribute)

	// Default properties for control
	const controlProps: FormControlProps = {
		fullWidth: true,
		error: errors.length > 0,

		...props,
	}

	return (
		<Grid item {...grid}>
			<FormControl {...controlProps}>
				{label && (
					<InputLabel {...labelProps}>
						{label}
						{required && ' *'}
					</InputLabel>
				)}
				{padded ? <Padder>{children}</Padder> : children}
				{errors.length > 0 && <FormHelperText>{errors[0].message}</FormHelperText>}
				{help && <FormHelperText focused>{help}</FormHelperText>}
			</FormControl>
		</Grid>
	)
}
export default Field
