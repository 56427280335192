import L, { GridLayer as LeafletLayer, LatLngBounds } from 'leaflet'
import mapboxgl from 'mapbox-gl'
import 'mapbox-gl-leaflet'
import { GridLayer, GridLayerProps, withLeaflet } from 'react-leaflet'

// Disable calls to Mapbox
// @ts-ignore
mapboxgl.config.API_URL = false

// https://docs.mapbox.com/mapbox-gl-js/api/#new-mapboxgl-map-options-
export interface IMapBoxGLLayer {
	accessToken: string
	style: any
	hash?: false | string
	interactive?: boolean
	bearingSnap?: number
	pitchWithRotate?: boolean
	clickTolerance?: number
	attributionControl?: boolean
	customAttribution?: string | string[]
	logoPosition?: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right'
	failIfMajorPerformanceCaveat?: boolean
	preserveDrawingBuffer?: boolean
	antialias?: boolean
	refreshExpiredTiles?: boolean
	maxBounds?: LatLngBounds
	scrollZoom?: boolean | object
	boxZoom?: boolean
	dragRotate?: boolean
	dragPan?: boolean
	keyboard?: boolean
	doubleClickZoom?: boolean
	touchZoomRotate?: boolean
	trackResize?: boolean

	convertToImage?: boolean
}

class MapBoxGLLayer extends GridLayer<IMapBoxGLLayer & GridLayerProps> {
	createLeafletElement(props: IMapBoxGLLayer) {
		const { convertToImage, ...layerProps } = props

		const layer = L.mapboxGL(layerProps)

		layer.addEventListener('load', () => {})

		// // Look for
		// if (convertToImage) {
		// 	let interval = setInterval(() => {
		// 		const map: mapboxgl.Map = layer.getMapboxMap()
		// 		if (map) {
		// 			// Stop looking.
		// 			clearInterval(interval)

		// 			// Wait for the map to load
		// 			map.once('load', () => {
		// 				// Create base64 of the map
		// 				const canvas = map.getCanvas()

		// 				// Replace canvas with static image
		// 				const image = canvas.toDataURL()
		// 				const container = map.getCanvasContainer()
		// 				const offset = $(container).offset()
		// 				$('body').append(
		// 					$('<img />')
		// 						.attr('src', image)
		// 						.css({
		// 							zIndex: 1,
		// 							position: 'fixed',
		// 							...offset
		// 						})
		// 				)
		// 				$(canvas).remove()
		// 			})
		// 		}
		// 	}, 25)
		// }

		return layer as LeafletLayer
	}
}
export default withLeaflet(MapBoxGLLayer)
