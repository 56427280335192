import { get, mapValues, set } from 'lodash'

//  █████╗ ██████╗ ██╗    ██████╗ ███████╗███████╗██╗   ██╗██╗  ████████╗    ████████╗██╗   ██╗██████╗ ███████╗███████╗
// ██╔══██╗██╔══██╗██║    ██╔══██╗██╔════╝██╔════╝██║   ██║██║  ╚══██╔══╝    ╚══██╔══╝╚██╗ ██╔╝██╔══██╗██╔════╝██╔════╝
// ███████║██████╔╝██║    ██████╔╝█████╗  ███████╗██║   ██║██║     ██║          ██║    ╚████╔╝ ██████╔╝█████╗  ███████╗
// ██╔══██║██╔═══╝ ██║    ██╔══██╗██╔══╝  ╚════██║██║   ██║██║     ██║          ██║     ╚██╔╝  ██╔═══╝ ██╔══╝  ╚════██║
// ██║  ██║██║     ██║    ██║  ██║███████╗███████║╚██████╔╝███████╗██║          ██║      ██║   ██║     ███████╗███████║
// ╚═╝  ╚═╝╚═╝     ╚═╝    ╚═╝  ╚═╝╚══════╝╚══════╝ ╚═════╝ ╚══════╝╚═╝          ╚═╝      ╚═╝   ╚═╝     ╚══════╝╚══════╝
//
export interface ApiResourceLinks {
	self?: string
	related?: string
	first?: string
	last?: string
}
export interface ApiResourceRelationship {
	links: ApiResourceLinks
	data: ApiLinkedResource[] | ApiLinkedResource
}

export interface ApiLinkedResource {
	id?: string | number
	type: string
}

export interface ApiResource extends ApiLinkedResource {
	attributes: {}
	links?: ApiResourceLinks
	relationships?: { [key: string]: ApiResourceRelationship }
}

export interface ApiResult {
	data: ApiResource[]
	links?: ApiResourceLinks
	included?: ApiResource[]
}

// ██████╗ ███████╗███████╗██╗   ██╗██╗  ████████╗    ████████╗██╗   ██╗██████╗ ███████╗███████╗
// ██╔══██╗██╔════╝██╔════╝██║   ██║██║  ╚══██╔══╝    ╚══██╔══╝╚██╗ ██╔╝██╔══██╗██╔════╝██╔════╝
// ██████╔╝█████╗  ███████╗██║   ██║██║     ██║          ██║    ╚████╔╝ ██████╔╝█████╗  ███████╗
// ██╔══██╗██╔══╝  ╚════██║██║   ██║██║     ██║          ██║     ╚██╔╝  ██╔═══╝ ██╔══╝  ╚════██║
// ██║  ██║███████╗███████║╚██████╔╝███████╗██║          ██║      ██║   ██║     ███████╗███████║
// ╚═╝  ╚═╝╚══════╝╚══════╝ ╚═════╝ ╚══════╝╚═╝          ╚═╝      ╚═╝   ╚═╝     ╚══════╝╚══════╝
//

export interface DeserializedModel {
	id: string
	[key: string]: string | boolean | number | DeserializedModel | DeserializedModel[]
}

// ██████╗ ███████╗███████╗███████╗██████╗ ██╗ █████╗ ██╗     ██╗███████╗███████╗    ███╗   ███╗ ██████╗ ██████╗ ███████╗██╗
// ██╔══██╗██╔════╝██╔════╝██╔════╝██╔══██╗██║██╔══██╗██║     ██║╚══███╔╝██╔════╝    ████╗ ████║██╔═══██╗██╔══██╗██╔════╝██║
// ██║  ██║█████╗  ███████╗█████╗  ██████╔╝██║███████║██║     ██║  ███╔╝ █████╗      ██╔████╔██║██║   ██║██║  ██║█████╗  ██║
// ██║  ██║██╔══╝  ╚════██║██╔══╝  ██╔══██╗██║██╔══██║██║     ██║ ███╔╝  ██╔══╝      ██║╚██╔╝██║██║   ██║██║  ██║██╔══╝  ██║
// ██████╔╝███████╗███████║███████╗██║  ██║██║██║  ██║███████╗██║███████╗███████╗    ██║ ╚═╝ ██║╚██████╔╝██████╔╝███████╗███████╗
// ╚═════╝ ╚══════╝╚══════╝╚══════╝╚═╝  ╚═╝╚═╝╚═╝  ╚═╝╚══════╝╚═╝╚══════╝╚══════╝    ╚═╝     ╚═╝ ╚═════╝ ╚═════╝ ╚══════╝╚══════╝
//

type LinkedModels = { [key: string]: { [id: string]: ApiResource } }
type DeserializedModels = { [key: string]: { [id: string]: DeserializedModel } }

export function deserializeModel(
	data: ApiResource,
	linkedModels: LinkedModels = {},
	deserializedModels: DeserializedModels = {}
): DeserializedModel {
	// Parse attributes
	const attributes = { ...data.attributes }

	// Look at relations
	const linked = data.relationships
		? mapValues(data.relationships, relation => {
				// One or many?
				const isArray = Array.isArray(relation.data)
				const records = isArray ? relation.data : [relation.data]

				const results = records.map(related => {
					// Already deseriazlied?
					const relKey = `${related.type}.${related.id}`
					const deserialized = get(deserializedModels, relKey, false)

					if (deserialized !== false) return deserialized

					// Find in linked models
					const linked: ApiResource = (get(linkedModels, relKey) as any) as ApiResource
					if (!linked) return null

					// Let deserialize it
					const relResult = deserializeModel(linked, linkedModels, deserializedModels)

					// Store it for next time (to prevent endless loops)
					set(deserializedModels, relKey, relResult)

					return relResult
				})
				return isArray ? results : results[0]
		  })
		: {}

	return {
		id: data.id.toString(),
		...attributes,
		...linked
	}
}

/**
 *
 * @param result The data received from the JSONAPI
 */
export function deserializeApiResult(result: ApiResult): DeserializedModel[] {
	// Index the linked models
	const includedModels: LinkedModels = {}
	const deserializedModels: DeserializedModels = {}
	if (result.included) {
		// Loop through linked resources
		result.included.map(model => {
			// Collect all records in a map
			const key = `${model.type}.${model.id}`
			set(includedModels, key, model)
		})
	}

	// Get the model(s)
	const models = (Array.isArray(result.data) ? result.data : [result.data]).map(model =>
		deserializeModel(model, includedModels, deserializedModels)
	)
	return models
}
