import A1_10 from '~assets/images/traffic-signs/Verkeersbord_A1-10.svg'
import A1_100 from '~assets/images/traffic-signs/Verkeersbord_A1-100.svg'
import A1_120 from '~assets/images/traffic-signs/Verkeersbord_A1-120.svg'
import A1_130 from '~assets/images/traffic-signs/Verkeersbord_A1-130.svg'
import A1_30 from '~assets/images/traffic-signs/Verkeersbord_A1-30.svg'
import A1_30zb from '~assets/images/traffic-signs/Verkeersbord_A1-30zb.svg'
import A1_30ze from '~assets/images/traffic-signs/Verkeersbord_A1-30ze.svg'
import A1_50 from '~assets/images/traffic-signs/Verkeersbord_A1-50.svg'
import A1_60 from '~assets/images/traffic-signs/Verkeersbord_A1-60.svg'
import A1_60zb from '~assets/images/traffic-signs/Verkeersbord_A1-60zb.svg'
import A1_60ze from '~assets/images/traffic-signs/Verkeersbord_A1-60ze.svg'
import A1_70 from '~assets/images/traffic-signs/Verkeersbord_A1-70.svg'
import A1_80 from '~assets/images/traffic-signs/Verkeersbord_A1-80.svg'
import A1_90 from '~assets/images/traffic-signs/Verkeersbord_A1-90.svg'
import A2 from '~assets/images/traffic-signs/1024px-Nederlands_verkeersbord_A2.svg.png'
import A3 from '~assets/images/traffic-signs/1280px-Nederlands_verkeersbord_A3.svg.png'
import A4 from '~assets/images/traffic-signs/1024px-Nederlands_verkeersbord_A4.svg.png'
import A5 from '~assets/images/traffic-signs/1024px-Nederlands_verkeersbord_A5.svg.png'
import B1 from '~assets/images/traffic-signs/1024px-Nederlands_verkeersbord_B1.svg.png'
import B2 from '~assets/images/traffic-signs/1024px-Nederlands_verkeersbord_B2.svg.png'
import B3 from '~assets/images/traffic-signs/1195px-Nederlands_verkeersbord_B3.svg.png'
import B4 from '~assets/images/traffic-signs/1195px-Nederlands_verkeersbord_B4.svg.png'
import B5 from '~assets/images/traffic-signs/1195px-Nederlands_verkeersbord_B5.svg.png'
import B6 from '~assets/images/traffic-signs/1195px-Nederlands_verkeersbord_B6.svg.png'
import B7 from '~assets/images/traffic-signs/1000px-Nederlands_verkeersbord_B7.svg.png'
import C1 from '~assets/images/traffic-signs/1024px-Nederlands_verkeersbord_C1.svg.png'
import C10 from '~assets/images/traffic-signs/1024px-Nederlands_verkeersbord_C10.svg.png'
import C11 from '~assets/images/traffic-signs/1024px-Nederlands_verkeersbord_C11.svg.png'
import C12 from '~assets/images/traffic-signs/1024px-Nederlands_verkeersbord_C12.svg.png'
import C13 from '~assets/images/traffic-signs/1024px-Nederlands_verkeersbord_C13.svg.png'
import C14 from '~assets/images/traffic-signs/1024px-Nederlands_verkeersbord_C14.svg.png'
import C15 from '~assets/images/traffic-signs/1024px-Nederlands_verkeersbord_C15.svg.png'
import C16 from '~assets/images/traffic-signs/1024px-Nederlands_verkeersbord_C16.svg.png'
import C17 from '~assets/images/traffic-signs/1024px-Nederlands_verkeersbord_C17.svg.png'
import C18 from '~assets/images/traffic-signs/1024px-Nederlands_verkeersbord_C18.svg.png'
import C19 from '~assets/images/traffic-signs/1024px-Nederlands_verkeersbord_C19.svg.png'
import C2 from '~assets/images/traffic-signs/1024px-Nederlands_verkeersbord_C2.svg.png'
import C20 from '~assets/images/traffic-signs/1024px-Nederlands_verkeersbord_C20.svg.png'
import C21 from '~assets/images/traffic-signs/1024px-Nederlands_verkeersbord_C21.svg.png'
import C22 from '~assets/images/traffic-signs/1024px-Nederlands_verkeersbord_C22.svg.png'
import C22a from '~assets/images/traffic-signs/1024px-Nederlands_verkeersbord_C22a.svg.png'
import C22b from '~assets/images/traffic-signs/1024px-Nederlands_verkeersbord_C22b.svg.png'
import C23_01 from '~assets/images/traffic-signs/1263px-Nederlands_verkeersbord_C23-01.svg.png'
import C23_02 from '~assets/images/traffic-signs/1263px-Nederlands_verkeersbord_C23-02.svg.png'
import C23_03 from '~assets/images/traffic-signs/1263px-Nederlands_verkeersbord_C23-03.svg.png'
import C3 from '~assets/images/traffic-signs/1024px-Nederlands_verkeersbord_C3.svg.png'
import C4L from '~assets/images/traffic-signs/1280px-Nederlands_verkeersbord_C4_(links).svg.png'
import C4R from '~assets/images/traffic-signs/1280px-Nederlands_verkeersbord_C4_(rechts).svg.png'
import C5 from '~assets/images/traffic-signs/1024px-Nederlands_verkeersbord_C5.svg.png'
import C6 from '~assets/images/traffic-signs/1024px-Nederlands_verkeersbord_C6.svg.png'
import C7 from '~assets/images/traffic-signs/1024px-Nederlands_verkeersbord_C7.svg.png'
import C7a from '~assets/images/traffic-signs/1024px-Nederlands_verkeersbord_C7a.svg.png'
import C7b from '~assets/images/traffic-signs/1024px-Nederlands_verkeersbord_C7b.svg.png'
import C8 from '~assets/images/traffic-signs/1024px-Nederlands_verkeersbord_C8.svg.png'
import C9 from '~assets/images/traffic-signs/1024px-Nederlands_verkeersbord_C9.svg.png'
import D1 from '~assets/images/traffic-signs/1024px-Nederlands_verkeersbord_D1.svg.png'
import D2L from '~assets/images/traffic-signs/1024px-Nederlands_verkeersbord_D2_L.svg.png'
import D2R from '~assets/images/traffic-signs/1024px-Nederlands_verkeersbord_D2_R.svg.png'
import D3 from '~assets/images/traffic-signs/1024px-Nederlands_verkeersbord_D3.svg.png'
import D4 from '~assets/images/traffic-signs/1024px-Nederlands_verkeersbord_D4.svg.png'
import D5 from '~assets/images/traffic-signs/1024px-Nederlands_verkeersbord_D5.svg.png'
import D6 from '~assets/images/traffic-signs/1024px-Nederlands_verkeersbord_D6.svg.png'
import D7 from '~assets/images/traffic-signs/1024px-Nederlands_verkeersbord_D7.svg.png'
import E1 from '~assets/images/traffic-signs/1024px-Nederlands_verkeersbord_E1.svg.png'
import E10 from '~assets/images/traffic-signs/819px-Nederlands_verkeersbord_E10.svg.png'
import E11 from '~assets/images/traffic-signs/819px-Nederlands_verkeersbord_E11.svg.png'
import E12 from '~assets/images/traffic-signs/682px-Nederlands_verkeersbord_E12.svg.png'
import E13 from '~assets/images/traffic-signs/682px-Nederlands_verkeersbord_E13.svg.png'
import E2 from '~assets/images/traffic-signs/1024px-Nederlands_verkeersbord_E2.svg.png'
import E3 from '~assets/images/traffic-signs/1024px-Nederlands_verkeersbord_E3.svg.png'
import E4 from '~assets/images/traffic-signs/1024px-Nederlands_verkeersbord_E4.svg.png'
import E5 from '~assets/images/traffic-signs/682px-Nederlands_verkeersbord_E5.svg.png'
import E6 from '~assets/images/traffic-signs/682px-Nederlands_verkeersbord_E6.svg.png'
import E7 from '~assets/images/traffic-signs/682px-Nederlands_verkeersbord_E7.svg.png'
import E8 from '~assets/images/traffic-signs/682px-Nederlands_verkeersbord_E8.svg.png'
import E9 from '~assets/images/traffic-signs/682px-Nederlands_verkeersbord_E9.svg.png'
import F1 from '~assets/images/traffic-signs/1024px-Nederlands_verkeersbord_F1.svg.png'
import F10 from '~assets/images/traffic-signs/1024px-Nederlands_verkeersbord_F10.svg.png'
import F2 from '~assets/images/traffic-signs/1024px-Nederlands_verkeersbord_F2.svg.png'
import F3 from '~assets/images/traffic-signs/1024px-Nederlands_verkeersbord_F3.svg.png'
import F4 from '~assets/images/traffic-signs/1024px-Nederlands_verkeersbord_F4.svg.png'
import F5 from '~assets/images/traffic-signs/1024px-Nederlands_verkeersbord_F5.svg.png'
import F6 from '~assets/images/traffic-signs/1024px-Nederlands_verkeersbord_F6.svg.png'
import F7 from '~assets/images/traffic-signs/1024px-Nederlands_verkeersbord_F7.svg.png'
import F8 from '~assets/images/traffic-signs/1024px-Nederlands_verkeersbord_F8.svg.png'
import F9 from '~assets/images/traffic-signs/1280px-Nederlands_verkeersbord_F9.svg.png'
import G1 from '~assets/images/traffic-signs/682px-Nederlands_verkeersbord_G1.svg.png'
import G10 from '~assets/images/traffic-signs/1024px-Nederlands_verkeersbord_G10.svg.png'
import G11 from '~assets/images/traffic-signs/1024px-Nederlands_verkeersbord_G11.svg.png'
import G12 from '~assets/images/traffic-signs/1024px-Nederlands_verkeersbord_G12.svg.png'
import G12a from '~assets/images/traffic-signs/1024px-Nederlands_verkeersbord_G12a.svg.png'
import G12b from '~assets/images/traffic-signs/1024px-Nederlands_verkeersbord_G12b.svg.png'
import G13 from '~assets/images/traffic-signs/1280px-Nederlands_verkeersbord_G13.svg.png'
import G14 from '~assets/images/traffic-signs/1280px-Nederlands_verkeersbord_G14.svg.png'
import G2 from '~assets/images/traffic-signs/682px-Nederlands_verkeersbord_G2.svg.png'
import G3 from '~assets/images/traffic-signs/1024px-Nederlands_verkeersbord_G3.svg.png'
import G4 from '~assets/images/traffic-signs/1024px-Nederlands_verkeersbord_G4.svg.png'
import G5 from '~assets/images/traffic-signs/1280px-Nederlands_verkeersbord_G5.svg.png'
import G6 from '~assets/images/traffic-signs/1280px-Nederlands_verkeersbord_G6.svg.png'
import G7 from '~assets/images/traffic-signs/1024px-Nederlands_verkeersbord_G7.svg.png'
import G8 from '~assets/images/traffic-signs/1024px-Nederlands_verkeersbord_G8.svg.png'
import G9 from '~assets/images/traffic-signs/1024px-Nederlands_verkeersbord_G9.svg.png'
import J1 from '~assets/images/traffic-signs/1195px-Nederlands_verkeersbord_J1.svg.png'
import J10 from '~assets/images/traffic-signs/1195px-Nederlands_verkeersbord_J10.svg.png'
import J11 from '~assets/images/traffic-signs/1195px-Nederlands_verkeersbord_J11.svg.png'
import J14 from '~assets/images/traffic-signs/1195px-Nederlands_verkeersbord_J14.svg.png'
import J15 from '~assets/images/traffic-signs/1195px-Nederlands_verkeersbord_J15.svg.png'
import J16 from '~assets/images/traffic-signs/1195px-Nederlands_verkeersbord_J16.svg.png'
import J17 from '~assets/images/traffic-signs/1195px-Nederlands_verkeersbord_J17.svg.png'
import J18 from '~assets/images/traffic-signs/1195px-Nederlands_verkeersbord_J18.svg.png'
import J19 from '~assets/images/traffic-signs/1195px-Nederlands_verkeersbord_J19.svg.png'
import J2 from '~assets/images/traffic-signs/1195px-Nederlands_verkeersbord_J2.svg.png'
import J20 from '~assets/images/traffic-signs/1195px-Nederlands_verkeersbord_J20.svg.png'
import J21 from '~assets/images/traffic-signs/1195px-Nederlands_verkeersbord_J21.svg.png'
import J22 from '~assets/images/traffic-signs/1195px-Nederlands_verkeersbord_J22.svg.png'
import J23 from '~assets/images/traffic-signs/1195px-Nederlands_verkeersbord_J23.svg.png'
import J24 from '~assets/images/traffic-signs/1195px-Nederlands_verkeersbord_J24.svg.png'
import J25 from '~assets/images/traffic-signs/1195px-Nederlands_verkeersbord_J25.svg.png'
import J26 from '~assets/images/traffic-signs/1195px-Nederlands_verkeersbord_J26.svg.png'
import J27 from '~assets/images/traffic-signs/1195px-Nederlands_verkeersbord_J27.svg.png'
import J28 from '~assets/images/traffic-signs/1195px-Nederlands_verkeersbord_J28.svg.png'
import J29 from '~assets/images/traffic-signs/1195px-Nederlands_verkeersbord_J29.svg.png'
import J3 from '~assets/images/traffic-signs/1195px-Nederlands_verkeersbord_J3.svg.png'
import J30 from '~assets/images/traffic-signs/1195px-Nederlands_verkeersbord_J30.svg.png'
import J31 from '~assets/images/traffic-signs/1195px-Nederlands_verkeersbord_J31.svg.png'
import J32 from '~assets/images/traffic-signs/1195px-Nederlands_verkeersbord_J32.svg.png'
import J33 from '~assets/images/traffic-signs/1195px-Nederlands_verkeersbord_J33.svg.png'
import J34 from '~assets/images/traffic-signs/1195px-Nederlands_verkeersbord_J34.svg.png'
import J35 from '~assets/images/traffic-signs/1195px-Nederlands_verkeersbord_J35.svg.png'
import J36 from '~assets/images/traffic-signs/1195px-Nederlands_verkeersbord_J36.svg.png'
import J38 from '~assets/images/traffic-signs/1195px-Nederlands_verkeersbord_J38.svg.png'
import J39 from '~assets/images/traffic-signs/1195px-Nederlands_verkeersbord_J39.svg.png'
import J4 from '~assets/images/traffic-signs/1195px-Nederlands_verkeersbord_J4.svg.png'
import J5 from '~assets/images/traffic-signs/1195px-Nederlands_verkeersbord_J5.svg.png'
import J6 from '~assets/images/traffic-signs/1195px-Nederlands_verkeersbord_J6.svg.png'
import J7 from '~assets/images/traffic-signs/1195px-Nederlands_verkeersbord_J7.svg.png'
import J8 from '~assets/images/traffic-signs/1195px-Nederlands_verkeersbord_J8.svg.png'
import J9 from '~assets/images/traffic-signs/1195px-Nederlands_verkeersbord_J9.svg.png'
import K14 from '~assets/images/traffic-signs/682px-Nederlands_verkeersbord_K14.svg.png'
import L10 from '~assets/images/traffic-signs/1024px-Nederlands_verkeersbord_L10.svg.png'
import L11 from '~assets/images/traffic-signs/1024px-Nederlands_verkeersbord_L11.svg.png'
import L12 from '~assets/images/traffic-signs/682px-Nederlands_verkeersbord_L12.svg.png'
import L14 from '~assets/images/traffic-signs/1024px-Nederlands_verkeersbord_L14.svg.png'
import L15 from '~assets/images/traffic-signs/682px-Nederlands_verkeersbord_L15.svg.png'
import L16 from '~assets/images/traffic-signs/1024px-Nederlands_verkeersbord_L16.svg.png'
import L17 from '~assets/images/traffic-signs/1024px-Nederlands_verkeersbord_L17.svg.png'
import L18 from '~assets/images/traffic-signs/1280px-Nederlands_verkeersbord_L18.svg.png'
import L2 from '~assets/images/traffic-signs/1024px-Nederlands_verkeersbord_L2.svg.png'
import L3a from '~assets/images/traffic-signs/1024px-Nederlands_verkeersbord_L3a.svg.png'
import L3b from '~assets/images/traffic-signs/1024px-Nederlands_verkeersbord_L3b.svg.png'
import L3c from '~assets/images/traffic-signs/1024px-Nederlands_verkeersbord_L3c.svg.png'
import L4 from '~assets/images/traffic-signs/1280px-Nederlands_verkeersbord_L4.svg.png'
import L5 from '~assets/images/traffic-signs/1092px-Nederlands_verkeersbord_L5.svg.png'
import L6 from '~assets/images/traffic-signs/1280px-Nederlands_verkeersbord_L6.svg.png'
import L7 from '~assets/images/traffic-signs/1092px-Nederlands_verkeersbord_L7.svg.png'
import L8 from '~assets/images/traffic-signs/682px-Nederlands_verkeersbord_L8.svg.png'
import L9 from '~assets/images/traffic-signs/682px-Nederlands_verkeersbord_L9.svg.png'

import F13 from '~assets/images/traffic-signs/F13.png'
import F14 from '~assets/images/traffic-signs/F14.png'
import F17 from '~assets/images/traffic-signs/F17.png'
import F18 from '~assets/images/traffic-signs/F18.png'
import F19 from '~assets/images/traffic-signs/F19.png'
import F20 from '~assets/images/traffic-signs/F20.png'

export type Image = {
	base64: string
	title: string
}

export const TrafficSigns: { [key: string]: Image[] } = {
	Favorieten: [
		{ base64: A1_30, title: 'A1_30' },
		{ base64: A1_50, title: 'A1_50' },
		{ base64: C16, title: 'C16' },
		{ base64: D2L, title: 'D2L' },
		{ base64: D2R, title: 'D2R' },
		{ base64: F13, title: 'F13' },
		{ base64: F14, title: 'F14' },
		{ base64: F17, title: 'F17' },
		{ base64: F18, title: 'F18' },
		{ base64: F19, title: 'F19' },
		{ base64: F20, title: 'F20' },
		{ base64: J1, title: 'J1' },
		{ base64: J2, title: 'J2' },
		{ base64: J3, title: 'J3' },
		{ base64: J4, title: 'J4' },
		{ base64: J5, title: 'J5' },
		{ base64: J7, title: 'J7' },
		{ base64: J8, title: 'J8' },
		{ base64: J9, title: 'J9' },
		{ base64: J15, title: 'J15' }
	],
	Alle: [
		{ base64: A1_10, title: 'A1_10' },
		{ base64: A1_100, title: 'A1_100' },
		{ base64: A1_120, title: 'A1_120' },
		{ base64: A1_130, title: 'A1_130' },
		{ base64: A1_30, title: 'A1_30' },
		{ base64: A1_30zb, title: 'A1_30zb' },
		{ base64: A1_30ze, title: 'A1_30ze' },
		{ base64: A1_50, title: 'A1_50' },
		{ base64: A1_60, title: 'A1_60' },
		{ base64: A1_60zb, title: 'A1_60zb' },
		{ base64: A1_60ze, title: 'A1_60ze' },
		{ base64: A1_70, title: 'A1_70' },
		{ base64: A1_80, title: 'A1_80' },
		{ base64: A1_90, title: 'A1_90' },
		{ base64: A2, title: 'A2' },
		{ base64: A3, title: 'A3' },
		{ base64: A4, title: 'A4' },
		{ base64: A5, title: 'A5' },
		{ base64: B1, title: 'B1' },
		{ base64: B2, title: 'B2' },
		{ base64: B3, title: 'B3' },
		{ base64: B4, title: 'B4' },
		{ base64: B5, title: 'B5' },
		{ base64: B6, title: 'B6' },
		{ base64: B7, title: 'B7' },
		{ base64: C1, title: 'C1' },
		{ base64: C10, title: 'C10' },
		{ base64: C11, title: 'C11' },
		{ base64: C12, title: 'C12' },
		{ base64: C13, title: 'C13' },
		{ base64: C14, title: 'C14' },
		{ base64: C15, title: 'C15' },
		{ base64: C16, title: 'C16' },
		{ base64: C17, title: 'C17' },
		{ base64: C18, title: 'C18' },
		{ base64: C19, title: 'C19' },
		{ base64: C2, title: 'C2' },
		{ base64: C20, title: 'C20' },
		{ base64: C21, title: 'C21' },
		{ base64: C22, title: 'C22' },
		{ base64: C22a, title: 'C22a' },
		{ base64: C22b, title: 'C22b' },
		{ base64: C23_01, title: 'C23-01' },
		{ base64: C23_02, title: 'C23-02' },
		{ base64: C23_03, title: 'C23-03' },
		{ base64: C3, title: 'C3' },
		{ base64: C4L, title: 'C4_(links)' },
		{ base64: C4R, title: 'C4_(rechts)' },
		{ base64: C4L, title: 'C4L' },
		{ base64: C4R, title: 'C4R' },
		{ base64: C5, title: 'C5' },
		{ base64: C6, title: 'C6' },
		{ base64: C7, title: 'C7' },
		{ base64: C7a, title: 'C7a' },
		{ base64: C7b, title: 'C7b' },
		{ base64: C8, title: 'C8' },
		{ base64: C9, title: 'C9' },
		{ base64: D1, title: 'D1' },
		{ base64: D2L, title: 'D2L' },
		{ base64: D2R, title: 'D2R' },
		{ base64: D3, title: 'D3' },
		{ base64: D4, title: 'D4' },
		{ base64: D5, title: 'D5' },
		{ base64: D6, title: 'D6' },
		{ base64: D7, title: 'D7' },
		{ base64: E1, title: 'E1' },
		{ base64: E2, title: 'E2' },
		{ base64: E3, title: 'E3' },
		{ base64: E4, title: 'E4' },
		{ base64: E5, title: 'E5' },
		{ base64: E6, title: 'E6' },
		{ base64: E7, title: 'E7' },
		{ base64: E8, title: 'E8' },
		{ base64: E9, title: 'E9' },
		{ base64: E10, title: 'E10' },
		{ base64: E11, title: 'E11' },
		{ base64: E12, title: 'E12' },
		{ base64: E13, title: 'E13' },
		{ base64: F1, title: 'F1' },
		{ base64: F2, title: 'F2' },
		{ base64: F3, title: 'F3' },
		{ base64: F4, title: 'F4' },
		{ base64: F5, title: 'F5' },
		{ base64: F6, title: 'F6' },
		{ base64: F7, title: 'F7' },
		{ base64: F8, title: 'F8' },
		{ base64: F9, title: 'F9' },
		{ base64: F10, title: 'F10' },
		{ base64: F13, title: 'F13' },
		{ base64: F14, title: 'F14' },
		{ base64: F17, title: 'F17' },
		{ base64: F18, title: 'F18' },
		{ base64: F19, title: 'F19' },
		{ base64: F20, title: 'F20' },
		{ base64: G1, title: 'G1' },
		{ base64: G10, title: 'G10' },
		{ base64: G11, title: 'G11' },
		{ base64: G12, title: 'G12' },
		{ base64: G12a, title: 'G12a' },
		{ base64: G12b, title: 'G12b' },
		{ base64: G13, title: 'G13' },
		{ base64: G14, title: 'G14' },
		{ base64: G2, title: 'G2' },
		{ base64: G3, title: 'G3' },
		{ base64: G4, title: 'G4' },
		{ base64: G5, title: 'G5' },
		{ base64: G6, title: 'G6' },
		{ base64: G7, title: 'G7' },
		{ base64: G8, title: 'G8' },
		{ base64: G9, title: 'G9' },
		{ base64: J1, title: 'J1' },
		{ base64: J2, title: 'J2' },
		{ base64: J3, title: 'J3' },
		{ base64: J4, title: 'J4' },
		{ base64: J5, title: 'J5' },
		{ base64: J6, title: 'J6' },
		{ base64: J7, title: 'J7' },
		{ base64: J8, title: 'J8' },
		{ base64: J9, title: 'J9' },
		{ base64: J10, title: 'J10' },
		{ base64: J11, title: 'J11' },
		{ base64: J14, title: 'J14' },
		{ base64: J15, title: 'J15' },
		{ base64: J16, title: 'J16' },
		{ base64: J17, title: 'J17' },
		{ base64: J18, title: 'J18' },
		{ base64: J19, title: 'J19' },
		{ base64: J20, title: 'J20' },
		{ base64: J21, title: 'J21' },
		{ base64: J22, title: 'J22' },
		{ base64: J23, title: 'J23' },
		{ base64: J24, title: 'J24' },
		{ base64: J25, title: 'J25' },
		{ base64: J26, title: 'J26' },
		{ base64: J27, title: 'J27' },
		{ base64: J28, title: 'J28' },
		{ base64: J29, title: 'J29' },
		{ base64: J30, title: 'J30' },
		{ base64: J31, title: 'J31' },
		{ base64: J32, title: 'J32' },
		{ base64: J33, title: 'J33' },
		{ base64: J34, title: 'J34' },
		{ base64: J35, title: 'J35' },
		{ base64: J36, title: 'J36' },
		{ base64: J38, title: 'J38' },
		{ base64: J39, title: 'J39' },
		{ base64: K14, title: 'K14' },
		{ base64: L10, title: 'L10' },
		{ base64: L11, title: 'L11' },
		{ base64: L12, title: 'L12' },
		{ base64: L14, title: 'L14' },
		{ base64: L15, title: 'L15' },
		{ base64: L16, title: 'L16' },
		{ base64: L17, title: 'L17' },
		{ base64: L18, title: 'L18' },
		{ base64: L2, title: 'L2' },
		{ base64: L3a, title: 'L3a' },
		{ base64: L3b, title: 'L3b' },
		{ base64: L3c, title: 'L3c' },
		{ base64: L4, title: 'L4' },
		{ base64: L5, title: 'L5' },
		{ base64: L6, title: 'L6' },
		{ base64: L7, title: 'L7' },
		{ base64: L8, title: 'L8' },
		{ base64: L9, title: 'L9' }
	]
}
