import * as React from 'react'
import {
	FormControlProps,
	GridProps,
	InputLabelProps,
	Grid as MuiGrid,
	FormControl,
	InputLabel,
	FormHelperText,
	FormGroup as MuiFormGroup
} from '@material-ui/core'
import styled from 'styled-components'
import classNames from 'classnames'

const FormGroup = styled(MuiFormGroup)`
	margin-top: 18px;
`

const Grid = styled(MuiGrid)`
	&.gutterBottom {
		margin-bottom: ${props => props.theme.spacing(2)}px;
	}
`

export interface IFieldProps extends FormControlProps {
	grid?: GridProps
	label?: string | React.ReactElement
	help?: string | React.ReactElement
	select?: boolean
	labelProps?: InputLabelProps
	attribute?: string
	gutterBottom?: boolean
}

const Field = ({ attribute, label, help, required, children, gutterBottom = true, select, ...props }: IFieldProps) => {
	// Default properties for control
	const controlProps: FormControlProps = {
		fullWidth: true,
		...props
	}

	const labelProps = { ...(props.labelProps || {}) }
	if (select) labelProps.shrink = true

	const grid = {
		xs: 12,
		...(props.grid || {}),
		className: classNames(props.grid && props.grid.className, { gutterBottom })
	}

	const wrap = select

	return (
		<Grid item {...grid}>
			<FormControl {...controlProps}>
				{label && (
					<InputLabel {...labelProps}>
						{label}
						{required && ' *'}
					</InputLabel>
				)}
				{wrap ? <FormGroup>{children}</FormGroup> : <React.Fragment>{children}</React.Fragment>}

				{help && <FormHelperText focused>{help}</FormHelperText>}
			</FormControl>
		</Grid>
	)
}
export default Field
