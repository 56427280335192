import { Container, TableCell } from '@material-ui/core'
import * as React from 'react'
import { useHistory, Link } from 'react-router-dom'
import RemoteView from '~components/remote-data/RemoteView'
import PageUri from '~core/enums/pages'
import { Line } from '~store/models/Line'
import AddBoxIcon from '@material-ui/icons/AddBox'
import Icon from '~components/gui/Icon'
import IconButton from '~components/buttons/IconButton'
import { LineConfiguration } from '~store/models/LineConfiguration'

export interface ILineIndexPageProps {}

const LineIndexPage = (props: ILineIndexPageProps) => {
	const history = useHistory()

	// Loading?
	return (
		<Container>
			<RemoteView
				title={'Lijnen'}
				// headerButtons={
				// 	<React.Fragment>
				// 		<IconButton
				// 			icon={'plus-square'}
				// 			edge={'end'}
				// 			to={`${PageUri.Lines}/nieuw`}
				// 			color='default'
				// 			title={'Nieuwe melding'}
				// 		/>
				// 	</React.Fragment>
				// }
				model={LineConfiguration}
				variant={'table'}
				attributes={[
					{ key: 'id', label: '#', sortable: true },
					{ key: 'line_number', label: 'Lijnnummer', sortable: true },
					{ key: 'name', label: 'Naam', sortable: true },
					{ key: 'concession_slug', label: 'Concessie' },
					{ key: 'category_key', label: 'Categorie' }
				]}
				table={{
					onSelectRow: record => history.push(`${PageUri.Lines}/${record.id}`)
				}}
			/>
		</Container>
	)
}
export default LineIndexPage
