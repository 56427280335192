import { Container, TableCell, Box, Chip } from '@material-ui/core'
import * as React from 'react'
import { useHistory, Link } from 'react-router-dom'
import RemoteView from '~components/remote-data/RemoteView'
import PageUri from '~core/enums/pages'
import { User } from '~store/models/User'
import IconButton from '~components/buttons/IconButton'
import { capitalize } from 'lodash'
import Chips from '~components/gui/Chips'

export interface IUserIndexPageProps {}

const UserIndexPage = (props: IUserIndexPageProps) => {
	const history = useHistory()

	// Loading?
	return (
		<Container>
			<RemoteView
				title={'Gebruikers'}
				headerButtons={
					<React.Fragment>
						<IconButton
							icon={'plus-square'}
							to={`${PageUri.Users}/nieuw`}
							edge={'end'}
							color='default'
							title={'Nieuwe gebruiker'}
						/>
					</React.Fragment>
				}
				model={User}
				variant={'table'}
				attributes={[
					{ key: 'id', label: '#', sortable: true },
					{ key: 'name', label: 'Naam', sortable: true },
					{ key: 'email', label: 'E-mailadres', sortable: true },
					{ key: 'concession_slugs', label: 'Concessie(s)' },
					{ key: 'role_names', label: 'Rollen' },
				]}
				table={{
					renderCell: {
						role_names: record => (
							<TableCell key={'role_names'}>
								{record.roleTitles.length === 0 ? '-' : record.roleTitles.join(', ')}
							</TableCell>
						),
						concession_slugs: record => (
							<TableCell key='concession_slugs'>
								{record.concession_slugs && <Chips items={record.concession_slugs.map(slug => capitalize(slug))} />}
							</TableCell>
						),
					},
					onSelectRow: record => history.push(`${PageUri.Users}/${record.id}`),
				}}
			/>
		</Container>
	)
}
export default UserIndexPage
