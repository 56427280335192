import { observer } from 'mobx-react'
import * as React from 'react'
import { useHistory } from 'react-router-dom'
import BackButton from '~components/buttons/header-buttons/BackButton'
import MapField from '~components/map-editor/MapField'
import ChipField from '~components/model-form/ChipField'
import DateTimeField from '~components/model-form/DateTimeField'
import Fieldset from '~components/model-form/Fieldset'
import ModelForm, { IModelFormRenderProps } from '~components/model-form/ModelForm'
import TranslationsField from '~components/model-form/TranslationsField'
import { IResourceRoutesFormProps } from '~components/routing/ResourceRoutes'
import PageUri from '~core/enums/pages'
import useStore from '~store/hooks/useStore'
import { Map } from '~store/models/Map'
import { Link, Grid } from '@material-ui/core'
import Icon from '~components/gui/Icon'
import Button from '~components/buttons/Button'
import SimpleField from '~components/model-form/SimpleField'

// ███████╗ ██████╗ ██████╗ ███╗   ███╗
// ██╔════╝██╔═══██╗██╔══██╗████╗ ████║
// █████╗  ██║   ██║██████╔╝██╔████╔██║
// ██╔══╝  ██║   ██║██╔══██╗██║╚██╔╝██║
// ██║     ╚██████╔╝██║  ██║██║ ╚═╝ ██║
// ╚═╝      ╚═════╝ ╚═╝  ╚═╝╚═╝     ╚═╝
//

const MapFormMain = observer(({ draft, resources, actionButtons }: IModelFormRenderProps<Map>) => {
	return (
		<React.Fragment>
			<SimpleField attribute='title' required label='Titel' />
			<MapField mapType={'driver'} label='Kaart voor chauffeur' attribute={'driverMap'} />

			{/* Actions */}
			<Fieldset>{actionButtons}</Fieldset>
		</React.Fragment>
	)
})

// ██╗    ██╗██████╗  █████╗ ██████╗ ██████╗ ███████╗██████╗
// ██║    ██║██╔══██╗██╔══██╗██╔══██╗██╔══██╗██╔════╝██╔══██╗
// ██║ █╗ ██║██████╔╝███████║██████╔╝██████╔╝█████╗  ██████╔╝
// ██║███╗██║██╔══██╗██╔══██║██╔═══╝ ██╔═══╝ ██╔══╝  ██╔══██╗
// ╚███╔███╔╝██║  ██║██║  ██║██║     ██║     ███████╗██║  ██║
//  ╚══╝╚══╝ ╚═╝  ╚═╝╚═╝  ╚═╝╚═╝     ╚═╝     ╚══════╝╚═╝  ╚═╝
//

const MapForm = ({ id, newModel, onDraft }: IResourceRoutesFormProps) => {
	const history = useHistory()
	const { api } = useStore()

	return (
		<ModelForm<Map>
			model={Map}
			modelId={id}
			newModel={newModel}
			apiOptions={{
				include: ['createdByUser']
			}}
			onCreated={record => history.push(`${PageUri.Maps}/${record.id}`)}
			onDeleted={record => history.push(PageUri.Maps)}
			paper
			header={{
				backUri: PageUri.Maps
			}}
			container
			// loadResources={async record => {
			// 	const { records: concessions } = await api.all(Concession)
			// 	return { concessions }
			// }}
			render={MapFormMain}
			renderTitle={({ record }) => (newModel ? 'Nieuwe kaart' : `Chauffeurkaarten: ${record.title}`)}
		/>
	)
}
export default observer(MapForm)
