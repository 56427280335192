import * as React from 'react'
import {
	model,
	Model,
	tProp,
	types,
	prop,
	registerRootStore,
	connectReduxDevTools,
	onSnapshot,
	SnapshotOutOf,
	fromSnapshot,
	SnapshotInOf,
	modelAction
} from 'mobx-keystone'
import { ApiStore } from './stores/ApiStore'
import Config from '../core/config/Config'
import { debounce } from 'lodash'
import { GuiStore } from './stores/GuiStore'
import { ResourceStore } from './stores/ResourceStore'
import { Map } from './models/Map'

const LocalStorageKey = 'keolis-reizen-cms:store'

@model('cms/RootStore')
export class RootStore extends Model({
	api: tProp(types.model<ApiStore>(ApiStore), () => new ApiStore({})),
	gui: tProp(types.model<GuiStore>(GuiStore), () => new GuiStore({})),
	resources: tProp(types.model<ResourceStore>(ResourceStore), () => new ResourceStore({}))
}) {}

// Initialize
export function createRootStore(snapshot?: SnapshotInOf<RootStore>): RootStore {
	// Persistent?
	let initialState = snapshot
	if (Config.debug && !initialState) {
		// Get it from local storage
		const stored = localStorage.getItem(LocalStorageKey)
		if (stored) initialState = JSON.parse(stored)
	}

	// Initialize it
	const store = initialState ? fromSnapshot(initialState) : new RootStore({})

	// Register it
	registerRootStore(store)

	// Debugging?
	if (Config.debug) {
		// Connect to redux devtools
		const remotedev = require('remotedev')
		const connection = remotedev.connectViaExtension({
			name: 'Keolis Reizen CMS'
		})
		connectReduxDevTools(remotedev, connection, store)

		// Watch the store to make it persistent
		const storeSnapshot = debounce((snap: SnapshotOutOf<RootStore>) => {
			localStorage.setItem(LocalStorageKey, JSON.stringify(snap))
		}, 250)
		onSnapshot(store, snap => storeSnapshot(snap))
	}

	return store
}
