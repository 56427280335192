import * as React from 'react'
import styled from 'styled-components'
import classNames from 'classnames'
import useStore from '~store/hooks/useStore'
import { Line } from '~store/models/Line'
import Field from '../Field'
import LineSelect from '~components/select/LineSelect'
import ColorPicker from '~components/forms/ColorPicker'
import { Journey } from '~store/models/Journey'
import JourneySelect from '~components/select/JourneySelect'
import { observer } from 'mobx-react'
import CheckSelect from '~components/forms/CheckSelect'
import { ListItemText, RadioGroup, FormControlLabel, Radio } from '@material-ui/core'
import { getDirectionTranslation } from '~core/enums/direction'
import moment from 'moment'
import ToolWindowButtons from '../ToolWindowButtons'
import Button from '~components/buttons/Button'
import { useMapModel } from '~store/hooks/useMapModel'
import { IToolProps } from '../IToolProps'
import { KeolisColors } from '../../../theme'
import { StopSummary } from '~store/models/StopSummary'
import StopSelect from '~components/select/StopSelect'

const SearchMethods = { All: 'Zoeken in alle lijnen', Stop: 'Zoeken op halte' }
type SearchMethod = keyof typeof SearchMethods

const LineTool = ({ onClose }: IToolProps) => {
	// Resources
	const { lines, journeys: allJourneys, stopSummaries } = useStore().resources
	const map = useMapModel()

	// State
	const [searchMethod, setSearchMethod] = React.useState<SearchMethod>('All')
	const [stopSummary, setStopSummary] = React.useState<StopSummary | undefined>()
	const [line, setLine] = React.useState<Line | undefined>()
	const [color, setColor] = React.useState<string>(KeolisColors[0])
	const [journeys, setJourneys] = React.useState<Journey[]>([])

	const [isBusy, setIsBusy] = React.useState<boolean>(false)

	// Lines source
	const searchLines = React.useMemo<Line[] | undefined>(() => {
		// All?
		if (searchMethod === 'All') return lines!

		// Stop?
		if (!stopSummary) return undefined
		return lines!.filter(line => {
			return !!stopSummary.journey_information.find(ji => ji.number === line.number)
		})
	}, [lines, searchMethod, stopSummary])

	// Filters
	const lineJourneys = React.useMemo(() => {
		// No line there?
		if (!line || !allJourneys) {
			setJourneys([])
			return null
		}

		// Filtered
		const filtered = allJourneys.filter(j => j.line_number === line.number)

		// By default select primary journeys
		setJourneys(filtered.filter(j => j.is_primary && j.lineVersion && j.lineVersion.is_current_version))

		return filtered
	}, [line, allJourneys])

	// Validation
	const formIsComplete = React.useMemo(() => journeys && journeys.length > 0 && color, [journeys, color])

	// Select stop
	const onSelectStopSummary = React.useCallback(
		s => {
			setStopSummary(s)
			setLine(undefined)
		},
		[setStopSummary, setLine]
	)

	// Submit
	const submit = React.useCallback(async () => {
		// Do it.
		setIsBusy(true)
		await map.addJourneyLayers(line!, journeys, color)
		setIsBusy(false)
		onClose()
	}, [map, line, journeys, color, onClose])

	return (
		<React.Fragment>
			<p>
				Zoek de route(s) uit KV1 die je wilt toevoegen aan de kaart, door eerst een lijn te kiezen en vervolgens de
				gewenste route. Je kunt zoeken op concessie, categorie, lijnnummer, bestemming en/of modaliteit (bus, trein,
				etc).
			</p>
			<p>
				Als je op halte wilt zoeken kun je eerst de gewenste halte kiezen en daarna de routes die deze halte passeren.
			</p>

			{/* Search method */}
			<Field>
				<RadioGroup
					row
					value={searchMethod}
					onChange={e => {
						setSearchMethod(e.target.value as SearchMethod)
						setLine(undefined)
					}}>
					{Object.keys(SearchMethods).map(key => (
						<FormControlLabel key={key} value={key} label={SearchMethods[key as SearchMethod]} control={<Radio />} />
					))}
				</RadioGroup>
			</Field>

			{/* Stop */}
			{searchMethod === 'Stop' && (
				<Field label={'Halte'} select>
					<StopSelect stops={stopSummaries!} value={stopSummary} onChange={onSelectStopSummary} />
				</Field>
			)}

			{/* Line */}
			{searchLines && (
				<React.Fragment>
					<Field label={'Lijn'} select>
						<LineSelect lines={searchLines} value={line} onChange={l => setLine(l as Line)} />
					</Field>
					{lineJourneys && (
						<Field label={'Route(s)'} select>
							<CheckSelect<Journey>
								renderText={journey => (
									<ListItemText
										primary={`Richting ${journey.toDestination!.name}`}
										secondary={[
											journey.key,
											journey.is_primary && 'Standaardroute',
											getDirectionTranslation(journey.direction),
										]
											.filter(v => !!v)
											.join(' | ')}
									/>
								)}
								options={lineJourneys}
								groupBy={journey =>
									journey.lineVersion
										? `${moment(journey.lineVersion.valid_from).format('LL')} t/m ${moment(
												journey.lineVersion.valid_until
										  ).format('LL')}`
										: '-'
								}
								value={journeys}
								onChange={v => setJourneys(v)}
							/>
						</Field>
					)}

					<Field label={'Kleur'} select>
						<ColorPicker value={color} onChange={c => setColor(c)} />
					</Field>
				</React.Fragment>
			)}
			<ToolWindowButtons>
				<Button loading={isBusy} color='primary' disabled={!formIsComplete} onClick={submit}>
					Toevoegen
				</Button>
			</ToolWindowButtons>
		</React.Fragment>
	)
}
export default observer(LineTool)
