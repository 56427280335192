import { Model, model, tProp, modelAction, modelFlow, _async, _await, types } from 'mobx-keystone'
import { ValidationRule, ModelValidationConfig } from '~api/model/validateModel'
import { computed } from 'mobx'
import { DirectionsEnum, getDirectionTranslation } from '~core/enums/direction'
import { Destination } from './Destination'
import { LineVersion } from './LineVersion'
import { JourneyStep } from './JourneyStep'
import { RootStore } from '~store/RootStore'
import { ApiStore } from '~store/stores/ApiStore'

@model('Journey')
export class Journey extends Model({
	id: tProp(types.maybe(types.number)),
	line_number: tProp(types.string),
	line_public_number: tProp(types.string),
	key: tProp(types.string),
	direction: tProp(types.enum<DirectionsEnum>(DirectionsEnum)),
	is_primary: tProp(types.boolean),

	toDestination: tProp(types.maybe(types.model<Destination>(Destination))),
	lineVersion: tProp(types.maybe(types.model<LineVersion>(LineVersion))),
	steps: tProp(types.maybe(types.array(types.model<JourneyStep>(JourneyStep))))
}) {
	api?: ApiStore
	onAttachedToRootStore(rootStore: RootStore) {
		this.api = rootStore.api
	}

	//  ██████╗ ██████╗ ███╗   ███╗██████╗ ██╗   ██╗████████╗███████╗██████╗
	// ██╔════╝██╔═══██╗████╗ ████║██╔══██╗██║   ██║╚══██╔══╝██╔════╝██╔══██╗
	// ██║     ██║   ██║██╔████╔██║██████╔╝██║   ██║   ██║   █████╗  ██║  ██║
	// ██║     ██║   ██║██║╚██╔╝██║██╔═══╝ ██║   ██║   ██║   ██╔══╝  ██║  ██║
	// ╚██████╗╚██████╔╝██║ ╚═╝ ██║██║     ╚██████╔╝   ██║   ███████╗██████╔╝
	//  ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝      ╚═════╝    ╚═╝   ╚══════╝╚═════╝
	//
	@computed
	get title(): string {
		return `Route ${this.key} - ${
			this.toDestination ? `richting ${this.toDestination.name}` : getDirectionTranslation(this.direction)
		}`
	}

	// ███╗   ███╗███████╗████████╗██╗  ██╗ ██████╗ ██████╗ ███████╗
	// ████╗ ████║██╔════╝╚══██╔══╝██║  ██║██╔═══██╗██╔══██╗██╔════╝
	// ██╔████╔██║█████╗     ██║   ███████║██║   ██║██║  ██║███████╗
	// ██║╚██╔╝██║██╔══╝     ██║   ██╔══██║██║   ██║██║  ██║╚════██║
	// ██║ ╚═╝ ██║███████╗   ██║   ██║  ██║╚██████╔╝██████╔╝███████║
	// ╚═╝     ╚═╝╚══════╝   ╚═╝   ╚═╝  ╚═╝ ╚═════╝ ╚═════╝ ╚══════╝
	//

	@modelFlow
	loadSteps = _async(function*(this: Journey, api?: ApiStore, refresh: boolean = false) {
		// Loaded already?
		if (!refresh && !!this.steps) return

		// Get 'em
		const result = yield* _await(
			(api || this.api!).get('/journey_steps', {
				filters: {
					journey_id: this.id!.toString()
				},
				include: ['fromStopVersion', 'toStopVersion'],
				castAs: JourneyStep,
				pageSize: 1000
			})
		)
		this.steps = result.records as JourneyStep[]
	})
}
