import { FontAwesomeIconName } from './icons'

type VehicleType = {
	icon: FontAwesomeIconName
	translation: string
}

export enum TransportType {
	Bus = 'Bus',
	Tram = 'Tram',
	Metro = 'Metro',
	Boat = 'Boat',
	Train = 'Train'
}

const VehicleTypes: { [key: string]: VehicleType } = <const>{
	Bus: { icon: 'bus', translation: 'Bus' },
	Tram: { icon: 'train', translation: 'Tram' },
	Metro: { icon: 'subway', translation: 'Metro' },
	Boat: { icon: 'ship', translation: 'Veerpont' },
	Train: { icon: 'train', translation: 'Trein' }
}
export type IVehicleType = 'Bus' | 'Tram' | 'Metro' | 'Boat' | 'Train'

export function getVehicleIcon(type: IVehicleType): FontAwesomeIconName {
	return VehicleTypes[type].icon
}
export function getVehicleTypeTranslation(type: IVehicleType): string {
	return VehicleTypes[type].translation
}

export function getVehicleIconForCHB(type: 'bus' | 'tram' | 'metro' | 'ferry'): FontAwesomeIconName {
	switch (type) {
		case 'bus':
			return 'bus'
		case 'tram':
			return 'train'
		case 'metro':
			return 'subway'
		case 'ferry':
			return 'ship'
	}
}
