import { InputAdornment } from '@material-ui/core'
import { observer } from 'mobx-react'
import * as React from 'react'
import { useHistory } from 'react-router-dom'
import BackButton from '~components/buttons/header-buttons/BackButton'
import CheckboxField from '~components/model-form/CheckboxField'
import ChipField from '~components/model-form/ChipField'
import Fieldset from '~components/model-form/Fieldset'
import ModelForm, { IModelFormRenderProps } from '~components/model-form/ModelForm'
import SelectField from '~components/model-form/SelectField'
import SimpleField from '~components/model-form/SimpleField'
import TranslationsField from '~components/model-form/TranslationsField'
import { IResourceRoutesFormProps } from '~components/routing/ResourceRoutes'
import PageUri from '~core/enums/pages'
import useStore from '~store/hooks/useStore'
import { Concession } from '~store/models/Concession'
import {
	AgeGroups,
	Operators,
	PaymentMethods,
	TravelModes,
	TravelProduct,
	TravelProductTypes,
} from '~store/models/TravelProduct'
import DateTimeField from '~components/model-form/DateTimeField'

// ███████╗ ██████╗ ██████╗ ███╗   ███╗
// ██╔════╝██╔═══██╗██╔══██╗████╗ ████║
// █████╗  ██║   ██║██████╔╝██╔████╔██║
// ██╔══╝  ██║   ██║██╔══██╗██║╚██╔╝██║
// ██║     ╚██████╔╝██║  ██║██║ ╚═╝ ██║
// ╚═╝      ╚═════╝ ╚═╝  ╚═╝╚═╝     ╚═╝
//

const TravelProductFormMain = observer(({ draft, resources, actionButtons }: IModelFormRenderProps<TravelProduct>) => {
	const product = draft.data

	const concessions = resources.concessions as Concession[]
	const concessionOptions = React.useMemo(() => {
		const options: { [key: string]: string } = {}
		concessions.forEach(c => (options[c.slug] = c.slug))
		return options
	}, [concessions])

	return (
		<React.Fragment>
			{/* Algemeen */}
			<Fieldset title='Algemeen'>
				<TranslationsField attribute='title' required label='Titel' />
				<TranslationsField
					attribute='description'
					required
					label='Omschrijving'
					input={{ multiline: true, rowsMin: 4 }}
				/>
				<TranslationsField attribute='more_information_url' label='Meer informatie URL' />
				<SelectField required attribute='type' label='Type' options={TravelProductTypes} />
			</Fieldset>

			{/* Reisgebied */}
			<Fieldset title={'Reisgebied'}>
				{/* Center zone */}
				<CheckboxField attribute={'has_center_zone'} label={'Product heeft centrum-zone'} />
				{product.has_center_zone && (
					<React.Fragment>
						<ChipField nullWhenEmpty attribute='allowed_center_zones' label={'Mogelijke centrum-zones (zonenummers)'} />
						<SimpleField
							grid={{ xs: 6 }}
							attribute={'zone_range'}
							label={'Aantal sterren'}
							inputMode={'numeric'}
							input={{ inputProps: { min: 0 } }}
							field={{ help: '(aantal zones vanaf centrum-zone - 1)' }}
						/>
						<SimpleField
							grid={{ xs: 6 }}
							attribute={'minimum_zone_range'}
							label={'Minimum aantal sterren'}
							inputMode={'numeric'}
							input={{ inputProps: { min: 0 } }}
							field={{ help: '(toon product niet voor reizen onder X sterren)' }}
						/>
						<CheckboxField
							attribute={'hide_zone_range'}
							label={
								<span>
									Reiziger kan zelf <em>geen</em> centrum-zone kiezen
								</span>
							}
						/>
					</React.Fragment>
				)}

				{/* Specifiek gebied */}
				<ChipField nullWhenEmpty attribute='valid_in_zones' label='Specifiek reisgebeid (zonenummers)' />
				<ChipField nullWhenEmpty attribute='not_valid_in_zones' label='Niet geldig in zones (zonenummers)' />
			</Fieldset>

			<Fieldset title={'Prijzen en verkoop'}>
				<CheckboxField
					attribute={'disable_price_calculations'}
					label={' Prijsberekening in de adviseur is niet mogelijk voor dit product'}
				/>
				{/* Webshop URL */}
				<TranslationsField attribute={'webshop_product_url'} label='Webshop URL (Maandabonnement)' />
				{product.hasPriceYearly && (
					<TranslationsField attribute={'webshop_product_year_url'} label='Webshop URL (Jaarabonnement)' />
				)}
				{/* Price: Ticket */}
				{product.hasPriceTicket && (
					<React.Fragment>
						<SimpleField
							inputMode={'numeric'}
							grid={{ xs: 6 }}
							input={{ startAdornment: <InputAdornment position={'start'}>€</InputAdornment> }}
							attribute='price_ticket_full'
							label='Ticketprijs (vol tarief / volwassenen)'
						/>
						<SimpleField
							inputMode={'numeric'}
							grid={{ xs: 6 }}
							input={{ startAdornment: <InputAdornment position={'start'}>€</InputAdornment> }}
							attribute='price_ticket_reduction'
							label='Ticketprijs (gereduceerd)'
						/>
					</React.Fragment>
				)}
				{/* Price: Monthly */}
				{product.hasPriceMonthly && (
					<React.Fragment>
						<SimpleField
							inputMode={'numeric'}
							grid={{ xs: 6 }}
							input={{ startAdornment: <InputAdornment position={'start'}>€</InputAdornment> }}
							attribute='price_monthly_full'
							label='Maandprijs (vol tarief / volwassenen)'
						/>
						<SimpleField
							inputMode={'numeric'}
							grid={{ xs: 6 }}
							input={{ startAdornment: <InputAdornment position={'start'}>€</InputAdornment> }}
							attribute='price_monthly_reduction'
							label='Maandprijs (gereduceerd)'
						/>
					</React.Fragment>
				)}
				{/* Price: Yearly */}
				{product.hasPriceYearly && (
					<React.Fragment>
						<SimpleField
							inputMode={'numeric'}
							grid={{ xs: 6 }}
							input={{ startAdornment: <InputAdornment position={'start'}>€</InputAdornment> }}
							attribute='price_yearly_full'
							label='Jaarprijs (vol tarief / volwassenen)'
						/>
						<SimpleField
							inputMode={'numeric'}
							grid={{ xs: 6 }}
							input={{ startAdornment: <InputAdornment position={'start'}>€</InputAdornment> }}
							attribute='price_yearly_reduction'
							label='Jaarprijs (gereduceerd)'
						/>
					</React.Fragment>
				)}

				{/* Payment methods */}
				<SelectField
					grid={{ xs: 6 }}
					multiple
					attribute='payment_methods'
					label='Betaalmethodes'
					field={{}}
					options={PaymentMethods}
				/>
			</Fieldset>

			{/* Korting */}
			<Fieldset title={'Korting'}>
				<SimpleField
					inputMode={'numeric'}
					grid={{ xs: 12 }}
					input={{ endAdornment: <InputAdornment position={'end'}>%</InputAdornment> }}
					attribute='fare_discount_percentage'
					label='Korting-percentage op rit'
					field={{ help: '(100% is standaard-abonnement)' }}
				/>
				<SimpleField
					inputMode={'numeric'}
					grid={{ xs: 4 }}
					input={{ endAdornment: <InputAdornment position={'end'}>%</InputAdornment> }}
					attribute='fare_discount_percentage_morning_peak'
					label='Korting-percentage ochtendspits'
				/>
				<SimpleField
					inputMode={'numeric'}
					grid={{ xs: 4 }}
					input={{ endAdornment: <InputAdornment position={'end'}>%</InputAdornment> }}
					attribute='fare_discount_percentage_afternoon_peak'
					label='Korting-percentage middagspits'
				/>
				<SimpleField
					inputMode={'numeric'}
					grid={{ xs: 4 }}
					input={{ endAdornment: <InputAdornment position={'end'}>%</InputAdornment> }}
					attribute='fare_discount_percentage_outside_peak'
					label='Korting-percentage daluren'
				/>
				<CheckboxField
					grid={{ xs: 6 }}
					attribute={'is_valid_during_morning_peak'}
					label={'Ticket is geldig in de ochtendspits'}
				/>
				<CheckboxField
					grid={{ xs: 6 }}
					attribute={'is_valid_during_afternoon_peak'}
					label={'Ticket is geldig in de middagspits'}
				/>
			</Fieldset>

			{/* Filters */}
			<Fieldset title='Filters'>
				{/* Operators */}
				<SelectField
					grid={{ xs: 6 }}
					multiple
					attribute='only_specific_operators'
					label='Alleen voor geselecteerde vervoerders'
					field={{ help: '(Whitelist)' }}
					options={Operators}
				/>
				<SelectField
					grid={{ xs: 6 }}
					multiple
					attribute='except_specific_operators'
					label='Behalve voor geselecteerde vervoerders'
					field={{ help: '(Blacklist)' }}
					options={Operators}
				/>

				{/* Travel modes */}
				<SelectField
					grid={{ xs: 6 }}
					multiple
					attribute='only_specific_travel_modes'
					label='Alleen voor geselecteerde vervoersmiddelen'
					options={TravelModes}
				/>
				<SelectField
					grid={{ xs: 6 }}
					multiple
					attribute='except_specific_travel_modes'
					label='Behalve voor geselecteerde vervoersmiddelen'
					options={TravelModes}
				/>

				{/* Concessions */}
				<SelectField
					multiple
					attribute='only_specific_concessions'
					label='Alleen voor geselecteerde concessies'
					options={concessionOptions}
				/>

				{/* Vervoerder/lijnnummer */}
				<ChipField grid={{ xs: 4 }} attribute={'only_specific_line_numbers'} label='Alleen voor lijnnummers' />
				<ChipField grid={{ xs: 4 }} attribute={'except_specific_line_numbers'} label='Behalve voor lijnnummers' />
				<ChipField grid={{ xs: 4 }} attribute={'including_specific_line_numbers'} label='Óók voor lijnnummers' />

				{/* Age groups */}
				<SelectField multiple attribute='for_ages' label='Leeftijdscategoriën' options={AgeGroups} />

				{/* Validity */}
				<DateTimeField
					options={{ clearable: true }}
					type={'DateTime'}
					grid={{ xs: 6 }}
					attribute={'valid_from'}
					label={'Geldig vanaf'}
				/>
				<DateTimeField
					options={{ clearable: true }}
					type={'DateTime'}
					grid={{ xs: 6 }}
					attribute={'valid_until'}
					label={'Geldig tot'}
				/>
			</Fieldset>

			{/* Actions */}
			<Fieldset>{actionButtons}</Fieldset>
		</React.Fragment>
	)
})

// ██╗    ██╗██████╗  █████╗ ██████╗ ██████╗ ███████╗██████╗
// ██║    ██║██╔══██╗██╔══██╗██╔══██╗██╔══██╗██╔════╝██╔══██╗
// ██║ █╗ ██║██████╔╝███████║██████╔╝██████╔╝█████╗  ██████╔╝
// ██║███╗██║██╔══██╗██╔══██║██╔═══╝ ██╔═══╝ ██╔══╝  ██╔══██╗
// ╚███╔███╔╝██║  ██║██║  ██║██║     ██║     ███████╗██║  ██║
//  ╚══╝╚══╝ ╚═╝  ╚═╝╚═╝  ╚═╝╚═╝     ╚═╝     ╚══════╝╚═╝  ╚═╝
//

const TravelProductForm = ({ id, newModel }: IResourceRoutesFormProps) => {
	const history = useHistory()
	const { api } = useStore()

	return (
		<ModelForm<TravelProduct>
			model={TravelProduct}
			modelId={id}
			newModel={newModel}
			onCreated={record => history.push(`${PageUri.TravelProducts}/${record.id}`)}
			onDeleted={record => history.push(PageUri.TravelProducts)}
			paper
			header={{
				backUri: PageUri.TravelProducts,
			}}
			container
			loadResources={async record => {
				const { records: concessions } = await api.all(Concession)
				return { concessions }
			}}
			render={TravelProductFormMain}
			renderTitle={({ record }) => (newModel ? 'Nieuw reisproduct' : `Reisproducten: ${record.title.nl}`)}
		/>
	)
}
export default observer(TravelProductForm)
