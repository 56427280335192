import * as React from 'react';

import { IMapLayerProps } from '../MapLayer';
import { Polyline, PolylineProps, MapLayer } from 'react-leaflet';
import {
  getLatLngsFromPolyline,
  getPolylineFromLatLngs,
} from '../../utils/geo';

import { observer } from 'mobx-react';
import { LatLng, LeafletMouseEvent } from 'leaflet';
import { LineStyle } from '../../enums/style';
import { Colors } from '../../utils/theme';

interface EditableLayer extends MapLayer {
  enableEdit: () => any;
  disableEdit: () => any;
  getLatLngs: () => LatLng[];
}

// ███████╗████████╗██╗   ██╗██╗     ███████╗██████╗
// ██╔════╝╚══██╔══╝╚██╗ ██╔╝██║     ██╔════╝██╔══██╗
// ███████╗   ██║    ╚████╔╝ ██║     █████╗  ██║  ██║
// ╚════██║   ██║     ╚██╔╝  ██║     ██╔══╝  ██║  ██║
// ███████║   ██║      ██║   ███████╗███████╗██████╔╝
// ╚══════╝   ╚═╝      ╚═╝   ╚══════╝╚══════╝╚═════╝
//

//  ██████╗ ██████╗ ███╗   ███╗██████╗  ██████╗ ███╗   ██╗███████╗███╗   ██╗████████╗
// ██╔════╝██╔═══██╗████╗ ████║██╔══██╗██╔═══██╗████╗  ██║██╔════╝████╗  ██║╚══██╔══╝
// ██║     ██║   ██║██╔████╔██║██████╔╝██║   ██║██╔██╗ ██║█████╗  ██╔██╗ ██║   ██║
// ██║     ██║   ██║██║╚██╔╝██║██╔═══╝ ██║   ██║██║╚██╗██║██╔══╝  ██║╚██╗██║   ██║
// ╚██████╗╚██████╔╝██║ ╚═╝ ██║██║     ╚██████╔╝██║ ╚████║███████╗██║ ╚████║   ██║
//  ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝      ╚═════╝ ╚═╝  ╚═══╝╚══════╝╚═╝  ╚═══╝   ╚═╝
//

const FreeLine = (props: IMapLayerProps) => {
  const { layer } = props;

  // Create polyline
  const polyProps: PolylineProps = React.useMemo(
    () => ({
      positions: getLatLngsFromPolyline(layer.polyline!),
      weight: 7,
    }),
    [layer.polyline]
  );
  const ref = React.useRef<Polyline>(null);

  // Enable edit
  const edit = React.useCallback(() => {
    if (ref.current) {
      const el = (ref.current.leafletElement as unknown) as EditableLayer;
      el.enableEdit();
    }
  }, [ref]);

  // Complete edit
  const finish = React.useCallback(() => {
    if (ref.current) {
      // Disable the editor
      const el = (ref.current.leafletElement as unknown) as EditableLayer;
      if (el.disableEdit) el.disableEdit();

      // Store latlng
      layer.setProperty('polyline', getPolylineFromLatLngs(el.getLatLngs()));
    }
  }, [layer]);

  // Edit / finish commands
  React.useEffect(() => {
    layer.isEditing ? edit() : finish();
  }, [layer.isEditing, ref]);

  // On click
  const { onClick } = props;
  const { isSplitting } = layer;
  const handleClick = React.useCallback(
    (e: LeafletMouseEvent) => {
      if (isSplitting) {
        layer.splitAt(e.latlng);
      } else {
        return onClick && onClick();
      }
    },
    [layer, onClick, isSplitting]
  );

  // Color.
  const color = layer.isHovering
    ? Colors.layerHover
    : layer.isSelected
    ? Colors.layerSelected
    : layer.layerColor;

  return (
    <Polyline
      dashArray={layer.lineStyle === LineStyle.Dotted ? [6, 16] : []}
      opacity={layer.isHovering || layer.isSelected ? 0.9 : 0.45}
      color={color}
      ref={ref}
      {...polyProps}
      onclick={handleClick}
      onmouseover={props.onMouseEnter}
      onmouseout={props.onMouseLeave}
    />
  );
};
export default observer(FreeLine);
