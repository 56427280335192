import { Container, TableCell, Chip, Paper, Box } from '@material-ui/core'
import * as React from 'react'
import { useHistory, Link } from 'react-router-dom'
import RemoteView from '~components/remote-data/RemoteView'
import PageUri from '~core/enums/pages'
import { Notification } from '~store/models/Notification'
import AddBoxIcon from '@material-ui/icons/AddBox'
import Icon from '~components/gui/Icon'
import IconButton from '~components/buttons/IconButton'
import styled from 'styled-components'
import { capitalize } from 'lodash'
import Chips from '~components/gui/Chips'
import useStore from '~store/hooks/useStore'

export interface INotificationIndexPageProps {}

const I = styled(Icon)`
	font-size: 16px;
	margin-right: 0.5em;
`
const DriverIcon = styled(I)`
	color: ${props => props.theme.palette.primary.main};
`
const PublicIcon = styled(I)`
	color: ${props => props.theme.palette.secondary.main};
`

const ForDescription = styled.div`
	white-space: nowrap;
	font-size: 12px;
	margin-bottom: 4px;
	i.fa {
		margin-right: 0.5em;
		color: ${props => props.theme.palette.primary.main};
		&.fa-stop {
			color: ${props => props.theme.palette.secondary.main};
		}
		&.fa-bookmark {
			color: ${props => props.theme.palette.info.main};
		}
	}
`

const NotificationIndexPage = (props: INotificationIndexPageProps) => {
	const history = useHistory()
	const { api } = useStore()
	const { user } = api

	const [showExpired, setShowExpired] = React.useState<boolean>(false)

	const filters = React.useMemo(() => {
		const filters: any = { show_expired: showExpired }
		if (user && user.concession_slugs) filters.for_concession_slugs = user.concession_slugs.join(',')
		return filters
	}, [showExpired, user])

	// Loading?
	return (
		user && (
			<Container>
				<RemoteView
					title={'Meldingen'}
					headerButtons={
						<React.Fragment>
							<IconButton
								icon={'trash-restore'}
								edge={'end'}
								color={showExpired ? 'secondary' : 'default'}
								title={`${showExpired ? 'Verberg' : 'Toon'} verlopen meldingen`}
								onClick={() => setShowExpired(!showExpired)}
							/>
							<IconButton
								icon={'plus-square'}
								edge={'end'}
								to={`${PageUri.Notifications}/nieuw`}
								color='default'
								title={'Nieuwe melding'}
							/>
						</React.Fragment>
					}
					apiOptions={{
						filters,
					}}
					model={Notification}
					variant={'table'}
					defaultSort={{ attribute: 'id', direction: 'desc' }}
					attributes={[
						{ key: 'id', label: '#', sortable: true, defaultSortDirection: 'desc' },
						{ key: 'for_concession_slugs', label: 'Concessie(s)', sortable: true },
						{ key: 'title', label: 'Titel' },
						{ key: 'isVisibleNow', label: 'Nu zichtbaar' },
						{ key: 'for_line_numbers', label: 'Lijnen / haltes' },
						{ key: 'visible_from', label: 'Van/tot', sortable: true, defaultSortDirection: 'desc' },
						{ key: 'driver_map_id', label: 'Kaart', sortable: true, defaultSortDirection: 'desc' },
					]}
					table={{
						onSelectRow: record => history.push(`${PageUri.Notifications}/${record.id}`),
						renderCell: {
							for_concession_slugs: record => (
								<TableCell key='for_concession_slugs'>
									{record.for_concession_slugs && (
										<Chips items={record.for_concession_slugs.map(slug => capitalize(slug))} />
									)}
								</TableCell>
							),

							for_line_numbers: record => (
								<TableCell key='for_line_numbers'>
									{record.for_concession_slugs && (
										<ForDescription>
											<Icon name={'bookmark'} />
											{record.for_concession_slugs.length < 3
												? record.for_concession_slugs.map(capitalize).join(', ')
												: `${record.for_concession_slugs.length} concessies`}
										</ForDescription>
									)}
									{record.for_line_numbers && (
										<ForDescription>
											<Icon name={'route'} />
											{record.for_line_numbers.length < 3
												? record.for_line_numbers.join(', ')
												: `${record.for_line_numbers.length} lijnen`}
										</ForDescription>
									)}
									{record.for_stop_codes && (
										<ForDescription>
											<Icon name={'stop'} />
											{record.for_stop_codes.length < 3
												? record.for_stop_codes.join(', ')
												: `${record.for_stop_codes.length} haltes`}
										</ForDescription>
									)}
								</TableCell>
							),

							driver_map_id: record => (
								<TableCell key='map' style={{ whiteSpace: 'nowrap' }}>
									{record.driver_map_id && <DriverIcon name={'bus'} />}
									{record.public_map_id && <PublicIcon name={'globe'} />}
								</TableCell>
							),
							visible_from: record => (
								<TableCell key='visible_from' style={{ whiteSpace: 'nowrap' }}>
									<small>{record.formattedDates}</small>
								</TableCell>
							),
						},
					}}
				/>
			</Container>
		)
	)
}
export default NotificationIndexPage
