import { useEffect, useMemo, useState } from 'react'
import { ModelClass, AnyModel, Draft, draft as makeDraft, isModel } from 'mobx-keystone'
import { IObjectDidChange, isObservable, Lambda, observe } from 'mobx'
import { each, flattenDeep } from 'lodash'

export const deepObserve = (model: AnyModel, listener: (change: IObjectDidChange) => void): Lambda => {
	// Collect the lambda's
	const lambdas: Lambda[] | any[] = []

	if (model && model.$) {
		// The object itself
		lambdas.push(observe(model.$, listener))

		// Go through other props
		each(model.$, (value, key) => {
			// Itself observable? Go deeper.
			if (isModel(value)) lambdas.push(deepObserve(value, listener))
		})
	}

	// Return the killer
	return () => {
		flattenDeep(lambdas).forEach(l => l())
	}
}

export const useDraftIsDirty = (draft: Draft<AnyModel> | undefined | null) => {
	const [isDirty, setIsDirty] = useState<boolean>(false)

	// Watch the draft, and its children
	useEffect(() => {
		// Watch the draft and its children
		return draft ? deepObserve(draft.data, () => setIsDirty(draft.isDirty)) : () => {}
	}, [draft, setIsDirty])

	return isDirty
}
