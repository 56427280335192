import { observer } from 'mobx-react';
import * as React from 'react';
import { useMapData } from '../context/MapDataContext';
import { Layer } from '../store/Layer';
import FreeLine from './layers/FreeLine';
import Icon from './layers/Icon';
import ImageLayer from './layers/Image';
import Label from './layers/Label';
import LineSegment from './layers/LineSegment';
import Stop from './layers/Stop';

export type LayerComponent = (props: IMapLayerProps) => any;
export const LayerComponents: { [key: string]: LayerComponent } = {
  LineSegment,
  Stop,
  Label,
  Icon,
  FreeLine,
  Image: ImageLayer,
};

//  ██████╗ ██████╗ ███╗   ███╗██████╗  ██████╗ ███╗   ██╗███████╗███╗   ██╗████████╗
// ██╔════╝██╔═══██╗████╗ ████║██╔══██╗██╔═══██╗████╗  ██║██╔════╝████╗  ██║╚══██╔══╝
// ██║     ██║   ██║██╔████╔██║██████╔╝██║   ██║██╔██╗ ██║█████╗  ██╔██╗ ██║   ██║
// ██║     ██║   ██║██║╚██╔╝██║██╔═══╝ ██║   ██║██║╚██╗██║██╔══╝  ██║╚██╗██║   ██║
// ╚██████╗╚██████╔╝██║ ╚═╝ ██║██║     ╚██████╔╝██║ ╚████║███████╗██║ ╚████║   ██║
//  ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝      ╚═════╝ ╚═╝  ╚═══╝╚══════╝╚═╝  ╚═══╝   ╚═╝
//
export interface IMapLayerProps {
  layer: Layer;
  parentLayer?: Layer;
  onClick?: () => any;
  onMouseEnter?: () => any;
  onMouseLeave?: () => any;
  interactive?: boolean;
}

const MapLayer = observer((props: IMapLayerProps) => {
  const { layer, interactive } = props;
  const mapData = useMapData();

  // Get component
  const LayerComponent = React.useMemo(() => LayerComponents[layer.type], [
    layer.type,
  ]);

  // Hover / select
  const handleMouseEnter = React.useCallback(
    () => interactive && mapData.hoverLayer(layer),
    [interactive, layer, mapData]
  );
  const handleMouseLeave = React.useCallback(
    () => interactive && layer.setHover(false),
    [interactive, layer]
  );
  const handleClick = React.useCallback(
    () => interactive && mapData.selectLayer(layer.isSelected ? null : layer),
    [interactive, layer, mapData]
  );

  // Not visible?
  if (!layer.isVisible) return null;

  return (
    <React.Fragment>
      {LayerComponent && (
        <LayerComponent
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={handleClick}
          {...props}
        />
      )}

      {/* Children */}
      {layer.subLayers &&
        layer.subLayers.map(
          sub =>
            sub.isVisible && (
              <MapLayer
                interactive={interactive}
                key={sub.$modelId}
                layer={sub}
                parentLayer={layer}
              />
            )
        )}
    </React.Fragment>
  );
});
export default MapLayer;
