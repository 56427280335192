import { Paper } from '@material-ui/core'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import * as React from 'react'
import styled from 'styled-components'
import { IToolConfig, Tools } from '~core/enums/tools'
import { Map } from '~store/models/Map'
import ToolbarButton from './ToolbarButton'

// ███████╗████████╗██╗   ██╗██╗     ███████╗██████╗
// ██╔════╝╚══██╔══╝╚██╗ ██╔╝██║     ██╔════╝██╔══██╗
// ███████╗   ██║    ╚████╔╝ ██║     █████╗  ██║  ██║
// ╚════██║   ██║     ╚██╔╝  ██║     ██╔══╝  ██║  ██║
// ███████║   ██║      ██║   ███████╗███████╗██████╔╝
// ╚══════╝   ╚═╝      ╚═╝   ╚══════╝╚══════╝╚═════╝
//

const Container = styled.div`
	position: absolute;
	top: 16px;
	left: 16px;
	z-index: 1000;

	padding: 0px;
	border-radius: 4px;

	transition: top 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);

	display: flex;

	&.hidden {
		top: -50px;
	}
`
const Buttons = styled(Paper)`
	overflow: hidden;
	border-radius: 4px;
	display: flex;
	flex-direction: row;
	margin-right: 0.5em;
`

//  ██████╗ ██████╗ ███╗   ███╗██████╗  ██████╗ ███╗   ██╗███████╗███╗   ██╗████████╗
// ██╔════╝██╔═══██╗████╗ ████║██╔══██╗██╔═══██╗████╗  ██║██╔════╝████╗  ██║╚══██╔══╝
// ██║     ██║   ██║██╔████╔██║██████╔╝██║   ██║██╔██╗ ██║█████╗  ██╔██╗ ██║   ██║
// ██║     ██║   ██║██║╚██╔╝██║██╔═══╝ ██║   ██║██║╚██╗██║██╔══╝  ██║╚██╗██║   ██║
// ╚██████╗╚██████╔╝██║ ╚═╝ ██║██║     ╚██████╔╝██║ ╚████║███████╗██║ ╚████║   ██║
//  ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝      ╚═════╝ ╚═╝  ╚═══╝╚══════╝╚═╝  ╚═══╝   ╚═╝
//
export interface IToolbarProps {
	activeTool: IToolConfig | null
	onSelectTool: (tool: IToolConfig) => any
	hidden?: boolean
	map: Map
}

const Toolbar = ({ map, activeTool, onSelectTool, hidden }: IToolbarProps) => {
	// Get the undo states
	const { undoManager } = map.mapData

	return (
		<Container className={classNames({ hidden })}>
			<Buttons elevation={3}>
				{Tools.map(tool => (
					<ToolbarButton
						key={tool.key}
						icon={tool.icon}
						label={tool.title}
						active={!!activeTool && activeTool.key === tool.key}
						onClick={() => onSelectTool(tool)}
					/>
				))}
			</Buttons>
			<Buttons elevation={3}>
				<ToolbarButton icon={'undo'} disabled={!undoManager.canUndo} onClick={() => undoManager.undo()} />
				<ToolbarButton icon={'redo'} disabled={!undoManager.canRedo} onClick={() => undoManager.redo()} />
			</Buttons>
		</Container>
	)
}
export default observer(Toolbar)
