import { Checkbox, GridProps, InputProps, ListItemText, MenuItem, Select, SelectProps } from '@material-ui/core'
import { map } from 'lodash'
import { AnyModel } from 'mobx-keystone'
import { observer } from 'mobx-react'
import * as React from 'react'
import Field, { IFieldProps } from './Field'
import { useFormRecord } from './ModelFormContext'
import { AnyApiModel } from '~api/model/ApiModel'

export interface ISelectFieldProps {
	record?: AnyApiModel
	attribute: string
	required?: boolean
	label?: string
	grid?: GridProps
	options: { [key: string]: string }
	children?: any
	select?: SelectProps
	multiple?: boolean
	field?: IFieldProps
	nullWhenEmpty?: boolean
	valueIsNumeric?: boolean
}

const SelectField = (props: ISelectFieldProps) => {
	const formRecord = useFormRecord()

	const {
		attribute,
		label,
		children,
		multiple,
		options,
		field,
		required = false,
		nullWhenEmpty = true,
		valueIsNumeric = false,
	} = props
	const record = props.record || formRecord

	const value = record && record[attribute] ? record[attribute] : multiple ? [] : null

	if (!record) throw `You need to set the record attribute, or render inside a ModelFormProvider`

	return (
		<Field {...field} label={label} required={required} grid={props.grid} attribute={attribute}>
			<Select
				{...props.select}
				multiple={multiple}
				value={value}
				onChange={e => {
					let newValue = e.target.value
					if (multiple && newValue.length === 0) newValue = null
					if (valueIsNumeric && typeof newValue === 'string') newValue = parseInt(newValue)
					record.setAttribute(attribute, newValue)
				}}
				renderValue={selected =>
					multiple ? (selected as string[]).map(v => options[v]).join(', ') : options[selected]
				}>
				{/* Items */}
				{map(options, (label, key) => (
					<MenuItem key={key} value={key}>
						{multiple && <Checkbox checked={value.indexOf(key) > -1} />}
						<ListItemText primary={label} />
					</MenuItem>
				))}
			</Select>
		</Field>
	)
}
export default observer(SelectField)
