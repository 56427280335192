import * as React from 'react'
import { Route } from 'react-router-dom'
import { AnyApiModel } from '~api/model/ApiModel'
import { IModelFormProps } from '~components/model-form/ModelForm'
import { Draft } from 'mobx-keystone'

export interface IResourceRoutesFormProps {
	id?: string | number
	newModel?: boolean
	onDraft?: (model: Draft<Notification>) => any
	title?: string
}

export interface IResourceRoutesProps {
	Index: React.ComponentType
	Form: React.ComponentType<IResourceRoutesFormProps>
	path: string
}

const ResourceRoutes = ({ Index, Form, path }: IResourceRoutesProps) => {
	return (
		<React.Fragment>
			<Route exact path={path} component={Index} />
			<Route exact path={`${path}/:id(\\d+)`} render={({ match }) => <Form id={match.params.id} />} />
			<Route exact path={`${path}/nieuw`}>
				<Form newModel />
			</Route>
		</React.Fragment>
	)
}
export default ResourceRoutes
