import * as React from 'react'
import { Map as ReactLeafletMap, MapProps } from 'react-leaflet'
import styled from 'styled-components'
import mapStyle from './map-style-more-pois'

import { LatLng, LatLngBounds, MapOptions } from 'leaflet'
import MapboxGLLayer from './MapboxGLLayer'

// ███████╗████████╗██╗   ██╗██╗     ███████╗██████╗
// ██╔════╝╚══██╔══╝╚██╗ ██╔╝██║     ██╔════╝██╔══██╗
// ███████╗   ██║    ╚████╔╝ ██║     █████╗  ██║  ██║
// ╚════██║   ██║     ╚██╔╝  ██║     ██╔══╝  ██║  ██║
// ███████║   ██║      ██║   ███████╗███████╗██████╔╝
// ╚══════╝   ╚═╝      ╚═╝   ╚══════╝╚══════╝╚═════╝
//

const MapContainer = styled('div')`
	height: 100%;
	> * {
		height: 100%;
	}
`

//  ██████╗ ██████╗ ███╗   ███╗██████╗  ██████╗ ███╗   ██╗███████╗███╗   ██╗████████╗
// ██╔════╝██╔═══██╗████╗ ████║██╔══██╗██╔═══██╗████╗  ██║██╔════╝████╗  ██║╚══██╔══╝
// ██║     ██║   ██║██╔████╔██║██████╔╝██║   ██║██╔██╗ ██║█████╗  ██╔██╗ ██║   ██║
// ██║     ██║   ██║██║╚██╔╝██║██╔═══╝ ██║   ██║██║╚██╗██║██╔══╝  ██║╚██╗██║   ██║
// ╚██████╗╚██████╔╝██║ ╚═╝ ██║██║     ╚██████╔╝██║ ╚████║███████╗██║ ╚████║   ██║
//  ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝      ╚═════╝ ╚═╝  ╚═══╝╚══════╝╚═╝  ╚═══╝   ╚═╝
//

export interface IMapProps {
	style?: React.CSSProperties
	children?: any
	bounds?: LatLngBounds
	zoom?: number
	center?: LatLngExpression
	onRef?: (map: ReactLeafletMap) => void
	mapProps?: Partial<MapProps>
}

const Map = (props: IMapProps) => {
	// State
	const [zoom, setZoom] = React.useState<number>(props.zoom || 10)
	const center = props.center || new LatLng(52.601145, 6.409836)

	// Get props
	const { style, children, bounds, onRef, mapProps = {} } = props

	const handleRef = React.useCallback(
		(ref: ReactLeafletMap) => {
			if (onRef) onRef(ref)
			if (ref && bounds) {
				ref.leafletElement.fitBounds(bounds)
			}
		},
		[onRef, bounds]
	)

	return (
		<MapContainer className={`map map-editor-map-container`} style={style} tabIndex={-1}>
			<ReactLeafletMap
				preferCanvas
				editable
				attributionControl={false}
				zoomControl={false}
				doubleClickZoom={true}
				minZoom={9}
				maxZoom={18}
				zoom={zoom}
				zoomSnap={0.25}
				wheelPxPerZoomLevel={60}
				center={center}
				{...mapProps}
				ref={handleRef}>
				{/* Mapbox tiles layer */}
				{/* 
        // @ts-ignore */}
				<MapboxGLLayer antialias preserveDrawingBuffer accessToken='no-token' style={mapStyle} />
				{/* Observe zoom, etc. */}
				{/* <MapObserver /> */}
				{children}
			</ReactLeafletMap>
		</MapContainer>
	)
}

export default Map
