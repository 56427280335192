import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles'

import { nlNL } from '@material-ui/core/locale'

export const theme = createMuiTheme(
	{
		palette: {
			type: 'dark',
			secondary: {
				light: '#5ddcf6',
				main: '#00aac3',
				dark: '#007b93',
				contrastText: '#ffffff'
			},
			primary: {
				light: '#ff6593',
				main: '#e72766',
				dark: '#ae003c'
			}
		},
		typography: {
			h1: { fontSize: 24 },
			h2: { fontSize: 20 },
			h3: { fontSize: 18, fontWeight: 500 },
			h4: { fontSize: 16 },
			h5: { fontSize: 14 },
			h6: { fontSize: 14 }
		}
	},
	nlNL
)

Object.assign(theme, {
	overrides: {
		MUIRichTextEditor: {
			root: {
				borderBottom: '1px solid #ccc'
			},
			toolbar: {
				'& button .MuiSvgIcon-root': {
					color: '#00aac3',
					fontSize: 18
				}
			},
			editor: {},
			placeHolder: {
				minHeight: 30
			}
		}
	}
})
export type CmsTheme = typeof theme

export const KeolisColors = [
	'#0e3192',
	'#23a491',
	'#f1500f',
	'#1a8a4f',
	'#00adaf',
	'#d5464f',
	'#e72766',
	'#ffffff',
	'#333333'
]
