import * as React from 'react'
import {
	createStyles,
	makeStyles,
	Theme,
	Table,
	TableContainer,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	TablePagination,
	TableFooter,
	TableSortLabel
} from '@material-ui/core'
import { ApiCallResultPageInfo, ApiSort } from '~api/types'
import { RemoteAttribute } from './RemoteView'
import { isModel } from 'mobx-keystone'
import classNames from 'classnames'
import styled from 'styled-components'

// ███████╗████████╗██╗   ██╗██╗     ███████╗███████╗
// ██╔════╝╚══██╔══╝╚██╗ ██╔╝██║     ██╔════╝██╔════╝
// ███████╗   ██║    ╚████╔╝ ██║     █████╗  ███████╗
// ╚════██║   ██║     ╚██╔╝  ██║     ██╔══╝  ╚════██║
// ███████║   ██║      ██║   ███████╗███████╗███████║
// ╚══════╝   ╚═╝      ╚═╝   ╚══════╝╚══════╝╚══════╝
//

const TR = styled(TableRow)`
	&:nth-child(2n) {
		background-color: #333;
	}
	&.isSelectable:hover {
		cursor: pointer;
	}
`

const TH = styled(TableCell)`
	font-size: 13px;
`

//  ██████╗ ██████╗ ███╗   ███╗██████╗  ██████╗ ███╗   ██╗███████╗███╗   ██╗████████╗
// ██╔════╝██╔═══██╗████╗ ████║██╔══██╗██╔═══██╗████╗  ██║██╔════╝████╗  ██║╚══██╔══╝
// ██║     ██║   ██║██╔████╔██║██████╔╝██║   ██║██╔██╗ ██║█████╗  ██╔██╗ ██║   ██║
// ██║     ██║   ██║██║╚██╔╝██║██╔═══╝ ██║   ██║██║╚██╗██║██╔══╝  ██║╚██╗██║   ██║
// ╚██████╗╚██████╔╝██║ ╚═╝ ██║██║     ╚██████╔╝██║ ╚████║███████╗██║ ╚████║   ██║
//  ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝      ╚═════╝ ╚═╝  ╚═══╝╚══════╝╚═╝  ╚═══╝   ╚═╝
//

export interface RemoteTableConfig<T> {
	renderRow?: (record: T) => React.ReactElement
	renderCell?: { [key: string]: (record: T) => React.ReactElement }
	onSelectRow?: (record: T) => any
}

export interface IRemoteTableProps<T> extends RemoteTableConfig<T> {
	records: T[]
	pageInfo?: ApiCallResultPageInfo

	attributes: RemoteAttribute[]
	children: any

	currentSort?: ApiSort
	onSort?: (sort: ApiSort) => any

	pagination?: React.ReactElement
}

function RemoteTable<T>({
	attributes,
	records,
	pagination,
	pageInfo,
	renderRow,
	renderCell,
	onSelectRow,
	currentSort,
	onSort,
	children
}: IRemoteTableProps<T>) {
	const isSelectable = React.useMemo(() => !!onSelectRow, [onSelectRow])

	// Make the table
	return (
		<React.Fragment>
			<TableContainer>
				<Table stickyHeader>
					<TableHead>
						<TableRow>
							{attributes.map(attr => (
								<TH key={attr.key}>
									{attr.sortable ? (
										<TableSortLabel
											active={currentSort && currentSort.attribute === attr.key}
											direction={
												currentSort && currentSort.attribute === attr.key
													? currentSort.direction
													: attr.defaultSortDirection || 'asc'
											}
											onClick={() =>
												onSort &&
												onSort({
													attribute: attr.key,
													direction:
														currentSort && currentSort.attribute === attr.key
															? currentSort.direction === 'asc'
																? 'desc'
																: 'asc'
															: attr.defaultSortDirection || 'asc'
												})
											}>
											{attr.label || attr.key}
										</TableSortLabel>
									) : (
										<span>{attr.label || attr.key}</span>
									)}
								</TH>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{records.map((record, index) => {
							// Custom?
							if (renderRow) return renderRow(record)

							// Default
							return (
								<TR
									className={classNames({ isSelectable })}
									hover={isSelectable}
									key={index}
									onClick={() => onSelectRow && onSelectRow(record)}>
									{attributes.map((attr, index) => {
										// Which key to use?
										const key = attr.display || attr.key

										// Custom set?
										if (renderCell && renderCell[key]) {
											return renderCell[key](record)
										}

										// Get value
										let value = record[key]

										// Translation?
										if (isModel(value) && value.constructor.name === 'Translation') value = value.nl

										// Boolie?
										if (typeof value === 'boolean') value = value ? 'Ja' : 'Nee'

										// Array?
										if (Array.isArray(value)) value = value.join(', ')

										// Object?
										if (typeof value === 'object' && value !== null) value = value.toString()

										// Default
										return <TableCell key={index}>{value}</TableCell>
									})}
								</TR>
							)
						})}
					</TableBody>
				</Table>
			</TableContainer>
			{pagination}
		</React.Fragment>
	)
}
export default RemoteTable
