import {
	ModelClass,
	AnyModel,
	ModelTypeInfo,
	getTypeInfo,
	getModelDataType,
	ObjectTypeInfoProps,
	TypeInfo,
	AnyStandardType,
	ArrayType,
	AnyType,
	ArrayTypeInfo,
	OrTypeInfo,
	LiteralTypeInfo,
	NumberTypeInfo,
	BooleanTypeInfo,
	StringTypeInfo
} from 'mobx-keystone'
import { getModelProps } from '~api/cast'
import { forEach } from 'lodash'

export function createFormModel<T extends AnyModel>(model: ModelClass<T>): T {
	// Get default values first
	const defaultValues = typeof model.getDefaultValues === 'function' ? model.getDefaultValues() : {}

	// Get props
	const attr: any = {}
	const props = getModelProps(model)
	forEach(props, (prop, key) => {
		attr[key] = defaultValues[key] !== undefined ? defaultValues[key] : getEmptyValueForModelProp(prop.typeInfo)
	})

	// Make the model
	return new model(attr)
}

export function getEmptyValueForModelProp(typeInfo: TypeInfo): any {
	// Get type name
	const type = typeof typeInfo.thisType === 'function' ? typeInfo.thisType() : typeInfo.thisType
	let name = type.getTypeName()

	// Start with type itself
	let info = typeInfo

	// An or type?
	let maybeNull = false
	let maybeUndefined = false
	if (info instanceof OrTypeInfo) {
		// Filter out maybe-types
		const possibleInfos = info.orTypeInfos.filter(info => {
			// Null? Undefined
			if (info instanceof LiteralTypeInfo) {
				if (info.literal === undefined) {
					maybeUndefined = true
					return false
				}
				if (info.literal === null) {
					maybeNull = true
					return false
				}
			}
			return true
		})

		// Multiple types
		if (possibleInfos.length > 1) {
			// An enum (literals only)?
			const literalInfos = possibleInfos.filter(info => info instanceof LiteralTypeInfo)
			if (literalInfos.length === possibleInfos.length) {
				const firstEnumValue = literalInfos[0] as LiteralTypeInfo
				return firstEnumValue.literal
			}

			console.warn('What to do with multiple types?', name)
		}

		// Use the first one
		info = possibleInfos[0]
	}

	// Maybe's?
	if (maybeUndefined) return undefined
	if (maybeNull) return null

	// Array?
	if (info instanceof ArrayTypeInfo) return []

	// Model?
	const modelMatch = /^Model\((.+)\)$/.exec(name)
	if (modelMatch) {
		// Cast it as a model
		return createFormModel(type.typeInfo.modelClass as ModelClass<AnyModel>)
	}

	// Primitives
	if (info instanceof StringTypeInfo) return ''
	if (info instanceof NumberTypeInfo) return 0
	if (info instanceof BooleanTypeInfo) return false

	// Failed.
	throw `Empty value not implemented: ${name}`
}
