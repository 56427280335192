import * as React from 'react'
import styled from 'styled-components'
import { Draw, Map, Polyline, DrawMap } from 'leaflet'
import { useLeafletMap } from '~components/leaflet/useLeafletMap'
import { Map as ReactLeafletMap } from 'react-leaflet'

// ███████╗████████╗██╗   ██╗██╗     ███████╗██████╗
// ██╔════╝╚══██╔══╝╚██╗ ██╔╝██║     ██╔════╝██╔══██╗
// ███████╗   ██║    ╚████╔╝ ██║     █████╗  ██║  ██║
// ╚════██║   ██║     ╚██╔╝  ██║     ██╔══╝  ██║  ██║
// ███████║   ██║      ██║   ███████╗███████╗██████╔╝
// ╚══════╝   ╚═╝      ╚═╝   ╚══════╝╚══════╝╚═════╝
//

//  ██████╗ ██████╗ ███╗   ███╗██████╗  ██████╗ ███╗   ██╗███████╗███╗   ██╗████████╗
// ██╔════╝██╔═══██╗████╗ ████║██╔══██╗██╔═══██╗████╗  ██║██╔════╝████╗  ██║╚══██╔══╝
// ██║     ██║   ██║██╔████╔██║██████╔╝██║   ██║██╔██╗ ██║█████╗  ██╔██╗ ██║   ██║
// ██║     ██║   ██║██║╚██╔╝██║██╔═══╝ ██║   ██║██║╚██╗██║██╔══╝  ██║╚██╗██║   ██║
// ╚██████╗╚██████╔╝██║ ╚═╝ ██║██║     ╚██████╔╝██║ ╚████║███████╗██║ ╚████║   ██║
//  ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝      ╚═════╝ ╚═╝  ╚═══╝╚══════╝╚═╝  ╚═══╝   ╚═╝
//
export interface IDrawPolylineProps {
	color: string
	leafletMap: ReactLeafletMap
}

const DrawPolyline = ({ leafletMap, color }: IDrawPolylineProps) => {
	// On mount
	React.useEffect(() => {
		// Enable the drawing tool on the map. (the MapListener will handle the draw-complete event.)
		new Draw.Polyline(leafletMap.leafletElement as DrawMap, {
			shapeOptions: {
				color
			}
		}).enable()
	}, [leafletMap, color])

	return null
}
export default DrawPolyline
