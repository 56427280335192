import { Model, model, tProp, types } from 'mobx-keystone'
import { ModelValidationConfig } from '~api/model/validateModel'
import { Line } from './Line'

@model('LineVersion')
export class LineVersion extends Model({
	id: tProp(types.number),
	line_id: tProp(types.number),
	is_request_only_line: tProp(types.boolean),
	valid_from: tProp(types.string),
	valid_until: tProp(types.string),
	is_current_version: tProp(types.boolean),
	line: tProp(types.maybe(types.model<Line>(Line)))
}) {
	static validation(record?: LineVersion): ModelValidationConfig {
		return {
			required: []
		}
	}
}
