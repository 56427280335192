import { Container, TableCell } from '@material-ui/core'
import * as React from 'react'
import { useHistory, Link } from 'react-router-dom'
import RemoteView from '~components/remote-data/RemoteView'
import PageUri from '~core/enums/pages'
import { TravelProduct } from '~store/models/TravelProduct'
import IconButton from '~components/buttons/IconButton'

export interface ITravelProductIndexPageProps {}

const TravelProductIndexPage = (props: ITravelProductIndexPageProps) => {
	const history = useHistory()

	// Loading?
	return (
		<Container>
			<RemoteView
				title={'Reisproducten'}
				headerButtons={
					<React.Fragment>
						<IconButton
							icon={'plus-square'}
							to={`${PageUri.TravelProducts}/nieuw`}
							edge={'end'}
							color='default'
							title={'Nieuw product'}
						/>
					</React.Fragment>
				}
				model={TravelProduct}
				variant={'table'}
				attributes={[
					{ key: 'id', label: '#', sortable: true },
					{ key: 'title', label: 'Titel', sortable: true },
					{ key: 'slug', label: 'Slug', sortable: true },
					{ key: 'type', display: 'typeTitle', label: 'Type', sortable: true },
				]}
				table={{
					renderCell: {
						fare_discount_percentage: record => (
							<TableCell key={'fare_discount_percentage'}>
								{record.fare_discount_percentage}%<br />
								{record.discountDescription && <small>({record.discountDescription})</small>}
							</TableCell>
						),
					},
					onSelectRow: record => history.push(`${PageUri.TravelProducts}/${record.id}`),
				}}
			/>
		</Container>
	)
}
export default TravelProductIndexPage
