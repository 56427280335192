import { GridProps } from '@material-ui/core'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AnyApiModel } from '~api/model/ApiModel'
import ChipInput from '~components/forms/ChipInput'
import Field from './Field'
import { useFormRecord } from './ModelFormContext'

export interface IChipFieldProps {
	record?: AnyApiModel
	attribute: string
	required?: boolean
	label: string
	grid?: GridProps
	chip?: any
	nullWhenEmpty?: boolean
}

const ChipField = (props: IChipFieldProps) => {
	const formRecord = useFormRecord()

	const { attribute, label, required = false, nullWhenEmpty } = props
	const record = props.record || formRecord

	const value = record ? record[attribute] : null

	const setValue = React.useCallback(
		(chips: string[]) => {
			record!.setAttribute(attribute, chips.length === 0 && nullWhenEmpty ? null : chips.map(v => v.trim()))
		},
		[record, attribute]
	)

	if (!record) throw `You need to set the record attribute, or render inside a ModelFormProvider`

	return (
		<Field label={label} labelProps={{ shrink: true }} required={required} grid={props.grid} attribute={attribute}>
			<ChipInput value={value} onChange={setValue} />
		</Field>
	)
}
export default observer(ChipField)
