import * as React from 'react'
import styled from 'styled-components'
import Select from './Select'
import SelectOption from './SelectOption'
import { IVehicleType, getVehicleIcon, getVehicleTypeTranslation } from '~core/enums/vehicles'
import { Line } from '~store/models/Line'

// ███████╗████████╗██╗   ██╗██╗     ███████╗██████╗
// ██╔════╝╚══██╔══╝╚██╗ ██╔╝██║     ██╔════╝██╔══██╗
// ███████╗   ██║    ╚████╔╝ ██║     █████╗  ██║  ██║
// ╚════██║   ██║     ╚██╔╝  ██║     ██╔══╝  ██║  ██║
// ███████║   ██║      ██║   ███████╗███████╗██████╔╝
// ╚══════╝   ╚═╝      ╚═╝   ╚══════╝╚══════╝╚═════╝
//

//  ██████╗ ██████╗ ███╗   ███╗██████╗  ██████╗ ███╗   ██╗███████╗███╗   ██╗████████╗
// ██╔════╝██╔═══██╗████╗ ████║██╔══██╗██╔═══██╗████╗  ██║██╔════╝████╗  ██║╚══██╔══╝
// ██║     ██║   ██║██╔████╔██║██████╔╝██║   ██║██╔██╗ ██║█████╗  ██╔██╗ ██║   ██║
// ██║     ██║   ██║██║╚██╔╝██║██╔═══╝ ██║   ██║██║╚██╗██║██╔══╝  ██║╚██╗██║   ██║
// ╚██████╗╚██████╔╝██║ ╚═╝ ██║██║     ╚██████╔╝██║ ╚████║███████╗██║ ╚████║   ██║
//  ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝      ╚═════╝ ╚═╝  ╚═══╝╚══════╝╚═╝  ╚═══╝   ╚═╝
//
export interface ILineSelectProps {
	lines: Line[]
	value?: Line
	onChange?: (line: Line) => any
}

const mapOption = (line: Line) => ({
	label: `${line.concession ? line.concession.slug : ''} ${line.number} ${line.public_number} ${
		line.name
	} ${getVehicleTypeTranslation(line.transport_type)}`,
	value: line.number,
	line
})

const LineSelect = (props: ILineSelectProps) => {
	const { lines, value, onChange } = props

	const options = React.useMemo(() => (lines ? lines.map(mapOption) : []), [lines])

	return (
		<Select
			onChange={v => v && onChange && onChange(v.line as Line)}
			value={value ? mapOption(value) : null}
			options={options}
			renderOption={option => {
				const line: Line = option.data.line
				return (
					<SelectOption
						meta={[
							line.number,
							getVehicleTypeTranslation(line.transport_type),
							line.concession ? line.concession.slug : '',
							line.category_key || ''
						]}
						icon={getVehicleIcon(line.transport_type)}
						title={`${line.public_number} - ${line.name}`}
					/>
				)
			}}
			placeholder={'Zoek een lijn'}
		/>
	)
}
export default LineSelect
