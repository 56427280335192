import * as React from 'react'
import {
	createStyles,
	makeStyles,
	Theme,
	Toolbar,
	Typography,
	AppBar,
	LinearProgress,
	InputBase,
	InputAdornment
} from '@material-ui/core'
import { truncate, debounce } from 'lodash'
import styled from 'styled-components'
import Icon from '~components/gui/Icon'
import BackButton from '~components/buttons/header-buttons/BackButton'

// ███████╗████████╗██╗   ██╗██╗     ███████╗███████╗
// ██╔════╝╚══██╔══╝╚██╗ ██╔╝██║     ██╔════╝██╔════╝
// ███████╗   ██║    ╚████╔╝ ██║     █████╗  ███████╗
// ╚════██║   ██║     ╚██╔╝  ██║     ██╔══╝  ╚════██║
// ███████║   ██║      ██║   ███████╗███████╗███████║
// ╚══════╝   ╚═╝      ╚═╝   ╚══════╝╚══════╝╚══════╝
//

const Grow = styled.div`
	flex-grow: 1;
`

const Loader = styled(LinearProgress)`
	position: relative;
	top: -4px;
	margin-bottom: -4px;
`

const Search = styled.div`
	position: 'relative';
	border-radius: ${props => props.theme.shape.borderRadius};
	background-color: fade(${props => props.theme.palette.common.white}, 0.15);
	&:hover {
		background-color: fade(${props => props.theme.palette.common.white}, 0.25);
	}
	margin-left: 0;
	width: 100%, ${props => props.theme.breakpoints.up('sm')} {
		margin-left: ${props => props.theme.spacing(1)};
		width: auto;
	}
`

const SearchInput = styled(InputBase)`
	color: inherit;
	> input {
		padding: ${props => props.theme.spacing(1)};
		transition: ${props => props.theme.transitions.create('width')};
		width: 12ch;
		&:focus {
			width: 20ch;
		}
	}
`

//  ██████╗ ██████╗ ███╗   ███╗██████╗  ██████╗ ███╗   ██╗███████╗███╗   ██╗████████╗
// ██╔════╝██╔═══██╗████╗ ████║██╔══██╗██╔═══██╗████╗  ██║██╔════╝████╗  ██║╚══██╔══╝
// ██║     ██║   ██║██╔████╔██║██████╔╝██║   ██║██╔██╗ ██║█████╗  ██╔██╗ ██║   ██║
// ██║     ██║   ██║██║╚██╔╝██║██╔═══╝ ██║   ██║██║╚██╗██║██╔══╝  ██║╚██╗██║   ██║
// ╚██████╗╚██████╔╝██║ ╚═╝ ██║██║     ╚██████╔╝██║ ╚████║███████╗██║ ╚████║   ██║
//  ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝      ╚═════╝ ╚═╝  ╚═══╝╚══════╝╚═╝  ╚═══╝   ╚═╝
//

export interface IPageHeaderConfigProps {
	buttons?: React.ReactElement
	backUri?: string
	isLoading?: boolean
	onSearch?: (query: string) => any
	title?: string
}

export interface IPageHeaderProps extends IPageHeaderConfigProps {}

const PageHeader = ({ title, backUri, isLoading, buttons, onSearch }: IPageHeaderProps) => {
	/**
	 * SEARCH
	 */
	const debouncedSearch = React.useMemo(() => {
		if (!onSearch) return
		return debounce((query: string) => {
			onSearch(query)
		}, 250)
	}, [onSearch])

	const handleSearch = React.useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => debouncedSearch && debouncedSearch(e.target.value),
		[debouncedSearch]
	)

	return (
		<React.Fragment>
			<AppBar position='static'>
				<Toolbar>
					{backUri && <BackButton to={backUri} />}
					<Typography variant='h3' color='inherit' title={title}>
						{truncate(title, { length: 80 })}
					</Typography>
					<Grow />
					{onSearch && (
						<Search>
							<SearchInput
								onChange={handleSearch}
								onFocus={e => e.target.select()}
								startAdornment={
									<InputAdornment position={'start'}>
										<Icon name={'search'} />
									</InputAdornment>
								}
								placeholder='Zoeken...'
							/>
						</Search>
					)}
					{buttons}
				</Toolbar>
			</AppBar>
			{isLoading && <Loader color='primary' />}
		</React.Fragment>
	)
}
export default PageHeader
