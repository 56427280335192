import { Checkbox, GridProps, InputProps, ListItemText, MenuItem, Simple, SimpleProps, Input } from '@material-ui/core'
import { map } from 'lodash'
import { AnyModel } from 'mobx-keystone'
import { observer } from 'mobx-react'
import * as React from 'react'
import Field, { IFieldProps } from './Field'
import { useFormRecord } from './ModelFormContext'
import { AnyApiModel } from '~api/model/ApiModel'

export interface ISimpleFieldProps {
	record?: AnyApiModel
	attribute: string
	required?: boolean
	label?: string
	inputMode?: 'none' | 'text' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search'
	grid?: GridProps
	field?: IFieldProps
	input?: InputProps
}

const SimpleField = (props: ISimpleFieldProps) => {
	const formRecord = useFormRecord()

	const { attribute, label, input, field, inputMode = 'text', required = false } = props
	const record = props.record || formRecord

	const value = record ? record[attribute] : null

	const getValue = React.useCallback(
		(v: any) => {
			switch (inputMode) {
				case 'numeric':
					if (v.length === 0) return null
					return parseFloat(v)

				case 'text':
				default:
					return v
			}
		},
		[inputMode]
	)

	const inputProps = {
		...input,
		inputProps: {
			...(input ? input.inputProps : {}),
			inputMode,
			type: inputMode === 'numeric' ? 'number' : inputMode,
		},
	}

	const onChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		if (record) record.setAttribute(attribute, getValue(e.target.value)
	},[record])

	if (!record) throw `You need to set the record attribute, or render inside a ModelFormProvider`
	return (
		<Field label={label} required={required} grid={props.grid} attribute={attribute} {...field}>
			<Input
				{...inputProps}
				value={value !== null ? value : ''}
				onChange={onChange}
			/>
		</Field>
	)
}
export default observer(SimpleField)
