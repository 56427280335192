import {
	Model,
	model,
	tProp,
	modelAction,
	modelFlow,
	_async,
	_await,
	types,
	tProp_setArray,
	SnapshotInOf,
	ModelPropsCreationData,
} from 'mobx-keystone'
import { computed } from 'mobx'
import { getErrorMessage } from '~api/helpers'

export enum GuiStoreNotificationSeverity {
	success = 'success',
	info = 'info',
	warning = 'warning',
	error = 'error',
}

@model('GuiStoreNotification')
export class GuiStoreNotification extends Model({
	message: tProp(types.string),
	severity: tProp(
		types.enum<GuiStoreNotificationSeverity>(GuiStoreNotificationSeverity),
		() => GuiStoreNotificationSeverity.info
	),
	autoHideDuration: tProp(types.maybeNull(types.number), 3000),
}) {}

@model('GuiStore')
export class GuiStore extends Model({
	notifications: tProp(types.array(types.model<GuiStoreNotification>(GuiStoreNotification)), () => []),
}) {
	// ███╗   ██╗ ██████╗ ████████╗██╗███████╗██╗ ██████╗ █████╗ ████████╗██╗ ██████╗ ███╗   ██╗███████╗
	// ████╗  ██║██╔═══██╗╚══██╔══╝██║██╔════╝██║██╔════╝██╔══██╗╚══██╔══╝██║██╔═══██╗████╗  ██║██╔════╝
	// ██╔██╗ ██║██║   ██║   ██║   ██║█████╗  ██║██║     ███████║   ██║   ██║██║   ██║██╔██╗ ██║███████╗
	// ██║╚██╗██║██║   ██║   ██║   ██║██╔══╝  ██║██║     ██╔══██║   ██║   ██║██║   ██║██║╚██╗██║╚════██║
	// ██║ ╚████║╚██████╔╝   ██║   ██║██║     ██║╚██████╗██║  ██║   ██║   ██║╚██████╔╝██║ ╚████║███████║
	// ╚═╝  ╚═══╝ ╚═════╝    ╚═╝   ╚═╝╚═╝     ╚═╝ ╚═════╝╚═╝  ╚═╝   ╚═╝   ╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝
	//

	@modelAction
	showNotification(data: ModelPropsCreationData<GuiStoreNotification>): GuiStoreNotification {
		const noti = new GuiStoreNotification(data)
		this.notifications.push(noti)

		return noti
	}

	@modelAction
	showError(error: Error): GuiStoreNotification {
		// Get message
		const message = getErrorMessage(error)

		// Make notification
		const noti = new GuiStoreNotification({
			message,
			severity: GuiStoreNotificationSeverity.error,
		})
		this.notifications.push(noti)
		return noti
	}

	@modelAction
	dismissNotification(noti: GuiStoreNotification): void {
		const index = this.notifications.indexOf(noti)
		if (index > -1) this.notifications.splice(index, 1)
	}
}
