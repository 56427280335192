import { AnyModel, Draft, ModelClass, getModelDataType, isModel } from 'mobx-keystone'
import { map, each } from 'lodash'
import Rules from './validationRules'

export type ValidationErrorType = 'Required' | 'Unknown'

export type ValidationCallback = (
	value: any,
	attribute: string,
	model: AnyModel
) => true | string | Promise<true | string>
export type ValidationRule = 'Required' | 'Numeric' | 'Email' | ValidationCallback

export type ValidationRuleSet = { [key: string]: ValidationRule[] }

export interface ModelValidationConfig {
	required?: string[]
	rules?: { [key: string]: ValidationRule[] | ValidationRule }
}

export type ValidationError = {
	key: string
	message: string
	rule: ValidationRule
}

export interface ValidationModel<T extends AnyModel> extends ModelClass<T> {
	validation?: (model?: T | null) => ModelValidationConfig
}

/**
 * GET MODEL VALIDATION RULES
 * @param model
 */
export function getModelValidationRules(model: Draft<AnyModel> | AnyModel | ModelClass<AnyModel>): ValidationRuleSet {
	// Get the modelClass
	const Model: ValidationModel<AnyModel> = (model instanceof Draft
		? model.data.constructor
		: isModel(model)
		? model.constructor
		: model) as ValidationModel<AnyModel>

	// And the model instance
	const record: AnyModel | null = model instanceof Draft ? model.data : isModel(model) ? model : null

	// Any rules given?
	const rules: ValidationRuleSet = {}
	if (Model.validation) {
		// Get config and merge rules
		const config = Model.validation(record)

		// Start with required
		config.required && config.required.forEach(key => (rules[key] = ['Required']))

		// And the custom rules
		config.rules &&
			map(config.rules, (itemRule, key) => {
				// Arrify
				const itemRules = Array.isArray(itemRule) ? itemRule : [itemRule]

				// Merge
				rules[key] = [...itemRules, ...rules[key]]
			})
	}

	return rules
}

/**
 * VALIDATE MODEL
 * @param draft
 */
export async function validateModel(draft: Draft<AnyModel>): Promise<ValidationError[] | true> {
	// Get the rules
	const allRules = getModelValidationRules(draft)
	const promises: Promise<true | ValidationError>[] = []
	each(allRules, (rules, key) => {
		// Get the value
		const value: any = draft.data[key]

		// Apply rule
		rules.forEach(rule => {
			// Get callback
			const cb = typeof rule === 'function' ? rule : Rules[rule]
			if (!cb) throw `Unknown validation rule "${rule}"`

			// Wrap in promise
			promises.push(
				new Promise(async resolve => {
					// Call it
					const result = cb(value, key, draft.data)
					const error = result instanceof Promise ? await result : result

					// Make into validation error
					resolve(
						error === true
							? true
							: {
									key,
									message: error,
									rule
							  }
					)
				})
			)
		})
	})

	// Wait for all to be done.
	const results = await Promise.all(promises)
	const errors = results.filter(e => e !== true)

	return errors.length === 0 ? true : (errors as ValidationError[])
}
