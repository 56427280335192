import * as React from 'react'
import { IconButton as MuiIconButton, IconButtonProps } from '@material-ui/core'

import { LinkProps, useHistory } from 'react-router-dom'
import { FontAwesomeIconName } from '~core/enums/icons'
import Icon from '~components/gui/Icon'

export type IIconButtonProps = IconButtonProps & {
	to?: string
	icon?: FontAwesomeIconName
}

const IconButton = ({ to, children, icon, ...props }: IIconButtonProps) => {
	const history = useHistory()
	const onClick = to
		? () => {
				history.push(to)
		  }
		: undefined

	return (
		<MuiIconButton onClick={onClick} {...props}>
			{children}
			{icon && <Icon name={icon} />}
		</MuiIconButton>
	)
}
export default IconButton
