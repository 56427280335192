import { Avatar, Card, CardActionArea, CardContent, CardHeader, Container, Grid, Typography } from '@material-ui/core'
import { observer } from 'mobx-react'
import * as React from 'react'
import { useHistory } from 'react-router-dom'
import Icon from '~components/gui/Icon'
import { getModulesForUser, Module } from '~core/security/modules'
import useStore from '~store/hooks/useStore'

export interface IDashboardPageProps {}

const DashboardPage = (props: IDashboardPageProps) => {
	const { api } = useStore()
	const { user } = api

	const history = useHistory()
	const modules = React.useMemo(() => getModulesForUser(user!), [user])

	return (
		<Container>
			<Grid container spacing={3} justify={'center'} alignItems='baseline'>
				{modules &&
					modules.map((mod: Module) => (
						<Grid item xs={4} key={`${mod.title}/${mod.uri}`}>
							<Card>
								<CardActionArea onClick={() => history.push(mod.uri)}>
									<CardHeader
										title={<Typography variant='h3'>{mod.title}</Typography>}
										avatar={<Avatar>{mod.icon ? <Icon name={mod.icon} /> : mod.title[0]}</Avatar>}
									/>
									<CardContent style={{ height: 90 }}>
										<Typography variant='body2'>{mod.description}</Typography>
									</CardContent>
								</CardActionArea>
							</Card>
						</Grid>
					))}
				<Grid item xs={4}>
					<Card>
						<CardActionArea onClick={() => api.logout()}>
							<CardHeader
								title={<Typography variant='h3'>Uitloggen </Typography>}
								avatar={
									<Avatar>
										<Icon name={'sign-out-alt'} />
									</Avatar>
								}
							/>

							<CardContent style={{ height: 90 }}>
								<Typography variant='body2'>
									Je bent ingelogd als <b>{user.name}</b>.
								</Typography>
							</CardContent>
						</CardActionArea>
					</Card>
				</Grid>
			</Grid>
		</Container>
	)
}
export default observer(DashboardPage)
