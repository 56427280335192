import * as React from 'react'
import styled from 'styled-components'
import { FontAwesomeIconName } from '~core/enums/icons'
import Icon from '../gui/Icon'
import { IVehicleType, getVehicleIcon } from '~core/enums/vehicles'

// ███████╗████████╗██╗   ██╗██╗     ███████╗██████╗
// ██╔════╝╚══██╔══╝╚██╗ ██╔╝██║     ██╔════╝██╔══██╗
// ███████╗   ██║    ╚████╔╝ ██║     █████╗  ██║  ██║
// ╚════██║   ██║     ╚██╔╝  ██║     ██╔══╝  ██║  ██║
// ███████║   ██║      ██║   ███████╗███████╗██████╔╝
// ╚══════╝   ╚═╝      ╚═╝   ╚══════╝╚══════╝╚═════╝
//

const Option = styled.div``

const Header = styled.div`
	display: flex;
	align-items: center;
	flex-direction: row;
`

const OptionIcon = styled(Icon)`
	font-size: 16px;
	margin-right: 0.5em;
`

const Title = styled.div`
	flex: 1;
	font-size: 15px;
	font-weight: bold;
`

const Label = styled.div`
	font-size: 14px;
	flex: 1;
`

const Meta = styled.div`
	margin-top: 3px;
	margin-bottom: 4px;
	display: flex;
	flex-wrap: wrap;
	opacity: 0.7;
	> * {
		font-size: 11px;
		display: inline-block;
		&:not(:last-child):after {
			content: '|';
			display: inline-block;
			margin: 0 4px;
		}
	}
`

const LineList = styled.div`
	margin-top: 3px;
	display: flex;
	flex-wrap: wrap;
	opacity: 0.7;
	> * {
		font-size: 11px;
		display: inline-block;
		margin-right: 4px;
		margin-bottom: 4px;
		.fa {
			margin-right: 4px;
		}
	}
`

const Img = styled.img`
	width: 32px;
	margin-right: 8px;
`

//  ██████╗ ██████╗ ███╗   ███╗██████╗  ██████╗ ███╗   ██╗███████╗███╗   ██╗████████╗
// ██╔════╝██╔═══██╗████╗ ████║██╔══██╗██╔═══██╗████╗  ██║██╔════╝████╗  ██║╚══██╔══╝
// ██║     ██║   ██║██╔████╔██║██████╔╝██║   ██║██╔██╗ ██║█████╗  ██╔██╗ ██║   ██║
// ██║     ██║   ██║██║╚██╔╝██║██╔═══╝ ██║   ██║██║╚██╗██║██╔══╝  ██║╚██╗██║   ██║
// ╚██████╗╚██████╔╝██║ ╚═╝ ██║██║     ╚██████╔╝██║ ╚████║███████╗██║ ╚████║   ██║
//  ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝      ╚═════╝ ╚═╝  ╚═══╝╚══════╝╚═╝  ╚═══╝   ╚═╝
//

export interface ISelectOptionLine {
	number: string
	type: IVehicleType
	destination?: string
}

export interface ISelectOptionProps {
	title?: string
	label?: string
	icon?: FontAwesomeIconName
	meta?: string[]
	imageBase64?: string
	lines?: ISelectOptionLine[]
}

const SelectOption = (props: ISelectOptionProps) => {
	const { title, icon, meta, label, lines, imageBase64 } = props
	return (
		<Option>
			{(title || label || icon) && (
				<Header>
					{imageBase64 && <Img src={imageBase64} />}
					{icon && <OptionIcon name={icon} />}
					{title && <Title>{title}</Title>}
					{label && <Label>{label}</Label>}
				</Header>
			)}
			{meta && (
				<Meta className={'meta'}>
					{meta.map((m, i) => (
						<span key={i}>{m}</span>
					))}
				</Meta>
			)}
			{lines && (
				<LineList className={'lines'}>
					{lines.map((l, i) => (
						<span key={i}>
							<Icon name={getVehicleIcon(l.type)} />
							<span>
								{l.number}
								{l.destination ? ` ${l.destination}` : ''}
							</span>
						</span>
					))}
				</LineList>
			)}
		</Option>
	)
}
export default SelectOption
