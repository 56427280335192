import {
	ModelClass,
	AnyModel,
	modelAction,
	ModelProps,
	_Model,
	Model,
	ExtendedModel,
	ModelInstanceData,
	BaseModel
} from 'mobx-keystone'
import { each } from 'lodash'
import inflection from 'inflection'
import { ModelValidationConfig } from './validateModel'

export interface ApiModelClass extends ModelClass<AnyModel> {}

export interface ApiModelRelationshipConfig {
	name: string
	model: ApiModelClass
	type: 'BelongsTo' | 'HasMany'
	localKey?: string
	foreignKey?: string
}

export interface ApiModelConfig {
	relationships?: ApiModelRelationshipConfig[]
	apiUri?: string
}

export class ApiBaseModel extends Model({}) {
	@modelAction
	setAttribute(key: string, value: any) {
		// Setter?
		const method = `set${inflection.classify(key)}`
		if (typeof this[method] === 'function') {
			this[method](value)
		} else {
			this[key] = value
		}
	}

	@modelAction
	setUnsafe(attributes: { [key: string]: any }) {
		each(attributes, (value, k) => {
			// Setter?
			const method = `set${inflection.classify(k)}`
			if (typeof this[method] === 'function') {
				this[method](value)
			} else {
				this[k] = value
			}
		})
	}

	public apiConfig(): ApiModelConfig {
		return {}
	}
	static validation(record?: Notification): ModelValidationConfig {
		return {
			required: []
		}
	}
}

export interface AnyApiModel extends ApiBaseModel {
	[key: string]: any
}

export function ApiModel<TProps extends ModelProps>(modelProps: TProps): _Model<AnyApiModel, TProps> {
	return ExtendedModel(ApiBaseModel, modelProps)
}
