export const Roles = {
	admin: 'Beheerder',
	producten: 'Reisproducten',
	vervoer: 'Vervoer',
	meldingen: 'Meldingen',
	content: 'Content'
}
export const RoleKeys = Object.keys(Roles)

export type Role = keyof typeof Roles
