import { EditableProperties, EditableProperty } from './editableProperties';
import { FontAwesomeIconName } from './icons';

export enum LayerType {
  Line = 'Line',
  Journey = 'Journey',
  Stop = 'Stop',
  LineSegment = 'LineSegment',
  Label = 'Label',
  Icon = 'Icon',
  Image = 'Image',
  SvgImage = 'SvgImage',
  FreeLine = 'FreeLine',
}

export interface ILayerConfig {
  key: LayerType;
  icon: FontAwesomeIconName;
  editableProperties?: EditableProperty[];
}

/**
 * Layers
 */
export const Layers: ILayerConfig[] = [
  // Line
  {
    key: LayerType.Line,
    icon: 'bus',
    editableProperties: [EditableProperties.Color],
  },
  {
    key: LayerType.Journey,
    icon: 'bus',
    editableProperties: [EditableProperties.Color],
  },

  {
    key: LayerType.LineSegment,
    icon: 'link',
    editableProperties: [
      EditableProperties.Color,
      EditableProperties.DottedLine,
      EditableProperties.IsEditing,
      EditableProperties.IsSplitting,
    ],
  },

  {
    key: LayerType.Stop,
    icon: 'circle',
    editableProperties: [
      EditableProperties.Color,
      EditableProperties.StopName,
      EditableProperties.IsCancelled,
      EditableProperties.ShowLabel,
      EditableProperties.LabelRotation,
    ],
  },
  {
    key: LayerType.Label,
    icon: 'align-left',
    editableProperties: [
      EditableProperties.Label,
      EditableProperties.FontSize,
      EditableProperties.Color,
      EditableProperties.BackgroundColor,
    ],
  },
  {
    key: LayerType.Icon,
    icon: 'exclamation-triangle',
    editableProperties: [
      EditableProperties.FontSize,
      EditableProperties.IconRotation,
      EditableProperties.Color,
      EditableProperties.BackgroundColor,
    ],
  },
  {
    key: LayerType.Image,
    icon: 'image',
    editableProperties: [
      EditableProperties.ImageSize,
      EditableProperties.ImageRotation,
    ],
  },
  {
    key: LayerType.SvgImage,
    icon: 'image',
    editableProperties: [
      EditableProperties.ImageSize,
      EditableProperties.ImageRotation,
    ],
  },
  {
    key: LayerType.FreeLine,
    icon: 'draw-polygon',
    editableProperties: [
      EditableProperties.Color,
      EditableProperties.DottedLine,
      EditableProperties.IsEditing,
      EditableProperties.IsSplitting,
    ],
  },
];

export const LayerKeys = Layers.map(t => t.key);
