import Config from '~core/config/Config'
import { ModelClass, AnyModel, isModel } from 'mobx-keystone'
import inflection from 'inflection'
import { AxiosError } from 'axios'
import { ApiBaseModel, AnyApiModel, ApiModelConfig } from './model/ApiModel'
import { createFormModel } from './model/createFormModel'

export function makeApiUrl(uri: string): string {
	return `${Config.apiUrl.replace(/\/$/, '')}${uri}`
}

export function getResourceName(model: ModelClass<AnyApiModel> | AnyApiModel): string {
	// Get class
	const modelClass = isModel(model) ? model.constructor : model

	// Does the model have it specified? Otherwise guess
	const config = getModelConfig(model)

	return config && config.apiUri
		? config && config.apiUri.replace(/^\//, '')
		: inflection.underscore(inflection.pluralize(modelClass.name))
}

export function getModelConfig(model: ModelClass<AnyApiModel> | AnyApiModel): ApiModelConfig | null {
	let config: ApiModelConfig | undefined
	if (isModel(model)) {
		config = model.apiConfig()
	} else {
		const instance = createFormModel(model)
		if (instance.apiConfig) config = instance.apiConfig()
	}
	return config || null
}

export function getApiUri(model: ModelClass<AnyApiModel> | AnyApiModel, idOrUri?: string | number): string {
	// Uri passed manually?
	if (typeof idOrUri === 'string' && idOrUri.substr(0, 1) === '/') return idOrUri

	// Get class
	const modelClass = isModel(model) ? model.constructor : model

	// Get the config
	let resource: string | undefined
	const config = getModelConfig(model)
	if (config && config.apiUri) resource = config.apiUri.replace(/^\//, '')

	// Does the model have it specified? Otherwise guess
	if (!resource) {
		resource = inflection.underscore(inflection.pluralize(modelClass.name))
	}

	// ID?
	if (!idOrUri) return `/${resource}`
	return `/${resource}/${idOrUri}`
}

export function getErrorMessage(error: any, throwIfUnknown: boolean = true): string {
	// Axios?
	if (error.isAxiosError) {
		// Get the error as Axios
		const axiosError = error as AxiosError
		if (axiosError.response && axiosError.response.data) {
			// Get response message
			const { message } = axiosError.response.data

			// Duplicate key?
			const match = /1062 Duplicate entry '([^']+)/.exec(message)
			if (match) {
				return `Er is al een record voor "${match[1]}"`
			}

			// Give back the JSON message
			return message
		}

		return axiosError.message
	}

	// Unknown error, just throw it
	if (throwIfUnknown) throw error

	// Then as stirng
	return error.toString()
}
