import * as React from 'react';
import { observer } from 'mobx-react';
import { MapData } from '../store/MapData';
import { MapDataProvider } from '../context/MapDataContext';
import MapLayer from './MapLayer';

export interface IMapElementProps {
  mapData: MapData;
  interactive?: boolean;
}

const MapElement = ({ mapData, interactive }: IMapElementProps) => {
  return (
    <React.Fragment>
      <MapDataProvider value={mapData}>
        {mapData.layers.map(layer => (
          <MapLayer
            interactive={interactive}
            key={layer.$modelId}
            layer={layer}
          />
        ))}
      </MapDataProvider>
    </React.Fragment>
  );
};
export default observer(MapElement);
