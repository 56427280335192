import * as React from 'react'
import styled from 'styled-components'
import classNames from 'classnames'
import { LatLng } from 'leaflet'
import { TextField, Input, InputAdornment, IconButton } from '@material-ui/core'
import Icon from '~components/gui/Icon'

// ███████╗████████╗██╗   ██╗██╗     ███████╗██████╗
// ██╔════╝╚══██╔══╝╚██╗ ██╔╝██║     ██╔════╝██╔══██╗
// ███████╗   ██║    ╚████╔╝ ██║     █████╗  ██║  ██║
// ╚════██║   ██║     ╚██╔╝  ██║     ██╔══╝  ██║  ██║
// ███████║   ██║      ██║   ███████╗███████╗██████╔╝
// ╚══════╝   ╚═╝      ╚═╝   ╚══════╝╚══════╝╚═════╝
//

//  ██████╗ ██████╗ ███╗   ███╗██████╗  ██████╗ ███╗   ██╗███████╗███╗   ██╗████████╗
// ██╔════╝██╔═══██╗████╗ ████║██╔══██╗██╔═══██╗████╗  ██║██╔════╝████╗  ██║╚══██╔══╝
// ██║     ██║   ██║██╔████╔██║██████╔╝██║   ██║██╔██╗ ██║█████╗  ██╔██╗ ██║   ██║
// ██║     ██║   ██║██║╚██╔╝██║██╔═══╝ ██║   ██║██║╚██╗██║██╔══╝  ██║╚██╗██║   ██║
// ╚██████╗╚██████╔╝██║ ╚═╝ ██║██║     ╚██████╔╝██║ ╚████║███████╗██║ ╚████║   ██║
//  ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝      ╚═════╝ ╚═╝  ╚═══╝╚══════╝╚═╝  ╚═══╝   ╚═╝
//
export interface ILocationPickerProps {
	value: LatLng | undefined | null
	onChange: (value: LatLng) => void
	pickLocation: () => Promise<LatLng>
}

const LocationPicker = ({ value, onChange, pickLocation }: ILocationPickerProps) => {
	const onClick = React.useCallback(async () => {
		const latLng = await pickLocation()
		onChange(latLng)
	}, [pickLocation, onChange])

	return (
		<Input
			value={value ? `(${value.lat}, ${value.lng})` : ''}
			placeholder={'Klik hier om een coordinaat op de kaart te kiezen'}
			onClick={onClick}
			readOnly
			startAdornment={
				<InputAdornment position='start'>
					<Icon name={'map-marker'} />
				</InputAdornment>
			}
		/>
	)
}
export default LocationPicker
