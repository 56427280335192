import * as React from 'react'
import { Dialog, DialogProps, DialogTitle, DialogContentText, DialogContent, DialogActions } from '@material-ui/core'
import Button from '~components/buttons/Button'

export interface IConfirmDialogProps {
	unwrap?: boolean
	dialog?: DialogProps

	title?: string
	content?: string | React.ReactElement

	cancelLabel?: string
	confirmLabel?: string

	onConfirm?: () => any
	onCancel?: () => any
}

const ConfirmDialogInside = ({
	title = 'Weet je het zeker?',
	content = 'Weet je zeker dat je dit record wilt verwijderen?',
	cancelLabel = 'Annuleren',
	confirmLabel = 'Ja, verwijder',
	onConfirm,
	onCancel
}: IConfirmDialogProps) => {
	return (
		<React.Fragment>
			{title && <DialogTitle>{title}</DialogTitle>}
			{content && (
				<DialogContent>
					{typeof content === 'string' ? <DialogContentText>{content}</DialogContentText> : content}
				</DialogContent>
			)}
			<DialogActions>
				<Button onClick={onCancel} color='primary' variant={'outlined'}>
					{cancelLabel}
				</Button>
				<Button onClick={onConfirm} color='primary' autoFocus>
					{confirmLabel}
				</Button>
			</DialogActions>
		</React.Fragment>
	)
}

const ConfirmDialog = ({ unwrap = false, dialog, ...props }: IConfirmDialogProps) => {
	if (unwrap || !dialog) return <ConfirmDialogInside {...props} />
	return (
		<Dialog {...dialog}>
			<ConfirmDialogInside {...props} />
		</Dialog>
	)
}
export default ConfirmDialog
