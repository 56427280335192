import { observer } from 'mobx-react'
import * as React from 'react'
import styled from 'styled-components'
import { useMapModel } from '~store/hooks/useMapModel'
import { TreeView } from '@material-ui/lab'
import LayerMenuItem from './LayerMenuItem'
import Icon from '~components/gui/Icon'
import { Layer } from '~keolis-reizen-map-element/index'

// ███████╗████████╗██╗   ██╗██╗     ███████╗██████╗
// ██╔════╝╚══██╔══╝╚██╗ ██╔╝██║     ██╔════╝██╔══██╗
// ███████╗   ██║    ╚████╔╝ ██║     █████╗  ██║  ██║
// ╚════██║   ██║     ╚██╔╝  ██║     ██╔══╝  ██║  ██║
// ███████║   ██║      ██║   ███████╗███████╗██████╔╝
// ╚══════╝   ╚═╝      ╚═╝   ╚══════╝╚══════╝╚═════╝
//

const Container = styled.div`
	flex-grow: 1;
	flex-shrink: 1;
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
	max-height: 100%;
`
const Tree = styled.div`
	color: white;
	flex: 1 1 auto;
	overflow-y: scroll;
`

const StartInstruction = styled.h1`
	display: block;
	text-align: center;
	padding: 40px 32px;
	font-size: 16px;
	opacity: 0.1;
	line-height: 1.3;
	user-select: none;
	i {
		font-size: 3em !important;
		margin-bottom: 0.25em;
	}
`

//  ██████╗ ██████╗ ███╗   ███╗██████╗  ██████╗ ███╗   ██╗███████╗███╗   ██╗████████╗
// ██╔════╝██╔═══██╗████╗ ████║██╔══██╗██╔═══██╗████╗  ██║██╔════╝████╗  ██║╚══██╔══╝
// ██║     ██║   ██║██╔████╔██║██████╔╝██║   ██║██╔██╗ ██║█████╗  ██╔██╗ ██║   ██║
// ██║     ██║   ██║██║╚██╔╝██║██╔═══╝ ██║   ██║██║╚██╗██║██╔══╝  ██║╚██╗██║   ██║
// ╚██████╗╚██████╔╝██║ ╚═╝ ██║██║     ╚██████╔╝██║ ╚████║███████╗██║ ╚████║   ██║
//  ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝      ╚═════╝ ╚═╝  ╚═══╝╚══════╝╚═╝  ╚═══╝   ╚═╝
//
export interface ILayerMenuProps {}

const LayerMenu = (props: ILayerMenuProps) => {
	const map = useMapModel()
	const { layers } = map.mapData

	const [lastSelectedLayer, setLastSelectedLayer] = React.useState<Layer | undefined>()
	const [lastSelectedRange, setLastSelectedRange] = React.useState<Layer[] | undefined>()

	const clickHandler = React.useCallback(
		(e: MouseEvent, layer: Layer) => {
			// Get keys, platform dependent
			const shiftKey = e.shiftKey
			const ctrlKey = /Mac/.test(navigator.platform) ? e.metaKey : e.ctrlKey

			// Control-select?
			if (ctrlKey) {
				// Just select/deselect the layer, without deselecting others
				layer.setSelected(!layer.isSelected)
				setLastSelectedLayer(layer)
				setLastSelectedRange(undefined)
			}

			// Shift?
			else if (shiftKey && lastSelectedLayer) {
				// Deselect previous range
				if (lastSelectedRange) lastSelectedRange.forEach(l => l.setSelected(false))

				// Select the range
				const range = map.mapData.selectLayerRange(lastSelectedLayer, layer)
				setLastSelectedRange(range)
			} else {
				// Did we have multiple selection?
				if (map.mapData.selectedLayers.length > 1) {
					// Select only me
					map.mapData.selectLayer(layer)
				} else {
					// Toggle select for the 1 layer
					map.mapData.selectLayer(layer.isSelected ? null : layer)
				}
				setLastSelectedRange(undefined)
				setLastSelectedLayer(layer)
			}
		},
		[map, lastSelectedLayer, lastSelectedRange]
	)

	return (
		<Container>
			{layers.length === 0 ? (
				<StartInstruction>
					<Icon name={'rocket'} />
					<br />
					Voeg een element toe om te beginnen.
				</StartInstruction>
			) : (
				<Tree>
					{layers.map(layer => (
						<LayerMenuItem onClick={clickHandler} key={layer.$modelId} layer={layer} />
					))}
				</Tree>
			)}
		</Container>
	)
}
export default observer(LayerMenu)
