import { Map } from '~store/models/Map'
import { sleep } from './sleep'
import html2canvas from 'html2canvas'
import $ from 'jquery'

export default async function getMapCanvas(
	map: Map,
	mapDiv: HTMLElement,
	statusCallback?: (msg: string) => any
): Promise<HTMLCanvasElement> {
	// Prepare map for save
	map.prepareForImage()

	// Hide controls
	$('.leaflet-control-zoom').css('display', 'none')

	// Scroll to top
	$(window).scrollTop(0)

	// Allow everything to re-render
	statusCallback && statusCallback('Afbeelding voorbereiden...')
	await sleep(250)

	// Get the map canvas
	const mapCanvas = mapDiv.querySelector('canvas') as HTMLCanvasElement

	// Get the offset of its container, and compare it with the editor viewport
	const dpi = window.devicePixelRatio
	const $mapContainer = $(mapCanvas).parent()
	const $imageContainer = $(mapDiv).find('.map-editor-map-container')
	const size = { width: $imageContainer.width()! * dpi, height: $imageContainer.height()! * dpi }

	const mapOffset = $mapContainer.offset()!
	const imageOffset = $imageContainer.offset()!

	// Generate canvas for layers
	const mapContainer = mapDiv.querySelector('.map-editor-map-container') as HTMLElement
	statusCallback && statusCallback('Kaart omzetten naar canvas...')
	const layersCanvas = await html2canvas(mapContainer, { logging: true })

	// Combine the canvases
	const $combinedCanvas = $(`<canvas width="${size.width}" height="${size.height}" />`).appendTo($('body'))
	const combinedCanvas: HTMLCanvasElement = $combinedCanvas[0]! as HTMLCanvasElement
	const combined: CanvasRenderingContext2D = combinedCanvas.getContext('2d')!

	// Draw the map on it.
	statusCallback && statusCallback('Lagen samenvoegen tot afbeelding...')
	combined.drawImage(
		mapCanvas,
		mapOffset.left - imageOffset.left,
		mapOffset.top - imageOffset.top,
		mapCanvas.width,
		mapCanvas.height
	)

	// Draw the layers on top of it
	combined.drawImage(layersCanvas, 0, 0, layersCanvas.width, layersCanvas.height)

	// Remove canvas
	$combinedCanvas.remove()

	// Reset controls
	$('.leaflet-control-zoom').css('display', '')

	return combinedCanvas
}
