import { observer } from 'mobx-react'
import * as React from 'react'
import { useHistory } from 'react-router-dom'
import BackButton from '~components/buttons/header-buttons/BackButton'
import MapField from '~components/map-editor/MapField'
import ChipField from '~components/model-form/ChipField'
import DateTimeField from '~components/model-form/DateTimeField'
import Fieldset from '~components/model-form/Fieldset'
import ModelForm, { IModelFormRenderProps } from '~components/model-form/ModelForm'
import TranslationsField from '~components/model-form/TranslationsField'
import { IResourceRoutesFormProps } from '~components/routing/ResourceRoutes'
import PageUri from '~core/enums/pages'
import useStore from '~store/hooks/useStore'
import { Map } from '~store/models/Map'
import { Notification } from '~store/models/Notification'
import { Link, Grid } from '@material-ui/core'
import Icon from '~components/gui/Icon'
import Button from '~components/buttons/Button'
import { Line } from '~store/models/Line'
import { Journey } from '~store/models/Journey'
import LineAndDirectionSelector from '~components/forms/LineAndDirectionSelector'
import Field from '~components/model-form/Field'
import { ApiFilter } from '~api/types'

// ███████╗ ██████╗ ██████╗ ███╗   ███╗
// ██╔════╝██╔═══██╗██╔══██╗████╗ ████║
// █████╗  ██║   ██║██████╔╝██╔████╔██║
// ██╔══╝  ██║   ██║██╔══██╗██║╚██╔╝██║
// ██║     ╚██████╔╝██║  ██║██║ ╚═╝ ██║
// ╚═╝      ╚═════╝ ╚═╝  ╚═╝╚═╝     ╚═╝
//

const NotificationFormMain = observer(({ draft, resources, actionButtons }: IModelFormRenderProps<Notification>) => {
	const unconnectedMaps = resources.unconnectedMaps as Map[]
	const journeys = resources.journeys as Journey[]

	return (
		<React.Fragment>
			{/* Algemeen */}
			<Fieldset title='Algemeen'>
				<TranslationsField attribute='title' required label='Titel' />
				<TranslationsField html attribute='body' required label='Tekst' />
			</Fieldset>

			{/* Geldigheid */}
			<Fieldset title='Geldigheid'>
				{/* Lines/stops */}
				<Field padded label={'Voor lijnen'} labelProps={{ shrink: true }}>
					<LineAndDirectionSelector
						numbers={draft.data.for_line_numbers}
						directions={draft.data.for_line_directions}
						journeys={journeys}
						onChange={(lineNumbers, lineDirections) => {
							draft.data.setAttribute('for_line_numbers', lineNumbers)
							draft.data.setAttribute('for_line_directions', lineDirections)
						}}
					/>
				</Field>

				{/* <ChipField attribute={'for_line_numbers'} label='Voor lijnen (lijnnummers)' /> */}
				<ChipField attribute={'for_stop_codes'} label='Voor haltes (haltecodes)' />

				{/* Visible */}
				<DateTimeField
					options={{ clearable: true }}
					type={'DateTime'}
					grid={{ xs: 6 }}
					attribute={'visible_from'}
					label={'Zichtbaar vanaf'}
				/>
				<DateTimeField
					options={{ clearable: true }}
					type={'DateTime'}
					grid={{ xs: 6 }}
					attribute={'visible_until'}
					label={'Zichtbaar tot'}
				/>

				{/* Show in website */}
				{draft.originalData.id && draft.originalData.isVisibleNow && (
					<Grid item xs={12}>
						<Link href={draft.originalData.publicUrl} target='_blank' color={'secondary'}>
							<Icon name={'globe'} /> Bekijk melding in de website
						</Link>
					</Grid>
				)}

				{/* Validity */}
				<DateTimeField
					options={{ clearable: true }}
					type={'DateTime'}
					grid={{ xs: 6 }}
					attribute={'valid_from'}
					label={'Geldig vanaf'}
					field={{ help: 'Indien dit afwijkt van de zichtbaarheid' }}
				/>
				<DateTimeField
					options={{ clearable: true }}
					type={'DateTime'}
					grid={{ xs: 6 }}
					attribute={'valid_until'}
					label={'Geldig tot'}
				/>
			</Fieldset>

			<Fieldset title='Kaart'>
				<MapField
					notification={draft.data}
					mapType={'driver'}
					grid={{ xs: 6 }}
					label='Kaart voor chauffeur'
					attribute={'driverMap'}
					copyToAttribute={'publicMap'}
					existingMaps={unconnectedMaps}
				/>
				<MapField
					notification={draft.data}
					mapType={'public'}
					grid={{ xs: 6 }}
					label='Kaart voor reizigers'
					attribute={'publicMap'}
					copyToAttribute={'driverMap'}
				/>
			</Fieldset>

			{/* Actions */}
			<Fieldset>{actionButtons}</Fieldset>
		</React.Fragment>
	)
})

// ██╗    ██╗██████╗  █████╗ ██████╗ ██████╗ ███████╗██████╗
// ██║    ██║██╔══██╗██╔══██╗██╔══██╗██╔══██╗██╔════╝██╔══██╗
// ██║ █╗ ██║██████╔╝███████║██████╔╝██████╔╝█████╗  ██████╔╝
// ██║███╗██║██╔══██╗██╔══██║██╔═══╝ ██╔═══╝ ██╔══╝  ██╔══██╗
// ╚███╔███╔╝██║  ██║██║  ██║██║     ██║     ███████╗██║  ██║
//  ╚══╝╚══╝ ╚═╝  ╚═╝╚═╝  ╚═╝╚═╝     ╚═╝     ╚══════╝╚═╝  ╚═╝
//

const NotificationForm = ({ id, newModel, onDraft, title }: IResourceRoutesFormProps) => {
	const history = useHistory()
	const { api } = useStore()
	const { user } = api

	const [notification, setNotification] = React.useState<Notification | undefined>()

	return (
		<ModelForm<Notification>
			model={Notification}
			modelId={id}
			newModel={newModel}
			apiOptions={{
				include: ['driverMap', 'publicMap'],
			}}
			allowDelete={user ? user.hasRole('admin') : false}
			onDraft={draft => onDraft && onDraft(draft)}
			onLoad={record => setNotification(record)}
			onCreated={record => history.push(`${PageUri.Notifications}/${record.id}`)}
			onDeleted={record => history.push(PageUri.Notifications)}
			confirmSaveMessage={record =>
				(!record.for_line_numbers || record.for_line_numbers.length === 0) &&
				(!record.for_stop_codes || record.for_stop_codes.length === 0)
					? 'Je hebt geen lijnen en/of haltes geselecteerd. Dat betekent dat deze melding op de homepage zal worden getoond. Is dat de bedoeling?'
					: null
			}
			paper
			header={{
				backUri: PageUri.Notifications,
				buttons: (
					<React.Fragment>
						{notification && (
							<Button
								startIcon={<Icon name={'copy'} />}
								variant={'text'}
								color={'inherit'}
								onClick={() => history.push(`${PageUri.Notifications}/${notification.id}/kopieren`)}
								size={'large'}>
								Kopieren
							</Button>
						)}
					</React.Fragment>
				),
			}}
			container
			loadResources={async record => {
				// Unconnected maps
				const { records: unconnectedMaps } = await api.all(Map, {
					filters: {
						without_notification: true,
					},
				})

				// Filters
				const filters: { [key: string]: boolean | string | string[] | ApiFilter } = { is_primary: true }
				if (user && user.concession_slugs) filters.for_concession_slugs = user.concession_slugs.join(',')

				// Get primary journeys
				const { records: journeys } = await api.all(Journey, {
					include: ['toDestination', 'lineVersion.line.concession'],
					filters,
					sort: {
						attribute: 'line_number',
						direction: 'asc',
					},
					pageSize: 10000,
				})

				return { unconnectedMaps, journeys }
			}}
			render={NotificationFormMain}
			renderTitle={({ record }) => (title ? title : newModel ? 'Nieuwe melding' : `Meldingen: ${record.title.nl}`)}
		/>
	)
}
export default observer(NotificationForm)
