import { GridProps } from '@material-ui/core'
import {
	DatePicker,
	DatePickerProps,
	DateTimePicker,
	DateTimePickerProps,
	TimePicker,
	TimePickerProps
} from '@material-ui/pickers'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AnyApiModel } from '~api/model/ApiModel'
import Field, { IFieldProps } from './Field'
import { useFormRecord } from './ModelFormContext'

export interface IDateTimeFieldProps {
	record?: AnyApiModel
	attribute: string
	required?: boolean
	label: string
	grid?: GridProps
	field?: IFieldProps
	type: 'DateTime' | 'Date' | 'Time'
	options?: Partial<DatePickerProps | DateTimePickerProps | TimePickerProps>
	nullWhenEmpty?: boolean
	format?: string
}

const DateTimeField = (props: IDateTimeFieldProps) => {
	const formRecord = useFormRecord()

	const { attribute, label, type, field, required = false, options } = props
	const record = props.record || formRecord

	const value = record ? record[attribute] : null

	const handleChange = React.useCallback(
		newValue => {
			// What type?
			let v = newValue
			if (v) {
				if (type === 'Date') {
					v = v.format('YYYY-MM-DD')
				} else if (type === 'DateTime') {
					v = v.format('YYYY-MM-DD HH:mm:ss')
				} else {
					v = v.format('HH:mm:ss')
				}
			}

			record!.setAttribute(attribute, v)
		},
		[record, attribute, type]
	)

	// What component?
	const Component = React.useMemo(() => {
		switch (type) {
			case 'DateTime':
				return DateTimePicker

			case 'Time':
				return TimePicker

			case 'Date':
			default:
				return DatePicker
		}
	}, [type])

	// What format?
	const format = React.useMemo(() => {
		if (props.format) return props.format
		if (type === 'Date') return 'LL'
		if (type === 'DateTime') return 'LLL'
		return 'LT'
	}, [props.format, type])

	if (!record) throw `You need to set the record attribute, or render inside a ModelFormProvider`

	return (
		<Field {...field} required={required} grid={props.grid} attribute={attribute}>
			<Component label={label} format={format} value={value} onChange={handleChange} {...options} />
		</Field>
	)
}
export default observer(DateTimeField)
