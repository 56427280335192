import htmr from 'htmr';
import { LatLng } from 'leaflet';
import { observer } from 'mobx-react';
import * as React from 'react';
import styled from 'styled-components';
import { IMapLayerProps } from '../MapLayer';
import ReactMarker from '../markers/ReactMarker';

// ███████╗████████╗██╗   ██╗██╗     ███████╗██████╗
// ██╔════╝╚══██╔══╝╚██╗ ██╔╝██║     ██╔════╝██╔══██╗
// ███████╗   ██║    ╚████╔╝ ██║     █████╗  ██║  ██║
// ╚════██║   ██║     ╚██╔╝  ██║     ██╔══╝  ██║  ██║
// ███████║   ██║      ██║   ███████╗███████╗██████╔╝
// ╚══════╝   ╚═╝      ╚═╝   ╚══════╝╚══════╝╚═════╝
//

const Lbl = styled.div`
  font-size: 13px;
  position: absolute;
  left: 0;
  top: 0;
  white-space: nowrap;
  padding: 3px 6px;
  border-radius: 4px;
  font-weight: bold;
`;

//  ██████╗ ██████╗ ███╗   ███╗██████╗  ██████╗ ███╗   ██╗███████╗███╗   ██╗████████╗
// ██╔════╝██╔═══██╗████╗ ████║██╔══██╗██╔═══██╗████╗  ██║██╔════╝████╗  ██║╚══██╔══╝
// ██║     ██║   ██║██╔████╔██║██████╔╝██║   ██║██╔██╗ ██║█████╗  ██╔██╗ ██║   ██║
// ██║     ██║   ██║██║╚██╔╝██║██╔═══╝ ██║   ██║██║╚██╗██║██╔══╝  ██║╚██╗██║   ██║
// ╚██████╗╚██████╔╝██║ ╚═╝ ██║██║     ╚██████╔╝██║ ╚████║███████╗██║ ╚████║   ██║
//  ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝      ╚═════╝ ╚═╝  ╚═══╝╚══════╝╚═╝  ╚═══╝   ╚═╝
//
const Label = (props: IMapLayerProps) => {
  const { layer } = props;

  const latLng = React.useMemo(
    () => new LatLng(layer.latitude!, layer.longitude!),
    [layer.latitude, layer.longitude]
  );

  return (
    <ReactMarker
      position={latLng}
      onClick={props.onClick}
      ondragend={e => layer.setLatLng(e.target.getLatLng())}
      draggable={layer.isSelected}
      onmouseover={layer.isSelected ? undefined : props.onMouseEnter}
      onmouseout={layer.isSelected ? undefined : props.onMouseLeave}
    >
      <Lbl
        style={{
          fontSize: layer.fontSize || 13,
          color: layer.color || undefined,
          backgroundColor: layer.backgroundColor || undefined,
        }}
      >
        {htmr((layer.label || '').replace(/\n/g, '<br>'))}
      </Lbl>
    </ReactMarker>
  );
};
export default observer(Label);
