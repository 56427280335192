import * as React from 'react'
import styled from 'styled-components'
import { FontAwesomeIconName } from '@enums/icons'
import Select from './Select'
import { map } from 'lodash'
import SelectOption from './SelectOption'

export const SelectableIcons: { [group: string]: FontAwesomeIconName[] } = {
	Pijlen: [
		'arrow-left',
		'arrow-right',
		'arrow-up',
		'arrow-down',
		'angle-left',
		'angle-right',
		'angle-up',
		'angle-down',
		'arrow-circle-left',
		'arrow-circle-right',
		'arrow-circle-up',
		'arrow-circle-down',
		'undo',
		'redo',
		'level-up-alt',
		'level-down-alt',
		'reply',
		'share',
		'times-circle',
	],
	Voertuigen: [
		'ambulance',
		'bus',
		'bus-alt',
		'car',
		'car-alt',
		'car-crash',
		'car-side',
		'charging-station',
		'gas-pump',
		'motorcycle',
		'shuttle-van',
		'taxi',
		'truck',
		'bicycle',
		'biking',
		'ship',
		'train',
		'tram',
		'snowplow',
		'plane',
	],
	Verkeer: [
		'traffic-light',
		'flag',
		'flag-checkered',
		'map-marker',
		'map-marker-alt',
		'map-pin',
		'map-signs',
		'parking',
		'directions',
	],

	Gebouwen: [
		'archway',
		'building',
		'campground',
		'church',
		'city',
		'home',
		'hospital',
		'hospital-alt',
		'hotel',
		'landmark',
		'monument',
		'mosque',
		'school',
		'university',
		'synagogue',
	],
	Weer: [
		'bolt',
		'cloud',
		'cloud-moon',
		'cloud-moon-rain',
		'cloud-rain',
		'cloud-showers-heavy',
		'cloud-sun',
		'cloud-sun-rain',
		'moon',
		'smog',
		'snowflake',
		'sun',
		'temperature-high',
		'temperature-low',
		'umbrella',
		'wind',
	],
}
const IconOptions = map(SelectableIcons, (icons, key) => ({
	label: key,
	options: icons.map(icon => ({
		label: icon,
		value: icon,
	})),
}))

// ███████╗████████╗██╗   ██╗██╗     ███████╗██████╗
// ██╔════╝╚══██╔══╝╚██╗ ██╔╝██║     ██╔════╝██╔══██╗
// ███████╗   ██║    ╚████╔╝ ██║     █████╗  ██║  ██║
// ╚════██║   ██║     ╚██╔╝  ██║     ██╔══╝  ██║  ██║
// ███████║   ██║      ██║   ███████╗███████╗██████╔╝
// ╚══════╝   ╚═╝      ╚═╝   ╚══════╝╚══════╝╚═════╝
//

//  ██████╗ ██████╗ ███╗   ███╗██████╗  ██████╗ ███╗   ██╗███████╗███╗   ██╗████████╗
// ██╔════╝██╔═══██╗████╗ ████║██╔══██╗██╔═══██╗████╗  ██║██╔════╝████╗  ██║╚══██╔══╝
// ██║     ██║   ██║██╔████╔██║██████╔╝██║   ██║██╔██╗ ██║█████╗  ██╔██╗ ██║   ██║
// ██║     ██║   ██║██║╚██╔╝██║██╔═══╝ ██║   ██║██║╚██╗██║██╔══╝  ██║╚██╗██║   ██║
// ╚██████╗╚██████╔╝██║ ╚═╝ ██║██║     ╚██████╔╝██║ ╚████║███████╗██║ ╚████║   ██║
//  ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝      ╚═════╝ ╚═╝  ╚═══╝╚══════╝╚═╝  ╚═══╝   ╚═╝
//
export interface IIconSelectProps {
	value: FontAwesomeIconName | null
	onChange: (value: FontAwesomeIconName) => any
}

const IconSelect = (props: IIconSelectProps) => {
	const { value, onChange } = props
	return (
		<Select
			onChange={v => v && onChange(v.value)}
			value={value ? { label: value, value } : null}
			options={IconOptions}
			renderOption={option => <SelectOption icon={option.data.value} title={option.data.label} />}
		/>
	)
}
export default IconSelect
