import { ValidationCallback } from './validateModel'
import { isModel } from 'mobx-keystone'

const Messages = {
	Required: 'Dit veld is verplicht',
	Email: 'Dit is geen geldig e-mailadres'
}

// Required
export const Required: ValidationCallback = (value: any) => {
	// Translation
	if (isModel(value) && value.$modelType === 'Translation') value = value.nl

	if ((typeof value === 'string' && value.length === 0) || value === undefined || value === null)
		return Messages.Required
	return true
}

// Email
export const Email: ValidationCallback = (value: any) => {
	if (typeof value !== 'string' || value.length === 0) return true
	return /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(
		value
	)
		? true
		: Messages.Email
}

const Rules: { [key: string]: ValidationCallback } = {
	Required,
	Email
}
export default Rules
