const PageUri = {
	Login: '/inloggen',

	Users: '/gebruikers',
	TravelProducts: '/reisproducten',
	Lang: '/website-teksten',
	NavigationItems: '/navigatie',
	Notifications: '/meldingen',
	Maps: '/kaarten',
	Lines: '/lijnen',
	Places: '/pois',
	PlaceCategories: '/poi-categorien',
	Dashboard: '/',
}
export default PageUri
