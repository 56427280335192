import * as React from 'react'
import IconButton from '../IconButton'
import styled from 'styled-components'

export interface IBackButtonProps {
	to: string
}

const Btn = styled(IconButton)`
	font-size: 14px;
	margin-right: 1em;
`

const BackButton = ({ to }: IBackButtonProps) => {
	return <Btn to={to} icon={'arrow-left'} edge={'start'} color='default' title={'Terug'} />
}
export default BackButton
