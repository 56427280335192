import * as React from 'react';
import styled from 'styled-components';
import { IMapLayerProps } from '../MapLayer';
import { LatLng } from 'leaflet';
import { observer } from 'mobx-react';
import ReactMarker from '../markers/ReactMarker';
import classnames from 'classnames';
import { Colors } from '../../utils/theme';

// ███████╗████████╗██╗   ██╗██╗     ███████╗██████╗
// ██╔════╝╚══██╔══╝╚██╗ ██╔╝██║     ██╔════╝██╔══██╗
// ███████╗   ██║    ╚████╔╝ ██║     █████╗  ██║  ██║
// ╚════██║   ██║     ╚██╔╝  ██║     ██╔══╝  ██║  ██║
// ███████║   ██║      ██║   ███████╗███████╗██████╔╝
// ╚══════╝   ╚═╝      ╚═╝   ╚══════╝╚══════╝╚═════╝
//

const Container = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  line-height: 1;

  transition: box-shadow ease-in-out 400ms;
  &.hover {
    box-shadow: 0 0 3px ${Colors.layerHover};
  }
  &.selected {
    box-shadow: 0 0 3px ${Colors.layerSelected};
  }
`;

const Img = styled('img')``;

//  ██████╗ ██████╗ ███╗   ███╗██████╗  ██████╗ ███╗   ██╗███████╗███╗   ██╗████████╗
// ██╔════╝██╔═══██╗████╗ ████║██╔══██╗██╔═══██╗████╗  ██║██╔════╝████╗  ██║╚══██╔══╝
// ██║     ██║   ██║██╔████╔██║██████╔╝██║   ██║██╔██╗ ██║█████╗  ██╔██╗ ██║   ██║
// ██║     ██║   ██║██║╚██╔╝██║██╔═══╝ ██║   ██║██║╚██╗██║██╔══╝  ██║╚██╗██║   ██║
// ╚██████╗╚██████╔╝██║ ╚═╝ ██║██║     ╚██████╔╝██║ ╚████║███████╗██║ ╚████║   ██║
//  ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝      ╚═════╝ ╚═╝  ╚═══╝╚══════╝╚═╝  ╚═══╝   ╚═╝
//
const Image = (props: IMapLayerProps) => {
  const { layer, onClick, interactive } = props;

  const latLng = React.useMemo(
    () => new LatLng(layer.latitude!, layer.longitude!),
    [layer.latitude, layer.longitude]
  );
  const classes = React.useMemo(
    () =>
      interactive
        ? classnames({ hover: layer.isHovering, selected: layer.isSelected })
        : '',
    [layer.isHovering, layer.isSelected, interactive]
  );

  return (
    <ReactMarker
      settings={{ iconSize: [layer.imageWidth!, layer.imageWidth!] }}
      position={latLng}
      onClick={onClick}
      ondragend={e => layer.setLatLng(e.target.getLatLng())}
      draggable={interactive && layer.isSelected}
      onmouseover={layer.isSelected ? undefined : props.onMouseEnter}
      onmouseout={layer.isSelected ? undefined : props.onMouseLeave}
    >
      <Container
        className={classes}
        style={{
          width: layer.imageWidth!,
          transform: `rotate(${layer.rotation || 0}deg)`,
        }}
      >
        <Img src={layer.imageBase64!} width={layer.imageWidth!} />
      </Container>
    </ReactMarker>
  );
};
export default observer(Image);
