import * as React from 'react'
import styled from 'styled-components'
import classNames from 'classnames'
import useStore from '~store/hooks/useStore'
import { Notification } from '~store/models/Notification'
import NotificationForm from './NotificationForm'
import { Translation } from '~store/models/utility/Translation'
import { Draft, draft as makeDraft, clone } from 'mobx-keystone'
import { Map } from '~store/models/Map'
import { saveModel } from '~api/model/saveModel'

// ███████╗████████╗██╗   ██╗██╗     ███████╗██████╗
// ██╔════╝╚══██╔══╝╚██╗ ██╔╝██║     ██╔════╝██╔══██╗
// ███████╗   ██║    ╚████╔╝ ██║     █████╗  ██║  ██║
// ╚════██║   ██║     ╚██╔╝  ██║     ██╔══╝  ██║  ██║
// ███████║   ██║      ██║   ███████╗███████╗██████╔╝
// ╚══════╝   ╚═╝      ╚═╝   ╚══════╝╚══════╝╚═════╝
//

//  ██████╗ ██████╗ ███╗   ███╗██████╗  ██████╗ ███╗   ██╗███████╗███╗   ██╗████████╗
// ██╔════╝██╔═══██╗████╗ ████║██╔══██╗██╔═══██╗████╗  ██║██╔════╝████╗  ██║╚══██╔══╝
// ██║     ██║   ██║██╔████╔██║██████╔╝██║   ██║██╔██╗ ██║█████╗  ██╔██╗ ██║   ██║
// ██║     ██║   ██║██║╚██╔╝██║██╔═══╝ ██║   ██║██║╚██╗██║██╔══╝  ██║╚██╗██║   ██║
// ╚██████╗╚██████╔╝██║ ╚═╝ ██║██║     ╚██████╔╝██║ ╚████║███████╗██║ ╚████║   ██║
//  ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝      ╚═════╝ ╚═╝  ╚═══╝╚══════╝╚═╝  ╚═══╝   ╚═╝
//
export interface ICopyNotificationFormProps {
	notificationId: number
}

const MapKeys = ['publicMap', 'driverMap']
const CloneKeys = ['for_line_numbers', 'for_stop_codes', 'for_concession_slugs']

const CopyNotificationForm = ({ notificationId }: ICopyNotificationFormProps) => {
	// Resources
	const { api } = useStore()

	// State
	const [source, setSource] = React.useState<Notification | undefined>()
	const [draft, setDraft] = React.useState<Draft<Notification> | undefined>()

	// Load the record
	React.useEffect(() => {
		setSource(undefined)
		api.one(Notification, notificationId, { include: ['driverMap', 'publicMap'] }).then(record => setSource(record))
	}, [notificationId])

	// Clone the record
	React.useEffect(() => {
		// Copy props
		if (draft && source) {
			const model = draft.data

			// Content
			model.title.setUnsafe({ nl: source.title.nl, en: source.title.en })
			model.body.setUnsafe({ nl: source.body.nl, en: source.body.en })

			// Lines, etc.
			CloneKeys.map(key => {
				if (source[key]) model.setAttribute(key, clone(source[key]))
			})

			// Maps
			MapKeys.map(key => {
				const sourceMap: Map = source[key]
				if (sourceMap) {
					// Copy it.
					const copyMap = new Map({
						data: sourceMap.data,
						latlng_bounds: sourceMap.latlng_bounds,
						images: sourceMap.images ? clone(sourceMap.images) : null
					})
					const draft = makeDraft(copyMap)
					api.saveModel(draft, { applyAttributesFromServer: ['id', 'public_token'] }).then(() => {
						// Store it.
						model.setAttribute(key, draft.originalData)
					})
				}
			})
		}
	}, [draft, source])

	return source ? (
		<NotificationForm newModel title={`Meldingen: (KOPIE) ${source.title.nl}`} onDraft={draft => setDraft(draft)} />
	) : (
		<div>Loading</div>
	)
}
export default CopyNotificationForm
