import { Container, TableCell } from '@material-ui/core'
import * as React from 'react'
import { useHistory, Link } from 'react-router-dom'
import RemoteView from '~components/remote-data/RemoteView'
import PageUri from '~core/enums/pages'
import { Line } from '~store/models/Line'
import AddBoxIcon from '@material-ui/icons/AddBox'
import Icon from '~components/gui/Icon'
import IconButton from '~components/buttons/IconButton'
import { LineConfiguration } from '~store/models/LineConfiguration'
import { Place } from '~store/models/Place'
import { FontAwesomeIconName } from '~core/enums/icons'
import Button from '~components/buttons/Button'

export interface PlaceIndexPageProps {}

const PlaceIndexPage = (props: PlaceIndexPageProps) => {
	const history = useHistory()

	// Loading?
	return (
		<Container>
			<RemoteView
				title={"POI's"}
				model={Place}
				variant={'table'}
				apiOptions={{ include: ['category'] }}
				attributes={[
					{ key: 'category.slug', display: 'category', label: 'Categorie', sortable: true },
					{ key: 'title', label: 'Titel', sortable: true },
					{ key: 'city', label: 'Plaats', sortable: true },
				]}
				table={{
					renderCell: {
						category: record => (
							<TableCell key={'category'}>
								<Icon name={record.iconName as FontAwesomeIconName} inline />
								{record.category!.title.nl}
							</TableCell>
						),
					},
					onSelectRow: record => history.push(`${PageUri.Places}/${record.id}`),
				}}
				headerButtons={
					<React.Fragment>
						<IconButton
							icon={'layer-group'}
							edge={'end'}
							to={`${PageUri.PlaceCategories}`}
							color='default'
							title='Categoriën'
						/>
						<IconButton
							icon={'plus-square'}
							edge={'end'}
							to={`${PageUri.Places}/nieuw`}
							color='default'
							title={'Nieuwe POI'}
						/>
					</React.Fragment>
				}
			/>
		</Container>
	)
}
export default PlaceIndexPage
