import * as React from 'react'
import { User } from '~store/models/User'
import { draft as makeDraft, Draft } from 'mobx-keystone'
import { observer } from 'mobx-react'
import ModelForm, { IModelFormRenderProps } from '~components/model-form/ModelForm'
import Fieldset from '~components/model-form/Fieldset'
import Field from '~components/model-form/Field'
import Button from '~components/buttons/Button'
import { useHistory, Link } from 'react-router-dom'
import PageUri from '~core/enums/pages'
import { IconButton, Input, Select, MenuItem, Checkbox, ListItemText, FormControlLabel } from '@material-ui/core'
import ViewListIcon from '@material-ui/icons/ViewList'
import BackButton from '~components/buttons/header-buttons/BackButton'
import { Roles } from '~core/security/roles'
import { map } from 'lodash'
import { IResourceRoutesFormProps } from '~components/routing/ResourceRoutes'
import useStore from '~store/hooks/useStore'
import { Concession } from '~store/models/Concession'
import SelectField from '~components/model-form/SelectField'

// ███████╗ ██████╗ ██████╗ ███╗   ███╗
// ██╔════╝██╔═══██╗██╔══██╗████╗ ████║
// █████╗  ██║   ██║██████╔╝██╔████╔██║
// ██╔══╝  ██║   ██║██╔══██╗██║╚██╔╝██║
// ██║     ╚██████╔╝██║  ██║██║ ╚═╝ ██║
// ╚═╝      ╚═════╝ ╚═╝  ╚═╝╚═╝     ╚═╝
//

const UserFormMain = observer(({ draft, newModel, resources, actionButtons }: IModelFormRenderProps<User>) => {
	const user = draft.data
	const concessions = resources.concessions as Concession[]
	const concessionOptions = React.useMemo(() => {
		const options: { [key: string]: string } = {}
		concessions.forEach(c => (options[c.slug] = c.slug))
		return options
	}, [concessions])

	return (
		<React.Fragment>
			<Fieldset title='Gebruiker'>
				{/* Name */}
				<Field attribute='name' label='Naam' required>
					<Input value={user.name} onChange={e => user.setAttribute('name', e.target.value)} />
				</Field>

				{/* E-mail */}
				<Field attribute='email' label='E-mailadres' required>
					<Input value={user.email} onChange={e => user.setAttribute('email', e.target.value)} />
				</Field>

				{/* Password */}
				<Field attribute='password' label='Wachtwoord' required={newModel}>
					<Input
						type='password'
						autoComplete={'newPassword'}
						value={user.password}
						onChange={e => user.setAttribute('password', e.target.value)}
					/>
				</Field>

				{/* Roles */}
				<Field attribute='role_names' label='Rollen'>
					<Select
						multiple
						value={user.role_names}
						onChange={e => user.setAttribute('role_names', e.target.value)}
						renderValue={selected => (selected as string[]).map(v => Roles[v]).join(', ')}>
						{map(Roles, (label, key) => (
							<MenuItem key={key} value={key}>
								<Checkbox checked={user.role_names.indexOf(key) > -1} />
								<ListItemText primary={label} />
							</MenuItem>
						))}
					</Select>
				</Field>

				{/* Concessies */}
				<SelectField attribute={'concession_slugs'} label={'Concessie'} multiple required options={concessionOptions} />
			</Fieldset>

			<Fieldset title={'Meldingen'}>
				{/* Send mail */}
				<Field attribute={'send_artisan_notifications_by_email'}>
					<FormControlLabel
						control={
							<Checkbox
								checked={user.send_artisan_notifications_by_email}
								onChange={e => user.setAttribute('send_artisan_notifications_by_email', e.target.checked)}
							/>
						}
						label={'Verstuur Artisan rapporten via e-mail'}
					/>
				</Field>

				{/* Slack webhook */}
				<Field attribute='send_artisan_notifications_to_slack_webhook_url' label='Slack webhook'>
					<Input
						value={user.send_artisan_notifications_to_slack_webhook_url || ''}
						onChange={e => user.setAttribute('send_artisan_notifications_to_slack_webhook_url', e.target.value)}
					/>
				</Field>

				{actionButtons}
			</Fieldset>
		</React.Fragment>
	)
})

// ██╗    ██╗██████╗  █████╗ ██████╗ ██████╗ ███████╗██████╗
// ██║    ██║██╔══██╗██╔══██╗██╔══██╗██╔══██╗██╔════╝██╔══██╗
// ██║ █╗ ██║██████╔╝███████║██████╔╝██████╔╝█████╗  ██████╔╝
// ██║███╗██║██╔══██╗██╔══██║██╔═══╝ ██╔═══╝ ██╔══╝  ██╔══██╗
// ╚███╔███╔╝██║  ██║██║  ██║██║     ██║     ███████╗██║  ██║
//  ╚══╝╚══╝ ╚═╝  ╚═╝╚═╝  ╚═╝╚═╝     ╚═╝     ╚══════╝╚═╝  ╚═╝
//

const UserForm = ({ id, newModel }: IResourceRoutesFormProps) => {
	const history = useHistory()
	const { api } = useStore()

	return (
		<ModelForm<User>
			model={User}
			modelId={id}
			newModel={newModel}
			onCreated={record => history.push(`${PageUri.Users}/${record.id}`)}
			onDeleted={record => history.push(PageUri.Users)}
			paper
			header={{
				backUri: PageUri.Users
			}}
			container
			loadResources={async record => {
				const { records: concessions } = await api.all(Concession)
				return { concessions }
			}}
			render={UserFormMain}
			renderTitle={({ record }) => (newModel ? 'Nieuwe gebruiker' : `Gebruikers: ${record.name}`)}
		/>
	)
}
export default UserForm
