/**
 * Editable properties
 */
export type EditablePropertyType =
  | 'string'
  | 'color'
  | 'boolean'
  | 'text'
  | 'number'
  | 'fontSize'
  | 'labelRotation'
  | 'rotation'
  | 'size'
  | 'editPolyline'
  | 'splitPolyline';

export type EditableProperty = {
  name: string;
  label: string;
  type: EditablePropertyType;
  getter?: string;
  setter?: string;
};

export const EditableProperties: { [key: string]: EditableProperty } = {
  IsVisible: {
    label: 'Zichtbaar',
    name: 'isVisible',
    type: 'boolean',
    setter: 'setVisible',
  },
  Name: {
    label: 'Naam',
    name: 'name',
    type: 'string',
  },
  Color: {
    label: 'Kleur',
    name: 'color',
    type: 'color',
  },
  BackgroundColor: {
    label: 'Achtergrond-kleur',
    name: 'backgroundColor',
    type: 'color',
  },
  StopName: {
    label: 'Haltenaam',
    name: 'stopName',
    type: 'string',
  },
  DottedLine: {
    label: 'Stippellijn',
    name: 'lineStyle',
    type: 'boolean',
    getter: 'hasDottedLine',
    setter: 'setDottedLine',
  },
  FontSize: {
    label: 'Grootte',
    name: 'fontSize',
    type: 'fontSize',
  },
  Label: {
    label: 'Tekst',
    name: 'label',
    type: 'text',
  },
  IsCancelled: {
    label: 'Halte is vervallen',
    name: 'isCancelled',
    type: 'boolean',
  },
  ShowLabel: {
    label: 'Toon label',
    name: 'showLabel',
    type: 'boolean',
  },
  LabelRotation: {
    label: 'Roteer label',
    name: 'labelRotation',
    type: 'labelRotation',
  },
  IconRotation: {
    label: 'Roteer icoon',
    name: 'rotation',
    type: 'rotation',
  },
  ImageRotation: {
    label: 'Roteer afbeelding',
    name: 'rotation',
    type: 'rotation',
  },
  ImageSize: {
    label: 'Grootte afbeelding',
    name: 'imageWidth',
    type: 'size',
  },
  IsEditing: {
    label: 'Wijzig lijn',
    name: 'isEditing',
    type: 'editPolyline',
  },
  IsSplitting: {
    label: 'Knip lijn',
    name: 'isSplitting',
    type: 'splitPolyline',
  },
};
