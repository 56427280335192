import { computed } from 'mobx'
import { Model, model, modelFlow, tProp, types, _async, _await } from 'mobx-keystone'
import { IResourceKey } from '~core/enums/resources'
import { RootStore } from '~store/RootStore'
import { Journey } from '../models/Journey'
import { Line } from '../models/Line'
import { ApiStore } from './ApiStore'
import { StopSummary } from '~store/models/StopSummary'
import { ApiFilter } from '~api/types'

@model('ResourceStore')
export class ResourceStore extends Model({
	lines: tProp(types.maybeNull(types.array(types.model<Line>(Line))), null),
	journeys: tProp(types.maybeNull(types.array(types.model<Journey>(Journey))), null),
	stopSummaries: tProp(types.maybeNull(types.array(types.model<StopSummary>(StopSummary))), null),
}) {
	api?: ApiStore
	onAttachedToRootStore(rootStore: RootStore) {
		this.api = rootStore.api
	}

	@computed
	get loadedResources(): IResourceKey[] {
		const result: IResourceKey[] = []
		if (this.lines) result.push('Lines')
		if (this.journeys) result.push('Journeys')
		if (this.stopSummaries) result.push('StopSummaries')
		return result
	}

	@modelFlow
	load = _async(function* (this: ResourceStore, key: IResourceKey, refresh: boolean = false) {
		// Already loaded?
		if (this.loadedResources.indexOf(key) > -1 && !refresh) return

		// Make the call
		const filters: { [key: string]: boolean | string | string[] | ApiFilter } = {}
		const user = this.api ? this.api.user : null
		switch (key) {
			case 'Lines':
				// Check user
				if (user && user.concession_slugs) filters.for_concession_slugs = user.concession_slugs.join(',')

				const lines = yield* _await(
					this.api!.get('/lines', { castAs: Line, filters, include: ['concession'], pageSize: 1000 })
				)
				this.lines = lines.records as Line[]
				break

			case 'Journeys':
				// Check user
				filters.is_primary = true
				if (user && user.concession_slugs) filters.for_concession_slugs = user.concession_slugs.join(',')

				const journeys = yield* _await(
					this.api!.get('/journeys', {
						castAs: Journey,
						filters,
						include: ['toDestination', 'lineVersion'],
						pageSize: 5000,
					})
				)
				this.journeys = journeys.records as Journey[]
				break

			case 'StopSummaries':
				const stopSummaries = yield* _await(this.api!.get('/stop_summaries', { castAs: StopSummary, pageSize: 10000 }))
				this.stopSummaries = stopSummaries.records as StopSummary[]
				break

			default:
				throw `Resource loading not implemented: ${key}`
		}
	})
}
