import { InputAdornment } from '@material-ui/core'
import { observer } from 'mobx-react'
import * as React from 'react'
import { useHistory } from 'react-router-dom'
import BackButton from '~components/buttons/header-buttons/BackButton'
import CheckboxField from '~components/model-form/CheckboxField'
import ChipField from '~components/model-form/ChipField'
import Fieldset from '~components/model-form/Fieldset'
import ModelForm, { IModelFormRenderProps } from '~components/model-form/ModelForm'
import SelectField from '~components/model-form/SelectField'
import SimpleField from '~components/model-form/SimpleField'
import TranslationsField from '~components/model-form/TranslationsField'
import { IResourceRoutesFormProps } from '~components/routing/ResourceRoutes'
import PageUri from '~core/enums/pages'
import useStore from '~store/hooks/useStore'
import { Concession } from '~store/models/Concession'
import DateTimeField from '~components/model-form/DateTimeField'
import { LineConfiguration } from '~store/models/LineConfiguration'

// ███████╗ ██████╗ ██████╗ ███╗   ███╗
// ██╔════╝██╔═══██╗██╔══██╗████╗ ████║
// █████╗  ██║   ██║██████╔╝██╔████╔██║
// ██╔══╝  ██║   ██║██╔══██╗██║╚██╔╝██║
// ██║     ╚██████╔╝██║  ██║██║ ╚═╝ ██║
// ╚═╝      ╚═════╝ ╚═╝  ╚═╝╚═╝     ╚═╝
//

const PrimaryJourneyAlgorithmOptions = {
	LongestJourney: 'Langste route',
	MostTrips: 'Meeste ritten',
	P25: 'Alle routes met 25% of meer van de ritten'
}

const LineFormMain = observer(({ draft, resources, actionButtons }: IModelFormRenderProps<LineConfiguration>) => {
	const config = draft.data

	const concessions = resources.concessions as Concession[]
	const concessionOptions = React.useMemo(() => {
		const options: { [key: string]: string } = {}
		concessions.forEach(c => (options[c.slug] = c.slug))
		return options
	}, [concessions])

	return (
		<React.Fragment>
			{/* Algemeen */}
			<Fieldset title='Configuratie'>
				<SimpleField attribute={'name'} label={'Naam'} required />
				<SimpleField
					attribute={'category_key'}
					label={'Categorie'}
					field={{ help: 'De sleutel zoals deze in de vertalingen is opgenomen' }}
				/>
				<SelectField attribute={'concession_slug'} label={'Concessie'} required options={concessionOptions} />
				<SelectField
					attribute={'primary_journey_algorithm'}
					label={'Algoritme voor het bepalen van de primaire routes'}
					required
					options={PrimaryJourneyAlgorithmOptions}
				/>
				<SimpleField
					attribute={'away_destination_name'}
					grid={{ xs: 6 }}
					label={'Bestemming heen-richting'}
					field={{ help: 'Als je dit wilt overschrijven in de dienstregeling' }}
				/>
				<SimpleField attribute={'return_destination_name'} grid={{ xs: 6 }} label={'Bestemming terug-richting'} />

				<CheckboxField attribute={'is_hidden'} label={'Lijn niet tonen op website'} />
			</Fieldset>

			{/* Actions */}
			<Fieldset>{actionButtons}</Fieldset>
		</React.Fragment>
	)
})

// ██╗    ██╗██████╗  █████╗ ██████╗ ██████╗ ███████╗██████╗
// ██║    ██║██╔══██╗██╔══██╗██╔══██╗██╔══██╗██╔════╝██╔══██╗
// ██║ █╗ ██║██████╔╝███████║██████╔╝██████╔╝█████╗  ██████╔╝
// ██║███╗██║██╔══██╗██╔══██║██╔═══╝ ██╔═══╝ ██╔══╝  ██╔══██╗
// ╚███╔███╔╝██║  ██║██║  ██║██║     ██║     ███████╗██║  ██║
//  ╚══╝╚══╝ ╚═╝  ╚═╝╚═╝  ╚═╝╚═╝     ╚═╝     ╚══════╝╚═╝  ╚═╝
//

const LineForm = ({ id, newModel }: IResourceRoutesFormProps) => {
	const history = useHistory()
	const { api } = useStore()

	return (
		<ModelForm<LineConfiguration>
			model={LineConfiguration}
			modelId={id}
			newModel={newModel}
			allowDelete={false}
			paper
			header={{
				backUri: PageUri.Lines
			}}
			container
			loadResources={async record => {
				const { records: concessions } = await api.all(Concession)
				return { concessions }
			}}
			render={LineFormMain}
			renderTitle={({ record }) => (newModel ? 'Nieuw config' : `Lijn ${record.line_number}: ${record.name}`)}
		/>
	)
}
export default observer(LineForm)
