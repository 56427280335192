import { AnyModel, isModel } from 'mobx-keystone'
import inflection from 'inflection'
import { getApiUri, getResourceName } from './helpers'
import { ApiResource } from './deserialize'
import { mapValues, each } from 'lodash'
import { AnyApiModel } from './model/ApiModel'

export function serializeModel(model: AnyApiModel, attributes?: { [key: string]: any }): ApiResource {
	// Get attributes
	const attr = { ...attributes } || { ...model.$ }

	// Id is not part of that
	const id = attr.id
	if (attr.id !== undefined) delete attr.id

	// Created/updated at's we don't do.
	if (attr.created_at !== undefined) delete attr.created_at
	if (attr.updated_at !== undefined) delete attr.updated_at

	// Check relations
	const config = model.apiConfig()
	if (config.relationships) {
		// For now, just delete the values
		config.relationships.forEach(rel => {
			if (attr[rel.name] !== undefined) delete attr[rel.name]
		})
	}

	// Get type
	const resourceName = getResourceName(model)

	// Look at relationships
	each(attr, (value, key) => {
		// Model?
		if (isModel(value)) {
			// Translation?
			if (value.$modelType === 'Translation') {
				// Simply as object
				attr[key] = {
					nl: value.nl,
					en: value.en
				}
			} else {
				// Otherwise, we delete it for now.
				delete attr[key]
			}
		}
	})

	// Make it up
	const result: ApiResource = {
		attributes: attr,
		type: resourceName
	}
	if (id) result.id = id

	return result
}
