import { Container, TableCell, Chip, Paper, Box, Typography, Dialog } from '@material-ui/core'
import * as React from 'react'
import { useHistory, Link } from 'react-router-dom'
import RemoteView from '~components/remote-data/RemoteView'
import PageUri from '~core/enums/pages'
import { Map } from '~store/models/Map'
import AddBoxIcon from '@material-ui/icons/AddBox'
import Icon from '~components/gui/Icon'
import IconButton from '~components/buttons/IconButton'
import styled from 'styled-components'
import { capitalize } from 'lodash'
import { AllModules } from '~core/security/modules'
import MapEditorModal from '~components/map-editor/MapEditorModal'
import moment from 'moment'
import MapField from '~components/map-editor/MapField'
import ConfirmDialog from '~components/dialogs/ConfirmDialog'
import { deleteModel } from '~api/model/deleteModel'
import useStore from '~store/hooks/useStore'

export interface IMapIndexPageProps {}

const I = styled(Icon)`
	font-size: 16px;
	margin-right: 0.5em;
`

const MapIndexPage = (props: IMapIndexPageProps) => {
	const history = useHistory()
	const { api } = useStore()

	const [currentMap, setCurrentMap] = React.useState<Map | null>(null)
	const [mapToDelete, setMapToDelete] = React.useState<Map | null>(null)

	const handleDeleteConfirm = React.useCallback(async () => {
		await api.deleteModel(mapToDelete!)
		window.location.reload()
	}, [mapToDelete])

	return (
		<Container>
			{/* Modal */}
			{currentMap && (
				<MapEditorModal
					requireTitle
					open
					map={currentMap}
					onSave={map => {
						alert('De kaart is opgeslagen.')
						window.location.reload()
					}}
					onClose={() => setCurrentMap(null)}
				/>
			)}

			{/* Index */}
			<RemoteView
				title={'Losse kaarten voor chauffeurs'}
				intro={
					<Typography variant={'body2'}>
						Op deze pagina kun je losse kaarten aanmaken, als er van een omleiding nog geen melding is. Je kunt deze
						kaart later eventueel koppelen aan een melding.
					</Typography>
				}
				headerButtons={
					<React.Fragment>
						<IconButton
							icon={'plus-square'}
							edge={'end'}
							onClick={() => setCurrentMap(new Map({}))}
							color='default'
							title={'Nieuwe kaart'}
						/>
					</React.Fragment>
				}
				model={Map}
				apiOptions={{
					filters: {
						without_notification: true,
					},
					include: ['createdByUser'],
				}}
				variant={'table'}
				rowsPerPageOptions={[10]}
				defaultSort={{ attribute: 'id', direction: 'desc' }}
				attributes={[
					{ key: 'id', label: '#', sortable: true, defaultSortDirection: 'desc' },
					{ key: 'title', label: 'Titel', sortable: true },
					{ key: 'data', label: 'Kaart' },
					{ key: 'updated_at', label: 'Aangemaakt', sortable: true },
				]}
				table={{
					// onSelectRow: record => setCurrentMap(record),
					renderCell: {
						createdByUser: record => (
							<TableCell key='createdByUser'>{record.createdByUser && record.createdByUser.name}</TableCell>
						),
						data: record => (
							<TableCell key='data' style={{ width: 400 }}>
								<MapField
									horizontal
									mapType='driver'
									record={record}
									onOpen={map => setCurrentMap(map)}
									onDelete={map => setMapToDelete(map)}
								/>
							</TableCell>
						),
						updated_at: record => (
							<TableCell key='updated_at'>
								{moment(record.updated_at!).format('LLL')}
								<br />
								{record.createdByUser && <small>{record.createdByUser.name}</small>}
							</TableCell>
						),
					},
				}}
			/>
			<Dialog open={!!mapToDelete} onClose={() => setMapToDelete(null)}>
				<ConfirmDialog
					content={`Weet je zeker dat je de kaart '${mapToDelete ? mapToDelete.title : ''}' wilt verwijderen?`}
					onConfirm={handleDeleteConfirm}
					onCancel={() => setMapToDelete(null)}
				/>
			</Dialog>
		</Container>
	)
}
export default MapIndexPage
