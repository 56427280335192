import * as React from 'react'
import Button from '~components/buttons/Button'
import ColorPicker from '~components/forms/ColorPicker'
import { useMapModel } from '~store/hooks/useMapModel'
import Field from '../Field'
import { IToolProps } from '../IToolProps'
import ToolWindowButtons from '../ToolWindowButtons'
import { KeolisColors } from '../../../theme'

const FreeLineTool = ({ onClose, startDrawingPolyline }: IToolProps) => {
	// Resources
	const map = useMapModel()

	// State
	const [color, setColor] = React.useState<string>(KeolisColors[0])

	// Submit
	const start = React.useCallback(async () => {
		// Start drawing
		const latLngs = await startDrawingPolyline(color)

		// Add line
		map.addFreeLineLayer(latLngs, color)
		onClose()
	}, [startDrawingPolyline, color])

	return (
		<React.Fragment>
			<p>Kies een kleur voor de lijn en klik op de knop om op de kaart te tekenen.</p>

			<Field label={'Kleur'} select>
				<ColorPicker value={color} onChange={c => setColor(c)} />
			</Field>
			<ToolWindowButtons>
				<Button color='primary' onClick={start}>
					Begin met tekenen
				</Button>
			</ToolWindowButtons>
		</React.Fragment>
	)
}
export default FreeLineTool
