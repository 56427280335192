import * as React from 'react'
import { Map as ReactLeafletMap, ZoomControl } from 'react-leaflet'

import styled from 'styled-components'
import { IToolConfig } from '~core/enums/tools'
import useStore from '~store/hooks/useStore'
import LeafletMap from '../leaflet/Map'
import LayerMenu from './layer-menu/LayerMenu'
import Toolbar from './Toolbar'
import ToolWindow from './ToolWindow'

import { MapElement } from '~keolis-reizen-map-element/index'
import { Draw, LatLng, LeafletMouseEvent, Polyline } from 'leaflet'
import { observer } from 'mobx-react'
import { LeafletMapProvider } from '~components/leaflet/useLeafletMap'
import usePrevious from '~hooks/usePrevious'
import { MapModelProvider } from '~store/hooks/useMapModel'
import { Map } from '~store/models/Map'
import { KeolisColors } from '../../theme'
import DrawPolyline from './drawing/DrawPolyline'
import { IToolProps } from './IToolProps'
import MapListener from './MapListener'
import LayerProperties from './properties/LayerProperties'
import FreeLineTool from './tools/FreeLineTool'
import IconTool from './tools/IconTool'
import LabelTool from './tools/LabelTool'
import LineTool from './tools/LineTool'
import StopTool from './tools/StopTool'
import TemporaryStopTool from './tools/TemporaryStopTool'

const Layout = styled.div`
	display: flex;
	height: 100%;
`
const MapContainer = styled.div`
	flex: 1 1 auto;
	position: relative;
`
const MenuContainer = styled.div`
	flex: 0 0 360px;
	display: flex;
	flex-direction: column;
	/* background: {({ theme }) => theme.colors.background.main}; */
`

export interface IMapEditorProps {
	map?: Map
}

const MapEditor = (props: IMapEditorProps) => {
	// ██╗   ██╗ █████╗ ██████╗ ██╗ █████╗ ██████╗ ██╗     ███████╗███████╗
	// ██║   ██║██╔══██╗██╔══██╗██║██╔══██╗██╔══██╗██║     ██╔════╝██╔════╝
	// ██║   ██║███████║██████╔╝██║███████║██████╔╝██║     █████╗  ███████╗
	// ╚██╗ ██╔╝██╔══██║██╔══██╗██║██╔══██║██╔══██╗██║     ██╔══╝  ╚════██║
	//  ╚████╔╝ ██║  ██║██║  ██║██║██║  ██║██████╔╝███████╗███████╗███████║
	//   ╚═══╝  ╚═╝  ╚═╝╚═╝  ╚═╝╚═╝╚═╝  ╚═╝╚═════╝ ╚══════╝╚══════╝╚══════╝
	//

	// State
	const [leafletMap, setLeafletMap] = React.useState<ReactLeafletMap | null>(null)
	const [isPickingLocation, setIsPickingLocation] = React.useState<boolean>(false)
	const [isDrawing, setIsDrawing] = React.useState<boolean>(false)
	const [drawPolylineColor, setDrawPolylineColor] = React.useState<string>(KeolisColors[0])

	const interfaceIsHidden = React.useMemo(() => isDrawing || isPickingLocation, [isDrawing, isPickingLocation])

	/**
	 * MapData
	 */
	const map = React.useMemo<Map>(() => {
		if (props.map) return props.map
		return new Map({})
	}, [props.map])
	const layers = map.mapData.layers

	/**
	 * Resources
	 */
	const { resources } = useStore()

	/**
	 * Active tool
	 */
	const [activeTool, setActiveTool] = React.useState<IToolConfig | null>(null)
	const ToolComponent = React.useMemo<React.FC<IToolProps> | null>(() => {
		if (!activeTool) return null
		switch (activeTool.key) {
			case 'Line':
				return LineTool

			case 'Stop':
				return StopTool

			case 'TemporaryStop':
				return TemporaryStopTool

			case 'Label':
				return LabelTool

			case 'Icon':
				return IconTool

			case 'FreeLine':
				return FreeLineTool

			default:
				throw `Not implemented: tool for ${activeTool.key}`
		}
	}, [activeTool])

	//  █████╗  ██████╗████████╗██╗ ██████╗ ███╗   ██╗███████╗
	// ██╔══██╗██╔════╝╚══██╔══╝██║██╔═══██╗████╗  ██║██╔════╝
	// ███████║██║        ██║   ██║██║   ██║██╔██╗ ██║███████╗
	// ██╔══██║██║        ██║   ██║██║   ██║██║╚██╗██║╚════██║
	// ██║  ██║╚██████╗   ██║   ██║╚██████╔╝██║ ╚████║███████║
	// ╚═╝  ╚═╝ ╚═════╝   ╚═╝   ╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝
	//

	/**
	 * PICK LOCATION
	 */
	const pickLocation = React.useCallback(async (): Promise<LatLng> => {
		// Activate
		const promise: Promise<LatLng> = new Promise(resolve => {
			// Listen once to the map
			leafletMap!.leafletElement.once('click', (e: LeafletMouseEvent) => {
				// Do it.
				resolve(e.latlng)

				// Show again.
				setIsPickingLocation(false)
			})
		})
		setIsPickingLocation(true)

		return promise
	}, [leafletMap])

	/**
	 * START DRAWING
	 */
	const startDrawingPolygon = React.useCallback(
		async (color: string): Promise<LatLng[]> => {
			// Create the promise
			const promise: Promise<LatLng[]> = new Promise(resolve => {
				// Listen to the draw event once
				leafletMap!.leafletElement.once(Draw.Event.CREATED, (e: any) => {
					// Create layer from the layer
					if (e.layerType === 'polyline') {
						// Get the lat longs and colors
						const polyline = e.layer as Polyline
						resolve(polyline.getLatLngs() as LatLng[])

						// Done
						setIsDrawing(false)
					} else {
						console.warn(`${e.layerType} drawing not implemented`)
					}
				})
			})

			// Start it
			setDrawPolylineColor(color)
			setIsDrawing(true)
			return promise
		},
		[leafletMap]
	)

	// ███████╗███████╗███████╗███████╗ ██████╗████████╗███████╗
	// ██╔════╝██╔════╝██╔════╝██╔════╝██╔════╝╚══██╔══╝██╔════╝
	// █████╗  █████╗  █████╗  █████╗  ██║        ██║   ███████╗
	// ██╔══╝  ██╔══╝  ██╔══╝  ██╔══╝  ██║        ██║   ╚════██║
	// ███████╗██║     ██║     ███████╗╚██████╗   ██║   ███████║
	// ╚══════╝╚═╝     ╚═╝     ╚══════╝ ╚═════╝   ╚═╝   ╚══════╝
	//

	const previousLayerCount = usePrevious<number>(layers.length)
	React.useEffect(() => {
		// First layer added?
		if (leafletMap && layers.length > 0 && previousLayerCount === 0) {
			// Zoom to it.
			const bounds = layers[0].latLngBounds
			if (bounds) leafletMap.leafletElement.fitBounds(bounds)
		}
	}, [layers.length, leafletMap])

	return (
		<Layout>
			<MapModelProvider value={map}>
				<LeafletMapProvider value={leafletMap}>
					<MapContainer>
						{/* LEAFLET MAP */}
						<LeafletMap bounds={map.bounds} onRef={map => setLeafletMap(map)}>
							<MapElement interactive mapData={map.mapData} />
							<MapListener map={map} />
							{isDrawing && <DrawPolyline leafletMap={leafletMap!} color={drawPolylineColor} />}
							{!interfaceIsHidden && <ZoomControl position={'bottomleft'} />}
						</LeafletMap>

						{/* TOOLS */}
						<Toolbar
							map={map}
							hidden={interfaceIsHidden}
							activeTool={activeTool}
							onSelectTool={tool => setActiveTool(tool)}
						/>
						{activeTool && ToolComponent && (
							<ToolWindow hidden={interfaceIsHidden} tool={activeTool} onClose={() => setActiveTool(null)}>
								<ToolComponent
									onClose={() => setActiveTool(null)}
									pickLocation={pickLocation}
									startDrawingPolyline={startDrawingPolygon}
								/>
							</ToolWindow>
						)}

						{/* Properties */}
						<LayerProperties map={map} layers={map.mapData.selectedLayers} />
					</MapContainer>
					<MenuContainer>
						<LayerMenu />
					</MenuContainer>
				</LeafletMapProvider>
			</MapModelProvider>
		</Layout>
	)
}
export default observer(MapEditor)
