import { observer } from 'mobx-react'
import * as React from 'react'
import { useHistory } from 'react-router-dom'
import BackButton from '~components/buttons/header-buttons/BackButton'
import Fieldset from '~components/model-form/Fieldset'
import ModelForm, { IModelFormRenderProps } from '~components/model-form/ModelForm'
import TranslationsField from '~components/model-form/TranslationsField'
import { IResourceRoutesFormProps } from '~components/routing/ResourceRoutes'
import PageUri from '~core/enums/pages'
import useStore from '~store/hooks/useStore'
import { Concession } from '~store/models/Concession'
import { NavigationItem } from '~store/models/NavigationItem'
import SimpleField from '~components/model-form/SimpleField'
import IconSelect from '~components/select/IconSelect'
import SelectField from '~components/model-form/SelectField'

// ███████╗ ██████╗ ██████╗ ███╗   ███╗
// ██╔════╝██╔═══██╗██╔══██╗████╗ ████║
// █████╗  ██║   ██║██████╔╝██╔████╔██║
// ██╔══╝  ██║   ██║██╔══██╗██║╚██╔╝██║
// ██║     ╚██████╔╝██║  ██║██║ ╚═╝ ██║
// ╚═╝      ╚═════╝ ╚═╝  ╚═╝╚═╝     ╚═╝
//

const NavigationItemFormMain = observer(
	({ draft, resources, actionButtons }: IModelFormRenderProps<NavigationItem>) => {
		return (
			<React.Fragment>
				{/* Algemeen */}
				<Fieldset title={`Navigatie "${draft.data.navigation!.key}"`}>
					<TranslationsField attribute='title' required label='Titel' />
					<TranslationsField attribute='url' required label='URL' />
					<SimpleField attribute='icon' required label='Icoon' />
				</Fieldset>

				{/* Actions */}
				<Fieldset>{actionButtons}</Fieldset>
			</React.Fragment>
		)
	}
)

// ██╗    ██╗██████╗  █████╗ ██████╗ ██████╗ ███████╗██████╗
// ██║    ██║██╔══██╗██╔══██╗██╔══██╗██╔══██╗██╔════╝██╔══██╗
// ██║ █╗ ██║██████╔╝███████║██████╔╝██████╔╝█████╗  ██████╔╝
// ██║███╗██║██╔══██╗██╔══██║██╔═══╝ ██╔═══╝ ██╔══╝  ██╔══██╗
// ╚███╔███╔╝██║  ██║██║  ██║██║     ██║     ███████╗██║  ██║
//  ╚══╝╚══╝ ╚═╝  ╚═╝╚═╝  ╚═╝╚═╝     ╚═╝     ╚══════╝╚═╝  ╚═╝
//

const NavigationItemForm = ({ id, newModel }: IResourceRoutesFormProps) => {
	const history = useHistory()
	const { api } = useStore()

	return (
		<ModelForm<NavigationItem>
			model={NavigationItem}
			modelId={id}
			newModel={newModel}
			apiOptions={{
				include: ['navigation']
			}}
			onCreated={record => history.push(`${PageUri.NavigationItems}/${record.id}`)}
			onDeleted={record => history.push(PageUri.NavigationItems)}
			paper
			header={{
				backUri: PageUri.NavigationItems
			}}
			container
			render={NavigationItemFormMain}
			renderTitle={({ record }) => (newModel ? 'Nieuw navigation-item' : `Navigatie: ${record.title.nl}`)}
		/>
	)
}
export default observer(NavigationItemForm)
