import { intersection } from 'lodash'
import { FontAwesomeIconName } from '~core/enums/icons'
import PageUri from '~core/enums/pages'
import { User } from '~store/models/User'
import { Role } from './roles'

export type Module = {
	roles: Role[]
	title: string
	description: string
	uri: string

	icon?: FontAwesomeIconName
}

export const AllModules: Module[] = [
	// Producten
	{
		roles: ['producten'],
		title: 'Reisproducten',
		uri: PageUri.TravelProducts,
		description: 'Beheer reisproducten en productadvies',
		icon: 'shopping-cart',
	},

	// Meldingen
	{
		roles: ['admin', 'meldingen'],
		title: 'Meldingen',
		uri: PageUri.Notifications,
		description: 'Beheer meldingen en kaartjes voor reizigers en chauffeurs',
		icon: 'flag',
	},
	// Chauffeurkaarten
	{
		roles: ['admin', 'meldingen'],
		title: 'Chauffeurkaarten',
		uri: PageUri.Maps,
		description: 'Beheer losse kaartjes voor chauffeurs bij omleidingen, etc.',
		icon: 'bus',
	},

	// Lijnen
	{
		roles: ['vervoer'],
		title: 'Lijnen',
		uri: PageUri.Lines,
		description: 'Beheer lijn configuraties',
		icon: 'route',
	},

	// POI's
	{
		roles: ['content'],
		title: "POI's",
		uri: PageUri.Places,
		description: 'Beheer attracties en bestemmingen op de kaart',
		icon: 'map-marker-alt',
	},

	// Teksten
	{
		roles: ['content'],
		title: 'Website-teksten',
		uri: PageUri.Lang,
		description: 'Wijzig teksten van de Keolis Reizen websites',
		icon: 'file-alt',
	},

	// Navigatie
	{
		roles: ['content'],
		title: 'Navigatie',
		uri: PageUri.NavigationItems,
		description: 'Wijzig de navigatie-items van de Keolis Reizen websites',
		icon: 'list-alt',
	},

	// Users
	{
		roles: ['admin'],
		title: 'Gebruikers',
		uri: PageUri.Users,
		description: 'Beheer toegang tot het Keolis Reizen CMS',
		icon: 'users',
	},
]

export function getModulesForUser(user: User): Module[] {
	// Filter
	const roles = user.role_names
	return AllModules.filter(mod => intersection(roles, mod.roles).length > 0)
}
