import { LatLngBounds, LatLng } from 'leaflet';
import { flatten } from 'lodash';

//@ts-ignore
import * as polyline from '@mapbox/polyline';

export function latLngBoundsFromBBox(bbox: string): LatLngBounds {
  const [lng1, lat1 = null, lng2 = null, lat2 = null] = bbox
    .split(/,/)
    .map(v => parseFloat(v));
  return new LatLngBounds([
    [lat1!, lng1!],
    [lat2!, lng2!],
  ]);
}

export function getLatLngsFromPolyline(...polylines: string[]) {
  return flatten(polylines.map(p => polyline.decode(p)));
}

export function getPolylineFromLatLngs(latLngs: LatLng[]): string {
  return polyline.encode(latLngs.map(x => [x.lat, x.lng]));
}
