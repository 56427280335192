import * as React from 'react'
import styled from 'styled-components'
import classNames from 'classnames'
import useStore from '~store/hooks/useStore'
import { useMapModel } from '~store/hooks/useMapModel'
import { StopSummary } from '~store/models/StopSummary'
import { KeolisColors } from '../../../theme'
import { IToolProps } from '../IToolProps'
import Field from '../Field'
import StopSelect from '~components/select/StopSelect'
import ColorPicker, { Transparent } from '~components/forms/ColorPicker'
import ToolWindowButtons from '../ToolWindowButtons'
import Button from '~components/buttons/Button'
import { LatLng } from 'leaflet'
import LocationPicker from '../LocationPicker'
import { Input } from '@material-ui/core'

const LabelTool = ({ onClose, pickLocation }: IToolProps) => {
	// Resources
	const map = useMapModel()

	// State
	const [latLng, setLatLng] = React.useState<LatLng | undefined>()
	const [text, setText] = React.useState<string>('')
	const [color, setColor] = React.useState<string>('#333333')
	const [backgroundColor, setBackgroundColor] = React.useState<string>(Transparent)

	// Validation
	const formIsComplete = React.useMemo(() => text.length > 0 && latLng && color && backgroundColor, [
		text,
		latLng,
		color,
		backgroundColor
	])

	// Submit
	const submit = React.useCallback(() => {
		// Do it.
		map.addLabelLayer(latLng!, text, color, backgroundColor)
		onClose()
	}, [map, latLng, text, color, backgroundColor, onClose])

	return (
		<React.Fragment>
			<p>Kies een locatie op de kaart en vul een tekst in om een label toe te voegen.</p>

			<Field label={'Locatie'} select>
				<LocationPicker value={latLng} onChange={l => setLatLng(l)} pickLocation={pickLocation} />
			</Field>
			<Field label={'Tekst'}>
				<Input value={text} multiline onChange={e => setText(e.target.value)} />
			</Field>
			<Field label={'Kleur'} select>
				<ColorPicker value={color} onChange={c => setColor(c)} />
			</Field>
			<Field label={'Achtergrond-kleur'} select>
				<ColorPicker value={backgroundColor} onChange={c => setBackgroundColor(c)} allowTransparent />
			</Field>
			<ToolWindowButtons>
				<Button color='primary' disabled={!formIsComplete} onClick={submit}>
					Toevoegen
				</Button>
			</ToolWindowButtons>
		</React.Fragment>
	)
}
export default LabelTool
