import * as React from 'react'
import styled from 'styled-components'
import Select from './Select'
import SelectOption from './SelectOption'
import { IVehicleType, getVehicleIcon, getVehicleTypeTranslation } from '~core/enums/vehicles'
import { StopSummary } from '~store/models/StopSummary'

// ███████╗████████╗██╗   ██╗██╗     ███████╗██████╗
// ██╔════╝╚══██╔══╝╚██╗ ██╔╝██║     ██╔════╝██╔══██╗
// ███████╗   ██║    ╚████╔╝ ██║     █████╗  ██║  ██║
// ╚════██║   ██║     ╚██╔╝  ██║     ██╔══╝  ██║  ██║
// ███████║   ██║      ██║   ███████╗███████╗██████╔╝
// ╚══════╝   ╚═╝      ╚═╝   ╚══════╝╚══════╝╚═════╝
//

//  ██████╗ ██████╗ ███╗   ███╗██████╗  ██████╗ ███╗   ██╗███████╗███╗   ██╗████████╗
// ██╔════╝██╔═══██╗████╗ ████║██╔══██╗██╔═══██╗████╗  ██║██╔════╝████╗  ██║╚══██╔══╝
// ██║     ██║   ██║██╔████╔██║██████╔╝██║   ██║██╔██╗ ██║█████╗  ██╔██╗ ██║   ██║
// ██║     ██║   ██║██║╚██╔╝██║██╔═══╝ ██║   ██║██║╚██╗██║██╔══╝  ██║╚██╗██║   ██║
// ╚██████╗╚██████╔╝██║ ╚═╝ ██║██║     ╚██████╔╝██║ ╚████║███████╗██║ ╚████║   ██║
//  ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝      ╚═════╝ ╚═╝  ╚═══╝╚══════╝╚═╝  ╚═══╝   ╚═╝
//
export interface IStopSelectProps {
	stops: StopSummary[]
	value?: StopSummary
	onChange?: (stop: StopSummary) => any
}

const mapOption = (stop: StopSummary) => ({
	label: `${stop.code} ${stop.name} ${stop.city}`,
	value: stop.code,
	stop
})

const StopSelect = (props: IStopSelectProps) => {
	const { stops, value, onChange } = props

	const options = React.useMemo(() => (stops ? stops.map(mapOption) : []), [stops])

	let i = 0
	const resultLimit = 20
	return (
		<Select
			onChange={v => v && onChange && onChange(v.stop as StopSummary)}
			value={value ? mapOption(value) : null}
			options={options}
			filterOption={({ label }, query) => label.toLowerCase().indexOf(query.toLowerCase()) >= 0 && i++ < resultLimit}
			onInputChange={() => {
				i = 0
			}}
			renderOption={option => {
				const stop: StopSummary = option.data.stop
				return (
					<SelectOption
						meta={
							[
								// getVehicleTypeTranslation(StopSummary.vehicletype),
								// getScheduleTypeTranslation(StopSummary.scheduletype)
							]
						}
						lines={stop.journey_information.map(info => ({
							number: info.public_number,
							type: info.type,
							destination: info.destination
						}))}
						title={`${stop.name} (${stop.code})`}
					/>
				)
			}}
			placeholder={'Zoek een halte'}
		/>
	)
}
export default StopSelect
