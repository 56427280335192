import { LatLngBounds } from 'leaflet';
import { computed } from 'mobx';
import {
  Model,
  model,
  modelAction,
  tProp,
  types,
  UndoManager,
  undoMiddleware,
} from 'mobx-keystone';
import { LayerType } from '../enums/layers';
import { latLngBoundsFromBBox } from '../utils/geo';
import { Layer } from './Layer';

@model('@mapElement/MapData')
export class MapData extends Model({
  layers: tProp(types.array(types.model<Layer>(Layer)), () => []),
}) {
  @computed
  get undoManager(): UndoManager {
    return undoMiddleware(this);
  }

  // ██████╗ ██████╗  ██████╗ ██████╗ ███████╗██████╗ ████████╗██╗███████╗███████╗
  // ██╔══██╗██╔══██╗██╔═══██╗██╔══██╗██╔════╝██╔══██╗╚══██╔══╝██║██╔════╝██╔════╝
  // ██████╔╝██████╔╝██║   ██║██████╔╝█████╗  ██████╔╝   ██║   ██║█████╗  ███████╗
  // ██╔═══╝ ██╔══██╗██║   ██║██╔═══╝ ██╔══╝  ██╔══██╗   ██║   ██║██╔══╝  ╚════██║
  // ██║     ██║  ██║╚██████╔╝██║     ███████╗██║  ██║   ██║   ██║███████╗███████║
  // ╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚═╝     ╚══════╝╚═╝  ╚═╝   ╚═╝   ╚═╝╚══════╝╚══════╝
  //
  getLatLngBounds(): LatLngBounds | null {
    // Combine children
    let bounds: LatLngBounds | null = null;
    this.layers.forEach(layer => {
      const layerBounds = layer.latLngBounds;
      if (!layerBounds) return;
      if (bounds) {
        bounds.extend(layerBounds);
      } else {
        bounds = latLngBoundsFromBBox(layerBounds.toBBoxString());
      }
    });
    return bounds;
  }

  getAllStopLayers(): Layer[] {
    return this.getAllLayers(layer => layer.type === LayerType.Stop);
  }

  getAllLayers(
    predicate?: (layer: Layer) => boolean,
    allowSublayersForNonPredicatedLayers: boolean = true
  ): Layer[] {
    // Go through 'em all
    const all: Layer[] = [];
    this.layers.forEach(sub => {
      const matches = predicate ? predicate(sub) : true;
      if (matches) all.push(sub);
      if (matches || allowSublayersForNonPredicatedLayers) {
        const subs = sub.getAllSubLayers(predicate);
        subs.forEach(l => all.push(l));
      }
    });

    return all;
  }

  @computed
  get hoveredLayers(): Layer[] {
    return this.getAllLayers(l => l.isHovering);
  }

  @computed
  get selectedLayers(): Layer[] {
    return this.getAllLayers(l => l.isSelected);
  }

  @modelAction
  hoverLayer(layer: Layer | null) {
    // Deselect all others
    this.hoveredLayers.forEach(l => l.setHover(false));

    // Select given
    if (layer) layer.setHover(true);
  }

  @modelAction
  selectLayer(layer: Layer | null) {
    // Deselect all others
    this.selectedLayers.forEach(l => l.setSelected(false));

    // Select given
    if (layer) layer.setSelected(true);
  }

  @modelAction
  selectLayerRange(from: Layer, to: Layer): Layer[] {
    // Get all (expanded) layers
    const layers = this.getAllLayers(
      layer => !layer.parentLayer || layer.parentLayer.isExpanded
    );

    // Find the indexes
    const fromIndex = layers.indexOf(from);
    const toIndex = layers.indexOf(to);
    const selection = layers.slice(
      Math.min(fromIndex, toIndex),
      Math.max(fromIndex, toIndex) + 1
    );

    // Select 'em
    selection.forEach(layer => layer.setSelected(true));

    return selection;
  }

  @modelAction
  addLayer(layer: Layer) {
    this.layers = [...this.layers, layer];
  }

  @modelAction
  insertLayer(layer: Layer, insertAfter: Layer) {
    // Get index
    const index = this.layers.indexOf(insertAfter) || 0;
    this.layers.splice(index, 0, layer);
  }

  @modelAction
  deleteLayer(layer: Layer) {
    this.layers = this.layers.filter(l => l !== layer);
  }
}
