import * as React from 'react'
import { uniq } from 'lodash'
import { createStyles, makeStyles, Theme, Input, Paper, Chip, InputAdornment } from '@material-ui/core'
import { observer } from 'mobx-react'
import Icon from '~components/gui/Icon'

// ███████╗████████╗██╗   ██╗██╗     ███████╗███████╗
// ██╔════╝╚══██╔══╝╚██╗ ██╔╝██║     ██╔════╝██╔════╝
// ███████╗   ██║    ╚████╔╝ ██║     █████╗  ███████╗
// ╚════██║   ██║     ╚██╔╝  ██║     ██╔══╝  ╚════██║
// ███████║   ██║      ██║   ███████╗███████╗███████║
// ╚══════╝   ╚═╝      ╚═╝   ╚══════╝╚══════╝╚══════╝
//

const styles = ({ palette, spacing }: Theme) =>
	createStyles({
		main: {},
		paper: {
			marginTop: spacing(2),
			display: 'flex',
			flexWrap: 'wrap',
			padding: spacing(0.5)
		},
		chip: {
			margin: spacing(0.5)
		}
	})
const useStyles = makeStyles(styles)

//  ██████╗ ██████╗ ███╗   ███╗██████╗  ██████╗ ███╗   ██╗███████╗███╗   ██╗████████╗
// ██╔════╝██╔═══██╗████╗ ████║██╔══██╗██╔═══██╗████╗  ██║██╔════╝████╗  ██║╚══██╔══╝
// ██║     ██║   ██║██╔████╔██║██████╔╝██║   ██║██╔██╗ ██║█████╗  ██╔██╗ ██║   ██║
// ██║     ██║   ██║██║╚██╔╝██║██╔═══╝ ██║   ██║██║╚██╗██║██╔══╝  ██║╚██╗██║   ██║
// ╚██████╗╚██████╔╝██║ ╚═╝ ██║██║     ╚██████╔╝██║ ╚████║███████╗██║ ╚████║   ██║
//  ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝      ╚═════╝ ╚═╝  ╚═══╝╚══════╝╚═╝  ╚═══╝   ╚═╝
//
export interface IChipInputProps {
	value: string[] | null
	onChange: (value: string[]) => any

	addOnSpace?: boolean
	addOnEnter?: boolean

	allowDuplicates?: boolean
	splitInputOnSpace?: boolean
}

const ChipInput = ({
	value,
	onChange,
	addOnSpace = true,
	addOnEnter = true,
	allowDuplicates = false,
	splitInputOnSpace = true
}: IChipInputProps) => {
	// Styles
	const classes = useStyles()

	// New item
	const [newItem, setNewItem] = React.useState<string>('')
	const onKey = React.useCallback(
		(e: React.KeyboardEvent<HTMLInputElement>) => {
			// Add?
			if ((newItem.trim().length > 0 && addOnSpace && e.key === ' ') || (addOnEnter && e.key === 'Enter')) {
				let newValue = value ? [...value] : []
				newValue = [...newValue, ...(splitInputOnSpace ? newItem.trim().split(/\s+/) : [newItem])]

				if (!allowDuplicates) newValue = uniq(newValue)
				onChange(newValue)
				setNewItem('')
			}
		},
		[addOnSpace, addOnEnter, newItem, onChange, allowDuplicates]
	)

	const handleDelete = (index: number) => {
		const newValue = value ? [...value] : []
		newValue.splice(index, 1)
		onChange(newValue)
	}

	return (
		<React.Fragment>
			{value && value.length > 0 && (
				<Paper className={classes.paper} elevation={0}>
					{value.map((label, index) => (
						<Chip
							key={`${label}@${index}`}
							label={label}
							onDelete={() => handleDelete(index)}
							className={classes.chip}
						/>
					))}
				</Paper>
			)}
			<Input
				startAdornment={
					<InputAdornment position={'start'}>
						<Icon name={'plus-circle'} style={{ opacity: 0.5 }} />
					</InputAdornment>
				}
				value={newItem}
				onChange={e => setNewItem(e.target.value)}
				onKeyDown={onKey}
			/>
		</React.Fragment>
	)
}
export default observer(ChipInput)
