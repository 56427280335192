import { Model, model, tProp, modelAction, modelFlow, _async, _await, types } from 'mobx-keystone'
import { ValidationRule, ModelValidationConfig } from '~api/model/validateModel'
import { computed } from 'mobx'
import { Translation } from './utility/Translation'
import moment, { Moment } from 'moment'
import { apiModel, ApiModel, ApiModelConfig } from '~api/model/ApiModel'
import { Map } from './Map'
import Config from '~core/config/Config'

enum NotificationSource {
	Application = 'Application',
	Kv15 = 'Kv15',
}
export const NotificationSources = {
	Application: 'Applicatie',
	Kv15: 'KV15',
}

const nullableArray = (type = types.string) => tProp(types.maybeNull(types.array(type)))

@model('Notification')
export class Notification extends ApiModel({
	//  █████╗ ████████╗████████╗██████╗ ██╗██████╗ ██╗   ██╗████████╗███████╗███████╗
	// ██╔══██╗╚══██╔══╝╚══██╔══╝██╔══██╗██║██╔══██╗██║   ██║╚══██╔══╝██╔════╝██╔════╝
	// ███████║   ██║      ██║   ██████╔╝██║██████╔╝██║   ██║   ██║   █████╗  ███████╗
	// ██╔══██║   ██║      ██║   ██╔══██╗██║██╔══██╗██║   ██║   ██║   ██╔══╝  ╚════██║
	// ██║  ██║   ██║      ██║   ██║  ██║██║██████╔╝╚██████╔╝   ██║   ███████╗███████║
	// ╚═╝  ╚═╝   ╚═╝      ╚═╝   ╚═╝  ╚═╝╚═╝╚═════╝  ╚═════╝    ╚═╝   ╚══════╝╚══════╝
	//
	id: tProp(types.maybe(types.number)),
	source: tProp(
		types.enum<NotificationSource>(NotificationSource),
		(): NotificationSource => NotificationSource.Application
	),
	source_key: tProp(types.maybeNull(types.string)),

	// Content
	title: tProp(types.model<Translation>(Translation)),
	body: tProp(types.model<Translation>(Translation)),
	source_data: tProp(types.maybeNull(types.unchecked())),

	// Filter
	for_line_numbers: nullableArray(),
	for_line_directions: nullableArray(),
	for_concession_slugs: nullableArray(),
	for_stop_codes: nullableArray(),

	// Dates
	visible_from: tProp(types.maybeNull(types.string)),
	visible_until: tProp(types.maybeNull(types.string)),
	valid_from: tProp(types.maybeNull(types.string)),
	valid_until: tProp(types.maybeNull(types.string)),

	// Maps
	driver_map_id: tProp(types.maybeNull(types.number), null),
	public_map_id: tProp(types.maybeNull(types.number), null),
	driverMap: tProp(types.maybeNull(types.model<Map>(Map)), null),
	publicMap: tProp(types.maybeNull(types.model<Map>(Map)), null),

	// Creation
	created_by_user_email: tProp(types.maybeNull(types.string)),
	created_at: tProp(types.maybeNull(types.string)),
	updated_at: tProp(types.maybeNull(types.string)),
}) {
	@modelAction
	setDriverMap(value: Map | null) {
		this.driverMap = value
		this.driver_map_id = value ? value.id || null : null
	}
	@modelAction
	setPublicMap(value: Map | null) {
		this.publicMap = value
		this.public_map_id = value ? value.id || null : null
	}

	//  ██████╗ ██████╗ ███╗   ███╗██████╗ ██╗   ██╗████████╗███████╗██████╗
	// ██╔════╝██╔═══██╗████╗ ████║██╔══██╗██║   ██║╚══██╔══╝██╔════╝██╔══██╗
	// ██║     ██║   ██║██╔████╔██║██████╔╝██║   ██║   ██║   █████╗  ██║  ██║
	// ██║     ██║   ██║██║╚██╔╝██║██╔═══╝ ██║   ██║   ██║   ██╔══╝  ██║  ██║
	// ╚██████╗╚██████╔╝██║ ╚═╝ ██║██║     ╚██████╔╝   ██║   ███████╗██████╔╝
	//  ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝      ╚═════╝    ╚═╝   ╚══════╝╚═════╝
	//

	@computed
	get isVisibleNow(): boolean {
		const now = moment()
		if (this.visibleFromMoment && now.isBefore(this.visibleFromMoment)) return false
		if (this.visibleUntilMoment && now.isAfter(this.visibleUntilMoment)) return false
		return true
	}

	@computed
	get visibleFromMoment(): Moment | null {
		return this.visible_from ? moment(this.visible_from) : null
	}

	@computed
	get visibleUntilMoment(): Moment | null {
		return this.visible_until ? moment(this.visible_until) : null
	}

	@computed
	get formattedDates(): string {
		if (this.visibleFromMoment && this.visibleUntilMoment)
			return `${this.visibleFromMoment.format('LL')} - ${this.visibleUntilMoment.format('LL')}`
		if (this.visibleFromMoment) return `Vanaf ${this.visibleFromMoment.format('LL')}`
		if (this.visibleUntilMoment) return `Tot ${this.visibleUntilMoment.format('LL')}`
		return 'Altijd'
	}

	@computed
	get shortFormattedDates(): string {
		if (this.visibleFromMoment && this.visibleUntilMoment)
			return `${this.visibleFromMoment.format('L')} - ${this.visibleUntilMoment.format('L')}`
		if (this.visibleFromMoment) return `Vanaf ${this.visibleFromMoment.format('L')}`
		if (this.visibleUntilMoment) return `Tot ${this.visibleUntilMoment.format('L')}`
		return 'Altijd'
	}

	@computed
	get publicUrl(): string {
		return `${Config.publicUrl}/nl/${
			this.for_concession_slugs && this.for_concession_slugs.length > 0 ? this.for_concession_slugs[0] : 'keolis'
		}/meldingen/${this.id}`
	}

	//  ██████╗ ██████╗ ███╗   ██╗███████╗██╗ ██████╗ ██╗   ██╗██████╗  █████╗ ████████╗██╗ ██████╗ ███╗   ██╗
	// ██╔════╝██╔═══██╗████╗  ██║██╔════╝██║██╔════╝ ██║   ██║██╔══██╗██╔══██╗╚══██╔══╝██║██╔═══██╗████╗  ██║
	// ██║     ██║   ██║██╔██╗ ██║█████╗  ██║██║  ███╗██║   ██║██████╔╝███████║   ██║   ██║██║   ██║██╔██╗ ██║
	// ██║     ██║   ██║██║╚██╗██║██╔══╝  ██║██║   ██║██║   ██║██╔══██╗██╔══██║   ██║   ██║██║   ██║██║╚██╗██║
	// ╚██████╗╚██████╔╝██║ ╚████║██║     ██║╚██████╔╝╚██████╔╝██║  ██║██║  ██║   ██║   ██║╚██████╔╝██║ ╚████║
	//  ╚═════╝ ╚═════╝ ╚═╝  ╚═══╝╚═╝     ╚═╝ ╚═════╝  ╚═════╝ ╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚═╝ ╚═════╝ ╚═╝  ╚═══╝
	//

	public apiConfig(): ApiModelConfig {
		return {
			relationships: [
				{
					name: 'driverMap',
					type: 'BelongsTo',
					model: Map,
				},
				{
					name: 'publicMap',
					type: 'BelongsTo',
					model: Map,
				},
			],
			apiUri: '/admin/notifications',
		}
	}
}
