import * as React from 'react'
import { Container } from '@material-ui/core'
import { Skeleton, Alert } from '@material-ui/lab'
import PageHeader from '~components/layout/pages/PageHeader'
import Axios from 'axios'
import Config from '~core/config/Config'
import { getErrorMessage } from '~api/helpers'
import { set } from 'lodash'
import YAML from 'js-yaml'
import TranslationEditor, { TranslationEditorNode } from '~components/translations/TranslationEditor'
import useStore from '~store/hooks/useStore'

export interface ILangPageProps {}

const LangPage = (props: ILangPageProps) => {
	// State
	const [lang, setLang] = React.useState<{ [key: string]: any } | null>(null)
	const [error, setError] = React.useState<string | null>(null)
	const { api } = useStore()

	// Load
	const loadLangs = React.useCallback(async () => {
		// Get em all
		setError(error)
		const promises = ['nl', 'en'].map(lang => {
			return Axios.get(`${Config.langUrl}/${lang}/website-${Config.operator}.yaml`, {
				params: {
					cacheBust: new Date().getTime(),
				},
			})
		})

		// Error
		try {
			// Wait for all languages
			const result = await Promise.all(promises)

			// Parse 'em
			const [nl, en] = result.map(yaml => YAML.safeLoad(yaml.data, 'utf-8'))

			// Set data
			setLang({ nl, en })
		} catch (error) {
			setError(getErrorMessage(error))
		}
	}, [])
	React.useEffect(() => {
		loadLangs()
	}, [])

	// Save
	const handleSubmit = React.useCallback(
		async (dirty: TranslationEditorNode[]) => {
			// Apply dirties to lang
			const changed = { ...lang }
			dirty.forEach(node => [
				Object.keys(node.translations!).map(l => {
					set(changed, `${l}.${node.path.join('.')}`, node.translations![l])
				}),
			])

			// Convert back to YAML
			const yamls: any = {}
			Object.keys(changed).forEach(l => {
				yamls[l] = YAML.safeDump(changed[l], {
					indent: 2,
				})
			})

			// Save it
			await api.call('/admin/lang', 'put', {
				data: yamls,
				deserialize: false,
			})

			// Apply changed
			setLang(changed)
			return true
		},
		[lang, api]
	)

	return (
		<React.Fragment>
			<PageHeader title='Website-teksten' isLoading={!lang} />
			{error && <Alert severity={'error'}>{error}</Alert>}
			{lang ? <TranslationEditor onSubmit={handleSubmit} data={lang} /> : <Skeleton height={1230} />}
		</React.Fragment>
	)
}
export default LangPage
