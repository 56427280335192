import { Model, model, tProp, types } from 'mobx-keystone'

@model('StopVersion')
export class StopVersion extends Model({
	id: tProp(types.number),
	stop_id: tProp(types.number),
	stop_area_id: tProp(types.number),
	stop_area_version_id: tProp(types.number),
	stop_summary_id: tProp(types.maybeNull(types.number)),
	valid_from: tProp(types.string),
	valid_until: tProp(types.string),
	is_current_version: tProp(types.boolean),
	code: tProp(types.string),
	name: tProp(types.string),
	city: tProp(types.string),
	can_get_in: tProp(types.boolean),
	can_get_out: tProp(types.boolean),
	is_wheelchair_accessible: tProp(types.maybeNull(types.boolean)),
	latitude: tProp(types.number),
	longitude: tProp(types.number)
}) {}
